import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Ng5SliderModule } from 'ng5-slider';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularSplitModule } from 'angular-split';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonDirectivesModule } from 'src/app/directives/common-directives.module';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/services/common-services/commonmethods.service';
import { AddMileageComponent } from './add-mileage/add-mileage.component';
import { OrganizationStructureComponent } from './organization-structure/organization-structure.component';
import { MileageComponent } from './mileage/mileage.component';
import { SidebarmenuComponent } from './sidebarmenu/sidebarmenu.component';
import { FinancePoliciesComponent } from './finance-policies/finance-policies.component';
import { ApprovalWorkflowComponent } from './approval-workflow/approval-workflow.component';
import { AccountConfigurationComponent } from './account-configuration/account-configuration.component';
import { AddMasterCategoryComponent } from './add-master-category/add-master-category.component';
import { AddMasterMerchantsComponent } from './add-master-merchants/add-master-merchants.component';
import { ManageNotificationsComponent } from './manage-notifications/manage-notifications.component';
import { ExpenseCategoryComponent } from './expense-category/expense-category.component';
import { MerchantsComponent } from './merchants/merchants.component';
import { ThemesComponent } from './themes/themes.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { IonicModule } from '@ionic/angular';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { ColorPickerModule } from 'ngx-color-picker';
// import { IonicSelectableModule } from 'ionic-selectable';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ExchangeRateComponent } from './exchange-rate/exchange-rate.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { AddExchangeRateComponent } from './add-exchange-rate/add-exchange-rate.component';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from "@ngx-translate/core";
import { MyPipesModule } from "../../pipes/my-pipes.module";
import { AddPolicyComponent } from './add-policy/add-policy.component';
import { AddApprovalWorkflowComponent } from './add-approval-workflow/add-approval-workflow.component';
import { AddLCComponent } from './add-lc/add-lc.component';
import { LocalConveyancePolicyComponent } from './local-conveyance-policy/local-conveyance-policy.component';
import { EmailConfigurationsComponent } from './email-configurations/email-configurations.component';
import { EmailConfigurationsEditComponent } from './email-configurations-edit/email-configurations-edit.component';
import { GeneralPolicyComponent } from './general-policy/general-policy.component';
import { TravelRequestComponent } from './travel-request/travel-request.component';
import { FinancialWorkflowComponent } from './financial-workflow/financial-workflow.component';
import { AddFinancialWorkflowComponent } from './add-financial-workflow/add-financial-workflow.component';
import { TaxCodeComponent } from './tax-code/tax-code.component';
import { AddTaxComponent } from './add-tax/add-tax.component';
import { FinanceIntegrationCodeComponent } from './finance-integration-code/finance-integration-code.component';
import { AddFinanceIntegrationComponent } from './add-finance-integration/add-finance-integration.component';
import { HsnCodeComponent } from './hsn-code/hsn-code.component';
import { AddHsnCodeComponent } from './add-hsn-code/add-hsn-code.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { ApprovalpoliciesComponent } from './approvalpolicies/approvalpolicies.component';
import { AddApprovalPoliciesComponent } from './add-approval-policies/add-approval-policies.component';
import { BannersComponent } from './banners/banners.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgSelectModule } from '@ng-select/ng-select';
import { BudgetComponent } from './budget/budget.component';
import { AddBudgetComponent } from './budget/add-budget/add-budget.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { PerdiemTypesComponent } from './perdiem-types/perdiem-types.component';
import { AddPerdiemTypesComponent } from './perdiem-types/add-perdiem-types/add-perdiem-types.component';
import { CityGroupsComponent } from './city-groups/city-groups.component';
import { AddCityGroupComponent } from './city-groups/add-city-group/add-city-group.component';


const routes: Routes = [
    {
        path: '', component: SidebarmenuComponent,
        children: [
            {
                path: 'budget', children: [
                    { path: '', component: BudgetComponent },
                    { path: 'add', component: AddBudgetComponent }
                ]
            },
            {
                path: 'financepolicy', children: [
                    { path: '', component: FinancePoliciesComponent },
                    { path: 'add', component: AddPolicyComponent }
                    // {path: 'update/:id/:guid', component: AddMasterCategoryComponent}
                ]
            },
            {
                path: 'generalpolicy', children: [
                    { path: '', component: GeneralPolicyComponent }
                ]
            },
            {
                path: 'localconveyancepolicy', children: [
                    { path: '', component: LocalConveyancePolicyComponent },
                    { path: 'add', component: AddLCComponent },
                ]
            },
            {
                path: 'approvalworkflow', children: [
                    { path: '', component: ApprovalWorkflowComponent },
                    { path: 'add', component: AddApprovalWorkflowComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddApprovalWorkflowComponent }
                ]
            },
            {
                path: 'approvalpolicies', children: [
                    { path: '', component: ApprovalpoliciesComponent },
                    { path: 'add', component: AddApprovalPoliciesComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddApprovalPoliciesComponent }
                ]
            },
            {
                path: 'financialworkflow', children: [
                    { path: '', component: FinancialWorkflowComponent },
                    { path: 'add', component: AddFinancialWorkflowComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddFinancialWorkflowComponent }

                ]
            },
            // {path: 'financialworkflow', component: FinancialWorkflowComponent},
            {
                path: 'emailconfigurations', children: [
                    { path: '', component: EmailConfigurationsComponent },
                    { path: 'editemailconfig', component: EmailConfigurationsEditComponent }
                ]
            },
            {
                path: 'bannersconfigurations', children: [
                    { path: '', component: BannersComponent },
                ]
            },
            {path: 'customconfigurations', children: [
                    {path: '', component: CustomFieldsComponent},
                    {path: ':id', component: CustomFieldsComponent},
                ]
            },
            { path: 'managenotification', component: ManageNotificationsComponent },
            { path: 'accountconfig', component: AccountConfigurationComponent },
            {
                path: 'expensecategory', children: [
                    { path: '', component: ExpenseCategoryComponent },
                    { path: 'add', component: AddMasterCategoryComponent },
                    { path: 'update/:id/:guid/:pId', component: AddMasterCategoryComponent }
                ]
            },

            {
                path: 'merchant', children: [
                    { path: '', component: MerchantsComponent },
                    { path: 'add', component: AddMasterMerchantsComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddMasterMerchantsComponent }
                ]
            },
            {
                path: 'hsn-code', children: [
                    { path: '', component: HsnCodeComponent },
                    { path: 'add', component: AddHsnCodeComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddHsnCodeComponent }
                ]
            },
            {
                path: 'tax', children: [
                    { path: '', component: TaxCodeComponent },
                    { path: 'add', component: AddTaxComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddTaxComponent }
                ]
            },
            {
                path: 'mileage', children: [
                    { path: '', component: MileageComponent },
                    { path: 'add', component: AddMileageComponent },
                    { path: 'update/:id/:guid', component: AddMileageComponent }
                ]
            },
            {
                path: 'financeintegration', children: [
                    { path: '', component: FinanceIntegrationCodeComponent },
                    { path: 'add', component: AddFinanceIntegrationComponent },
                    { path: 'update/:id/:guid/:statusId', component: AddFinanceIntegrationComponent }
                ]
            },
            { path: 'themes', component: ThemesComponent },
            { path: 'travel', component: TravelRequestComponent },
            { path: 'personalInfo', component: PersonalInformationComponent },
            { path: 'userpersonalInfo', component: PersonalInformationComponent },
            {
                path: 'exchangeRate', children: [
                    { path: '', component: ExchangeRateComponent },
                    { path: 'add', component: AddExchangeRateComponent },
                    { path: 'update/:id/:guid', component: AddExchangeRateComponent }
                ]
            },
            { path: 'upgrade', component: UpgradeComponent },
            {
                path: 'perdiemTypes', children: [
                    { path: '', component: PerdiemTypesComponent },
                    { path: 'add', component: AddPerdiemTypesComponent },
                    { path: 'update/:id/:guid', component: AddPerdiemTypesComponent }
                ]
            },
            {
                path: 'cityGroups', children: [
                    { path: '', component: CityGroupsComponent },
                    { path: 'add', component: AddCityGroupComponent },
                    { path: 'update/:id/:guid', component: AddCityGroupComponent }
                ]
            },

        ]
    },

];

@NgModule({
    declarations: [
        MileageComponent,
        AddMileageComponent,
        OrganizationStructureComponent,
        SidebarmenuComponent,
        FinancePoliciesComponent,
        ApprovalWorkflowComponent,
        FinancialWorkflowComponent,
        AccountConfigurationComponent,
        AddMasterCategoryComponent,
        AddMasterMerchantsComponent,
        AddTaxComponent,
        ManageNotificationsComponent,
        ExpenseCategoryComponent,
        MerchantsComponent,
        TaxCodeComponent,
        ThemesComponent,
        PersonalInformationComponent,
        ExchangeRateComponent,
        UpgradeComponent,
        PerdiemTypesComponent, AddPerdiemTypesComponent,
        CityGroupsComponent, AddCityGroupComponent,
        AddExchangeRateComponent, AddPolicyComponent, AddApprovalWorkflowComponent, AddFinancialWorkflowComponent,
        LocalConveyancePolicyComponent,
        AddLCComponent, EmailConfigurationsComponent, EmailConfigurationsEditComponent, BannersComponent,
        GeneralPolicyComponent, TravelRequestComponent, AddFinanceIntegrationComponent, FinanceIntegrationCodeComponent, AddHsnCodeComponent, HsnCodeComponent, ApprovalpoliciesComponent, AddApprovalPoliciesComponent,
        BudgetComponent, AddBudgetComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        RouterModule.forChild(routes),
        AngularSplitModule,
        CommonDirectivesModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTreeModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule, MatSlideToggleModule,
        MatNativeDateModule,
        ColorPickerModule,
        MatTooltipModule,
        IonicModule.forRoot(),
        ImageCropperModule,
        TranslateModule,
        PdfViewerModule,
        MyPipesModule, MatChipsModule, Ng5SliderModule, NgxSelectModule,MatProgressBarModule,NgSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: [
        RouterModule, MatAutocompleteModule
    ],
    entryComponents: [],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SettingModule {
}
