import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { MenuController, NavController } from '@ionic/angular';
import { UserLeftMenu } from 'src/app/models/Settings/userLeftMenu.model';
import { SelectLegalEntity } from 'src/app/models/Settings/selecteLegalEntity.model';
import { isNgTemplate } from '@angular/compiler';
import { SettingsService } from 'src/app/services/settings-services';
@Component({
  selector: 'app-sidebarmenu',
  templateUrl: './sidebarmenu.component.html',
  styleUrls: ['./sidebarmenu.component.scss'],
})
export class SidebarmenuComponent implements OnInit {
  platformType: number;
  public leftSidebarCollapsed: boolean = true;
  companyInfo: any;
  menuName = "account";
  subMenuName: any;
  innersubMenuName: any;
  mobMenuName = 'account';
  mobSubMenuName: any;
  hideMasterMenu = -1;
  hideChildMasterMenu = -1;
  pages: any;
  sideBarPage = 'settings';
  userLeftMenuModel = new UserLeftMenu();
  settingsSideBarData: any = [
    {
      "menuId": "Settings",
      "route": "/settings",
      "childMenu": [
        {
          "id": 1,
          "menuId": "workflows",
          "menuName": "Workflows",
          "icon": "assets/images/approvalworkflow.svg",
          "title": "workflows",
          "childMenu": [
            {
              "id": 1,
              "menuId": "ApprovalWorkflow",
              "menuName": "Manager Workflows",
              "title": "workflow",
              "route": "/approvalworkflow"
            },
            {
              "id": 2,
              "menuId": "financialworkflow",
              "menuName": "Finance Workflows",
              "title": "financialworkflows",
              "route": "/finacialworkflow"
            },
          ]
        },
        {
          "id": 2,
          "menuId": "policy",
          "menuName": "Policies",
          "icon": "assets/images/financepolicy.svg",
          "title": "policy",
          "childMenu": [
            {
              "id": 1,
              "menuId": "FinancePolicy",
              "menuName": "Budget Policies",
              "title": "finance",
              "route": "/financepolicy"
            },
            {
              "id": 2,
              "menuId": "GeneralPolicy",
              "menuName": "General Policies",
              "title": "general",
              "route": "/generalpolicy"
            },
            {
              "id": 3,
              "menuId": "approvalpolicies",
              "menuName": "Approval Policies",
              "title": "approvalpolicies",
              "route": "/approvalpolicies"
            },
            {
              "id": 4,
              "menuId": "Budget",
              "menuName": "Budgets",
              "title": "budget",
              "route": "/budget"
            }
          ]
        },
        {
          "id": 3,
          "menuId": "configuration",
          "menuName": "Configurations",
          "icon": "assets/images/Group-1.svg",
          "title": "configuration",
          "childMenu": [
            {
              "id": 1,
              "menuId": "AccountConfig",
              "menuName": "General Configurations",
              "title": "account",
              "route": "/accountconfig"
            },
            {
              "id": 2,
              "menuId": "Emailconfigurations",
              "menuName": "Email Configurations",
              "title": "emailconfigurations",
              "route": "/emailconfigurations"
            },
            {
              "id": 2,
              "menuId": "bannersconfigurations",
              "menuName": "Banners",
              "title": "bannersconfigurations",
              "route": "/bannersconfigurations"
            },
            {
              "id": 20,
              "menuId": "customconfigurations",
              "menuName": "Custom Fields",
              "title": "customfields",
              "innerChild": [
                {
                  "id": 21,
                  "menuId": "ExpenseConfig",
                  "menuName": "Expense",
                  "title": "ExpenseRequest",
                  "route": "/customfieldscondig",
                },
                {
                  "id": 22,
                  "menuId": "MileageConfig",
                  "menuName": "Mileage",
                  "title": "customfields",
                  "route": "/customfieldscondig",
                },
                {
                  "id": 23,
                  "menuId": "PerdiemConfig",
                  "menuName": "Per Diem",
                  "title": "customfields",
                  "route": "/customfieldscondig",
                },
              ]
            },
          ]
        },
        {
          "id": 4,
          "catType": 1,
          "menuId": "Master",
          "menuName": "Master Data",
          "icon": "assets/images/master-data.svg",
          "title": "master",

          "childMenu": [
            {
              "id": 1,
              "catType": 1,
              "menuId": "ExpenseCategory",
              "menuName": "Categories",
              "title": "expense",
              "route": "/expensecategory"
            },
            {
              "id": 2,
              "menuId": "Mileage",
              "menuName": "Mileage",
              "title": "mileage",
              "route": "/mileage"
            },
            {
              "id": 3,
              "catType": 1,
              "menuId": "Merchant",
              "menuName": "Merchants",
              "title": "merchant",
              "route": "/merchant"
            },
            {
              "id": 4,
              "menuId": "Financeintegration",
              "menuName": "Finance Integration Code",
              "title": "financeintegration",
              "route": "/financeintegration"
            },
            {
              "id": 5,
              "catType": 1,
              "menuId": "hsn-code",
              "menuName": "HSN Code",
              "title": "hsn-code",
              "route": "/hsn-code"
            },
            {
              "id": 6,
              "catType": 1,
              "menuId": "Tax",
              "menuName": "Tax Code",
              "title": "tax",
              "route": "/tax"
            },
            {
              "id": 8,
              "menuId": "cityGroups",
              "menuName": "City Groups",
              "title": "City Groups",
              "route": "/cityGroups"
            },
            {
              "id": 7,
              "menuId": "PerdiemTypes",
              "menuName": "Per Diem Types",
              "title": "Per Diem Types",
              "route": "/perdiemTypes"
            }

          ]

        },
        {
          "id": 5,
          "catType": 3,
          "menuId": "Master",
          "menuName": "Petty Cash",
          "icon": "assets/images/Pettycash.svg",
          "title": "master",

          "childMenu": [
            {
              "id": 1,
              "catType": 3,
              "menuId": "ExpenseCategory",
              "menuName": "Expense Categories",
              "title": "expense",
              "route": "/expensecategory"
            },
            {
              "id": 3,
              "catType": 3,
              "menuId": "Merchant",
              "menuName": "Merchants",
              "title": "merchant",
              "route": "/merchant"
            }
          ]

        },
        // {
        //   "id": 6,
        //   "menuId": "localconveyancepolicy",
        //   "menuName": "Local Conveyance Policy",
        //   "icon": "assets/images/LocalConveyence.svg",
        //   "title": "localconveyancepolicy",
        //   "route": "/localconveyancepolicy"
        // },

        {
          "id": 7,
          "menuId": "Theme",
          "menuName": "Theme",
          "icon": "assets/images/theme.svg",
          "title": "theme",
          "route": "/themes"
        },
        // {
        //   "id": 8,
        //   "menuId": "Travel",
        //   "menuName": "Travel",
        //   "icon": "assets/images/travel.svg",
        //   "title": "travel",
        //   "route": "/travel"
        // },


      ]
    }
  ];
  managUserSideBarMenu = [
    {
      "id": 1,
      "menuId": "PersonalInfo",
      "menuName": "Personal Information",
      "icon": "assets/svg/personal-information.svg",
      "title": "personal",
      "route": "/personalInfo"
    }
  ]
  masterSideMenu = this.settingsSideBarData[0].childMenu[3].childMenu;
  childmasterSideMenu = [];
  showMobileMasterMenu: boolean = false;
  baseLanguageList = [{ "value": "ZH", "text": "Chinese (Mandarin)" }, { "value": "EN", "text": "English" }];
  dateFormatList = [{ value: '1/4/2017', format: 'M/d/yyyy' }, { value: '1/4/17', format: 'M/d/yy' }, { value: '01/04/17', format: 'MM/dd/yy' }, { value: '01/04/2017', format: 'MM/dd/yyyy' }, { value: '17/01/04', format: 'yy/MM/dd' }, { value: '2017-01-04', format: 'yyyy-MM-dd' }, { value: '04-Jan-17', format: 'dd-MMM-yy' }];
  currencyFormatList = [{ value: 'INR (1,10,000)', format: '#,##,###' }, { value: 'USD (1,000,00)', format: '#,###,##' }];
  userInfo: any;
  selectedUserInfObject: any;
  personalInfo: any;
  legalEntityName: any;
  legalEntityLogo: any;
  selectedLegalEntity: any;
  selectLegalEntityModel = new SelectLegalEntity();
  profileImgPath: any;

  constructor(private platformService: PlatformService, private router: Router, private settingService: SettingsService, private sharedService: SharedService, private menuCtrl: MenuController, public navCtrl: NavController) {
    this.sharedService.getSideBarFlag().subscribe(flag => {
      this.leftSidebarCollapsed = flag;
      this.updateFlags();
    });
  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.getPersonalInfo();
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.selectLegalEntityModel.companyId = this.userInfo.companyId;
    this.selectLegalEntityModel.companyGuId = this.userInfo.companyGuId;
    this.selectLegalEntityModel.legalEntityId = this.userInfo.legalEntityId;
    this.selectLegalEntityModel.legalEntityGuId = this.userInfo.legalEntityGuId;
    this.selectLegalEntityModel.legalEntityName = this.userInfo.companyName;
    this.selectLegalEntityModel.legalEntityLogo = this.userInfo.legalEntityLogoPath;
    this.sharedService.getCompanyInfo().subscribe(data => {
      if (data) {
        this.legalEntityName = data.legalEntityName;
        this.legalEntityLogo = data.legalEntityLogo;
      } else {
        this.legalEntityName = this.selectedLegalEntity.legalEntityName;
        this.legalEntityLogo = this.selectedLegalEntity.legalEntityLogo;
      }
    });
    this.pages = [
      // {
      //   id: 1,
      //   title: "Personal Information",
      //   url: "/settings/personalInfo",
      //   imgs: "assets/svg/personal-information.svg",
      //   menuName: 'personalinfo'
      // },
      {
        id: 3,
        title: "Master Data",
        imgs: "assets/svg/master-data.svg",
        menuName: 'master',
        children: [
          {
            id: 7,
            title: "Expense Categories",
            url: "/settings/expensecategory",
            menuName: 'expense'
          },
          {
            id: 8,
            title: "Mileage Categories",
            url: "/settings/mileage",
            menuName: 'mileage'
          },
          {
            id: 13,
            title: "Per Diem Types",
            url: "/settings/perdiemTypes",
            menuName: 'PerdiemTypes'
          },
          {
            id: 14,
            title: "City Groups",
            url: "/settings/cityGroups",
            menuName: 'cityGroups'
          },

          {
            id: 9,
            title: "Merchants",
            url: "/settings/merchant",
            menuName: 'merchants'
          },
          {
            id: 10,
            title: "Finance integration",
            url: "/settings/financeintegration",
            menuName: 'financeintegration'
          },
          {
            id: 11,
            title: "hsn-code",
            url: "/settings/hsn-code",
            menuName: 'hsn-code'
          },
          {
            id: 12,
            title: "Tax",
            url: "/settings/tax",
            menuName: 'tax'
          },
        ]
      },
      {
        id: 4,
        title: "Account Configuration",
        url: "/settings/accountconfig",
        imgs: "assets/svg/account-configuration.svg",
        menuName: 'account'
      },
      {
        id: 5,
        title: "Exchange Rate",
        url: "/settings/exchangeRate",
        imgs: "assets/svg/exchange-rate.svg",
        menuName: 'exchange'
      },
      // {
      //   id: 5,
      //   title: "Upgrade",
      //   url: "/settings/upgrade",
      //   imgs: "assets/svg/upgrade.svg",
      // }
    ];
    sessionStorage.setItem('baseLanguageJson', JSON.stringify(this.baseLanguageList));
    sessionStorage.setItem('dateFormatJson', JSON.stringify(this.dateFormatList));
    sessionStorage.setItem('currencyFormatJson', JSON.stringify(this.currencyFormatList));
    this.updateFlags();
    this.setuserdata();
  }

  menutoggle() {
    this.menuCtrl.enable(true, 'settingMenu');
    this.menuCtrl.enable(false, 'mainMenu');
  }

  updateFlags() {
    if (this.router.url.indexOf("/settings/financepolicy") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "policy";
      this.subMenuName = "finance";
    } else if (this.router.url.indexOf("/settings/generalpolicy") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "policy";
      this.subMenuName = "general";
    } else if (this.router.url.indexOf("/settings/localconveyancepolicy") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "";
      this.menuName = "localconveyancepolicy";
    }
    else if (this.router.url.indexOf("/settings/approvalworkflow") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "workflows";
      this.subMenuName = "workflow";
    } else if (this.router.url.indexOf("/settings/approvalpolicies") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "approvalpolicies";
      this.menuName = "policy";
    } else if (this.router.url.indexOf("/settings/financialworkflow") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "workflows";
      this.subMenuName = "financialworkflows";
    } else if (this.router.url.indexOf("/settings/managenotification") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "notification";
      this.subMenuName = "";
    } else if (this.router.url.indexOf("/settings/accountconfig") != -1) {
      this.sideBarPage = 'settings';
      this.menuName = "configuration";
      this.subMenuName = "account";
    } else if (this.router.url.indexOf("/settings/expensecategory") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "expense";
      this.menuName = "master";
    } else if (this.router.url.indexOf("/settings/merchant") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "merchant";
      this.menuName = "master";
    } else if (this.router.url.indexOf("/settings/financeintegration") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "financeintegration";
      this.menuName = "master";
    }
    else if (this.router.url.indexOf("/settings/mileage") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "mileage";
      this.menuName = "master";
    } else if (this.router.url.indexOf("/settings/hsn-code") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "hsn-code";
      this.menuName = "master";
    }
    else if (this.router.url.indexOf("/settings/tax") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "tax";
      this.menuName = "master";
    }
    else if (this.router.url.indexOf("/settings/themes") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "";
      this.menuName = "theme";
    } else if (this.router.url.indexOf("/settings/travel") != -1) {
      this.sideBarPage = 'settings';
      this.subMenuName = "";
      this.menuName = "travel";
    } else if (this.router.url.indexOf("/settings/personalInfo") != -1) {
      this.sideBarPage = 'personalInfo';
      this.menuName = "personal";
    } else if (this.router.url.indexOf("/settings/userpersonalInfo") != -1) {
      this.sideBarPage = 'personalInfo';
      this.menuName = "personal";
    }
  }
  getPersonalInfo() {
    const requestObj = {
      "userId": this.userInfo.userId,
      "userGuid": this.userInfo.userGuId,
    }
    this.settingService.getSingleUser(requestObj).subscribe(data => {
      if (data.payload) {
        sessionStorage.setItem("personalInfo", JSON.stringify(data.payload))
        this.personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
        if (this.personalInfo) {
          this.profileImgPath = this.personalInfo.profilePicPath;
        } else {
          if (this.userInfo.profilePicPath) {
            this.profileImgPath = this.userInfo.profilePicPath;
          } else if (!this.userInfo.profilePicPath) {
            if (this.userInfo.gender) {
              if (this.userInfo.gender === "Male") {
                this.profileImgPath = "./assets/images/userdefaultmale.svg";
              } else if (this.userInfo.gender === "Female") {
                this.profileImgPath = "./assets/images/userdefaultfemale.svg";
              }
            } else if (!this.userInfo.gender) {
              this.profileImgPath = "./assets/images/userdefaultmale.svg";
            }
          }
        }
      }
    });
  }
  setuserdata() {
    this.personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
    this.sharedService.getPersonalInfo().subscribe(data => {
      if (data) {
        this.selectedUserInfObject = data;
        this.userLeftMenuModel = this.selectedUserInfObject;
      }
    });
    this.userLeftMenuModel.firstName = this.personalInfo ? this.personalInfo.firstName : this.userInfo.firstName;
    this.userLeftMenuModel.lastName = this.personalInfo ? this.personalInfo.lastName : this.userInfo.lastName;
    this.userLeftMenuModel.profilePicPath = this.personalInfo ? this.personalInfo.profilePicPath : this.userInfo.profilePicPath;
    this.userLeftMenuModel.employeeId = this.personalInfo ? this.personalInfo.employeeId : this.userInfo.employeeId;
    this.userLeftMenuModel.designationName = this.personalInfo ? this.personalInfo.designationName : this.userInfo.designationName;
    this.userLeftMenuModel.gender = this.personalInfo ? this.personalInfo.gender : this.userInfo.gender;
  }

  navigateToSideBarsMenu(item) {
    console.log('selected item :',item);
    let menuId = item.menuId;
    menuId = menuId.toLowerCase();
    switch (menuId) {
      case "policy":
        this.menuName = "policy";
        this.subMenuName = "finance";
        this.masterMenu(item.id);
        if (item.id == 2) {
          this.masterSideMenu = this.settingsSideBarData[0].childMenu[1].childMenu;
        }
        this.navigateTo('/settings/financepolicy');
        break;
      case "budget":
        this.menuName = "policy";
        this.subMenuName = "budget";
        this.navigateTo('/settings/budget');
        break;
      case "financepolicy":
        this.menuName = "policy";
        this.subMenuName = "finance";
        this.navigateTo('/settings/financepolicy');
        break;
      case "generalpolicy":
        this.menuName = "policy";
        this.subMenuName = "general";
        this.navigateTo('/settings/generalpolicy');
        break;
      case "workflows":
        this.menuName = "workflows";
        this.subMenuName = "workflow";
        this.masterMenu(item.id);
        if (item.id == 1) {
          this.masterSideMenu = this.settingsSideBarData[0].childMenu[0].childMenu;
        }
        sessionStorage.setItem('typeStr', JSON.stringify(1))
        this.navigateTo('/settings/approvalworkflow');
        break;
      case "approvalworkflow":
        this.menuName = "workflows";
        this.subMenuName = "workflow";
        sessionStorage.setItem('typeStr', JSON.stringify(1))
        this.navigateTo('/settings/approvalworkflow');
        break;
      case "approvalpolicies":
        this.subMenuName = "approvalpolicies";
        this.menuName = "policy";
        this.navigateTo('/settings/approvalpolicies');
        break;
      case "financialworkflow":
        this.menuName = "workflows";
        this.subMenuName = "financialworkflows";
        sessionStorage.setItem('typeStr', JSON.stringify(2))
        this.navigateTo('/settings/financialworkflow');
        break;
      case "emailconfigurations":
        this.menuName = "configuration";
        this.subMenuName = "emailconfigurations";
        this.navigateTo('/settings/emailconfigurations');
        break;
      case "bannersconfigurations":
        this.menuName = "configuration";
        this.subMenuName = "bannersconfigurations";
        this.navigateTo('/settings/bannersconfigurations');
        break;
      case "accountconfig":
        this.menuName = "configuration";
        this.subMenuName = "account";
        this.navigateTo('/settings/accountconfig');
        break;
      case "configuration":
        this.menuName = "configuration";
        this.subMenuName = "account";
        this.masterMenu(item.id);
        if (item.id == 3) {
          this.masterSideMenu = this.settingsSideBarData[0].childMenu[2].childMenu;
        }
        this.navigateTo('/settings/accountconfig');
        break;
      case "master":
        this.menuName = "master";
        this.subMenuName = "expense";
        this.masterMenu(item.id);
        if (item.id == 4) {
          this.masterSideMenu = this.settingsSideBarData[0].childMenu[3].childMenu;
        } else if (item.id == 5) {
          this.masterSideMenu = this.settingsSideBarData[0].childMenu[4].childMenu;
        }
        this.navigateTo('/settings/expensecategory', item.catType);
        break;
      case "expensecategory":
        this.subMenuName = "expense";
        this.menuName = "master";
        this.navigateTo('/settings/expensecategory', item.catType);
        break;
      case "merchant":
        this.subMenuName = "merchant";
        this.menuName = "master";
        this.navigateTo('/settings/merchant', item.catType);
        break;
      case "financeintegration":
        this.subMenuName = "financeintegration";
        this.menuName = "master";
        this.navigateTo('/settings/financeintegration', item.catType);
        break;
      case "hsn-code":
        this.subMenuName = "hsn-code";
        this.menuName = "master";
        this.navigateTo('/settings/hsn-code', item.catType);
        break;
      case "tax":
        this.subMenuName = "tax";
        this.menuName = "master";
        this.navigateTo('/settings/tax', item.catType);
        break;
      case "mileage":
        this.subMenuName = "mileage";
        this.menuName = "master";
        this.navigateTo('/settings/mileage');
        break;
      case "theme":
        this.subMenuName = "";
        this.menuName = "theme";
        this.navigateTo('/settings/themes');
        break;
      case "localconveyancepolicy":
        this.subMenuName = "localconveyancepolicy";
        this.menuName = "localconveyancepolicy";
        this.navigateTo('/settings/localconveyancepolicy');
        break;
      case "travel":
        this.subMenuName = "travelRequest";
        this.menuName = "travelRequest";
        this.navigateTo('/settings/travel');
        break;
      case "customconfigurations":
        this.menuName = "configuration";
        this.subMenuName = 'customfields';
        this.innersubMenuName = "ExpenseRequest";
        this.childmasterMenu(item.id);
        // this.masterSideMenu = this.settingsSideBarData[0].childMenu[2].childMenu;
        this.childmasterSideMenu = this.settingsSideBarData[0].childMenu[2].childMenu[3].innerChild;
        this.navigateTo('/settings/customconfigurations'+"/1");
        break;
      case "expenseconfig":
        this.menuName = "configuration";
        this.subMenuName = 'customfields';
        this.innersubMenuName = "ExpenseRequest";
        this.navigateTo('/settings/customconfigurations'+"/1");
        break;
      case "mileageconfig":
        this.menuName = "configuration";
        this.subMenuName = 'customfields';
        this.innersubMenuName = "MileageRequest";
        this.navigateTo('/settings/customconfigurations'+"/2");
        break;
      case "perdiemconfig":
        this.menuName = "configuration";
        this.subMenuName = 'customfields';
        this.innersubMenuName = "PerdiemRequest";
        this.navigateTo('/settings/customconfigurations'+"/3");
        break;
      case "perdiemtypes":
          this.subMenuName = "Per Diem Types";
          this.menuName = "master";
          this.navigateTo('/settings/perdiemTypes');
          break;
      case "citygroups":
              this.subMenuName = "City Groups";
              this.menuName = "master";
              this.navigateTo('/settings/cityGroups');
              break;
    }
  }

  navigateTomanageUsers(item) {
    let menuId = item.menuId;
    menuId = menuId.toLowerCase();
    switch (menuId) {
      case "personalinfo":
        this.menuName = "personal";
        this.navigateTo('/settings/personalInfo');
        break;
    }
  }

  navigateTo(redirectValue: any, categoryTyp?) {
    this.leftSidebarCollapsed = true;
    if (categoryTyp) {
      let navInfo = {
        url: redirectValue,
        categoryType: categoryTyp
      };
      sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
      this.sharedService.setLoadCatgryApi(categoryTyp);
    }

    this.navCtrl.navigateRoot([redirectValue]).then(() => {
      this.sharedService.setSideBarFlag(this.leftSidebarCollapsed);
    });
  }

  masterMenu(itemId) {
    this.leftSidebarCollapsed = true;
    if (this.hideMasterMenu == itemId) {
      this.hideMasterMenu = -1;
    } else {
      this.hideMasterMenu = itemId;
    }
  }
  childmasterMenu(itemId) {
    this.leftSidebarCollapsed = true;
    if (this.hideChildMasterMenu == itemId) {
      this.hideChildMasterMenu = -1;
    } else {
      this.hideChildMasterMenu = itemId;
    }
  }

  showLeftNavigation() {
    this.leftSidebarCollapsed = !this.leftSidebarCollapsed;
    this.sharedService.setSideBarFlag(this.leftSidebarCollapsed);
    this.hideMasterMenu = -1;
  }

  navigateToHome() {
    this.menuCtrl.enable(true, 'mainMenu');
    this.menuCtrl.enable(false, 'settingMenu');
    this.navCtrl.navigateRoot(['/dashboard']);
    sessionStorage.setItem("selectedLegalEntity", JSON.stringify(this.selectLegalEntityModel));
    this.sharedService.setCompanyInfo(this.selectLegalEntityModel);
  }

  navigateToGlobalSettings() {
  //  window.open('http://localhost:8101/' + 'login/' + this.userInfo.ssoToken+'?showUserApprovers='+this.userInfo?.showUserApprovers +'&showCostCenterManagers='+this.userInfo?.showCostCenterManagers);
   window.open(environment.globalSettingsUrl + 'login/' + this.userInfo.ssoToken+'?showUserApprovers='+this.userInfo?.showUserApprovers +'&showCostCenterManagers='+this.userInfo?.showCostCenterManagers);
  }

  navigateToOrganizationStructure() {
    this.navCtrl.navigateRoot(["/organization"], { replaceUrl: true });
  }

  navigateToMobileSettings(val) {
    if (val.id !== 2) {
      switch (val.menuName) {
        case 'personalinfo':
          this.mobMenuName = 'personalinfo';
          this.mobSubMenuName = '';
          break;
        case 'account':
          this.mobMenuName = 'account';
          this.mobSubMenuName = '';
          break;
        case 'exchange':
          this.mobMenuName = 'exchange';
          this.mobSubMenuName = '';
          break;
        case 'expense':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'expense';
          break;
        case 'financeintegration':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'financeintegration';
          break;
        case 'hsn-code':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'hsn-code';
          break;
        case 'mileage':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'mileage';
          break;
        case 'merchants':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'merchants';
          break;
        case 'tax':
          this.mobMenuName = 'master';
          this.mobSubMenuName = 'tax';
          break;
      }
      this.navCtrl.navigateRoot([val.url]);
    }
    else {
      this.mobMenuName = "master";
      this.mobileMasterMenu();
    }
  }

  mobileMasterMenu() {
    this.showMobileMasterMenu = !this.showMobileMasterMenu;
  }
}
