import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-city-groups',
  templateUrl: './city-groups.component.html',
  styleUrls: ['./city-groups.component.scss'],
})
export class CityGroupsComponent implements OnInit {
  @ViewChild("toggleStatusRef", { static: false }) private toggleStatusRef;
  @ViewChild("moreDataRef", { static: false }) private moreDataRef;
  selectedCGType: any;
  selectedCGTypeIndex: any;
  modalRef: BsModalRef;
  searchQuery: string = '';
  itemsPerPage: number = 25;
  currentPage: number = 1;
  showLoader: boolean = false;
  loadersList: any = [1, 2, 3, 4, 5];
  userInfo: any;
  selectedLegalEntity: any;
  cityGroupsList: any = [];
  isEnabled = false;
  enabling = false;

  constructor(
    private router: NavController, private modalService: BsModalService,
    private settingService: SettingsService,
    private translate: TranslateService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  generateList(data) {
    return data?.map(group => ({
      ...group,
      countryNames: this.convertToList(group.countryNames),
      countryIds: this.convertToList(group.countryIds),
      cityNames: this.convertToList(group.cityNames),
      cityIds: this.convertToList(group.cityIds),
    }));
  }

  convertToList(commaSeparatedString: string): string[] {
    if (!commaSeparatedString) {
      return [];
    }
    return commaSeparatedString.split(',').map(item => item.trim());
  }

  ionViewWillEnter() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getCityGroupsList();
  }

  getCityGroupsList() {
    this.showLoader = true;
    let obj = {
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getCityGroupsList(obj).subscribe(res => {
      this.cityGroupsList = this.generateList(res?.payload || []);
      this.showLoader = false;
    })
  }

  get filteredData() {
    return this.cityGroupsList.filter(item => {
      const searchTerm = this.searchQuery.toLowerCase();
      return (
        item.cityGroupName.toLowerCase().includes(searchTerm)
      );
    });
  }

  get paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredData.slice(startIndex, endIndex);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get displayRange(): { start: number; end: number; total: number } {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.filteredData.length);
    return {
      start,
      end,
      total: this.filteredData.length
    };
  }

  onSearch(event: Event): void {
    this.searchQuery = (event.target as HTMLInputElement).value;
    this.currentPage = 1;
  }

  onItemsPerPageChange(event: Event): void {
    this.itemsPerPage = parseInt((event.target as HTMLSelectElement).value);
    this.currentPage = 1;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  mouseover = false;
  selectedIndex = -1;
  toggleStatusDropdown(index, event: MouseEvent) {
    event.stopPropagation();
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  onCO() {
    if (!this.mouseover) {
      this.selectedIndex = -1;
    }
  }

  addCG() {
    this.router.navigateRoot(['/settings/cityGroups/add']);
  }


  moreHover = false;
  handleRowClick(item: any, showMore?: Boolean) {
    const selection = window?.getSelection();
    if (selection && selection?.toString()?.length > 0) {
      return;
    }

    if (showMore) {
      this.showMoreData(item)
    } else {
      this.updateCG(item);
    }
  }

  updateCG(data) {
    this.router.navigateRoot(['/settings/cityGroups/update', data.cityGroupId, data.cityGroupGUID]);
  }

  openConfirmDialog(pdType, index): void {
    this.selectedCGType = pdType;
    this.selectedCGTypeIndex = index;
    this.enabling = pdType.statusId != 1 ? true : false;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.toggleStatusRef, config);
  }

  cancel(): void {
    this.modalRef.hide();
  }

  changeStatus(): void {
    this.modalRef.hide();
    if (this.enabling)
      this.enableCGType();
    else
      this.disableCGType();
  }

  enableCGType() {
    let obj = {
      cityGroupId: this.selectedCGType?.cityGroupId,
      cityGroupGUID: this.selectedCGType?.cityGroupGUID,
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.enableCityGroup(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.getCityGroup();
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  disableCGType() {
    let obj = {
      cityGroupId: this.selectedCGType?.cityGroupId,
      cityGroupGUID: this.selectedCGType?.cityGroupGUID,
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.disableCityGroup(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.getCityGroup();
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  getCityGroup() {
    let obj = {
      cityGroupId: this.selectedCGType?.cityGroupId,
      cityGroupGUID: this.selectedCGType?.cityGroupGUID,
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getCityGroup(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0 && res?.payload) {
        let updatedObj = this.generateList([res?.payload]);
        if (updatedObj?.length > 0)
          this.cityGroupsList[this.selectedCGTypeIndex] = updatedObj[0];
      }
    })
  }

  moreDataObj: any;
  showMoreData(data) {
    this.moreDataObj = data;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.moreDataRef, config);
  }

}
