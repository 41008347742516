import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-perdiem-types',
  templateUrl: './perdiem-types.component.html',
  styleUrls: ['./perdiem-types.component.scss'],
})
export class PerdiemTypesComponent implements OnInit {
  modalRef: BsModalRef;
  searchQuery: string = '';
  itemsPerPage: number = 25;
  currentPage: number = 1;
  showLoader: boolean = false;
  loadersList: any = [1,2,3,4,5];
  userInfo: any;
  selectedLegalEntity: any;
  pdTypesList: any = [];
  

  constructor(
    private router: NavController, private modalService: BsModalService,
    private settingService: SettingsService,
    private translate: TranslateService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void { }

  ionViewWillEnter() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getPerDiemTypesList();
  }

  getPerDiemTypesList() {
    this.showLoader = true;
    let obj = {
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getPerDiemTypesList(obj).subscribe(res => {
      this.pdTypesList = res?.payload || [];
      this.showLoader = false;
    })
  }

  get filteredData() {
    return this.pdTypesList.filter(item => {
      const searchTerm = this.searchQuery.toLowerCase();
      return (
        item.perDiemTypeName.toLowerCase().includes(searchTerm)
      );
    });
  }

  get paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredData.slice(startIndex, endIndex);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get displayRange(): { start: number; end: number; total: number } {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.filteredData.length);
    return {
      start,
      end,
      total: this.filteredData.length
    };
  }

  onSearch(event: Event): void {
    this.searchQuery = (event.target as HTMLInputElement).value;
    this.currentPage = 1;
  }

  onItemsPerPageChange(event: Event): void {
    this.itemsPerPage = parseInt((event.target as HTMLSelectElement).value);
    this.currentPage = 1;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  mouseover = false;
  selectedIndex = -1;
  toggleStatusDropdown(index, event: MouseEvent) {
    event.stopPropagation();
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  onCO() {
    if(!this.mouseover) {
      this.selectedIndex = -1;
    }
  }

  addPD() {
    this.router.navigateRoot(['/settings/perdiemTypes/add']);
  }


  handleRowClick(item: any) {
    const selection = window?.getSelection();
    if (selection && selection?.toString()?.length > 0) {
      return;
    }
    this.updatePD(item);
  }

  updatePD(data) {
    this.router.navigateRoot(['/settings/perdiemTypes/update', data.perDiemTypeId, data.perDiemTypeGUID]);
  }

  @ViewChild("toggleStatusRef", { static: false }) private toggleStatusRef;
  isEnabled = false;
  enabling = false;

  selectedPdType: any;
  selectedPdTypeIndex: any;
  openConfirmDialog(pdType, index): void {
    this.selectedPdType = pdType;
    this.selectedPdTypeIndex = index;
    this.enabling = pdType.statusId != 1 ? true : false;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.toggleStatusRef, config);
  }

  cancel(): void {
    this.modalRef.hide();
  }

  changeStatus(): void {
    this.modalRef.hide();
    if(this.enabling)
      this.enablePdType();
    else
      this.disablePdType();
  }

  enablePdType() {
    let obj = {
      perDiemTypeId:  this.selectedPdType?.perDiemTypeId,
      perDiemTypeGUID:  this.selectedPdType?.perDiemTypeGUID,
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.enablePerDiemType(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.getPerDiemType();
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  disablePdType() {
    let obj = {
      perDiemTypeId:  this.selectedPdType?.perDiemTypeId,
      perDiemTypeGUID:  this.selectedPdType?.perDiemTypeGUID,
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.disablePerDiemType(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.getPerDiemType();
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  getPerDiemType() {
    let obj = {
      perDiemTypeId:  this.selectedPdType?.perDiemTypeId,
      perDiemTypeGUID:  this.selectedPdType?.perDiemTypeGUID,
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getPerDiemType(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0 && res?.payload) {
        this.pdTypesList[this.selectedPdTypeIndex] = res?.payload;
      }
    })
  }

}
