import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonSlides, LoadingController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { AddReportExpense, FinanceIntCodeModel, GetExpenseModels, HsnCodeModel, SendBack, TaxCodeModel } from 'src/app/models/Expenses/expenses.model';
import {
    AddCommentModel,
    GetCommentModel,
    GetReportModal,
    GetTripInfo,
    RecordAdvancePayment,
    RecordReportPaymentModel,
    RejectArray,
    RejectedModel,
    RemoveReportModal,
    ReportSummaryRequestModal,
    ReportSummaryResponseModal
} from 'src/app/models/Reports/reports.model';
import { ExpensesService } from 'src/app/services/expenses.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import * as _ from "lodash";
import * as Chart from 'chart.js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ExpenseAndMileageComponent } from '../../dashboard/expenseandmileage/expenseandmileage.component';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CustomPopOverComponent } from '../../custom-pop-over/custom-pop-over.component';
import { ImageViewComponent } from '../../dashboard/image-view/image-view.component';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';
import { DownloadModel, FilterModal, UserModel } from 'src/app/models/Common/common.model';
import { RecordPaymentComponent } from '../record-payment/record-payment.component';
import { CommonService } from 'src/app/services/common-services/common.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';




@Component({
    selector: "app-report-detailview",
    templateUrl: "./report-detailview.component.html",
    styleUrls: ["./report-detailview.component.scss"],
})
export class ReportDetailviewComponent implements OnInit {
    @ViewChild('slides', { static: false }) slider: IonSlides;
    @ViewChild('target') private myScrollContainer: ElementRef;

    @Output() backto: EventEmitter<number> = new EventEmitter<number>();
    platformType: number;
    @Input() currentClaimIndex;
    @Input() detailReport;
    @Input() reportList;
    @Input() fromPage;
    userInfo: any;
    isFirstOpen = true;
    isSecondOpen = true;
    isThirdOpen = true;
    isFourthOpen = true;
    isfifthOpen = true;
    isApprovalOpen = true;
    reportSummary: any;
    expenseList: any;
    reportHistoryList: any;
    showDetails = -1;
    repSummary = "expense";
    dummyList = [1, 2, 3];
    public doughnutChartLabels = [];
    public doughnutChartData = [];
    public doughnutETChartData = [];
    public doughnutChartType: ChartType = "doughnut";
    public categoryoptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' }
    };
    // public doughnutChartDataTrips = [];
    // public doughnutChartLabelsTrips = [];
    // public doughnutChartTypeTrips: ChartType = "doughnut";
    // public categoryoptionsTrips: ChartOptions = {
    //     responsive: true,
    //     legend: { display: false }
    // };

    public _backgroundColors = [{ backgroundColor: ["#ed8f8f", "#c991e3", "#82e7d4", "#ff7f0e", "#2ca02c", "1f77b4"] }];
    singleAmount = [];
    flightIndx: any;
    totalAmount: any;
    categoryName = [];
    categoryId = [];
    selectedExpense = [];
    rejectedExpense = [];
    reportSummaryRequestModal = new ReportSummaryRequestModal();
    // amount = [];
    // labels = [];
    chart: Chart;
    //exp = [];
    indeterminateState: boolean;
    checkAll: boolean;
    showDetailreport: boolean;
    removeReportModal = new RemoveReportModal();
    modalRef: any;
    modalRefDynamic: any;
    modalEmailResendRef: any;
    dropdownFlag: boolean;
    reportBtns = false;
    approveBtns = false;
    partiallyBtn = false;
    rejectExparray = [];
    duplicateRejectArray = [];
    rejectedModel = new RejectedModel();
    reasonForm: FormGroup;
    buttonDisabled = false;
    deltbuttonDisabled = false;
    approveDisable = false;
    rejectDisable = false;
    showSummaryLoading = false;
    proofList: any;
    editreport: any;
    public barChartOptions: ChartOptions = {
        responsive: true,
    };
    public barChartType: ChartType = "bar";
    public barChartLegend = true;
    public barChartPlugins = [];
    paymentModeList = [{ id: 1, name: "Cash" }, { id: 2, name: "Cheque" }, { id: 3, name: "Bank Transfer" }, { id: 4, name: "NEFT" }, { id: 5, name: "RTGS" }, { id: 6, name: "IMPS" }, { id: 7, name: "UPI" }, { id: 11, name: "Net Banking" }, { id: 12, name: "Other" }];
    getReportModal = new GetReportModal();
    rejectArray = new RejectArray();
    addReportExpense = new AddReportExpense();
    proofid: any;
    downloadProof: any;
    downloadProofs = [];
    removeBtn = false;
    commentText: any;
    nextvalue: any;
    openExpense: any;
    imgNum = 1;
    dataEvent: string;
    advanceForm: FormGroup;
    recordPaymentbtns: boolean;
    reportPymtForm: FormGroup;
    recordRptPymntModel = new RecordReportPaymentModel();
    AdvanceSubmitDis = false;
    reportSubmitDis = false;
    globalAvailableAdv: any = 0;
    expensesAmt: any = 0;
    totalReportAmt: any = 0;
    appliedAmt: any = 0;
    finanaceBtns: any;
    apiAvailableAdv: any = 0;
    partialBtnLoad = false;
    submitted = false;
    reportSubmit = false;
    showExpViolation: boolean;
    violationList: any;
    expId;
    showSelectViolation: any;
    addCommentModel = new AddCommentModel();
    openedExpChat: any;
    addDisable = false;
    chatList: any;
    chatLoad = false;
    hideNewRepBtn = true;
    approveMessage: { statusdes: any, status: any };
    pendingExps = [];
    finPending = [];
    fromPettyCash = false;
    tripIdDetails: any;
    singleExpenseRejectReason: any;
    rejectBoolean: boolean = false;
    rejectPopupTitle: boolean = true;
    rejectTitle: boolean = true;
    expense: any;
    fromStr: any;
    isTripOpen = true;
    isTripOpenn = true;

    isAllExpenses = true;
    isApprovedExpenses = true;
    awaitingexpenseList: any;
    approvedexpenseList: any;
    rejectedexpenseList = [];
    tripsDetailsList = [];
    tripsDetailsListAssciate = [];

    shownHeader: string;
    checkimg = 'right-grey'
    crossimg = 'close-grey'
    tripsTotalAmount: any;
    allTotalAmount: any;
    uniqLabels = [];
    amountList = [];
    expIds = [];
    uniExpId = [];
    labels = [];

    invoiceDetails: any;
    imagePath: any;
    pdfData: any;
    defaultIndex = 0;
    isPdf: boolean;
    isZip: boolean;

    getExpenseIds = new GetExpenseModels();
    mainInvoiceDetails: any;
    isExpenseSelect: boolean = false;
    editBtn: boolean = false;
    selectedExp: any;
    finacerIntegrationCodeData: any;
    firstDimensionCodeData=[];
    taxCodeData: any;
    hsnCodeList: any;
    expenseWithCodesList = [];
    isFinanceCodeEnabled: any;
    isFinanceCodeEnabledAdvance: any;
    isTaxCodeEnabled: any;
    isHsnCodeEnabled: any;
    isApprRejBtnEnable: any;
    financeApproverIds = [];
    adminBtnShow: any;
    adminFinaceTaxShow: boolean;
    downloadButton: boolean = false;
    downloadfile: boolean = false;
    showDownloadOptions: boolean = false;
    downloadActionPoolCount = 0;
    downloadTime: any;
    hidePaymentButton: any;
    isAdmin: any;
    selectionTypeArr = [];
    partialEnableVal: boolean = false;
    rejectEnableVal: boolean = false;
    sameValCliamApprove: boolean = false;
    partialApprove: any;
    approvedExpenseList = [];
    disableApprove: boolean = false;
    showApproveAmt: boolean = false;
    AcknologmentViolationBooleanCheck: boolean = false;
    showViolationCheckBox: boolean = false;
    button_disable: boolean = true;
    selectedList = [];
    indeterminateStates: boolean;
    isTripOpenIcon = false;

    reportCostSummary = [];
    @ViewChild("financeIntfocusOff", { static: false }) financeIntfocusOff: ElementRef;
    searchTempList: any;
    finacerIntegrationTempCodeData = [];
    taxCodeTempList: any;
    hsnCodeTempList: any;

    listLoaderDic = [
        { noApi: true, loader: false },
        { noApi: true, loader: false },
        { noApi: true, loader: false },
    ];
    finaceIntCodeName: any;
    defaultTaxCode: any;
    defaultHsnCode: any;
    defaultTaxId: any;
    defaultHsnId: any;

    isLoading = false;
    categoryBaseExpenseList = [];
    selectedCategoryBaseExpenseList = [];
    unselectedCategoryBaseExpenseList = [];
    previewAttachmentsArray: any[];
    AcknologmentBooleanCheck: boolean = true;
    showloader: boolean = false;
    checkedAll: boolean = false;
    check: boolean = false;
    personalInfo: any;
    profileImgPath: any;
    sendbackloader: boolean = false;
    sendBackReason: string = '';
    sendEmailOnSendBack: boolean = true;
    sendBackErrortxt: boolean = false;
    reportlistdata = [];
    indicatorDisbale: boolean = true;
    issettlementpdetailicon =true;
    substatusmouseover: boolean = false;
    confirmshowloader: boolean = false;

    @ViewChild("offlineBills", { static: false }) private offlineBills;
    @ViewChild("approveAll", { static: false }) private approveAllTemp;
    mainheadehidden: any;

    // public pieChartOptions: ChartOptions = {
    //     responsive: true,
    //     legend: { position: 'bottom' }
    //   };
    pieChartOptions: any;

      public pieChartLabels: Label[] = ['q','e','t'];
      public pieChartData: SingleDataSet = [100,200,300];
      public pieChartType: ChartType = 'pie';
      public pieChartLegend = true;
      public pieChartPlugins = [];
    @ViewChild("firstIntfocusOff", { static: false }) firstIntfocusOff: ElementRef;
    @ViewChild("secondIntfocusOff", { static: false }) secondIntfocusOff: ElementRef;
    @ViewChild("thirdIntfocusOff", { static: false }) thirdIntfocusOff: ElementRef;
    @ViewChild("emailHistoryModal", { static: false }) private emailHistoryModal;
    @ViewChild("emailResendModal", { static: false }) private emailResendModal;
    firstDimensionTempCodeData = [];
    thirdDimensionCodeData = [];
    thirdDimensionTempCodeData = [];
    secondDimensionCodeData = [];
    secondDimensionTempCodeData = [];

    isErrorcode10:boolean = false;
    budgetCategoryItems = [];
    anomaliesCategoryItems = [];
    settlementattachmentproofs=[];

    totalApprovedAmount:number = 0;
    appliedAdvance: number = 0;
    totalReimbursable: number = 0;
    isReadOnly: any;
    isAdvanceReadOnly: boolean;
    showAdvancebtn: boolean;
    tripdetailslist=[];
    advancebtns: boolean;
    tripAttachement: boolean;
    submitDisable:boolean = false;
    tripTravellers=[];
    repushloading:boolean = true;    
    integrationHistory:Array<any> = [];
    selectedStatus: any;
    showdropdown: any;
    statusReason: any;
    isDropdownOpen: boolean = false;
    finenceStatus: any;
    customFieldsInfo: any;
    showeditoption: boolean = true;
    editbledata: any;
    sapRequestData: any;
   

    checkParent: boolean;
    showRepushLoading: any;
    trip_estimate: any;
    tripEstimateInUSD: any;
    isMobileDevice: boolean = false;
    redirectToList: boolean = false;

    @Output() backEvent = new EventEmitter<void>();
    fCurrencies: any = [];

    constructor(
        private renderer: Renderer2,
        public platformService: PlatformService,
        public modalController: ModalController,
        public router: NavController,
        public expenseService: ExpensesService,
        public toastr: ToastrService,
        public reportsService: ReportsService,
        private modalService: BsModalService,
        public alertController: AlertController,
        public formBuilder: FormBuilder,
        public sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private popoverController: PopoverController,
        private zone: NgZone,
        public reportService: ReportsService,
        private commonService: CommonService,
        private translate: TranslateService, private scroller: ViewportScroller,
        private angularRouter: Router,
        private elementRef: ElementRef,
        private loadingController: LoadingController
    ) {
        if (this.angularRouter.url.includes('petty-cash/report')) {
            this.fromPettyCash = true;
        }
        this.fromPettyCash = this.fromPage === 'petty-cash';
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.isFinanceCodeEnabled = this.userInfo?.isFinanceCodeEnabled;
        this.isFinanceCodeEnabledAdvance = this.userInfo?.isFinanceCodeEnabledAdvance;
        this.isTaxCodeEnabled = this.userInfo?.isTaxCodeEnabled;
        this.isHsnCodeEnabled = this.userInfo?.isHsnCodeEnabled;
        this.isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
        this.fCurrencies = JSON.parse(sessionStorage.getItem('foreignCurrencies'));

        this.getMasterCategoryList();
        if (JSON.parse(sessionStorage.getItem('isEmailApproval'))) {
            this.indicatorDisbale = false;
        } else {
            this.indicatorDisbale = true;
        }
    }

    ngOnInit() {
        this.approveAmountDebounce.pipe(debounceTime(600)).subscribe(({ event, index }) => {
            this.approveAmount(event, index);
        });
        this.loadStyles(['/assets/styles/external-style.css']);
        this.personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
        this.mainheadehidden = JSON.parse(sessionStorage.getItem('mainheadehidden'));
        if (this.personalInfo && this.personalInfo.profilePicPath != null) {
            this.profileImgPath = this.personalInfo.profilePicPath;
        } else {
            if (this.userInfo.profilePicPath != null) {
                this.profileImgPath = this.userInfo.profilePicPath;
            } else {
                if (this.userInfo.gender) {
                    if (this.userInfo.gender === "Male") {
                        this.profileImgPath = "./assets/images/userdefaultmale.svg";
                    } else if (this.userInfo.gender === "Female") {
                        this.profileImgPath = "./assets/images/userdefaultfemale.svg";
                    }
                } else if (!this.userInfo.gender) {
                    this.profileImgPath = "./assets/images/userdefaultmale.svg";
                }
            }
        }
        this.fromPettyCash = this.fromPage === 'petty-cash';
        this.platformType = this.platformService.getPlatformType();
        // console.log('undeinnds :',this.detailReport);
        if(JSON.parse(sessionStorage.getItem('selectedValue')) != null){
            this.detailReport = JSON.parse(sessionStorage.getItem('selectedValue'));
        }
        this.showReportDetail(this.detailReport);
        this.reasonForm = this.formBuilder.group({
            rejectedExpenseList: this.formBuilder.array([this.createItem()]),
        });
        if (this.platformType == 2) {
            this.sharedService.getExpenseData().subscribe((data) => {
                if (data) {
                    //  this.onSaveClick(data);
                }
            });
        }

        this.validateAdvance();
        this.validateReport();

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewRepBtn = false;
        }
        if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4 || this.userInfo.roleId == 2) {
            this.getFinanceIntegrationCodeAndTaxCode();
        }
        this.customFieldsInfo = JSON.parse(sessionStorage.getItem('customFieldsInfo'));
        this.finenceStatus = this.customFieldsInfo?.statusDesc?.split(",");
    }
    loadStyles(styles: string[]) {
        for (const style of styles) {
          const link = document.createElement('link');
          link.href = style;
          link.rel = 'stylesheet';
          document.head.appendChild(link);
        }
      }

    ionViewWillEnter() {
    }
 
    ngAfterViewInit() {
        // Set the width for Popover 1
        // const popover1Trigger = this.renderer.selectRootElement('#popover1Trigger');
        // this.renderer.setStyle(popover1Trigger, 'min-width', '750px'); // Adjust the width as needed
      }

    getFinanceIntegrationCodeAndTaxCode() {
        // console.log('rajesh :',this.reportSummary);
        let sendObj =
        {
            "legalEntityId": this.userInfo.legalEntityId,
            "legalEntityGUID": this.userInfo.legalEntityGuId,
            "claimTypeId":this.reportSummary?.claimTypeId ? this.reportSummary?.claimTypeId : null
        }
        this.reportsService.getFinaceIntegrationCodeTaxCode(sendObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                // this.finacerIntegrationCodeData = res.payload.financeCodeList;
                // this.taxCodeData = res.payload.taxCodeList;
                // this.hsnCodeList = res.payload.hsnCodeList;

                // localStorage.setItem('recurrent-codes-list', JSON.stringify(res.payload))
                this.recurrentCodeSetup(res.payload);
            }
        });
    }


    recurrentCodeSetup(data?) {
        let listarray = data?.financeCodeList;
        this.finacerIntegrationCodeData = listarray.map((list) => new FinanceIntCodeModel(list));
        // console.log('rajesh :',this.finacerIntegrationCodeData);
        this.finacerIntegrationTempCodeData = listarray.map((list) => new FinanceIntCodeModel(list));
        let listarray_tax = data?.taxCodeList;
        this.taxCodeData = listarray_tax.map((list) => new TaxCodeModel(list));
        this.taxCodeTempList = listarray_tax.map((list) => new TaxCodeModel(list));

        let listarray_hsn = data?.hsnCodeList;
        this.hsnCodeList = listarray_hsn.map((list) => new HsnCodeModel(list));
        this.hsnCodeTempList = listarray_hsn.map((list) => new HsnCodeModel(list));

        listarray_tax?.forEach(elm => {
            if (elm.isDefault) {
                this.defaultTaxCode = elm.taxCode;
                this.defaultTaxId = elm.taxCodeId;
            }
        });
        listarray_hsn?.forEach(elm => {
            if (elm.isDefault) {
                this.defaultHsnCode = elm.hsnCode;
                this.defaultHsnId = elm.hsnId;
            }
        });
    }

    showRepushButton: any;
    repushButtonLoader = false;
        RepushHistoryClick(RepushHistory: TemplateRef<any>) {
            this.redirectToList = false;
            const config = {
                ignoreBackdropClick: false,
                class: "modal-xl modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(RepushHistory, config);
            this.integrationHistory = [];
            this.expenseService.getIntegrationHistory({ claimId: this.reportSummary?.claimId, claimGUID: this.reportSummary?.claimGUID }).subscribe((res) => {
                if (res.errorCode == 0) {
                    this.integrationHistory = [];
                    if (res.payload.integrationHistory.length != 0) {
                        res.payload.integrationHistory.forEach(element => {
                            element.repushSpinner = false;
                        });
                        this.showRepushButton = res?.payload?.showRepushButton || false;
                        // res.payload.integrationHistory = _.map(res.payload.integrationHistory, (item) => ({ ...item, repushSpinner: false }));
                    }
                    this.repushloading = false;
                    this.integrationHistory = [...res.payload.integrationHistory];
                }

            })
        }


        RepushRequest(item?, index?) {
            let obj = {
                claimId:this.integrationHistory[0]?.claimId,
                claimGUID:this.integrationHistory[0]?.claimGUID,
                legalEntityGuId:this.userInfo?.legalEntityGuId,
                legalEntityId:this.userInfo?.legalEntityId
            }
            this.repushButtonLoader = true;
            // this.integrationHistory[index].repushSpinner = true;
            this.expenseService.repushRequest(obj).subscribe(res => {
                // this.integrationHistory[index].repushSpinner = false;
                // this.integrationHistory[index].showRepushButton = false;
                this.repushButtonLoader = false;
                this.toastr.success('Success', res.payload);
                this.getReportSummary();
                this.modalClose();
            }, err => {
                this.repushButtonLoader = false;
            })
        }


    seletTaxCode(code, i) {
        this.expenseList[i].taxCodeId = code?.taxCodeId;
        this.expenseList[i].taxCode = code?.taxCode;
        this.expenseList[i].taxShow = false;
    }

    seletGsTaxCode(code, i) {
        this.expenseList[i].gstTaxCodeId = code?.taxCodeId;
        this.expenseList[i].gstaxCode = code?.taxCode;
        this.expenseList[i].gstaxShow = false;
    }

    displayMerchant(item) {
        let fieldsInfo = JSON.parse(sessionStorage.getItem('customFieldsInfo'))?.expenseForm?.systemFieldList || [];
        let index = fieldsInfo?.findIndex(f => f.fieldName == 'Merchant');
        let displayMerchant = false;
        if(index >= 0) {
            let merchantFieldObj = fieldsInfo[index];
            // merchantFieldObj.categoryIds = [1418, 241, 239];
            if(merchantFieldObj?.categoryIds && merchantFieldObj?.categoryIds?.length > 0) {
                displayMerchant  = merchantFieldObj?.categoryIds?.includes(item?.categoryId);
            } else {
                displayMerchant = true;
            }
        }
        return displayMerchant;
    }

    loadMerchantList(item) {
        // console.clear();
        // console.log(item);
        // console.log('categoriesList __________ ', this.categoriesList);
        this.merchantsList = [];
        const index = this.categoriesList?.findIndex(c => c?.categoryId == item?.categoryId);
        if(index >= 0) {
            // console.log(this.categoriesList[index]);
            this.merchantsList = this.categoriesList[index]?.merchantList || [];
        }
        // console.log('merchantsList __________ ', this.merchantsList);
        // console.log(this.expenseList[i]);
    }

    merchantsList: any = [];
    selectedMerchant(merchant, i) {
        // console.clear();
        // console.log(i, merchant);
        // console.log('categoriesList __________ ', this.categoriesList);

        // const index = this.categoriesList?.findIndex(c => c?.categoryId == item?.categoryId);
        // if(index >= 0) {
        //     console.log(this.categoriesList[index]);
        //     this.merchantsList = this.categoriesList[index]?.merchant || [];
        // } else {
        //     this.merchantsList = [];
        // }

        this.expenseList[i].merchantId = merchant?.merchantId || null;
        this.expenseList[i].merchantCode = merchant?.merchantCode || null;
        this.expenseList[i].merchantName = merchant?.merchantName || null;
        // console.log(this.expenseList[i]);
        
    }

    categoriesList: any;
    getMasterCategoryList() {
        let obj = {
            isDefault: false,
            recordType: 1,
            legalEntityId: this.userInfo.legalEntityId,
            legalEntityGUID: this.userInfo.legalEntityGuId,
        }
        this.commonService.getCatgryMasterlist(obj).subscribe((res) => {
            this.categoriesList = res?.payload?.expenseCategoryList || [];
            // console.log(this.categoriesList);
        });
    }
 
    // financeIntegrationCode(code, i) {
    //     this.expenseList[i].financeIntegrationId = code?.financeIntegrationId;
    //     this.expenseList[i].financeIntegrationCode = code?.financeIntegrationCode;
    //     this.expenseList[i].financeIntShow = false;
    //     this.expenseList[i].finaceIntCodeName = code.financeIntegrationCode + ' - ' + code.financeIntegrationName;
    
    //     this.expenseList[i].firstDimensionCodeData = [];
    //     this.expenseList[i].secondDimensionCodeData = [];
    //     this.expenseList[i].thirdDimensionCodeData = [];
    //     this.expenseList[i].baDimension1Name = ''; // Initialize baDimension1Name
    //     this.expenseList[i].baDimension2Name = ''; // Initialize baDimension2Name
    //     this.expenseList[i].baDimension3Name = ''; // Initialize baDimension3Name
    
    //     console.log("code.dimensions", code.dimensions);
    //     code.dimensions.forEach((element, index) => { // Added index to track the first object
    //         let request = {
    //             "id": element.id,
    //             "dimensionCode": element.dimensionCode
    //         };
    //         if (element.level == 1) {
    //             this.expenseList[i].firstDimensionCodeData.push(request);
    //             this.expenseList[i].firstDimensionTempCodeData.push(request);
    //             if (index === 0) {
    //                 this.expenseList[i].baDimension1Name = element.dimensionCode; 
    //                 console.log(this.expenseList[i].baDimension1Name)
    //                 // Set baDimension1Name for the first object
    //             }
    //         } else if (element.level == 2) {
    //             this.expenseList[i].secondDimensionCodeData.push(request);
    //             this.expenseList[i].secondDimensionTempCodeData.push(request);
    //             if (index === 0) {
    //                 this.expenseList[i].baDimension2Name = element.dimensionCode;
    //                 console.log("this.expenseList[i].baDimension2Name",this.expenseList[i].baDimension2Name) // Set baDimension2Name for the first object
    //             }
    //         } 
    //         else if (element.level == 3) {
    //             this.expenseList[i].thirdDimensionCodeData.push(request);
    //             this.expenseList[i].thirdDimensionTempCodeData.push(request);
    //             if (index === 0) {
    //                 this.expenseList[i].baDimension3Name = element.dimensionCode; 
    //                 console.log("this.expenseList[i].baDimension3Name",this.expenseList[i].baDimension3Name) // Set baDimension2Name for the first object
    //                 // Set baDimension3Name for the first object
    //             }
    //         }
    //     });
    // }
    financeIntegrationCode(code, i) {
        console.log('selected code :',code);
        this.expenseList[i].financeIntegrationId = code?.financeIntegrationId;
        //this.expenseList[i].financeIntegrationCode = code?.financeIntegrationCode;
        // if(code.financeIntegrationCode){
        //     this.expenseList[i].financeIntegrationCode = code.financeIntegrationCode + ' - ' + code.financeIntegrationName;
        // }else{
            
        // }
        this.expenseList[i].financeIntegrationName =  code.financeIntegrationName;
        this.expenseList[i].financeIntegrationCode =  code.financeIntegrationCode ? code.financeIntegrationCode : '';
        this.expenseList[i].financeIntShow = false;
        // if(code.financeIntegrationCode){
        //     this.expenseList[i].financeIntegrationName = code.financeIntegrationCode + ' - ' + code.financeIntegrationName;
        // }else{
        //     this.expenseList[i].financeIntegrationName =  code.financeIntegrationName;
        // }
    
        this.expenseList[i].firstDimensionCodeData = [];
        this.expenseList[i].secondDimensionCodeData = [];
        this.expenseList[i].thirdDimensionCodeData = [];
        this.expenseList[i].baDimension1Name = '';
        this.expenseList[i].baDimension2Name = '';
        this.expenseList[i].baDimension3Name = '';
        this.expenseList[i].baDimension1 = null;
        this.expenseList[i].baDimension2 = null;
        this.expenseList[i].baDimension3 = null;
    
    
        // Initialize variables to track the first objects for each dimension level
        let firstFirstDimension = null;
        let firstSecondDimension = null;
        let firstThirdDimension = null;
    
        code.dimensions.forEach((element) => {
            let request = {
                "id": element.id,
                "dimensionCode": element.dimensionCode
            };
    
            if (element.level == 1) {
                this.expenseList[i].firstDimensionCodeData.push(request);
                this.expenseList[i].firstDimensionTempCodeData.push(request);
    
                // Update baDimension1Name if it's the first object for this level
                if (!firstFirstDimension) {
                    this.expenseList[i].baDimension1 = element.id;
                    this.expenseList[i].baDimension1Name = element.dimensionCode;
                    firstFirstDimension = element;
                }
            } else if (element.level == 2) {
                this.expenseList[i].secondDimensionCodeData.push(request);
                this.expenseList[i].secondDimensionTempCodeData.push(request);
    
                // Update baDimension2Name if it's the first object for this level
                if (!firstSecondDimension) {
                    this.expenseList[i].baDimension2 = element.id;
                    this.expenseList[i].baDimension2Name = element.dimensionCode;
                    firstSecondDimension = element;
                }
            } else if (element.level == 3) {
                this.expenseList[i].thirdDimensionCodeData.push(request);
                this.expenseList[i].thirdDimensionTempCodeData.push(request);
    
                // Update baDimension3Name if it's the first object for this level
                if (!firstThirdDimension) {
                    this.expenseList[i].baDimension3 = element.id;
                    this.expenseList[i].baDimension3Name = element.dimensionCode;
                    firstThirdDimension = element;
                }
            }
        });
    }
    
    
    hsnCodeCall(code, i) {
        this.expenseList[i].hsnId = code?.hsnId;
        this.expenseList[i].hsnCode = code?.hsnCode;
        this.expenseList[i].hsnShow = false;

    }

    pdfchecking(value) {
        if (value?.url || value?.url != null) {
            if (value.url.split('?')[0].split('.').pop() == 'pdf') {
                return 'pdf'
            } else if(value.url.split('?')[0].split('.').pop() == 'zip' || value.url.split('?')[0].split('.').pop() == 'rar') {
                return 'zip'
            } else {
                return 'png'
            }
        }
    }

    validateAdvance() {
        this.advanceForm = this.formBuilder.group({
            paymentMode: ["", Validators.required],
            comment: [""],
            reference: [""],
        });

    }

    get advf() {
        return this.advanceForm.controls;
    }

    validateReport() {
        this.reportPymtForm = this.formBuilder.group({
            paymentMode: ["", Validators.required],
            isLinkedToAdvancePayment: [""],
            isLinkedToPettyCashPayment: [""],
            comment: [""],
            reference: [""],
        });
    }

    get reportf() {
        return this.reportPymtForm.controls;
    }

    scrollToElement(el): void {
        setTimeout(() => {
            this.myScrollContainer.nativeElement.scroll({
                top: this.myScrollContainer.nativeElement.scrollHeight,
                left: 0,
                behavior: 'smooth',
                block: 'start'
            });
            this.isSecondOpen = true;
        }, 100);

    }


    createItem(exp?) {
        if (exp) {
            // return this.formBuilder.group({
            //     expenseId: [exp?.expenseId],
            //     reason:["", Validators.required],
            // });
            if (this.userInfo.isRejectReasonMandatory) {
                return this.formBuilder.group({
                    expenseId: [exp?.expenseId],
                    reason: ["", Validators.required],
                });
            } else {
                return this.formBuilder.group({
                    expenseId: [exp?.expenseId],
                    reason: [""],
                });
            }
        } else {
            return this.formBuilder.group({
                expenseId: [exp?.expenseId],
                reason: ["", Validators.required],
            });
        }
    }

    get rejectControls() {
        return this.reasonForm.get("rejectedExpenseList") as FormArray;
    }

    isShowExpense(value) {
        if (this.showDetails === value) {
            this.showDetails = -1;
        } else {
            this.showDetails = value;
        }
    }
   
    get isRelatedProofs() {

        if (this.expenseList) {
            for (const item of this.expenseList) {
                if (item.expenseAttachments && item.expenseAttachments.length && item.expenseAttachments[0].attachmentType == '1') {
                    return false;
                }
            }
        }
        return true;
    }

    async segmentChanged($event: any) {
        this.repSummary = $event.detail.value;
        let index;
        if (this.repSummary === 'expense') {
            index = 0;
        } else if (this.repSummary === 'report') {
            index = 1;
        } else if (this.repSummary === 'proofs') {
            index = 2;
        } else if (this.repSummary === 'history') {
            index = 3;
        } else if (this.repSummary === 'payment') {
            index = 4;
        } else if (this.repSummary === 'tripdetails') {
            index = 5;
        }
        this.focusSegment($event['srcElement']['children'][index]['id']);
        await this.slider.slideTo(index);
        await this.slider.update();
        this.changeDetectorRef.detectChanges();
    }

    async slideChanged() {
        const index = await this.slider.getActiveIndex();
        if (index === 0) {
            this.repSummary = 'expense';
        } else if (index === 1) {
            this.repSummary = 'report';
        } else if (index === 2) {
            this.repSummary = 'proofs';
        } else if (index === 3) {
            this.repSummary = 'history';
        } else if (index === 4) {
            if (this.reportSummary.status == 7 && this.reportSummary.recordType == 1) {
                this.repSummary = 'payment';
            }/* else if (this.reportSummary.recordType == 2) {
                this.repSummary = 'tripdetails';
            }*/
        } else if (index === 5) {
            this.repSummary = 'tripdetails';
        }
        this.changeDetectorRef.detectChanges();
    }

    focusSegment(segmentId) {
        document.getElementById(segmentId).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }
    showSettlementButton(){
        let requestobj={
            claimGUID:this.reportSummaryRequestModal.claimGUID,
            claimId:this.reportSummaryRequestModal.claimId
        }

        this.reportsService.getSettlAdvance(requestobj).subscribe((data) => {

            if (!data.isError && data.errorCode === 0) {
                this.modalRef.hide();
                this.getReportSummary();
            }
        });

    }
    selectedTripInvoiceArray: any[];
    pdfloader: boolean = false;
    tripsPdfCall(pdfSelection1: TemplateRef<any>, trip,type) {
      this.selectedTripInvoiceArray = [];
      this.pdfloader = true;
      if(type == "settlement"){
        this.getsettlementProofs(trip.settlementSummary);
      }else{
       // this.getpdfTripProofs(trip);
      }
  
      //this.getTripDetails(trip);
      const config = {
        ignoreBackdropClick: false,
        class: "modal-dialog-centered modal-lg baa",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(pdfSelection1, config);
    }
    getsettlementProofs(settlementdetails){
        this.invoiceDetails = [];
        this.invoiceDetails = settlementdetails.attahcments ||[];
    
        if (this.invoiceDetails) {
          let urlStr = this.invoiceDetails[0]?.url;
          if (urlStr?.split("?")[0]?.split(".")?.pop() == "pdf") {
            this.isPdf = true;
            this.isZip = false;
            this.getPdfAsBase64(urlStr).then((baseRes) => {
              const linkSource = `data:application/pdf;base64,${baseRes}`;
              this.pdfData = linkSource;
             // this.invoiceDetails[0].content = baseRes
              this.pdfloader = false;
            })
          } else if (urlStr?.split("?")[0].split(".").pop() == "zip" || urlStr?.split("?")[0].split(".").pop() == "rar") {
            this.isPdf = false;
            this.isZip = true;
          } else {
            this.isPdf = false;
            this.isZip = false;
            this.pdfloader = false;
          }
        }
      }

    filterModal = new FilterModal();
    downloadModel = new DownloadModel();

    fromApprovalsPage: any;
    tripData: any = {
        bookingType: '',
        customFields: '',
        costCenterCode: '',
        reasonForTravel: '',
        bookingTypeName: ''
    }
    showMultiCurrencies: boolean = false;
    getReportSummary(hideLoader?) {

        this.showSummaryLoading = hideLoader ? false : true;
        this.reportSummaryRequestModal.recordType = this.detailReport.recordType;
        // this.reportSummaryRequestModal.viewType = this.userInfo.roleId;
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            this.reportSummaryRequestModal.viewType = this.userInfo.roleId;
        } else {
            this.reportSummaryRequestModal.viewType = 1;
        }
        let isEmailApproval = JSON.parse(sessionStorage.getItem("isEmailApproval"))
        if (this.angularRouter.url == '/expenses') {
            this.reportSummaryRequestModal.isApproval = false;
            this.fromApprovalsPage = false;
        } else if (this.angularRouter.url == '/approvals' || isEmailApproval) {
            this.reportSummaryRequestModal.isApproval = true;
            this.fromApprovalsPage = true;
        }
        this.reportsService.getReportSummary(this.reportSummaryRequestModal).subscribe((data) => {

            if (!data.isError && data.errorCode === 0) {
                let summary = data?.payload?.reportSummary;
                let userRoleId = this.userInfo?.roleId;
                if (summary?.legalEntityId && summary?.legalEntityGUID && [2, 3, 4]?.includes(userRoleId) && this.isAdmin) {
                    this.getCustomFieldsInfo(summary?.legalEntityId, summary?.legalEntityGUID);
                }
                this.editbledata = data.payload?.editableFields;
                this.showMultiCurrencies = data?.payload?.reportSummary?.showMultiCurrencies || false;
                if(this.showMultiCurrencies) {
                    data?.payload?.reportSummary?.expenseList?.forEach(el => {
                        const validateIcon = icon => this.commonService.isIconPresent(icon) ? icon : null;                        
                        el.originalCurrencyIcon = validateIcon(el.originalCurrencyIcon);
                        el.baseCurrencyIcon = validateIcon(el.baseCurrencyIcon);
                    });
                }
                this.sapRequestData = data.payload?.sapRequestData;
                if(this.editbledata)
                {
                    const jsondata = JSON.parse(this.editbledata);
                    this.editbledata = jsondata;
                    // console.log(this.editbledata)
                }

                console.log('isEmail Approval :',JSON.parse(sessionStorage.getItem("isEmailApproval")));
                sessionStorage.setItem('detailReport',JSON.stringify(data.payload?.reportSummary));

                this.tripData.customFields = data.payload?.reportSummary?.customFields || null;
                this.tripData.reasonForTravel = data.payload?.reportSummary?.reasonForTravel || null;
                this.tripData.bookingType = data.payload?.reportSummary?.bookingType || null;
                this.tripData.costCenterCode = data.payload?.reportSummary?.costCenterCode || null;
                this.tripData.bookingTypeName = data.payload?.reportSummary?.bookingTypeName || null;
                this.tripData.tripStartDate = data?.payload?.tripStartDate || null;
                this.tripData.tripEndDate = data?.payload?.tripEndDate || null;
                sessionStorage.setItem('tripData', JSON.stringify(this.tripData));
                this.finanaceBtns = data.payload;
                this.partialEnableVal = false;
                this.rejectEnableVal = false;
                this.rejectedExpense = [];
                this.reportCostSummary = [];
               this.advancebtns =data.payload.showApproveButton


                this.hidePaymentButton = data.payload?.reportSummary?.hidePaymentButton;
                this.reportCostSummary = data.payload?.reportSummary.reportCostSummary;
                let previouseList = [];
                let approverList = [];
                previouseList = data.payload.reportSummary.expenseList;
                approverList = data.payload.reportSummary.approversList;
                approverList?.forEach(element => {
                    if (element.approversList) {
                        data.payload.reportSummary.approversList.push(element);
                    }
                });
                data.payload.reportSummary.expenseList = [];
                previouseList.forEach(element => {
                    if (element.hasApproveRejectAction) {
                        data.payload.reportSummary.expenseList.push(element);
                    }
                });
                previouseList.forEach(element => {
                    if (!element.hasApproveRejectAction) {
                        data.payload.reportSummary.expenseList.push(element);
                    }
                });
                previouseList.forEach(element => {
                    if (element.hasViolation) {
                        this.showViolationCheckBox = true;

                    }else{
                        this.showViolationCheckBox = false; 
                    }
                });
                console.log('incoming report summary :',data.payload.reportSummary);
                this.sharedService.setReportSummaryData(data.payload.reportSummary);
                this.reportSummary = new ReportSummaryResponseModal(data.payload.reportSummary);
                // PASS customGlcode in place of financeIntegrationCode
                this.reportSummary?.expenseList?.forEach((element, i) => {
                    if(element.customGlcode && element.customGlcode != ''){
                        element.financeIntegrationCode = element.customGlcode;
                        let finIntgName = element.financeIntegrationName?.split('-');
                        element.financeIntegrationName = finIntgName[0] ? finIntgName[0] + ' - ' + element.financeIntegrationCode : element.financeIntegrationCode;
                    }
                });
                this.reportSummary.isLinkedToAdvancePayment = this.reportSummary.isLinkedToAdvancePayment || false;
                this.trip_estimate = data?.payload?.reportSummary?.claimTaggedTripList[0]?.tripEstimateCost || null;
                this.tripEstimateInUSD = data?.payload?.reportSummary?.tripEstimateInUSD || null;
                // console.log('incoming report summary :',this.reportSummary);
                if(this.reportSummary?.claimTaggedTripList?.length != 0 && ((this.reportSummary?.status == 3 && data.payload.showApproveButton) || this.reportSummary?.status == 11)){
                    const request = {
                        claimId:this.reportSummary?.claimId,
                        claimGuid:this.reportSummary?.claimGUID,
                        tripId:this.reportSummary?.tripId,
                        tripGUID:this.reportSummary?.claimTaggedTripList[0]?.tripGUID
                    }
                    this.expenseService.updateTripService(request).subscribe(res =>{
                        console.log('trip update service :',res);
                        if(res.payload?.serviceList) {
                            this.reportSummary.claimTaggedTripList = res.payload?.serviceList;
                        }
                        if(res?.payload?.serviceList[0]?.tripEstimateCost) {
                            this.trip_estimate = res?.payload?.serviceList[0]?.tripEstimateCost || null;
                            this.tripEstimateInUSD = res?.payload?.serviceList[0]?.tripEstimateInUSD || null;
                        }
                    })
                }

                if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4 || this.userInfo.roleId == 2) {
                    this.getFinanceIntegrationCodeAndTaxCode();
                }
              

                const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));

                const current_modalObj = {
                    name: 'claim_detailview',
                    data: {
                        currentClaimIndex: this.currentClaimIndex,
                        reportListdata: current_modal?.data?.reportListdata,
                        detailReport: this.reportSummary,
                        fromPage: current_modal?.data?.fromPage,
                    }
                }
                sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));

                this.approveMessage = { statusdes: this.reportSummary.statusDescription, status: this.reportSummary.status };
                if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4 && this.reportSummary.status == 3) {
                    this.reportSummary.expenseList.forEach((element, i) => {
                    });
                }
                if (this.reportSummary.recordType == 3) {
                    this.expenseList = [];
                    this.rejectedexpenseList = [];
                    // this.expenseList = this.reportSummary.expenseList[0].localConveyanceList;
                    //newCodeLC
                    // this.expenseList = this.reportSummary.expenseList[0];
                    this.reportSummary.expenseList[0].localConveyanceList.forEach(element => {
                        if (element.status == 4 || element.status == 5) {
                            //this.expenseList.push(element);
                        } else {
                            if (element.status == 6 || element.status == 7) {
                                //  element.checkImage = true
                                element.checkimg = 'right-green';
                                element.isSELECT = null;
                            } else {
                                element.checkImage = false
                                element.crossImage = false
                                element.checkimg = 'right-grey';
                                element.crossimg = 'close-grey';
                                element.isSELECT = null;
                            }
                            this.expenseList.push(element);
                        }
                    });

                    this.expenseList.forEach(element => {
                        if (element.status == 4 || element.status == 5) {
                            this.rejectedexpenseList.push(element);
                        }
                        element.rejectReason = '';
                    });

                } else {
                    //newCode
                    this.expenseList = [];
                    this.rejectedexpenseList = [];

                    this.reportSummary.expenseList.forEach(element => {

                        if (element.status == 4 || element.status == 5) {
                        } else {

                            if (element.status == 6 || element.status == 7) {
                                element.checkImage = true
                                element.checkimg = 'right-green';
                                element.isSELECT = null;
                            } else {
                                element.checkImage = false
                                element.crossImage = false
                                element.checkimg = 'right-grey';
                                element.crossimg = 'close-grey';
                                element.isSELECT = null;
                            }
                            if(!element.violationList) {
                                element.violationList = [];
                            }
                            if(element.isAlcohol) {
                                element.violationList.push({violationMessage: "Alcohol expenses can't be claimed." })
                            }
                            this.expenseList.push(element);
                        }
                    });
                    this.reportSummary.expenseList.forEach(element => {
                        if (element.status == 4 || element.status == 5) {
                            this.rejectedexpenseList.push(element);
                        }
                        element.rejectReason = '';
                    });
                    this.expenseList.forEach(elm => {
                        if (elm.expenseAttachments) {
                            elm.expenseAttachments.forEach(obj => {
                                if (obj.attachmentType == null || obj.attachmentType == 1) {
                                    elm.hasAttach = true;
                                } else {
                                    elm.hasAttach = false;
                                }
                            });
                        }
                    });
                    this.rejectedexpenseList.forEach(elm => {
                        if (elm.expenseAttachments) {
                            elm.expenseAttachments.forEach(obj => {
                                if (obj.attachmentType == null || obj.attachmentType == 1) {
                                    elm.hasAttach = true;
                                } else {
                                    elm.hasAttach = false;
                                }
                            });
                        }
                    });

                    // tripDetails

                    this.tripsDetailsList = [];
                    this.reportSummary.claimTaggedTripList.forEach(element => {
                        this.tripsDetailsList.push(element);
                    });
                    this.reportSummary.tripTravellersList.forEach(element => {
                        this.tripTravellers.push(element);
                    });
                    // const ids = this.tripsDetailsList.map(o => o.tripId)
                    // this.tripsDetailsList = this.tripsDetailsList.filter(({ tripId }, index) => !ids.includes(tripId, index + 1));

                    var expList = this.reportSummary.expenseList;
                    var attachmentsExp = [];

                    expList.forEach(element => {

                        if (element.expenseAttachments != null) {
                            element.expenseAttachments.forEach(objone => {
                                attachmentsExp.push(objone);
                            });
                        }
                    });

                    this.tripsDetailsList.forEach(istrip => {
                        attachmentsExp.forEach(elm => {

                            if (elm.referenceId == istrip.tripId && (elm.attachmentType == "2" || elm.attachmentType == "1")) {
                                istrip.isTripShow = true;
                            }
                        });
                    });

                    this.tripsTotalAmount = this.tripsDetailsList
                        .map(item => item.totalAmount)
                        .reduce((prev, curr) => prev + curr, 0);

                    // const str1 = parseInt(this.reportSummary?.totalBaseAmount);
                    // const str2 = parseInt(this.tripsTotalAmount);
                    // this.allTotalAmount = str1 + str2

                }
                this.pendingExps = [];
                this.finPending = [];
                this.categoryBaseExpenseList = [];
                this.expenseList.forEach(element => {
                    if (element.hasApproveRejectAction) {
                        this.categoryBaseExpenseList.push(element);
                    }
                    if (element.status == 2) {
                        this.pendingExps.push(element);
                    }
                    if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4 && element.status == 3) {
                        this.finPending.push(element);
                    }
                    element.rejectReason = '';
                    element.firstDimensionCodeData = [];
                    element.secondDimensionCodeData = [];
                    element.thirdDimensionCodeData = [];
                    element.firstDimensionTempCodeData = [];
                    element.secondDimensionTempCodeData = [];
                    element.thirdDimensionTempCodeData = [];
                    // element.baDimension1Name = '';
                    // element.baDimension2Name = '';
                    // element.baDimension3Name = '';
                });
                this.selectedExpense = [];
                this.reportHistoryList = this.reportSummary.reportHistoryList;
                this.getReportModal.claimGUID = this.reportSummary.claimGUID;
                this.getReportModal.claimId = this.reportSummary.claimId;

                // link to advance is setup based on configutation in general setting and available adavance balance
                // if(this.apiAvailableAdv != 0 && this.userInfo.isLinkedToAdvanceMandatory){
                //     this.recordRptPymntModel.isLinkedToAdvancePayment = true;
                //     this.getReportModal.isLinkedToAdvancePayment = true;
                // }else{
                //     this.recordRptPymntModel.isLinkedToAdvancePayment = false;
                //     this.getReportModal.isLinkedToAdvancePayment = false;
                // }

                this.recordRptPymntModel.isLinkedToAdvancePayment = this.reportSummary?.isLinkedToAdvancePayment;
                this.getReportModal.isLinkedToAdvancePayment = this.reportSummary?.isLinkedToAdvancePayment;
                this.recordRptPymntModel.isLinkedToPettyCashPayment = this.reportSummary?.isLinkedToPettyCashPayment;
                this.getReportModal.isLinkedToPettyCashPayment = this.reportSummary?.isLinkedToPettyCashPayment;
                // if (this.reportSummary.status == 3) {//Finance approved, we need it for recordPAymt or adv
                //     this.advanceAmtApi(this.reportSummary.userId, this.reportSummary.userGUID);
                // }
                // if (this.reportSummary.status == 6) {//Finance approved, we need it for recordPAymt or adv
                //     this.advanceAmtApi(this.reportSummary.userId, this.reportSummary.userGUID);
                // }
                this.advanceAmtApi(this.reportSummary.userId, this.reportSummary.userGUID);
                // if(this.reportSummary.claimTaggedTripList.length != 0){
                //     this.getServiceList(this.reportSummary.claimTaggedTripList[0]);
                // }

                this.checkAll = false;
                this.indeterminateState = false;
                this.showSummaryLoading = false;
                this.setLeftStatus();
                // this.doughnutCharts();
                /*report btns code */

                //newCode

                let userStr: any;
                let pathStr = window.location.pathname;

                if (pathStr == '/reports') {
                    userStr = true;
                } else {
                    userStr = false;
                }

                //  isApprRejBtnEnable

                // finance flow

                this.financeApproverIds = this.reportSummary.financeApproverIds;
                var userid = this.userInfo.userId;
                let isEnableOptions = _.find(this.financeApproverIds, function (object) {
                    return object == userid;
                });

                if (isEnableOptions != undefined && this.isAdmin) {
                    this.isApprRejBtnEnable = true;
                } else {
                    this.isApprRejBtnEnable = false;
                }

                if (this.reportSummary.status == 6) {
                    this.disableApprove = true;
                } else {
                    this.disableApprove = false;
                }

                if (this.reportSummary.status == 6 || this.reportSummary.status == 7) {
                    this.showApproveAmt = true;
                } else {
                    this.showApproveAmt = false;
                }

                // for manager flow show finance intergration code and tax code

                if (this.reportSummary.status == 2 || this.reportSummary.status == 4) {
                    this.adminFinaceTaxShow = false;
                } else {
                    this.adminFinaceTaxShow = true;
                }

                // manager flow

                if (this.reportSummary.status === 2 && this.reportSummary.approverId == this.userInfo.userId) {//&& this.userInfo.roleId != 3
                    this.approveBtns = true;
                }
                else {
                    this.approveBtns = false;
                }

                // admin flow

                if (this.userInfo.roleId == 2 && data.payload?.showApproveButton) {
                    this.adminBtnShow = true;
                } else {
                    this.adminBtnShow = false;
                }
                // record payment button      

                if (this.reportSummary.status === 1) {
                    this.shownHeader = "All Expenses";
                }
                if ((this.reportSummary.status === 2) && (this.reportSummary.approverId !== this.userInfo.userId) && userStr) {

                    this.shownHeader = "Awaiting For Approval"; //for user
                }
                if ((this.reportSummary.status === 2) && (this.reportSummary.approverId !== this.userInfo.userId) && !userStr) {

                    this.shownHeader = "Approved Expenses"; //for approvar
                }
                if ((this.reportSummary.status === 2) && (this.reportSummary.approverId == this.userInfo.userId)) {

                    this.shownHeader = "Awaiting For Approval";
                } else {
                    this.shownHeader = this.reportSummary?.statusDescription;
                }

                if (this.reportSummary.status === 3 && (this.reportSummary.approverId == this.userInfo.userId)) {

                    this.shownHeader = "Awaiting For Financer";
                }
                if (this.reportSummary.status === 3 && this.userInfo.roleId === 1 && this.reportSummary.approverId !== this.userInfo.userId) {

                    this.shownHeader = "Approved Expenses";
                }
                if (this.reportSummary.status === 3 && this.userInfo.roleId === 1 && this.reportSummary.approverId !== this.userInfo.userId && userStr) {

                    this.shownHeader = "Awaiting For Approval";
                }
                if (this.reportSummary.status === 3 && this.userInfo.roleId === 3) {

                    this.shownHeader = "Awaiting For Approval";
                }

                if (this.reportSummary.status === 6) {
                    this.shownHeader = "Approved Expenses";
                }
                if (this.reportSummary.status === 7) {
                    this.shownHeader = "Approved Expenses";
                }


                this.expenseList.forEach(obj => {
                    if (obj.taxCodeId == null || obj.taxCodeId == '') {
                        obj.taxCodeId = this.defaultTaxId;
                        obj.taxCode = this.defaultTaxCode;
                    }
                    if (obj.hsnId == null || obj.hsnId == '') {
                        obj.hsnId = this.defaultHsnId;
                        obj.hsnCode = this.defaultHsnCode;
                    }
                });
                this.expenseList.forEach(obj => {
                    if (obj.financeIntegrationCode) {
                        obj.financeIntegrationName = obj.financeIntegrationName;
                    }else{
                        obj.financeIntegrationName =  obj.financeIntegrationName;
                    }

                });
                // this.getExpenseAttachmentsCall();
                // this.reportCostSummeryChart();
                this.openPiechart();

                this.tripdetailslist = this.reportSummary?.claimTaggedTripList||[];

                this.tripdetailslist.forEach(element => {
                    if(element?.tripServiceAttachments){
                        element?.tripServiceAttachments.forEach(childelement => {
                          if(childelement?.fileURL != null){
                            this.tripAttachement =true
                          }else{
                            this.tripAttachement =false
                          }
                        });
                      }
                });
                this.reportSummary.advanceSummary.tripAdvanceAppliedAmount=parseFloat((this.reportSummary?.advanceSummary.tripAdvanceAppliedAmount || 0).toString().replace(/,/g, ''));
                
                this.reportSummary.advanceSummary.amount=parseFloat((this.reportSummary?.advanceSummary.amount || 0).toString().replace(/,/g, ''));
                // this.reportSummary.advanceSummary.tripAdvanceAppliedAmount=parseInt(this.reportSummary?.advanceSummary.tripAdvanceAppliedAmount?.toString().replace(/,/g, '') || 0);
                let files = data.payload.reportSummary.settlementSummary.attahcments || [];
                this.settlementattachmentproofs = [];
                files?.forEach((f) => {
                    if(f.fileURL) { this.settlementattachmentproofs.push({ url: f.fileURL }) }
                })
                data.payload.reportSummary.settlementSummary.attahcments = [];
                this.settlementattachmentproofs.forEach((rr) => {
                    if (this.returnExtension(rr.url) == 'pdf') {
                        this.getPdfAsBase64(rr.url).then((baseRes) => {
                            rr.content = `data:application/pdf;base64,${baseRes}`;
                            data.payload.reportSummary?.settlementSummary.attahcments.push(rr)
                        })
                    } else {
                        data.payload.reportSummary?.settlementSummary.attahcments.push(rr)
                       
                    }
                })
                
                this.getAdvanceLinkSummary();

                // this.showChar();
                // if (this.tripsDetailsList.length > 0) {
                //     this.showTripsChar();
                // } else {
                //     this.showExpenseChar();
                // }
                // if (this.expenseList.length > 1 && this.tripsDetailsList.length > 0) {
                //     this.expenseList.forEach((rep) => {
                //         if (rep.isAssociated && rep.expenseTaggedTripList.length > 0) {
                //             this.showAssociateTripChar();
                //         }
                //     });
                // }
                
                if(this.redirectToList && this.hidePaymentButton && !isEmailApproval) {
                    this.backToreport();
                }

            } else if (data.isError && data.errorCode === 2) {
                this.showSummaryLoading = false;
                this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }
    convertStringToNumber(): number {
        // Remove comma separators and convert to a number
        const numberWithoutCommas1 = parseInt(this.reportSummary?.approvedAmount.replace(/,/,''));
        const numberWithoutCommas2 = this.reportSummary?.advanceSummary?.advanceCode != null ? parseFloat(this.reportSummary?.advanceSummary?.tripAdvanceAppliedAmount) : parseFloat(this.reportSummary?.advanceAmount);
        return isNaN(numberWithoutCommas1 - numberWithoutCommas2) ? 0 : numberWithoutCommas1 - numberWithoutCommas2; // Handle invalid inputs
    }
      

    // removeExtraDigits(val){
    //     const first2Str = String(val).slice(0, 6); // 👉️ '13'
    //     const first2Num = Number(first2Str); // 👉️ 13
    //     val = first2Num
    //    let myVal = val.patchValue(first2Num);
    //     return myVal
    // }


    getExpenseAttachmentsCall(obj, index?) {
        //  this.getExpenseIds.expenseIds = this.reportSummary.expenseIds;
        // this.present();
        this.getExpenseIds.expenseIds = [obj];
        this.getExpenseIds.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseIds.legalEntityGUID = this.userInfo.legalEntityGuId;

        if (index) {
            this.defaultIndex = index;
        }

        // this.invoiceDetails = [];
        this.invoiceDetails = obj.expenseAttachments;
        this.mainInvoiceDetails = obj.expenseAttachments;

        if (this.invoiceDetails) {
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                // this.isPdf = true;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                // this.pdfData = linkSource;
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if (urlStr.split('?')[0].split('.').pop() == "zip" || urlStr.split('?')[0].split('.').pop() == "rar") {
                this.isPdf = false;
                this.isZip = true;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
        }

        // this.expenseService
        //     .expenseAttachViewer(this.getExpenseIds)
        //     .subscribe((res) => {
        //         if (!res.isError && res.errorCode === 0) {
        //             this._dismiss();
        //             if (index) {
        //                 this.defaultIndex = index;
        //             }
        //             // this.invoiceDetails = [];
        //             this.invoiceDetails = res.payload.attachementList;
        //             this.mainInvoiceDetails = res.payload.attachementList
        //             if (this.invoiceDetails) {
        //                 let urlStr = this.invoiceDetails[this.defaultIndex].url;
        //                 if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
        //                     this.isPdf = true;
        //                     const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
        //                     this.pdfData = linkSource;
        //                 } else {
        //                     this.isPdf = false;
        //                 }
        //             }
        //         } else if (res.isError && res.errorCode === 2) {
        //             this._dismiss();
        //         }
        //     });
    }

    showReportDetail(list) {

        if (list?.recordType == 6) {
            this.backToreport(list);
            return;
        }
        this.detailReport = list;
        this.editreport = list;
        this.reportSummaryRequestModal.claimId =  this.fromPage == 'advance' ? list?.claimId:list.claimId;
        this.reportSummaryRequestModal.claimGUID = this.fromPage == 'advance' ? list?.claimGUID:list.claimGUID;
        this.getReportModal.claimId = this.fromPage == 'advance' ? list?.claimId:list.claimId;
        this.getReportModal.claimGUID = this.fromPage == 'advance' ? list?.claimGUID:list.claimGUID;
        this.getReportModal.recordType = this.fromPage == 'advance' ? list?.recordType:list.recordType;
        this.addReportExpense.claimId = this.fromPage == 'advance' ? list?.claimId:list.claimId;
        this.getReportSummary();
        const reportArry = [];
        reportArry.push(this.fromPage == 'advance' ? list?.reportSummary?.claimId:list.claimId);
        this.removeReportModal.claimIds = reportArry;
        if (list.status === 1 || list.status === 11) {
            this.reportBtns = true;
        } else {
            this.reportBtns = false;
        }

        if (list.status == 3 && this.userInfo.roleId == 3 || this.userInfo.roleId == 4) {
            this.recordPaymentbtns = true;
        } else {
            this.recordPaymentbtns = false;
        }
        if (this.platformType == 1) {
            this.nextvalue = this.reportList?.indexOf(list);
        }
    }
    getServiceList(tripdetails){
        let obj = {
            tripId: tripdetails.tripId,
            tripGUID: tripdetails.tripGUID,
            legalEntityGuid: this.userInfo?.legalEntityGuId,
            legalEntityId: this.userInfo?.legalEntityId,
        }
        this.reportsService.getTripServiceList(obj).subscribe(response => {
            if (response.errorCode == 0) {
                this.reportSummary.advanceSummary = response.payload.advanceSummary;
                this.tripsDetailsList = [...response.payload.serviceList];
                this.tripsDetailsList?.forEach(element => {
                    element.id = tripdetails.id;
                    element.claimId = tripdetails.claimId;
                    element.claimGUID = tripdetails.claimGUID;
                });
            }
          });
    }

    rejectAll(rejectReasonModal: TemplateRef<any>) {
        this.redirectToList = true;
        
        this.rejectPopupTitle = true;
        this.duplicateRejectArray = [];
        this.rejectBoolean = true;
        this.rejectExparray = [];
        this.rejectControls.controls = [];

        if (this.userInfo.isCategoryBasedEnabled) {
            this.categoryBaseExpenseList.forEach(element => {
                this.rejectExparray.push(element);
                this.rejectControls.push(this.createItem(element));
            });
        } else {
            this.expenseList.forEach((element) => {
                // if (element.isSelected) {
                //     this.duplicateRejectArray.push(element);
                //     this.rejectControls.push(this.createItem(element));
                // } else if (element.status == 2) {
                //     this.rejectExparray.push(element);
                //     this.rejectControls.push(this.createItem(element));

                // } //NewCodeRej

                if (!element.crossImage) {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                } else if (element.status === 2) {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                }

            });
        }
        if (this.duplicateRejectArray.length == this.expenseList.length) {
            this.expenseList.forEach((element) => {
                this.rejectExparray.push(element);
                this.rejectControls.push(this.createItem(element));
            });
        }
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(rejectReasonModal, config);
    }
    async rejectApprovalAll() {
        this.rejectTitle = true;
        this.duplicateRejectArray = [];
        this.rejectBoolean = true;
        this.rejectExparray = [];
        this.rejectControls.controls = [];

        if (this.userInfo.isCategoryBasedEnabled) {
            this.categoryBaseExpenseList.forEach(element => {
                this.rejectExparray.push(element);
                this.rejectControls.push(this.createItem(element));
            });
        } else {
            this.expenseList.forEach((element) => {
                if (!element.crossImage) {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                } else if (element.status === 2) {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                }

            });
        }
        // this.expenseList.forEach((element) => {
        //     if (!element.crossImage) {
        //         this.rejectExparray.push(element);
        //         this.rejectControls.push(this.createItem(element));
        //     } else if (element.status === 2) {
        //         this.rejectExparray.push(element);
        //         this.rejectControls.push(this.createItem(element));
        //     }

        // });
        if (this.duplicateRejectArray.length == this.expenseList.length) {
            this.expenseList.forEach((element) => {
                this.rejectExparray.push(element);
                this.rejectControls.push(this.createItem(element));
            });
        }
        const modal = await this.modalController.create({
            component: RejectModalComponent,
            cssClass: "my-custom-class",
            componentProps: {
                rejectExparray: this.rejectExparray,
                claimId: this.getReportModal.claimId,
                claimGUID: this.getReportModal.claimGUID,
                reportSummary: this.reportSummary,
                rejectBoolean: this.rejectBoolean,
                rejectTitle: this.rejectTitle,
                categoryBaseExpenseList: this.categoryBaseExpenseList,
                partialBtnLoad: this.partialBtnLoad
            },
        });
        await modal.present();
        modal.onDidDismiss().then((dataReturned) => {
            if (this.platformType === 2) {
                // if (dataReturned.data.data === true) {
                //     this.modalController.dismiss({}, '', 'approvemodal');
                // }
                this.getReportSummary();
            }


        });
        this.selectAllexpenses();
        this.indeterminateState = false;
        this.selectedExpense = [];
    }


    getTripDetails(showPopup: TemplateRef<any>) {
        let getTripModl = new GetTripInfo();
        getTripModl.dynamoId = this.reportSummary?.dynamoId;
        getTripModl.dynamoGUID = this.reportSummary?.dynamoGUID;
        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                this.tripIdDetails = res?.payload;
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(showPopup, config);
            }
        });
    }

    async Recordpayment(from) {
        if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4) {
            const fareDetailsModal = await this.modalController.create({
                component: RecordPaymentComponent,
                componentProps: {
                    reportSummary: this.reportSummary,
                    advanceAmt: this.globalAvailableAdv,
                    advorReport: from,
                    avalAmt: this.apiAvailableAdv
                },
                keyboardClose: false,
            });
            fareDetailsModal.onDidDismiss()
                .then((callback) => {
                    if (callback) {
                        this.getReportSummary();
                        this.setLeftStatus();
                    }
                });
            return await fareDetailsModal.present();
        }
    }

    async rejectallReportsPopUp() {
        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.rejectall'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('approvals.rejectall'),
                    handler: () => {
                        this.zone.run(() => {
                            this.rejectReport(false);
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    rejectReport(booleanValue) {
        if (booleanValue) {
            if (this.singleExpenseRejectReason) {
                this.rejectDisable = true;
                this.getReportModal.userId = this.userInfo.userId;
                this.getReportModal.userGUID = this.userInfo.userGuId;
                this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
                this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                this.reportsService
                    .rejectAll(this.getReportModal)
                    .subscribe((res) => {
                        if (!res.isError && res.errorCode === 0) {
                            this.rejectDisable = false;
                            this.modalRef.hide();
                            if (this.platformType == 2) {
                                this.dismiss();
                            }
                            this.getReportSummary();
                            this.approveBtns = false;
                            // this.setLeftStatus();
                            this.selectedExpense = [];
                            this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                        } else if (res.isError && res.errorCode === 2) {
                            this.rejectDisable = false;
                            this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                        }
                    });
            } else {
                this.toastr.error('Please provide the reject reason', 'Error');
            }
        } else {
            this.rejectDisable = true;

            let rejectaaray = [];
            if (this.userInfo.isCategoryBasedEnabled) {
                this.categoryBaseExpenseList.forEach(element => {
                    let req = {
                        expenseId: element.expenseId,
                        isApproved: false
                    }
                    rejectaaray.push(req);
                });
            }
            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
                this.getReportModal.rejectedExpenseList = null;
            }

            this.getReportModal.userId = this.userInfo.userId;
            this.getReportModal.userGUID = this.userInfo.userGuId;
            this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.reportsService
                .rejectAll(this.getReportModal)
                .subscribe((res) => {
                    if (!res.isError && res.errorCode === 0) {
                        this.rejectDisable = false;
                        this.modalRef.hide();
                        if (this.platformType == 2) {
                            this.dismiss();
                        }
                        this.getReportSummary();
                        this.approveBtns = false;
                        // this.setLeftStatus();
                        this.selectedExpense = [];
                        this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                    } else if (res.isError && res.errorCode === 2) {
                        this.rejectDisable = false;
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    }
                });
        }
    }

    approveReports(AcknologmentForm1?: TemplateRef<any>) {
        this.redirectToList = true;
        this.isErrorcode10 = false;
        this.budgetCategoryItems = [];
        this.anomaliesCategoryItems = [];
        let filterdata = this.reportSummary.expenseList.filter((obj) => { return obj.hasViolation == true });
        if (filterdata.length != 0) {
            // if(this.userInfo.isEnableBudgetCheck && (this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
            // // if((this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
            //     this.ApproveApiCall(AcknologmentForm1);
            // }else{
                this.getReportModal.skipBudgetCheckMessage = true;
                const config = {
                    ignoreBackdropClick: false,
                    class: "modal-lg modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(AcknologmentForm1, config);
            // }
        } else {
            this.approveallReports();
        }

    }
    approveallReport() {
        this.showloader = true;
        let rejectaaray = [];
        if (this.userInfo.isCategoryBasedEnabled) {
            this.categoryBaseExpenseList.forEach(element => {
                let req = {
                    expenseId: element.expenseId,
                    isApproved: true
                }
                rejectaaray.push(req);
            });
        }
        if (this.userInfo.isCategoryBasedEnabled) {
            this.getReportModal.expenseList = rejectaaray;
        }

        if(this.isErrorcode10 && this.budgetCategoryItems.length != 0){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV){
                this.toastr.error('BudgFet not available, unable to proceed', this.translate.instant('alerts.error'));
                return;
            }else{
                this.getReportModal.skipBudgetCheckMessage = true;
            }
        }

        this.ApproveApiCall();
    }

    isRestrictSubmit: boolean = false;
    ApproveApiCall(AcknologmentForm1?:TemplateRef<any>){
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.getReportModal.recordType = this.editreport.recordType;
        this.approveDisable = true;
        if(this.isRestrictSubmit) {
            this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
            this.showloader = false;
            this.approveBtns = true;
            this.approveDisable = false;
            return;
        }
        this.reportsService.Approve(this.getReportModal).subscribe((res) => {
                if(res?.errorCode != 10) { this.isRestrictSubmit = false; }
                if(res.errorCode == 10){
                    // this.modalRef.hide();
                    this.showloader = false;
                    this.isErrorcode10 = true;
                    this.getReportModal.skipAnomolies = true;
                    this.getReportModal.skipBudgetCheckMessage = true;
                    this.budgetCategoryItems = [];
                    this.anomaliesCategoryItems = [];
                    this.budgetCategoryItems = [...res.payload.budgetResponse];
                    this.anomaliesCategoryItems = [...res.payload.anomalies];
                    this.isRestrictSubmit = res.payload.isRestrict;
                    if (!document.querySelector('.modal-backdrop')) {
                        const config = {
                            ignoreBackdropClick: false,
                            class: "modal-lg modal-dialog-centered",
                            keyboard: false,
                        };
                        this.modalRef = this.modalService.show(AcknologmentForm1, config);
                    }
                } else if (!res.isError && res.errorCode === 0) {
                    this.approveBtns = false;
                    if (this.platformType === 2) {
                        this.dismiss();
                    }
                    this.getReportSummary();
                    this.showloader = false;
                    this.approveDisable = false;
                    this.approveBtns = false;
                    this.selectedExpense = [];
                    this.isErrorcode10 =  false;
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.modalRef.hide();
                } else if (res.isError && res.errorCode === 2) {
                    this.showloader = false;
                    this.approveBtns = true;
                    this.approveDisable = false;
                    this.getReportModal.skipAnomolies = false;
                    this.getReportModal.skipBudgetCheckMessage = false;
                    this.isErrorcode10 =  false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }});
    }
    async approveallReports() {

        // if(this.userInfo.isEnableBudgetCheck && (this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
        // // if((this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
        //     this.approveAllBtn();
        // }else{
            this.getReportModal.skipBudgetCheckMessage = true;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.approveAllTemp, config);
        // }
    }

    approveAllBtn() {

        if( this.userInfo?.isEnableBudgetCheck && this.isErrorcode10 && this.budgetCategoryItems.length != 0 ){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV?.length > 0){
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }else{
                this.getReportModal.skipBudgetCheckMessage = true;
                this.getReportModal.skipAnomolies = true;
            }
        }

        this.zone.run(() => {
            let rejectaaray = [];
            if (this.userInfo.isCategoryBasedEnabled) {
                this.categoryBaseExpenseList.forEach(element => {
                    let req = {
                        expenseId: element.expenseId,
                        isApproved: true
                    }
                    rejectaaray.push(req);
                });
            }
            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
            }
            this.getReportModal.userId = this.userInfo.userId;
            this.getReportModal.userGUID = this.userInfo.userGuId;
            this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.getReportModal.recordType = this.detailReport?.recordType;
            this.approveDisable = true;
            if(this.isRestrictSubmit) {
                this.showloader = false;
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }
            this.reportsService.Approve(this.getReportModal).subscribe((res) => {
                    if(res?.errorCode != 10) { this.isRestrictSubmit = false; }
                    if(res.errorCode === 10){
                        // this.modalRef?.hide();
                        this.approveDisable = false;
                        this.approveBtns = true;
                        this.isErrorcode10 = true;
                        this.getReportModal.skipAnomolies = true;
                        this.getReportModal.skipBudgetCheckMessage = true;
                        this.budgetCategoryItems = [...res.payload.budgetResponse];
                        this.anomaliesCategoryItems = [...res.payload.anomalies];
                        this.isRestrictSubmit = res?.payload?.isRestrict;
                        
                        if (!document.querySelector('.modal-backdrop')) {
                            const config = {
                                ignoreBackdropClick: true,
                                class: "modal-lg modal-dialog-centered",
                                keyboard: false,
                            };
                            this.modalRef = this.modalService.show(this.approveAllTemp, config);
                        }

                    } else if (!res.isError && res.errorCode === 0) {
                        if (this.platformType === 2) {
                            this.dismiss();
                        }
                        this.getReportSummary();
                        this.approveDisable = false;
                        this.approveBtns = false;
                        this.isErrorcode10 = false;
                        this.getReportModal.skipBudgetCheckMessage = false;
                        this.getReportModal.skipAnomolies = false;
                        this.budgetCategoryItems = [];
                        this.anomaliesCategoryItems = [];
                        this.selectedExpense = [];

                        this.modalRef.hide();
                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    } else if (res.isError && res.errorCode === 2) {
                        this.approveBtns = true;
                        this.approveDisable = false;
                        this.isErrorcode10 = false;
                        this.budgetCategoryItems = [];
                        this.anomaliesCategoryItems = [];
                        this.getReportModal.skipAnomolies = false;
                        this.getReportModal.skipBudgetCheckMessage = false;
                        this.modalRef.hide();
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    }
                });
        });
    }

    totalReimburseableAmount(){
        return (this.reportSummary?.advanceSummary?.advanceCode != null ? this.reportSummary?.advanceSummary?.tripAdvanceAppliedAmount : this.reportSummary?.advanceAppliedAmount) - this.reportSummary?.approvedAmount;
    }

    setLeftStatus() {

        for (let i = 0; i < this.reportList?.length; i++) {
            if (
                this.reportList[i].claimId === this.getReportModal.claimId
            ) {

                this.reportList[i].status = this.approveMessage.status;
                this.reportList[i].statusDescription = this.approveMessage.statusdes;
            }

        }
    }

    addReportModal(selectReport: TemplateRef<any>, list?) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(selectReport, config);
    }
    // reportCostSummeryChart() {
    //     this.uniqLabels = [];
    //     this.amountList = [];
    //     this.expIds = [];
    //     this.uniExpId = [];
    //     this.labels = [];
    //     this.reportCostSummary.forEach((rep) => {

    //         // if(rep.categoryName.length > 15){
    //         //     this.labels.push((rep.categoryName).slice(0,10));
    //         // }else{
    //         //     this.labels.push(rep.categoryName);
    //         // }
    //         this.labels.push(rep.categoryName);
            
    //         this.uniqLabels = _.uniq(this.labels);
    //         const index = this.labels.findIndex((item) => item === rep.categoryName);
    //         if (rep?.amount) {
    //             if (this.amountList[index]) {
    //                 this.amountList[index] += +rep?.amount;
    //             } else {
    //                 this.amountList[index] = +rep.amount;
    //             }
    //         }

    //     });


    //     this.categoryoptions = {
    //         tooltips: {
    //             callbacks: {
    //                 label: function (tooltipItem, data) {
    //                     let value: any = data.datasets[0].data[tooltipItem.index];
    //                     let currencyPipe = new CurrencyPipe('en');
    //                     let formattedNumber = currencyPipe.transform(value, 'INR', 'symbol');
    //                     value = data.labels[tooltipItem.index] + ' ' + formattedNumber; //value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //                     return value;
    //                 }
    //             }
    //         },
    //         responsive: true,
    //         maintainAspectRatio: false,
    //     };

    //     this.doughnutChartData = this.amountList;
    //     this.doughnutChartLabels = this.uniqLabels;
    //     this.doughnutChartType = "doughnut";
    //     this.pieChartData = this.amountList;
    //     this.pieChartLabels = this.uniqLabels;
    //     this.allTotalAmount = this.amountList.reduce((prev, curr) => prev + curr, 0);

    // }
  
    
    openPiechart() {
        let data = [];
        this.amountList = [];
        if(this.reportCostSummary?.length == 0) {
            this.reportCostSummary.push({amount: 0, categoryName: 'No Data'});
        }
        let totalll =0 
        const currencySymbol = String.fromCharCode(parseInt(this.userInfo?.currencySymbol?.replace('&#', ''), 10));
        this.reportCostSummary.forEach(d => {
            let label = '';
            if(d.categoryName.length>15){
                label = d.categoryName.slice(0,15) +'...'
            } else {
                label = d.categoryName
            }
            data.push([label, d.amount <= 0 ? 1 : d.amount, d.categoryName + ` : ${currencySymbol} ` + d.amount]);
            totalll += +d.amount
        });

        this.pieChartOptions = {
            type: 'PieChart',
            data: data,
            columnNames: ['Browser', 'Percentage', {'type': 'string', 'role': 'tooltip'}],
            webOptions: {
                pieHole: 0.7,
                chartArea: { width: '80%', height: '80%' },
                pieSliceText: 'none',
                legend: {
                    position: 'labeled',
                },
                width:600,
                height:265, 
                tooltip: { text: 'percentage' },
            },
            mobileOptions: {
                pieHole: 0.7,
                chartArea: { width: '90%', height: '100%' },
                pieSliceText: 'none',
                legend: {
                    position: 'labeled',
                },
                width:340,
                // height:385,
                tooltip: { text: 'percentage' },
            },
        }
        this.allTotalAmount = totalll;
    }


    selectAllexpenses() {
        setTimeout(() => {
            this.expenseList.forEach((item) => {
                item.isSelected = this.checkAll;
            });
        });
    }

    approveExpenceReject(list, i, elmbool) { // newCodeApr19
        if (list.status == 1) {
            if (list.recordType == 1 || list.recordType == 2 || list.recordType == 4) {

                this.expenseList[i].checkImage = !this.expenseList[i].checkImage;
                this.expenseList[i].crossImage = !this.expenseList[i].crossImage;
                this.expenseList.forEach((item) => {
                    if (item.checkImage) {
                        item.checkimg = 'right-green';
                        item.crossimg = 'close-grey';
                        this.selectedExpense.push(item.expenseId);
                        this.selectedExpense = _.uniq(this.selectedExpense);
                    } else {
                        item.checkimg = 'right-grey';
                        item.crossimg = 'close-red';
                        const index = _.indexOf(this.selectedExpense, item.expenseId);
                        if (index != -1) this.selectedExpense.splice(index, 1);
                    }
                });
                this.addReportExpense.expenseIds = this.selectedExpense;
            } else {//Local ConveyanceList
                this.expenseList[i].checkImage = !this.expenseList[i].checkImage;
                this.expenseList[i].crossImage = !this.expenseList[i].crossImage;
                this.expenseList.forEach((item) => {
                    if (item.checkImage) {
                        item.checkimg = 'right-green';
                        item.crossimg = 'close-grey';
                        this.selectedExpense.push(item.localConveyanceId);
                        this.selectedExpense = _.uniq(this.selectedExpense);
                    } else {
                        item.checkimg = 'right-grey';
                        item.crossimg = 'close-red';
                        const index = _.indexOf(this.selectedExpense, item.localConveyanceId);
                        if (index != -1) this.selectedExpense.splice(index, 1);
                    }
                });
                this.addReportExpense.expenseIds = this.selectedExpense;
            }
            if (this.selectedExpense?.length > 0) {
                this.editBtn = true;
                if (this.selectedExpense.length == 1) {
                    this.editBtn = false;
                    this.selectedExp = list;
                } else {
                    this.editBtn = true;
                }
            } else {
                this.editBtn = false;
            }
        } else {
            if (list.recordType == 1 || list.recordType == 2 || list.recordType == 4) {
                //Expenses and pettycash expenses
                if (elmbool) {
                    this.expenseList[i].checkImage = true;
                    // this.categoryBaseExpenseList[i].checkImage = true;
                } else {
                    this.expenseList[i].crossImage = true;
                    // this.categoryBaseExpenseList[i].crossImage = true;
                }
                if (elmbool) {
                    this.expenseList.forEach((item, index) => {
                        let indeV = index;
                        if (item.checkImage) {
                            item.crossImage = false;
                            item.checkImage = true;
                            item.checkimg = 'right-green';
                            item.crossimg = 'close-grey';
                            item.isSELECT = true;

                            // this.categoryBaseExpenseList[indeV].crossImage = false;
                            // this.categoryBaseExpenseList[indeV].checkImage = true;
                            // this.categoryBaseExpenseList[indeV].checkimg = 'right-green';
                            // this.categoryBaseExpenseList[indeV].crossimg = 'close-grey';
                            // this.categoryBaseExpenseList[indeV].isSELECT = true;

                            this.selectedExpense.push(item.expenseId);
                            if (this.userInfo.isCategoryBasedEnabled && item.hasApproveRejectAction) {
                                this.selectedCategoryBaseExpenseList.push(item.expenseId);
                            }
                            this.selectedExpense = _.uniq(this.selectedExpense);
                            this.selectedCategoryBaseExpenseList = _.uniq(this.selectedCategoryBaseExpenseList);
                            const index = _.indexOf(this.rejectedExpense, item.expenseId);
                            if (index != -1) this.rejectedExpense.splice(index, 1);
                            if (index != -1) this.unselectedCategoryBaseExpenseList.splice(index, 1);
                        }
                    });
                } else {
                    this.expenseList.forEach((item, index) => {
                        var indeV = index;
                        if (item.crossImage) {
                            item.crossImage = true;
                            item.checkImage = false;
                            item.checkimg = 'right-grey';
                            item.crossimg = 'close-red';
                            item.isSELECT = true;

                            // this.categoryBaseExpenseList[indeV].crossImage = true;
                            // this.categoryBaseExpenseList[indeV].checkImage = false;
                            // this.categoryBaseExpenseList[indeV].checkimg = 'right-green';
                            // this.categoryBaseExpenseList[indeV].crossimg = 'close-grey';
                            // this.categoryBaseExpenseList[indeV].isSELECT = true;

                            const index = _.indexOf(this.selectedExpense, item.expenseId);
                            if (index != -1) this.selectedExpense.splice(index, 1);
                            if (index != -1) this.selectedCategoryBaseExpenseList.splice(index, 1);

                            this.rejectedExpense.push(item.expenseId);
                            if (this.userInfo.isCategoryBasedEnabled && item.hasApproveRejectAction) {
                                this.unselectedCategoryBaseExpenseList.push(item.expenseId);
                            }
                            this.rejectedExpense = _.uniq(this.rejectedExpense);
                            this.unselectedCategoryBaseExpenseList = _.uniq(this.unselectedCategoryBaseExpenseList);
                        }
                    });
                }

                if (this.expenseList.every(value => value.isSELECT === true)) {
                    this.partialEnableVal = true;
                }
                this.addReportExpense.expenseIds = this.selectedExpense;


            } else {

                if (elmbool) {
                    this.expenseList[i].checkImage = true;
                } else {
                    this.expenseList[i].crossImage = true;
                }
                if (elmbool) {
                    this.expenseList.forEach((item) => {
                        if (item.checkImage) {
                            item.crossImage = false;
                            item.checkImage = true;
                            item.checkimg = 'right-green';
                            item.crossimg = 'close-grey';
                            item.isSELECT = true;
                            this.selectedExpense.push(item.localConveyanceId);
                            this.selectedExpense = _.uniq(this.selectedExpense);
                            const index = _.indexOf(this.rejectedExpense, item.localConveyanceId);
                            if (index != -1) this.rejectedExpense.splice(index, 1);
                        }
                    });
                } else {
                    this.expenseList.forEach((item) => {
                        if (item.crossImage) {
                            item.crossImage = true;
                            item.checkImage = false;
                            item.checkimg = 'right-grey';
                            item.crossimg = 'close-red';
                            item.isSELECT = true;
                            const index = _.indexOf(this.selectedExpense, item.localConveyanceId);
                            if (index != -1) this.selectedExpense.splice(index, 1);

                            this.rejectedExpense.push(item.localConveyanceId);
                            this.rejectedExpense = _.uniq(this.rejectedExpense);
                        }
                    });
                }

                if (this.expenseList.every(value => value.isSELECT === true)) {
                    this.partialEnableVal = true;
                }

                this.addReportExpense.expenseIds = this.selectedExpense;
            }
            if (this.selectedExpense?.length > 0) {
                this.editBtn = true;
                if (this.selectedExpense.length == 1) {
                    this.editBtn = false;
                    this.selectedExp = list;
                } else {
                    this.editBtn = true;
                }
            } else {
                this.editBtn = false;
            }
        }
    }

    selectSingleexpenses(list, e) {
        list.isSelected = e.target.checked;
        if (list.recordType == 1 || list.recordType == 4) {
            if (list.isSelected) {
                this.selectedExpense.push(list.expenseId);
                this.selectedCategoryBaseExpenseList.push(list.expenseId);
            } else {
                const index = _.indexOf(this.selectedExpense, list.expenseId);
                const index1 = _.indexOf(this.duplicateRejectArray, list.expenseId);
                this.selectedExpense.splice(index, 1);
                this.selectedCategoryBaseExpenseList.splice(index, 1);
                this.duplicateRejectArray.splice(index1, 1);
            }
            this.addReportExpense.expenseIds = this.selectedExpense;
        } else {
            if (list.isSelected) {
                this.selectedExpense.push(list.localConveyanceId);
            } else {
                const index = _.indexOf(this.selectedExpense, list.localConveyanceId);
                this.selectedExpense.splice(index, 1);
            }
            this.addReportExpense.expenseIds = this.selectedExpense;
        }

        if (
            this.expenseList.length !== this.selectedExpense.length &&
            this.selectedExpense.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedExpense.length === 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
    }

    backToAdvance(){
        console.log('break');
        if(this.reportSummary?.recordType == 2){
            this.backEvent.emit();
        }else{
            this.backToreport();
        }
    }

    backToreport(report?) {
        this.redirectToList = false;
        if (this.fromPage === "approvePage" && this.platformType === 2) {
            this.modalController.dismiss();
        }
        this.backto.emit(report);
        this.sharedService.setRefreshData(false);
        // if (this.fromPage === "approvePage") {
        //     this.router.navigateRoot(["approvePage"]);
        // } else {
        //     this.router.navigateRoot(["expenses"]);
        // }

    }

    oncancelClick() {
        if (this.platformType == 1) {
            this.modalRef.hide();
        }
    }

    async removeReportPopUp(proofPopup?: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-md",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(proofPopup, config);
    }

    deleteDraftClaim() {
        this.zone.run(() => {
            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.removeReportModal.userId = this.userInfo.userId;
            this.removeReportModal.userGUID = this.userInfo.userGuId;
            this.deltbuttonDisabled = true;
            if (this.fromPettyCash) {
                this.removeReportModal.recordType = 4;
            }
            this.reportsService
                .removeReport(this.removeReportModal)
                .subscribe((data) => {
                    if (!data.isError && data.errorCode === 0) {
                        this.deltbuttonDisabled = false;
                        this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                        this.backToreport(true);
                    } else if (data.isError && data.errorCode === 2) {
                        this.deltbuttonDisabled = false;
                        this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                    }
                    this.modalRef.hide();
                });
        });
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            mode: "md",
            cssClass: 'my-custom-class text-center submitOk',
            message: `<img src="assets/svg/success-icon.svg" class="reportsuccess"/> <br>${this.translate.instant('alerts.reportsuccess')}`,
            buttons: [{
                text: this.translate.instant('common.ok'),
                cssClass: 'primary text-center',
            }],
        });

        await alert.present();
    }

    strToNum(str) {
        return parseFloat(str?.toString().replace(/,/g, '') || 0);
    }

    acknowledgeOfflineBills() {
        this.acknowledingOfflineBills = false;
        const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(this.offlineBills, config);
    }

    acknowledingOfflineBills: boolean = false;
    confirmOfflineBills() {
        this.acknowledingOfflineBills = true;
        let claim = {
            'claimId': this.reportSummary.claimId,
            'claimGUID': this.reportSummary.claimGUID
        }
        this.reportService.acknowledgeOfflineBills(claim).subscribe(res => {
            this.toastr.success('Success', res.payload);
            this.getReportSummary();
            this.modalClose();
        })
    }

    skipApproalFlowSubmit: boolean = false;
    async submitReport(AcknologmentForm?: TemplateRef<any>, skipApprovalFlow?) {
        this.submitDisable = true;
        this.isErrorcode10 = false;
        this.budgetCategoryItems = [];
        this.anomaliesCategoryItems = [];
        this.skipApproalFlowSubmit = skipApprovalFlow || null;
        if(this.skipApproalFlowSubmit && this.strToNum(this.reportSummary?.sentBackAmount) != this.strToNum(this.reportSummary?.totalBaseAmount)) {
            this.buttonDisabled = false;
            this.submitDisable = false;
            this.showloader = false;
            this.modalRef?.hide();
            this.dTempHeader = 'Skip Approval FLow';
            this.dTempBody = `New claim amount must be equal to the previously approved claim amount (<i class="fa ${this.userInfo?.baseCurrency?.currencyIcon}"></i> ${this.reportSummary?.sentBackAmount})`;
            const config = {
                ignoreBackdropClick: false,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
            return true;
        }

        // if(this.userInfo.isEnableBudgetCheck){
        //     this.Confirmacknologment(AcknologmentForm);
        // }else{
            // this.removeReportModal.skipBudgetCheckMessage = true;
            const config = {
                ignoreBackdropClick: false,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(AcknologmentForm, config);
        // }
    }

    async openNewexpMobile(type, report) {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: {
                type,
                report: report,
            },
        });
        return await modal.present();
    }

    setRejectedComments(ele: any) {
        var dict = {};
        if (ele.expenseId) {
            dict = { expenseId: ele.expenseId, reason: ele?.reason };
            return dict;
        }
        return dict;
    }

    partialSubmit() {
        this.submitted = true;
        if (!this.reasonForm.valid) {
            return;
        }
        if (!this.rejectBoolean) {
            this.partialBtnLoad = true;
            let rejectaaray = [];
            this.reasonForm.controls.rejectedExpenseList.value.forEach(element => {
                let dict = this.setRejectedComments(element);
                rejectaaray.push(dict);
            });
            if (this.userInfo.isCategoryBasedEnabled) {
                rejectaaray.forEach(element => {
                    element.isApproved = false;
                });
                this.categoryBaseExpenseList.forEach(element => {
                    if (element.checkImage) {
                        let req = {
                            expenseId: element.expenseId,
                            isApproved: true
                        }
                        rejectaaray.push(req);
                    }
                });
            }
            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
            } else {
                this.getReportModal.rejectedExpenseList = rejectaaray;
            }

            if(this.userInfo?.isEnableBudgetCheck && this.isErrorcode10 && this.budgetCategoryItems.length != 0){
                const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
                if(isRestrictV?.length > 0){
                    this.partialBtnLoad = false;
                    this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                    return;
                }else{
                    this.getReportModal.skipBudgetCheckMessage = true;
                }
            }
            this.PartialApprovalAPIcall();
        } else {
            this.partialBtnLoad = true;
            let rejectaaray = [];
            this.reasonForm.controls.rejectedExpenseList.value.forEach(element => {
                let dict = this.setRejectedComments(element);
                rejectaaray.push(dict);
            });

            if (this.userInfo.isCategoryBasedEnabled) {
                rejectaaray.forEach(element => {
                    element.isApproved = false;
                });
                this.categoryBaseExpenseList.forEach(element => {
                    if (element.checkImage) {
                        let req = {
                            expenseId: element.expenseId,
                            isApproved: true
                        }
                        rejectaaray.push(req);
                    }
                });
            }

            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
            } else {
                this.getReportModal.rejectedExpenseList = rejectaaray;
            }

            this.getReportModal.userId = this.userInfo.userId;
            this.getReportModal.userGUID = this.userInfo.userGuId;
            this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;

            this.reportsService.rejectAll(this.getReportModal).subscribe((res) => {
                    if (!res.isError && res.errorCode === 0) {
                        this.rejectDisable = false;
                        this.getReportModal = new GetReportModal();
                        this.modalRef.hide();
                        if (this.platformType == 2) {
                            this.dismiss();
                        }
                        this.getReportSummary();
                        this.approveBtns = false;
                        this.setLeftStatus();
                        this.selectedExpense = [];
                        this.rejectExparray = [];
                        this.rejectBoolean = false;
                        this.partialBtnLoad = false;
                        
                    
                        this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                    } else if (res.isError && res.errorCode === 2) {
                        this.rejectDisable = false;
                        this.partialBtnLoad = true;
                        this.getReportModal = new GetReportModal();
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    }
                });
        }
    }

    PartialApprovalAPIcall(rejectModal?:TemplateRef<any>){
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.recordType = this.detailReport?.recordType;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.partialBtnLoad = true;
        if(this.isRestrictSubmit) {
            this.showloader = false;
            this.partialBtnLoad = false;
            this.approveDisable = false;
            this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
            return;
        }
        this.reportsService.Approve(this.getReportModal).subscribe((res) => {
            if(res?.errorCode != 10) { this.isRestrictSubmit = false; }
            if(res.errorCode == 10){
                this.partialBtnLoad = false;
                this.isErrorcode10 = true;
                this.approveBtns = true;
                this.budgetCategoryItems = [];
                this.anomaliesCategoryItems = [];
                this.getReportModal.skipAnomolies = true;
                this.getReportModal.skipBudgetCheckMessage = true;
                this.budgetCategoryItems = [...res.payload.budgetResponse];
                this.anomaliesCategoryItems = [...res.payload.anomalies];
                this.isRestrictSubmit = res?.payload?.isRestrict;
                if (!document.querySelector('.modal-backdrop')) {
                    const config = {
                        ignoreBackdropClick: true,
                        class: "modal-lg modal-dialog-centered",
                        keyboard: false,
                    };
                    this.modalRef = this.modalService.show(rejectModal, config);
                }
            } else if (!res.isError && res.errorCode === 0) {
                this.modalRef?.hide();
                this.partialBtnLoad = false;
                this.getReportSummary();
                this.setLeftStatus();
                this.approveBtns = false;
                this.approveDisable = false;
                this.selectedExpense = [];
                this.rejectExparray = [];
                this.categoryBaseExpenseList = [];
                this.selectedCategoryBaseExpenseList = [];
                this.unselectedCategoryBaseExpenseList = [];
                this.getReportModal = new GetReportModal();
                this.sharedService.setRefreshData(true);
                this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
            } else if (res.isError && res.errorCode === 2) {
                this.partialBtnLoad = false;
                this.approveDisable = false;
                this.getReportModal = new GetReportModal();
                this.getReportModal.skipAnomolies = false;
                this.getReportModal.skipBudgetCheckMessage = false;
                this.isErrorcode10 = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    openRejectModal(rejectModal: TemplateRef<any>) {
        this.redirectToList = true;
        this.approveDisable = true;
        this.rejectBoolean = false;
        this.rejectPopupTitle = false;
        this.rejectExparray = [];
        this.rejectControls.controls = [];
        if (this.userInfo.isCategoryBasedEnabled) {
            this.categoryBaseExpenseList.forEach((element) => {
                if (element.checkImage) { } else {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                }

            });
        } else {
            this.expenseList.forEach((element) => {
                if (element.checkImage) { } else {
                    this.rejectExparray.push(element);
                    this.rejectControls.push(this.createItem(element));
                }

            });
        }

        // checkning

        if(this.reportSummary?.recordType == 2){
            this.getReportModal.skipBudgetCheckMessage = true;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(rejectModal, config);
        }else{
            // if(this.userInfo.isEnableBudgetCheck && (this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
            // // if((this.userInfo.roleId == 1 || this.userInfo.roleId == 2)){
            //     this.PartialApprovalAPIcall(rejectModal);
            // }else{
                this.getReportModal.skipBudgetCheckMessage = true;
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-lg modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(rejectModal, config);
            // }
        }
        
        
    }

    async RejectMobModal() {
        this.rejectTitle = false;
        this.rejectBoolean = false;
        this.rejectExparray = [];
        this.rejectControls.controls = [];

        if (this.userInfo.isCategoryBasedEnabled) {
            this.selectedCategoryBaseExpenseList.forEach(element => {
                this.categoryBaseExpenseList.forEach(Innerelement => {
                    if (element == Innerelement.expenseId) {
                        this.rejectExparray.push(Innerelement);
                    }
                });
            });
        } else {
            this.expenseList.forEach((element) => {
                if (element.isSelected) {
                } else if (element.status == 2 || element.status == 3) {
                    this.rejectExparray.push(element);
                }
            });
        }

        const modal = await this.modalController.create({
            component: RejectModalComponent,
            cssClass: "my-custom-class",
            componentProps: {
                rejectExparray: this.rejectExparray,
                claimId: this.getReportModal.claimId,
                claimGUID: this.getReportModal.claimGUID,
                reportSummary: this.reportSummary,
                rejectBoolean: this.rejectBoolean,
                categoryBaseExpenseList: this.categoryBaseExpenseList,
                editreport: this.editreport,
                expenseWithCodesList: this.expenseWithCodesList,
                isAdmin: this.isAdmin
            },
        });
        await modal.present();
        modal.onDidDismiss().then((dataReturned) => {
            if (this.platformType === 2) {
                // if (dataReturned.data.data === true) {
                //    // this.modalController.dismiss({}, '', 'approvemodal');
                //    this.getReportSummary();
                // }
                this.getReportSummary();
            }


        });
        this.selectAllexpenses();
        this.indeterminateState = false;
        this.selectedExpense = [];


    }

    dismiss() {
        this.modalController.dismiss();
    }

    onSaveClick(data) {
        if (data) {
            this.addReportExpense.claimId = this.reportSummary.claimId;
            this.addReportExpense.expenseIds = [];
            this.addReportExpense.expenseIds.push(data.expenseId);
            this.addReportExpense.legalEntityId = this.userInfo.legalEntityId;
            this.addReportExpense.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.addReportExpense.userId = this.userInfo.userId;
            this.addReportExpense.userGUID = this.userInfo.userGuId;
            this.expenseService.addToReport(this.addReportExpense).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    //   this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.indeterminateState = false;
                    this.checkAll = false;

                    this.oncancelClick();

                    this.getReportSummary();
                    this.addReportExpense.claimId = null;
                } else if (res.isError === true) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
        }
    }

    openProofModal(proofPopup: TemplateRef<any>, list) {
        if (list.expenseAttachments[0].url.split('?')[0].split('.').pop() == 'pdf') {
            window.open(list.expenseAttachments[0].url);
        } else {
            this.proofList = list?.expenseAttachments;
            this.openExpense = list;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-dialog-centered modal-lg",
                keyboard: false,

            };
            this.modalRef = this.modalService.show(proofPopup, config);
        }
    }


    pdfSelectionCall(pdfSelection: TemplateRef<any>, obj, type, trip, index?) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection, config);



        if (this.reportSummary?.recordType != 2) {

            // this.getExpenseAttachmentsCall(obj?.expenseId, index);
            this.getExpenseAttachmentsCall(obj, index);

            if (type == 1) {  // trip related

                this.invoiceDetails = [];
                this.mainInvoiceDetails.forEach(elm => {

                    if (elm.referenceId == trip.tripId && (elm.attachmentType == "2" || elm.attachmentType == "1")) {
                        this.invoiceDetails.push(elm);
                    }
                });

            } else if (type == 3) { // related proofs section
                this.invoiceDetails = [];
                this.mainInvoiceDetails.forEach(elm => {
                    if (elm.expenseId == obj.expenseId && elm.attachmentType == "1") {
                        this.invoiceDetails.push(elm);
                    }
                });

            }
            else {    // type=2 expense section

                this.invoiceDetails = [];
                let attchmnt = this.mainInvoiceDetails;

                attchmnt.forEach(element => {
                    if (obj?.expenseId == element?.expenseId) {
                        if (element.attachmentType == "1") {
                            this.invoiceDetails.push(element);
                        }
                    }
                });
            }
        } else {
            this.getExpenseAttachmentsCall(this.reportSummary?.expenseList[0], 0);
            // this.invoiceDetails = [];
            // this.reportSummary?.expenseList[0]?.expenseAttachments?.forEach(elm => {
            //     this.invoiceDetails.push(elm);
            // });
        }


        let urlStr = this.invoiceDetails[this.defaultIndex].url;
        if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
            this.isPdf = true;
            this.isZip = false;
            const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
            this.pdfData = linkSource;
        } else if (urlStr.split('?')[0].split('.').pop() == "zip" || urlStr.split('?')[0].split('.').pop() == "rar") {
            this.isPdf = false;
            this.isZip = true;
        } else {
            this.isPdf = false;
            this.isZip = false;
        }

    }

    pdfSelectionCalltripview(pdfSelection: TemplateRef<any>, trip) {
        console.log('selcted trip :',trip);
        this.previewAttachmentsArray = [];
        this.pdfloader = true;
        //this.getSingleTripDeatils(trip);
        this.getpdfTripProofs(trip);
        // this.selectedTripInvoiceArray = trip.attchements;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection, config);
    }
    getpdfTripProofs(expenseDetails){
        this.invoiceDetails = [];
        this.invoiceDetails = expenseDetails.tripServiceAttachments ||[];
        if (this.invoiceDetails.length > 0 && this.invoiceDetails[0]?.fileURL && this.invoiceDetails[0]?.fileURL !== 'null') {

            this.invoiceDetails.forEach(element => {
                
                const urlStr = element.fileURL;
                if (urlStr.split("?")[0].split(".").pop() == "pdf") {
                    this.isPdf = true;
                    this.isZip = false;
                    this.getPdfAsBase64(urlStr).then((baseRes) => {
                        const linkSource = `data:application/pdf;base64,${baseRes}`;
                        this.pdfData = linkSource;
                        element.content = baseRes;
                        this.pdfloader = false;
                    });
                } else if (urlStr.split('?')[0].split('.').pop() == "zip" || urlStr.split('?')[0].split('.').pop() == "rar") {
                    this.isPdf = false;
                    this.isZip = true;
                } else {
                    this.pdfloader = false;
                    this.isPdf = false;
                    this.isZip = false;
                }
            });
        } else {
          // Handle the case when invoiceDetails is an empty array or URL is null or 'camw'
          this.pdfloader = false;
          this.isPdf = false;
          this.isZip = false;
        }
      }
    getSingleTripDeatils(selectedTrip?) {
        this.invoiceDetails = [];
        let getTripModl = new GetTripInfo();
        getTripModl.legalEntityId = this.userInfo.legalEntityId;
        getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
        getTripModl.companyId = this.userInfo.companyId;
        getTripModl.companyGUID = this.userInfo.companyGuId;
        getTripModl.tripId = selectedTrip.tripId;
        getTripModl.tripGUID = selectedTrip.tripGUID;
        getTripModl.productId = selectedTrip.productId;
        getTripModl.requestId = selectedTrip.requestId;
        getTripModl.requestGUID = selectedTrip.requestGUID;

        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                res?.payload.flightDetails?.flights[0]?.invoiceDetails?.forEach((innerelement) => {
                    if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                        let object_data = {
                            fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                            content: innerelement.base64Invoice,
                            url: innerelement.invoiceUrl,
                            isDeleted: "",
                            attachmentType: 1,
                            referenceId: res?.payload.tripId,
                        };
                        this.invoiceDetails.push(object_data);
                    }
                });
            }

            this.pdfloader = false;
        });
    }
    returnExtension(filename) {
        let text = filename.split("?")[0].split(".").pop();
        return text.toLowerCase();
    }
    base64dataRe(incomingD) {
        const linkSource = `data:application/pdf;base64,${incomingD}`;
        return linkSource;
    }
    preBtn() {
        if (this.defaultIndex != 0) {
            this.defaultIndex = this.defaultIndex - 1;
            this.zoom = 1.0;
        }
    }
    closeSelection() {
        this.defaultIndex = 0;
        this.modalRef.hide();
    }
    prevBtn() {
        if (this.defaultIndex != 0) {
            this.defaultIndex = this.defaultIndex - 1;
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                this.isPdf = true;
                this.isZip = false;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;

                // this.pdfData = linkSource;
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if (urlStr.split('?')[0].split('.').pop() == "zip" || urlStr.split('?')[0].split('.').pop() == "rar") {
                this.isPdf = false;
                this.isZip = true;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
            this.zoom = 1.0;
        }
    }

    nextBtn() {
        if ((this.defaultIndex + 1) == this.invoiceDetails.length) {
            // this.defaultIndex = 0;
        } else {
            this.defaultIndex = this.defaultIndex + 1;
            console.log('index details :',this.invoiceDetails);
            let urlStr = this.invoiceDetails[this.defaultIndex]?.fileURL ? this.invoiceDetails[this.defaultIndex]?.fileURL : this.invoiceDetails[this.defaultIndex]?.url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                this.isPdf = true;
                this.isZip = false;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;

                // this.pdfData = linkSource;
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if (urlStr.split('?')[0].split('.').pop() == "zip" || urlStr.split('?')[0].split('.').pop() == "rar") {
                this.isPdf = false;
                this.isZip = true;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
            this.zoom = 1.0;
        }
    }

    downloadBtn() {
        let attachObj = { attachmentIds: [this.invoiceDetails[this.defaultIndex].attachmentId] };

        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.downloadProofs = res.payload.downloadFileResponseDTO;
                this.downloadProofs.forEach(element => {
                    if (element) {
                        window.open(element.url);
                    }
                });

                // window.open(this.downloadProof);
                this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }
    modalClose() {
        this.modalRef.hide();
    }

    openNewReport() {

        if (this.reportSummary?.status == 1 && this.selectedExpense?.length == 1) {

            this.editBtn = true;

            var url = 'reports';
            if (this.fromPage == 'approvePage') {
                url = 'approvals';
            }
            if (this.reportSummary?.status == 1) {
                this.EditExpense(this.selectedExp, false);
                return;
            }
            let navInfo = {
                url: url,
                fromPage: 'viewSlide',
                expenseArray: this.expenseList,
                isReadOnly: true,
                currentIndx: 0,
                goToReportDetails: true,
                detailReport: this.detailReport
            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            this.router.navigateRoot(["newExpenseMileageBulk"]);

        } else {
            this.editBtn = false;

            let navInfo = {
                url: 'report-detailView',
                report: this.editreport
            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            //this.backToreport();
            if (this.fromPettyCash) {
                this.router.navigateRoot(["petty-cash/new-report"]);
            } else {
                this.router.navigateRoot(["newreport"]);
            }
        }


    }

    // async addNewMileage(type) {

    //     // if (this.fromPettyCash) {
    //     //     sessionStorage.setItem('fromPettyCash', JSON.stringify(true));
    //     // }
    //     const modal = await this.modalController.create({
    //         component: ExpenseAndMileageComponent,
    //         cssClass: "my-custom-class",
    //         componentProps: {
    //             isEdit: true,
    //             this.expense
    //         },
    //     });
    //     return await modal.present();
    // }

    async editNewexpmobile() {
        if (this.expense.recordType == 4) {
            sessionStorage.setItem('fromPettyCash', JSON.stringify(false));
        }
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: {
                expenseId: this.expense.expenseId,
                expenseGUID: this.expense.expenseGUID,
                type: this.expense.categoryType,
                duplicate: false,
                claimName: this.expense.claimName,
                isEdit: true

            },
        });
        return await modal.present();
    }


    removeExp(expense?, slidingItem?) {
        if (this.platformType == 2) {
            this.addReportExpense.expenseIds = [];
            this.addReportExpense.expenseIds.push(expense.expenseId);
        }
        this.removeBtn = true;
        this.addReportExpense.userId = this.userInfo.userId;
        this.addReportExpense.userGUID = this.userInfo.userGuId;
        this.reportsService.removeExpense(this.addReportExpense).subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
                this.getReportSummary();
                this.selectedExpense = [];
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.removeBtn = false;
                slidingItem.close();
            } else if (res.isError && res.errorCode == 2) {
                this.removeBtn = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    // async openPopOver(ev: any, str, expense) {
    //     this.expense = expense;
    //     this.fromStr = str;
    //     if (expense.status == 1) {
    //         const popover = await this.popoverController.create({
    //             component: CustomPopOverComponent,
    //             componentProps: {
    //                 actionsObj: [
    //                     {
    //                         name: 'common.edit',
    //                         action: 1,
    //                         canDisabled: false
    //                     },
    //                     {
    //                         name: 'common.download',
    //                         action: 2,
    //                         canDisabled: false
    //                     },
    //                     {
    //                         name: 'common.delete',
    //                         action: 3,
    //                         canDisabled: false
    //                     }
    //                 ]
    //             },
    //             cssClass: 'custom-popover',
    //             event: ev,
    //             translucent: true
    //         });
    //         popover.onDidDismiss().then(result => {
    //             if (result.data && result.role !== 'backdrop') {
    //                 switch (result.data.action) {
    //                     case 1:

    //                         if (this.fromStr == 1) { // 1 for report details
    //                             this.openNewReport();
    //                         } else {  // 2 for expence details
    //                             this.editNewexpmobile();
    //                         }

    //                         break;
    //                     case 2:
    //                         break;
    //                     case 3:
    //                         this.removeExp(this.expense);
    //                         // this.removeReportPopUp();
    //                         break;
    //                 }
    //             }
    //         });
    //         await popover.present();
    //     }
    //     if (expense.status !== 1) {
    //         const popover = await this.popoverController.create({
    //             component: CustomPopOverComponent,
    //             componentProps: {
    //                 actionsObj: [
    //                     {
    //                         name: 'common.download',
    //                         action: 2,
    //                         canDisabled: false
    //                     }
    //                 ]
    //             },
    //             cssClass: 'custom-popover',
    //             event: ev,
    //             translucent: true
    //         });
    //         popover.onDidDismiss().then(result => {
    //             if (result.data && result.role !== 'backdrop') {
    //                 switch (result.data.action) {
    //                     case 1:
    //                         if (this.fromStr == 1) { // 1 for report details
    //                             this.openNewReport();
    //                         }

    //                         break;
    //                     case 2:
    //                         //
    //                         break;
    //                     case 3:
    //                         this.removeReportPopUp();
    //                         break;
    //                 }
    //             }
    //         });
    //         await popover.present();
    //     }
    // }
    report: any;
    async openPopOver(ev: any, reportSummary) {
        this.report = reportSummary;
        if (reportSummary.status == 1) {
            const popover = await this.popoverController.create({
                component: CustomPopOverComponent,
                componentProps: {
                    actionsObj: [
                        {
                            name: 'common.edit',
                            action: 1,
                            canDisabled: false
                        },
                        {
                            name: 'common.download',
                            action: 2,
                            canDisabled: false
                        },
                        {
                            name: 'common.delete',
                            action: 3,
                            canDisabled: false
                        }
                    ]
                },
                cssClass: 'custom-popover',
                event: ev,
                translucent: true
            });
            popover.onDidDismiss().then(result => {
                if (result.data && result.role !== 'backdrop') {
                    switch (result.data.action) {
                        case 1:
                            this.openNewReport();
                            // if (this.fromStr == 1) { // 1 for report details
                            //     this.openNewReport();
                            // } else {  // 2 for expence details
                            //     this.editNewexpmobile();
                            // }

                            break;
                        case 2:
                            break;
                        case 3:
                            this.removeReportPopUp();
                            // if (this.fromStr == 1) {
                            //     this.removeReportPopUp();
                            // } else {
                            //     this.removeExp(this.expense);
                            // }
                            break;
                    }
                }
            });
            await popover.present();
        }
        if (reportSummary.status !== 1) {
            const popover = await this.popoverController.create({
                component: CustomPopOverComponent,
                componentProps: {
                    actionsObj: [
                        {
                            name: 'common.download',
                            action: 2,
                            canDisabled: false
                        },
                    ]
                },
                cssClass: 'custom-popover',
                event: ev,
                translucent: true
            });
            popover.onDidDismiss().then(result => {
                if (result.data && result.role !== 'backdrop') {
                    switch (result.data.action) {
                        case 1:
                            // 2 for expence details
                            this.editNewexpmobile();
                            break;
                        case 2:
                            break;
                        case 3:
                            this.removeExp(this.expense);
                            break;
                    }
                }
            });
            await popover.present();
        }
    }
    async openPopOverexp(ev: any, expense) {
        this.expense = expense;
        if (expense.status == 1) {
            const popover = await this.popoverController.create({
                component: CustomPopOverComponent,
                componentProps: {
                    actionsObj: [
                        {
                            name: 'common.edit',
                            action: 1,
                            canDisabled: false
                        },
                        {
                            name: 'common.download',
                            action: 2,
                            canDisabled: false
                        },
                        {
                            name: 'common.delete',
                            action: 3,
                            canDisabled: false
                        }
                    ]
                },
                cssClass: 'custom-popover',
                event: ev,
                translucent: true
            });
            popover.onDidDismiss().then(result => {
                if (result.data && result.role !== 'backdrop') {
                    switch (result.data.action) {
                        case 1:
                            // 2 for expence details
                            this.editNewexpmobile();
                            break;
                        case 2:
                            break;
                        case 3:
                            this.removeExp(this.expense);
                            break;
                    }
                }
            });
            await popover.present();
        }
        if (expense.status !== 1) {
            const popover = await this.popoverController.create({
                component: CustomPopOverComponent,
                componentProps: {
                    actionsObj: [
                        {
                            name: 'common.download',
                            action: 2,
                            canDisabled: false
                        },
                    ]
                },
                cssClass: 'custom-popover',
                event: ev,
                translucent: true
            });
            popover.onDidDismiss().then(result => {
                if (result.data && result.role !== 'backdrop') {
                    switch (result.data.action) {
                        case 1:
                            // 2 for expence details
                            this.editNewexpmobile();
                            break;
                        case 2:
                            break;
                        case 3:
                            this.removeExp(this.expense);
                            break;
                    }
                }
            });
            await popover.present();
        }
    }

    openPreview(pic) {
        this.modalController
            .create({
                component: ImageViewComponent,
                componentProps: {
                    photo: pic,
                    frompage: 'expDetails'
                },
            })
            .then((modal) => modal.present());
    }

    downloadImage() {
        if (!this.proofid) {
            this.proofid = this.proofList[0].attachmentId;
        }
        let attachObj = { attachmentIds: [this.proofid] };
        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                // this.downloadProof = res['payload']['url'];
                // window.open(this.downloadProof);
                this.downloadProofs = res.payload.downloadFileResponseDTO;
                this.downloadProofs.forEach(element => {
                    if (element) {
                        window.open(element.url);
                    }
                });
                this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
            } else {
                this.toastr.error(res.errorMessage, 'Error');
            }
        });

    }

    onSlide(item) {
        const imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
        this.proofid = this.proofList[parseInt(item.current.replace("slideId_", ""), 10)].attachmentId;
        this.imgNum = imageIndex + 1;
    }

    advPymtRecord(recordPaymentAdvance: TemplateRef<any>) {
        this.recordAdvancePaymentModel.paymentMode = '';
        this.recordAdvancePaymentModel.referenceNumber = '';
        this.recordAdvancePaymentModel.comments = '';

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(recordPaymentAdvance, config);
    }
    reportPymtRecord(recordPaymentReport: TemplateRef<any>) {

        var isAdvLinked = false;
        this.appliedAmt = 0;
        this.recordRptPymntModel.paymentMode = '';
        this.recordRptPymntModel.referenceNumber = '';
        this.recordRptPymntModel.comments = '';
        if (this.reportSummary?.isLinkedToAdvancePayment && (this.reportSummary?.recordType == 1)) {
            this.appliedAmt = this.reportSummary?.unformattedAdvanceAmount;
            isAdvLinked = true;
        } else if (this.reportSummary?.isLinkedToPettyCashPayment && (this.reportSummary?.recordType == 4)) {
            this.appliedAmt = this.reportSummary?.unformattedPettyCashAmount;
            isAdvLinked = true;
        }

        if (isAdvLinked == true) {
            this.expensesAmt = this.reportSummary?.unformattedApprovedAmount;
            if (this.expensesAmt > 0) {
                if ((this.expensesAmt > this.globalAvailableAdv) || (this.expensesAmt == this.globalAvailableAdv)) {
                    this.totalReportAmt = this.expensesAmt - this.appliedAmt;
                } else if (this.expensesAmt < this.globalAvailableAdv) {
                    this.totalReportAmt = 0;
                }
            }
        }

        this.totalApproveAmountCal();

        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(recordPaymentReport, config);
        this.getAdvanceLinkSummary(true);
    }



    recordAdvancePaymentModel = new RecordAdvancePayment();

    recordAdvForm() {

        this.submitted = true;
        if (!this.advanceForm.valid) {
            return;
        }

        let advanceList = [];
        this.reportSummary?.expenseList.forEach(element => {
          let requst = {
            expenseId: element.expenseId,
            originalAmount: parseFloat(element?.originalAmount.replace(/,/g, '')),
            availableTripAdvanceAmount:parseInt(element?.unformattedOriginalAmount),
            originalCurrencyId:element?.originalCurrencyId,
            exchangeRate:element?.exchangeRate,
            editedExchangeRate:element.editedExchangeRate
          }
          advanceList.push(requst);
        });

        this.recordAdvancePaymentModel.userId = this.userInfo.userId;
        this.recordAdvancePaymentModel.userGUID = this.userInfo.userGuId;
        this.recordAdvancePaymentModel.recordType = this.reportSummary.recordType;
        this.recordAdvancePaymentModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
            this.recordAdvancePaymentModel.viewType = this.userInfo.roleId;
        } else {
            this.recordAdvancePaymentModel.viewType = 1;
        }
        this.recordAdvancePaymentModel.reimbursedAmount = this.editreport.approvedAmount && this.editreport.approvedAmount != '' ? this.editreport.approvedAmount.replace(/,/g, "") : this.editreport.totalBaseAmount.replace(/,/g, "");
        this.recordAdvancePaymentModel.claimId = this.editreport.claimId;
        this.recordAdvancePaymentModel.claimGUID = this.editreport.claimGUID;
        this.recordAdvancePaymentModel.legalEntityId = this.userInfo.legalEntityId;
        this.recordAdvancePaymentModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.recordAdvancePaymentModel.isTripAdvance = this.reportSummary?.isTripAdvance;
        this.recordAdvancePaymentModel.advanceList = advanceList;
        this.AdvanceSubmitDis = true;
        this.reportService.recordAdvanceAPI(this.recordAdvancePaymentModel).subscribe(res => {
            if (!res.isError && res.errorCode == 0) {
                this.recordPaymentbtns = false;
                this.AdvanceSubmitDis = false;
                this.getReportSummary();
                this.modalRef.hide();

                this.advanceForm.reset();
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));

            } else if (res.isError && res.errorCode == 2) {
                this.modalRef.hide();
                this.recordPaymentbtns = false;
                this.AdvanceSubmitDis = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    formatDate(date) {
        if (date) {
            return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');

        }
    }

    async openTripEstimatePopup(tripEstimatePopOver?: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-xl",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(tripEstimatePopOver, config);
    }

    selectAdvanceModeOption(ev) {
        this.advanceForm.controls.paymentMode.patchValue(ev.name);
    }

    selectModeOption(ev) {
        this.reportPymtForm.controls.paymentMode.patchValue(ev.name);
    }

    recordReportForm() {
        this.reportSubmit = true;
        if (!this.reportPymtForm.valid) {
            return;
        }
        this.recordRptPymntModel.userId = this.userInfo.userId;
        this.recordRptPymntModel.userGUID = this.userInfo.userGuId;
        this.recordRptPymntModel.recordType = this.detailReport?.recordType;
        this.recordRptPymntModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
            this.recordRptPymntModel.viewType = this.userInfo.roleId;
        } else {
            this.recordRptPymntModel.viewType = 1;
        }
        this.recordRptPymntModel.claimId = this.editreport.claimId;
        this.recordRptPymntModel.claimGUID = this.editreport.claimGUID;
        //this.recordRptPymntModel.advanceApplied = +this.appliedAmt;

        if (this.recordRptPymntModel?.isLinkedToAdvancePayment) {
            this.recordRptPymntModel.advanceApplied = this.appliedAdvance;
        } else if (this.recordRptPymntModel?.isLinkedToPettyCashPayment) {
            this.recordRptPymntModel.pettyCashAmount = this.appliedAdvance;
        }

        if (this.recordRptPymntModel?.isLinkedToAdvancePayment || this.recordRptPymntModel?.isLinkedToPettyCashPayment) {
            // this.recordRptPymntModel.reimbursedAmount = this.totalReportAmt;
            this.recordRptPymntModel.reimbursedAmount = this.reimbursedAmountCalculation();
            this.recordRptPymntModel.advanceApplied = this.appliedAdavnceAmountCalculation();
        } else {
            this.recordRptPymntModel.reimbursedAmount = this.reportSummary?.advanceSummary?.advanceCode != null ? this.getReimbursedAmount(this.reportSummary?.employeePaidApproved, this.reportSummary?.advanceSummary.tripAdvanceAppliedAmount) : this.reimbursedAmountCalculation();
            this.recordRptPymntModel.reimbursedAmount = Number(this.recordRptPymntModel?.reimbursedAmount?.toString()?.replace(/,/g, '') || 0);
            this.recordRptPymntModel.advanceApplied = this.appliedAdavnceAmountCalculation();
        }
        console.log('sdsdsds',this.recordRptPymntModel);
        this.reportSubmitDis = true;
        this.reportsService.recordAdvanceAPI(this.recordRptPymntModel).subscribe(res => {
            if (!res.isError && res.errorCode == 0) {
                this.recordPaymentbtns = false;
                this.reportSubmitDis = false;

                this.getReportSummary();
                this.modalRef.hide();
                new RecordAdvancePayment();
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));

            } else if (res.isError && res.errorCode == 2) {
                this.modalRef.hide();
                this.reportSubmitDis = false;
                this.recordPaymentbtns = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    advanceAmtApi(userId, userGuId) {
        let walletModel = new UserModel();
        walletModel.userId = userId;
        walletModel.userGUID = userGuId;
        this.reportsService.walletRequest(walletModel).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                if (this.reportSummary.recordType == 4) {
                    this.apiAvailableAdv = res.payload?.pettyCashAdvanceBalance;
                } else {
                    this.apiAvailableAdv = res.payload?.advanceBalance;
                }
                this.globalAvailableAdv = this.apiAvailableAdv;
                this.expensesAmt = this.reportSummary?.unformattedApprovedAmount;
            }
        });
    }

    advanceApplied: any;
    availableAdvance: any;
    linkedAdvnce(linked: any, apiAvailablAdv) {
        this.totalReimbursable = 0;
        this.globalAvailableAdv = apiAvailablAdv;
        this.expensesAmt = this.totalApprovedAmount;

        // which is use for to identify islinked advance current status 
        this.reportSummary.isLinktoAdvace = linked ? true:false;
        if (linked) {
            this.availableAdvance = this.strToNum(this.reportSummary?.availableBalance) + this.strToNum(this.reportSummary?.advanceAmount);
            if(this.availableAdvance >= this.strToNum(this.expensesAmt)) {
                this.availableAdvance = this.availableAdvance - this.strToNum(this.expensesAmt);
                this.advanceApplied = this.strToNum(this.expensesAmt);
            } else {
                this.advanceApplied = this.strToNum(this.expensesAmt) - this.availableAdvance;
                this.availableAdvance = 0;
            }
            this.totalReimbursable = this.strToNum(this.totalApprovedAmount) - this.strToNum(this.advanceApplied);
        } else {
            this.availableAdvance = this.strToNum(this.reportSummary?.advanceAmount) + this.strToNum(this.reportSummary?.availableBalance);
            this.advanceApplied = 0;
            this.totalReimbursable = this.strToNum(this.totalApprovedAmount) - this.strToNum(this.advanceApplied);
        }
    }
    Financeapprove() {
        this.expenseList.forEach(elm => {
            let codesObj = {
                "taxCodeId": elm.taxCodeId,
                "taxCode": elm.taxCode,
                "financeIntegrationId": elm.financeIntegrationId,
                "financeIntegrationCode": elm.financeIntegrationCode,
                "expenseId": elm.expenseId,
                "hsnCode": elm.hsnCode,
                "hsnId": elm.hsnId,
                "approveAmount": elm.unformattedOriginalApproveAmount
            }
            this.expenseWithCodesList.push(codesObj);
        });

        this.approveFinancePop();
    }

    async approveFinancePop() {
        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.approveall'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('approvals.approall'),
                    handler: () => {
                        this.zone.run(() => {
                            this.getReportModal.userId = this.userInfo.userId;
                            this.getReportModal.userGUID = this.userInfo.userGuId;
                            this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.getReportModal.recordType = this.detailReport?.recordType;
                            this.approveDisable = true;
                            this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
                            if(this.isRestrictSubmit) {
                                this.showloader = false;
                                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                                return;
                            }
                            this.reportsService
                                .FinanceApprove(this.getReportModal)
                                .subscribe((res) => {
                                    if (!res.isError && res.errorCode === 0) {
                                        this.approveBtns = false;
                                        if (this.platformType === 2) {
                                            // this.dismiss();
                                        }
                                        this.getReportSummary();
                                        this.approveDisable = false;
                                        this.approveBtns = false;

                                        this.selectedExpense = [];
                                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                                    } else if (res.isError && res.errorCode === 2) {
                                        this.approveBtns = true;
                                        this.approveDisable = false;
                                        this.expenseWithCodesList = [];
                                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    financeReject() {

        this.expenseList.forEach(elm => {
            let codesObj = {
                "taxCodeId": elm.taxCodeId,
                "taxCode": elm.taxCode,
                "financeIntegrationId": elm.financeIntegrationId,
                "financeIntegrationCode": elm.financeIntegrationCode,
                "expenseId": elm.expenseId,
                "hsnCode": elm.hsnCode,
                "hsnId": elm.hsnId,
                "approveAmount": elm.unformattedOriginalApproveAmount
            }
            this.expenseWithCodesList.push(codesObj);
        });

        // this.financerejectallPopUp();
    }

    async financerejectallPopUp() {
        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.rejectall'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('approvals.rejectall'),
                    handler: () => {
                        this.zone.run(() => {
                            this.rejectDisable = true;
                            this.getReportModal.userId = this.userInfo.userId;
                            this.getReportModal.userGUID = this.userInfo.userGuId;
                            this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
                            this.reportsService
                                .FinanceReject(this.getReportModal)
                                .subscribe((res) => {
                                    if (!res.isError && res.errorCode === 0) {
                                        this.rejectDisable = false;
                                        if (this.platformType == 2) {
                                            this.dismiss();
                                        }
                                        this.getReportSummary();
                                        this.approveBtns = false;

                                        this.selectedExpense = [];
                                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                                    } else if (res.isError && res.errorCode === 2) {
                                        this.rejectDisable = false;
                                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    async financereject() {
        const modal = await this.modalController.create({
            component: FinanceModalPage,
            cssClass: 'my-custom-class',
            componentProps: {
                expenseList: this.expenseList,
                claimId: this.getReportModal.claimId,
                claimGUID: this.getReportModal.claimGUID,
            }


        });
        modal.onDidDismiss().then((dataReturned) => {
            if (this.platformType == 2) {
                this.getReportSummary();
            }

        });
        return await modal.present();
    }

    finpartialapprovepop(rejectFinanceModal: TemplateRef<any>) {
        this.redirectToList = true;

        this.expenseList.forEach(elm => {
            let codesObj = {
                "taxCodeId": elm.taxCodeId,
                "taxCode": elm.taxCode,
                "financeIntegrationId": elm.financeIntegrationId,
                "financeIntegrationCode": elm.financeIntegrationCode,
                "expenseId": elm.expenseId,
                "hsnCode": elm.hsnCode,
                "hsnId": elm.hsnId,
                "approveAmount": elm.unformattedOriginalApproveAmount
            }
            this.expenseWithCodesList.push(codesObj);
        });
        this.rejectExparray = [];
        this.rejectControls.controls = [];
        this.expenseList.forEach(element => {
            this.rejectControls.push(this.createItem(element));
        });
        
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(rejectFinanceModal, config);
    }
    rejectAllFinance() {
        this.submitted = true;
        if (!this.reasonForm.valid) {
            return;
        }
        let rejectaaray = [];
        this.expenseList.forEach(element => {
            let dict = this.setRejectedComments(element);
            rejectaaray.push(dict);
        });

        this.getReportModal.rejectedExpenseList = rejectaaray;
        this.rejectDisable = true;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
        this.reportsService
            .FinanceReject(this.getReportModal)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.rejectDisable = false;
                    this.getReportSummary();
                    this.modalRef.hide();
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                } else if (res.isError && res.errorCode === 2) {
                    this.rejectDisable = false;
                    this.modalRef.hide();
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }

    financeprartialSubmit() {
        let rejectaaray = [];
        this.reasonForm.controls.rejectedExpenseList.value.forEach(element => {
            let dict = this.setRejectedComments(element);
            rejectaaray.push(dict);
        });

        if (this.userInfo.isCategoryBasedEnabled) {
            rejectaaray.forEach(element => {
                element.isApproved = false;
            });
            this.categoryBaseExpenseList.forEach(element => {
                if (element.checkImage) {
                    let req = {
                        expenseId: element.expenseId,
                        isApproved: true
                    }
                    rejectaaray.push(req);
                }
            });
        }
        if (this.userInfo.isCategoryBasedEnabled) {
            this.getReportModal.expenseList = rejectaaray;
        } else {
            this.getReportModal.rejectedExpenseList = rejectaaray;
        }

        // this.getReportModal.rejectedExpenseList = rejectaaray;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.recordType = this.detailReport?.recordType;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.partialBtnLoad = true;
        if (!this.isAdmin && this.reportSummary.status == 2) {

            if (!this.rejectBoolean) {
                this.reportsService.Approve(this.getReportModal).subscribe((res) => {
                    if (!res.isError && res.errorCode === 0) {
                        this.modalRef.hide();
                        this.partialBtnLoad = false;
                        this.getReportSummary();
                        this.approveBtns = false;
                        this.selectedExpense = [];
                        this.rejectExparray = [];
                        this.expenseWithCodesList = [];
                        this.sharedService.setRefreshData(true);
                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    } else if (res.isError && res.errorCode === 2) {
                        this.partialBtnLoad = false;
                        this.expenseWithCodesList = [];
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    }
                });

            } else {
                this.reportsService
                    .rejectAll(this.getReportModal).subscribe((res) => {
                        if (!res.isError && res.errorCode === 0) {
                            this.modalRef.hide();
                            this.partialBtnLoad = false;
                            this.getReportSummary();
                            this.approveBtns = false;
                            this.selectedExpense = [];
                            this.rejectExparray = [];
                            this.expenseWithCodesList = [];
                            this.sharedService.setRefreshData(true);
                            this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                        } else if (res.isError && res.errorCode === 2) {
                            this.partialBtnLoad = false;
                            this.expenseWithCodesList = [];
                            this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                        }
                    });
            }


        } else {
            this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
            if(this.isRestrictSubmit) {
                this.showloader = false;
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }
            this.reportsService.FinanceApprove(this.getReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.modalRef.hide();
                    this.partialBtnLoad = false;
                    this.getReportSummary();
                    this.approveBtns = false;
                    // for (let i = 0; i < this.reportList.length; i++) {
                    //     if (this.reportList[i].claimId === this.getReportModal.claimId) {

                    //         this.reportList[i].status = this.approveMessage.status;
                    //         this.reportList[i].statusDescription = this.approveMessage.statusdes;
                    //     }
                    // }
                    this.selectedExpense = [];
                    this.rejectExparray = [];
                    this.expenseWithCodesList = [];
                    this.sharedService.setRefreshData(true);
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                } else if (res.isError && res.errorCode === 2) {
                    this.partialBtnLoad = false;
                    this.expenseWithCodesList = [];
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
        }



    }

    multiEdit(e) {
        let navInfo = {
            url: 'reports',
            fromPage: 'viewSlide',
            expenseArray: e.expenseIds
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.router.navigateRoot(["newExpenseMileageBulk"]);
    }

    openViewOnlyExp(expList, currentIndx, item, status) {
        this.detailReport.claimName = this.reportSummary?.claimName;
        this.detailReport.claimStartDate = this.reportSummary?.claimStartDate;
        this.detailReport.claimEndDate = this.reportSummary?.claimEndDate;
        if(this.mainheadehidden){
            this.detailReport = this.reportSummary;
        }
        this.expenseList.forEach(elm => {
            let obj = {
                "expenseId": elm.expenseId,
                "unformattedOriginalApproveAmount": elm.unformattedOriginalApproveAmount,
                "status": false
            }
            this.approvedExpenseList.push(obj);
        });
        sessionStorage.setItem('approvedExpenseList', JSON.stringify(this.approvedExpenseList));
        var url = 'reports';
        if (this.fromPage == 'approvePage') {
            url = 'approvals';
        }
        if (status == 1 && this.reportSummary?.recordType != 2) {
            this.EditExpense(item, false, expList, currentIndx);
            return;
        }

        expList.forEach(e => {
            e.isSelected = true;
        });
        let currentAmount = 0;
        expList.forEach(element => {
            currentAmount += parseInt(element.baseAmount?.toString().replace(/,/g, '') || 0)
        });
        let navInfo = {
            url: url,
            fromPage: 'viewSlide',
            expenseId: item.expenseId,
            expenseGUID: item.expenseGUID,
            expenseArray: [expList[currentIndx]],
            isReadOnly: true,
            // showHsnBtns: true,
            showHsnBtns: (this.userInfo.roleId == 3 || this.userInfo.roleId == 4) ? true : false,
            currentIndx: currentIndx,
            goToReportDetails: true,
            detailReport: this.reportSummary,
            expenseList: [expList[currentIndx]],
            allExpenses: expList,
            editExpenseIndex: currentIndx,
            walletAdvance:(this.detailReport?.isLinkedToAdvancePayment ==true)?this.apiAvailableAdv+currentAmount:0,
            claimTaggedTripList: this.tripsDetailsList || [], 
            tripTravellers: this.tripTravellers || [], 
            isLinkedToPettyCashPayment:this.detailReport?.isLinkedToPettyCashPayment,
            advanceapplied:this.detailReport.isLinkedToAdvancePayment ==true? this.reportSummary?.availableBalance:0        

        };
        console.log('report summary is :',this.reportSummary);
        if(this.reportSummary?.recordType == 2){
            if (this.reportSummary?.claimTaggedTripList.length> 0) {
                sessionStorage.setItem('hideTripDetails', 'true');
            } else {
                sessionStorage.setItem('hideTripDetails', 'false');
            }
            sessionStorage.setItem('fromApprovalsPage', JSON.stringify(this.fromApprovalsPage || false));

            let navInfo = {
                url: "advance",
                advance: this.reportSummary,
                showDetailreport:true,
                currentExpenseIndex:this.currentClaimIndex,
                expenseList:this.reportList,
                selectedAdvance:this.detailReport,
                fromPage:this.fromPage,

              };
              sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
              this.router.navigateRoot(["newAdvance"]);
        }else{
              sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            //   this.router.navigateRoot(["newExpenseMileageBulk"]);
            if(this.isMobileDevice) {
                this.router.navigateRoot(["newExpenseMileageBulk"]);
            } else {
                this.openExpenseCreationForm();
            }
        }
    }


    editTotalClaim(expList) {
        // console.log('dsdsd');
        this.detailReport.claimName = this.reportSummary?.claimName;
        this.detailReport.claimStartDate = this.reportSummary?.claimStartDate;
        this.detailReport.claimEndDate = this.reportSummary?.claimEndDate;
        this.detailReport.claimCategoryId = this.reportSummary?.claimCategoryId;
        this.detailReport.claimCategoryName = this.reportSummary?.claimCategoryName;
        this.detailReport.claimTypeId = this.reportSummary?.claimTypeId;
        this.detailReport.claimTypeName = this.reportSummary?.claimTypeName;
        this.detailReport.claimStartTime = this.reportSummary?.claimStartTime;
        this.detailReport.claimEndTime = this.reportSummary?.claimEndTime;
        this.detailReport.claimLevelVoilation = this.reportSummary?.claimLevelVoilation;
        this.detailReport.companyPaid = this.reportSummary?.companyPaid;
        this.detailReport.employeePaid = this.reportSummary?.employeePaid;
        this.detailReport.isSubmitWithoutApprovalFlow = this.reportSummary?.isSubmitWithoutApprovalFlow;
        this.detailReport.sentBackAmount = this.reportSummary?.sentBackAmount;
        this.detailReport.overallClaimType = this.reportSummary?.overallClaimType;
        this.detailReport.nonTravelAdvanceRefId = this.reportSummary?.nonTravelAdvanceRefId;
        console.log(this.detailReport);
        console.log(this.reportSummary);
        
        this.expenseList.forEach(elm => {
            let obj = {
                "expenseId": elm.expenseId,
                "unformattedOriginalApproveAmount": elm.unformattedOriginalApproveAmount,
                "status": false
            }
            this.approvedExpenseList.push(obj);
        });
        sessionStorage.setItem('approvedExpenseList', JSON.stringify(this.approvedExpenseList));
        var url = 'reports';
        if (this.fromPage == 'approvePage') {
            url = 'approvals';
        }

        expList.forEach(e => {
            e.isSelected = true;
        });
        let currentAmount = 0;
        expList.forEach(element => {
            currentAmount += parseInt(element.baseAmount?.toString().replace(/,/g, '') || 0)
        });
        let navInfo = {
            url: url,
            fromPage: 'reports',
            expenseId: expList[0]?.expenseId || null,
            expenseGUID: expList[0]?.expenseGUID || null,
            expenseArray: expList,
            isReadOnly: false,
            showHsnBtns: (this.userInfo.roleId == 3 || this.userInfo.roleId == 4) ? true : false,
            currentIndx: 0,
            goToReportDetails: true,
            detailReport: this.detailReport,
            report: this.detailReport,
            showMultiCurrencies: this.showMultiCurrencies,
            isLinkedToAdvancePayment:this.detailReport.isLinkedToAdvancePayment,
            isLinkedToPettyCashPayment:this.detailReport.isLinkedToPettyCashPayment,
            walletAdvance:(this.detailReport?.isLinkedToAdvancePayment ==true)?this.apiAvailableAdv+currentAmount:0,
            expenseList: expList,
            isEdit: true,
            claimTaggedTripList: this.tripsDetailsList || [],
            tripTravellers: this.tripTravellers || [], 
            advanceSummary:this.reportSummary?.advanceSummary,
            tripEstimateDetails:this.reportSummary?.tripEstimateDetails,
            advanceapplied:(this.detailReport.isLinkedToAdvancePayment ==true)? this.reportSummary?.availableBalance:0
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        // this.router.navigateRoot(["newExpenseMileageBulk"]);
        this.router.navigateRoot(["newreport"]);
    }

    viewExpense(e, value) {
        this.showSelectViolation = e.violationCode;
        if (value == 'web') {
            setTimeout(() => {
                document.getElementById(e.expenseIds[0].expenseId).scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest', inline: 'start',

                });
            }, 100);
        }

        this.reportSummary.expenseList.forEach(element => {
            element.showWarning = false;
        });

        this.reportSummary.expenseList.forEach(element => {
            const index = e.expenseIds.find((item) =>
                item.expenseId == element.expenseId);
            if (index) {
                element.showWarning = true;
            }
        });
    }
    viewViolations() {
        this.reportSummary.expenseList.forEach(element => {
            element.showWarning = false;
        });
        this.reportSummary.expenseList.forEach(element => {
            this.reportSummary?.violationList.forEach(e => {
                const index = e.expenseIds.find((item) =>
                    item.expenseId == element.expenseId);
                if (index) {
                    element.showWarning = true;
                }
            });
        });
    }

    getCommentModel = new GetCommentModel();

    openChat(chatPopup: TemplateRef<any>, expense) {
        if (this.reportSummary.recordType == 3) {
            expense = this.reportSummary.expenseList[0];
        }
        this.openedExpChat = expense;
        this.getMessages(true);

        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(chatPopup, config);
    }

    getMessages(load) {
        if (load) {
            this.chatLoad = true;
        }
        this.getCommentModel.referenceId = this.openedExpChat.expenseId;
        this.getCommentModel.referenceGUID = this.openedExpChat.expenseGUID;
        this.getCommentModel.referenceType = this.openedExpChat?.recordType;
        this.reportsService.getCommentHistory(this.getCommentModel).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.chatList = res.payload.historyList;
                this.addCommentModel.comments ='';
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.payload);
            }
            this.chatLoad = false;
        });
    }

    EditExpense(item, duplicate: any, expList?, currentIndx?) {
        this.detailReport.claimName = this.reportSummary?.claimName;
        this.detailReport.claimStartDate = this.reportSummary?.claimStartDate;
        this.detailReport.claimEndDate = this.reportSummary?.claimEndDate;
        this.detailReport.claimCategoryId = this.reportSummary?.claimCategoryId;
        this.detailReport.claimCategoryName = this.reportSummary?.claimCategoryName;
        this.detailReport.claimTypeId = this.reportSummary?.claimTypeId;
        this.detailReport.claimTypeName = this.reportSummary?.claimTypeName;
        this.detailReport.claimStartTime = this.reportSummary?.claimStartTime;
        this.detailReport.claimEndTime = this.reportSummary?.claimEndTime;
        this.detailReport.canEditClaim = this.reportSummary?.canEditClaim;
        expList?.forEach(e => {
            e.isSelected = true;
        });
        let currentAmount = 0;
        expList.forEach(element => {
            currentAmount += parseInt(element.baseAmount?.toString().replace(/,/g, '') || 0)
        });
        let navInfo = {
            url: 'reports',
            isEdit: true,
            expenseId: item.expenseId,
            expenseGUID: item.expenseGUID,
            status: item.status,
            expenseArray: expList ? [expList[currentIndx]] : [],
            isDuplicate: duplicate,
            goToReportDetails: true,
            detailReport: this.detailReport,
            type: item.categoryType,
            expenseList: expList ? [expList[currentIndx]] : [],
            allExpenses: expList,
            editExpenseIndex: currentIndx,
            claimTaggedTripList: this.tripsDetailsList || [],
            tripTravellers: this.tripTravellers || [], 
            isLinkedToAdvancePayment:this.detailReport.isLinkedToAdvancePayment,
            isLinkedToPettyCashPayment:this.detailReport.isLinkedToPettyCashPayment,
            walletAdvance:(this.detailReport?.isLinkedToAdvancePayment ==true)?this.apiAvailableAdv +currentAmount:0,
            advanceapplied:this.detailReport.isLinkedToAdvancePayment ==true? this.reportSummary?.availableBalance +currentAmount:0        
        };
        sessionStorage.setItem('fromPettyCash', JSON.stringify(this.fromPettyCash));
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        // this.router.navigateRoot(["newExpenseMileageBulk"]);
        if(this.isMobileDevice) {
            this.router.navigateRoot(["newExpenseMileageBulk"]);
        } else {
            this.openExpenseCreationForm();
        }
    }

    async openExpenseCreationForm() {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "auto-height"
        });
        modal.onDidDismiss().then(res => {
            let pInfo = JSON.parse(sessionStorage.getItem('toPathInfo'));
            // if(pInfo?.refresh_claimData == true || pInfo?.refresh_claimData == 'true') {
                // this.expenseList = [];
                // this.reportModel.claimId = pInfo?.report?.claimId;
                // this.reportModel.claimGUID = pInfo?.report?.claimGUID;
                this.getReportSummary(true);
            // }
        })
        return await modal.present();
    }

    addMessage() {

        this.addCommentModel.userId = this.userInfo.userId;
        this.addCommentModel.userGUID = this.userInfo.userGuId;
        this.addCommentModel.referenceId = this.openedExpChat.expenseId;
        this.addCommentModel.referenceGUID = this.openedExpChat.expenseGUID;
        this.addCommentModel.referenceType = this.openedExpChat?.recordType;;
        this.addCommentModel.legalEntityId = this.userInfo.legalEntityId;
        this.addDisable = true;
        this.reportsService.addComment(this.addCommentModel).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.toastr.success(res.payload);
                this.addCommentModel.comments = "";
                this.addDisable = false;
                this.getMessages(false);
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.payload);
                this.addDisable = false;

            }
        }, (err: any) => {
            this.addDisable = false;
            this.chatLoad = false;
          });
    }
    totalRejectedAmount() {
        var amount = 0;
        this.reportSummary.expenseList.forEach(element => {
            if (element.status == 4 || element.status == 5) {
                // let originalA = element.baseAmount.replace(/[^\w\s]/gi, "")
                let originalA = element.baseAmount;
                amount += parseFloat(originalA);
            }
        });

        return amount;
    }


    closeDownloadOptTemp() {
        this.modalRef.hide();
    }

    bulkDownload(downloadOptTemp: TemplateRef<any>) {

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(downloadOptTemp, config);
    }

    sendBack(sendBackTemp: TemplateRef<any>) {
        this.redirectToList = true;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(sendBackTemp, config);
    }
    settleadvancepop(settleAdvancepop: TemplateRef<any>){
        const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(settleAdvancepop, config); 
    }

    downloadOptSelector(value) {
        switch (value) {
            case 'excel':
                this.showDownloadOptions = true;
                this.downloadApiCall('NULL');
                break;
            case 'pdf':
                this.showDownloadOptions = true;
                this.downloadApiCall('PDF');
                break;
        }
    }

    downloadApiCall(value?, list?) {
        this.downloadButton = true;
        this.downloadModel.referenceIds = this.reportSummary.claimId;
        //    if (this.selectedReport.length) {
        //        this.downloadModel.referenceIds = this.selectedReport.toString();
        //    } else {
        //        this.downloadModel.referenceIds = null;
        //    }
        //    if (this.dropdownValue) {
        //        this.downloadModel.referenceIds = this.dropdownValue.claimId;
        //        this.downloadBtn = false;
        //    }
        this.filterModal.isDraft = null;
        this.downloadModel.filterBy = this.filterModal;
        this.downloadModel.userId = this.userInfo.userId;
        this.downloadModel.userGUID = this.userInfo.userGuId;
        this.downloadModel.entityType = 2;
        this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
        this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.downloadModel.docType = value;
        this.downloadModel.viewType = 2;
        //    this.downloadModel.viewType = this.expenseAndReportModel.viewType;
        this.downloadModel.isSummaryView = true;
        this.downloadModel.isApprover = false;
        this.downloadModel.jobType = this.reportSummary?.recordType == 2 ? 1 : 2;
        this.downloadModel.downloadActionType = this.reportSummary?.recordType == 2 ? 'ADVANCE_SUMMARY' : 'CLAIMS';
        this.downloadModel.recordType = this.reportSummary?.recordType;
        
        this.commonService.downloadList(this.downloadModel).subscribe(res => {
            if (!res.error && res.payload) {
                this.downloadPoolingStart(res.payload, 3000);
            }
        });
    }

    downloadPoolingStart(request, time) {
        this.downloadActionPoolCount++;
        this.downloadModel.jobId = request.jobId;
        this.downloadModel.jobGUID = request.jobGUID;
        this.downloadModel.downloadActionType = this.reportSummary?.recordType == 2 ? 'ADVANCE_SUMMARY' : 'CLAIMS';
        this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
            if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 20) {
                this.downloadTime = setTimeout(() => {
                    this.downloadPoolingStart(res.payload, time);
                }, time);
            } else if (!res.isError && res.payload.status == 3) {
                this.downloadButton = false;
                this.showDownloadOptions = false;
                this.modalRef.hide();
                this.downloadActionPoolCount = 0;
                window.open(res['payload']['resultFileURL'], '_self');
                this.toastr.success('Download successfully', 'Success');
            } else {
                this.downloadButton = false;
                this.showDownloadOptions = false;
                this.modalRef.hide();
                this.toastr.error(res.errorMessage, 'Error');
                this.downloadActionPoolCount = 0;
            }
        });
    }

    approvePopCall(ApprovePop: TemplateRef<any>, val) {
        this.redirectToList = true;
        var isAdvLinked = false;
        this.appliedAmt = 0;
        this.recordRptPymntModel.paymentMode = '';
        this.recordRptPymntModel.referenceNumber = '';
        this.recordRptPymntModel.comments = '';
        if (this.reportSummary?.isLinkedToAdvancePayment && (this.reportSummary?.recordType == 1)) {
            this.appliedAmt = this.reportSummary?.unformattedAdvanceAmount;
            isAdvLinked = true;
        } else if (this.reportSummary?.isLinkedToPettyCashPayment && (this.reportSummary?.recordType == 4)) {
            this.appliedAmt = this.reportSummary?.unformattedPettyCashAmount;
            isAdvLinked = true;
        }

        if (isAdvLinked == true) {
            this.expensesAmt = this.reportSummary?.unformattedApprovedAmount;
            if (this.expensesAmt > 0) {
                if ((this.expensesAmt > this.globalAvailableAdv) || (this.expensesAmt == this.globalAvailableAdv)) {
                    this.totalReportAmt = this.expensesAmt - this.appliedAmt;
                } else if (this.expensesAmt < this.globalAvailableAdv) {
                    this.totalReportAmt = 0;
                }
            }
        }

        this.partialApprove = val;
        this.expenseList.forEach(element => {
            //  element.checkImage = true;
            element.approvedAmount = element.unformattedOriginalApproveAmount;
            element.rejectedAmount = (element.unformattedRejectedBaseAmount || 0);
        });
        this.expenseList.forEach(element => {
            if (element.financeIntegrationName) {
                element.finacerIntegrationCodeData = this.finacerIntegrationCodeData;
                let indexOf = this.finacerIntegrationCodeData?.findIndex((value) => element?.financeIntegrationId == value?.financeIntegrationId);
                
                if (indexOf !== -1) { // Check if indexOf is not -1 to ensure a match was found
                    const dimensions = this.finacerIntegrationCodeData[indexOf]?.dimensions || [];
                    
                    for (const innerelement of dimensions) {
                        let request = {
                            "id": innerelement.id,
                            "dimensionCode": innerelement.dimensionCode
                        };
        
                        if (innerelement.level === 1) {
                            element.firstDimensionCodeData.push(request);
                            element.firstDimensionTempCodeData.push(request);
                            if (!element.baDimension1Name) {
                                element.baDimension1Name = innerelement.dimensionCode;
                                element.baDimension1 = innerelement.id;
                            }
                        } else if (innerelement.level === 2) {
                            element.secondDimensionCodeData.push(request);
                            element.secondDimensionTempCodeData.push(request);
                            
                            if (!element.baDimension2Name) {
                                element.baDimension2Name = innerelement.dimensionCode;
                                element.baDimension2 = innerelement.id;
                            }
                        } else if (innerelement.level === 3){
                            element.thirdDimensionCodeData.push(request);
                            element.thirdDimensionTempCodeData.push(request);
                            
                            if (!element.baDimension3Name) {
                                element.baDimension3Name = innerelement.dimensionCode;
                                element.baDimension3 = innerelement.id;
                            }
                        }
                    }
                }
            }
        });
    
        if(this.reportSummary?.recordType == 2){

            this.getReportModal.skipBudgetCheckMessage = true;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-xl modal-dialog-centered modal-large",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(ApprovePop, config); 
        }else{
            
            // if((this.userInfo.roleId == 3 || this.userInfo.roleId == 4)){     
            //     this.approveAllClick(ApprovePop);          
            // }else{
                
            // }

            this.getReportModal.skipBudgetCheckMessage = true;
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-xl modal-dialog-centered modal-large",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(ApprovePop, config); 
        }
        
        this.totalApproveAmountCal();
        this.linkedAdvnce(this.getReportModal.isLinkedToAdvancePayment,this.apiAvailableAdv);
    }

    approveAdvPopCall(advanceApprovePop: TemplateRef<any>, val) {
        this.redirectToList = true;

        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(advanceApprovePop, config);

    }

    getAbsValue(val) {
        return val < 0 ? Math.abs(val) : val;
    }
    
    closeAppPop() {
        this.isModifyRepushClicked = false;
        this.isErrorcode10 = false;
        this.budgetCategoryItems = [];
        this.anomaliesCategoryItems = [];
        this.getReportModal.skipAnomolies = false;
        this.getReportModal.skipBudgetCheckMessage = false;
        this.modalRef.hide()
            this.expenseList.forEach(elm => {
                elm.secondDimensionCodeData =[];
                elm.thirdDimensionCodeData=[];
                elm.firstDimensionCodeData =[];
                // elm.baDimension2Name='';
                // elm.baDimension1Name='';
                // elm.baDimension3Name=''
                // lmg.checkImage = false;
            });
    }

    reasonForReject(val, index) {
        let reasonVal = val.target.value;
        this.expenseList[index].reason = reasonVal;
    }

    private approveAmountDebounce: Subject<{ event: any; index: number }> = new Subject();
    onApproveAmtChange(event: any, index: number) {
        this.approveAmountDebounce.next({ event, index });
    }

    approveAmount(val, index) {

        let amountVal = this.expenseList[index].unformattedOriginalAmount;
        let approveVal = val.target.value;


        if (val.target.value.length > 0) {
            if (amountVal >= approveVal) {
                this.expenseList[index].unformattedOriginalApproveAmount = parseFloat((approveVal || 0).toString().replace(/,/g, ''));
                this.expenseList[index].unformattedOriginalRejectedAmount = this.expenseList[index].unformattedOriginalAmount - parseFloat(approveVal);
                this.expenseList[index].rejectedAmount = this.expenseList[index].unformattedOriginalAmount - parseFloat(approveVal);
                this.totalApproveAmountCal();  //total Approved Amount Calculation
                this.linkedToAdvanceChange(this.reportSummary.isLinktoAdvace);
            } else {
                this.expenseList[index].rejectedAmount = "";
                this.expenseList[index].unformattedOriginalRejectedAmount = 0;
                this.expenseList[index].unformattedOriginalApproveAmount = "";
                this.toastr.error("Approve amount can't be greater than requested amount", 'Error');
                this.totalApproveAmountCal(); //total Approved Amount Calculation
                this.linkedToAdvanceChange(this.reportSummary.isLinktoAdvace);
                return;
            }

        } else {
            this.expenseList[index].unformattedOriginalApproveAmount = 0;
            this.expenseList[index].rejectedAmount = 0;
            this.totalApproveAmountCal(); //total Approved Amount Calculation
        }

    }

    claimedApprovedAmtSame() {
        this.sameValCliamApprove = !this.sameValCliamApprove
        if (this.sameValCliamApprove) {
            this.expenseList.forEach(element => {
                element.unformattedOriginalApproveAmount = element.unformattedOriginalAmount;
                element.rejectedAmount = 0;
                element.unformattedOriginalRejectedAmount = 0;
            });
        } else {
            this.expenseList.forEach(element => {
                element.rejectedAmount = (element.unformattedOriginalApproveAmount - parseFloat(element.approvedAmount));
                element.unformattedOriginalRejectedAmount = (element.unformattedOriginalApproveAmount - parseFloat(element.approvedAmount));
                element.unformattedOriginalApproveAmount = element.unformattedOriginalAmount - element.rejectedAmount;
            });
        }


    }
    numberValidation(event: any, isTime?) {
        const pattern = /^[0-9!.@#$%^&*()]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    approveClaim() {
        let rejectArray = this.expenseList
            .filter(element => element.crossImage)
            .map(element => this.setRejectedComments(element));

        const isReasonNull = rejectArray.some(object => !object.reason);

        if (this.userInfo.isRejectReasonMandatory && isReasonNull) {
            this.toastr.error('Please enter reason for rejection', this.translate.instant('alerts.error'));
            return;
        }

        if(this.isModifyRepushClicked) {
            this.isModifyRepushClicked = false;
            this.loadRepushModify = true;
            const obj = {
                claimId: this.reportSummary?.claimId,
                claimGUID: this.reportSummary?.claimGUID,
                legalEntityGuId: this.userInfo.legalEntityGuId,
                legalEntityId: this.userInfo.legalEntityId
            }
            this.expenseService.modifyRepush(obj).subscribe(res => {
                this.loadRepushModify = false;
                this.approveAllClick();
            }, err => {
                this.toastr.error(err, this.translate.instant('alerts.error'));
            })
        } else {
            this.approveAllClick();
        }
    }


    approveAllClick(ApprovePop?:TemplateRef<any>) {
        let rejectaaray = [];
        this.expenseList.forEach(element => {
            if (element.crossImage) {
                let dict = this.setRejectedComments(element);
                rejectaaray.push(dict);
            }
        });

        this.expenseWithCodesList = [];
        this.expenseList.forEach(elm => {            
            let codesObj = {
                "taxCodeId": elm.taxCodeId,
                "taxCode": elm.taxCode,
                "financeIntegrationId": elm.financeIntegrationId,
                "financeIntegrationCode": elm.financeIntegrationCode,
                "expenseId": elm.expenseId,
                "hsnCode": elm.hsnCode,
                "hsnId": elm.hsnId,
                "approveAmount": elm.unformattedOriginalApproveAmount,
                "baDimension1Name": elm.baDimension1Name,
                "baDimension2Name": elm.baDimension2Name,
                "baDimension3Name": elm.baDimension3Name,
                "baDimension1" : elm.baDimension1,
                "baDimension2" : elm.baDimension2,
                "baDimension3" : elm.baDimension3,
                "merchantId": elm.merchantId,
                "merchantCode": elm.merchantCode,
                "merchantName": elm.merchantName,
                // "gstFinanceId":elm.gstFinanceId,
                // "gstTaxCodeId":elm.gstTaxCodeId
            }
            this.expenseWithCodesList.push(codesObj);
        });
        if (this.getReportModal?.isLinkedToAdvancePayment) {
            this.getReportModal.advanceApplied = +this.appliedAmt;
        } else if (this.getReportModal?.isLinkedToPettyCashPayment) {
            this.getReportModal.pettyCashAmount = +this.appliedAmt;
        }
        if (this.getReportModal?.isLinkedToAdvancePayment || this.getReportModal?.isLinkedToPettyCashPayment) {
            this.getReportModal.reimbursedAmount = this.totalReportAmt;
        } else {
            this.getReportModal.reimbursedAmount = this.expensesAmt;
        }


        if(this.userInfo?.isEnableBudgetCheck && this.isErrorcode10 && this.budgetCategoryItems.length != 0){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV?.length > 0){
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }else{
                this.getReportModal.skipBudgetCheckMessage = true;
                this.getReportModal.skipAnomolies = true;
            }
        }

        // sample code is added


        if(this.isErrorcode10 == true){
            this.getReportModal.skipAnomolies = true;
            this.getReportModal.skipBudgetCheckMessage = true;
        }else{
            if(this.userInfo.isEnableBudgetCheck == true){
                this.getReportModal.skipAnomolies = false;
                this.getReportModal.skipBudgetCheckMessage = false;
            }
        }

        let missingMandatoryEditbleData = false;
        this.editbledata?.forEach(ed => {
            ed?.ExpenseValue?.forEach(element => {
                if(element?.is_Mandatory && (!element?.parameter_Value || element?.parameter_Value == '') && !missingMandatoryEditbleData){
                    missingMandatoryEditbleData = true;
                }
            });
        })

        if(missingMandatoryEditbleData){
            this.toastr.error('Please fill all mandatory fields', this.translate.instant('alerts.error'));
            return;
        }

        this.getReportModal.rejectedExpenseList = rejectaaray;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.getReportModal.recordType = this.detailReport?.recordType;
        this.approveDisable = true;
        this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
        // console.log(this.editbledata)
        this.getReportModal.sapRequestData = this.sapRequestData ? this.sapRequestData : '';
        this.getReportModal.editableFields = this.editbledata ? JSON.stringify(this.editbledata) : '';
        this.getReportModal.nonTravelAdvanceRefId = this.reportSummary.nonTravelAdvanceRefId || null;

        console.log(this.getReportModal);
        if(this.isRestrictSubmit) {
            this.showloader = false;
            this.approveBtns = true;
            this.approveDisable = false;
            this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
            return;
        }
        this.reportsService
            .FinanceApprove(this.getReportModal)
            .subscribe((res) => {
                if(res?.errorCode != 10) { this.isRestrictSubmit = false; }
                if(res.errorCode == 10){
                   // this.approveBtns = true;
                    this.approveDisable = false;
                    this.isErrorcode10 = true;
                    this.budgetCategoryItems = [];
                    this.anomaliesCategoryItems = [];
                    this.getReportModal.skipAnomolies = true;
                    this.getReportModal.skipBudgetCheckMessage = true;
                    this.budgetCategoryItems = [...res.payload.budgetResponse];
                    this.anomaliesCategoryItems = [...res.payload.anomalies];
                    this.isRestrictSubmit = res?.payload?.isRestrict;
                    // this.expenseWithCodesList =[];
                    // const config = {
                    //     ignoreBackdropClick: true,
                    //     class: "modal-xl modal-dialog-centered modal-large",
                    //     keyboard: false,
                    // };
                    // this.modalRef = this.modalService.show(ApprovePop, config);  

                } else if (!res.isError && res.errorCode == 0) {
                    this.approveBtns = false;
                    this.getReportSummary();
                    this.approveDisable = false;
                    this.selectedExpense = [];
                    this.expenseWithCodesList = [];
                    this.getReportModal.skipAnomolies = false;
                    this.getReportModal.skipBudgetCheckMessage = false;
                    this.closeAppPop();
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                } else if (res.isError && res.errorCode == 2) {
                    this.approveBtns = true;
                    this.approveDisable = false;
                    this.getReportModal.skipAnomolies = false;
                    this.getReportModal.skipBudgetCheckMessage = false;
                    this.isErrorcode10 = false;
                    this.closeAppPop();
                    this.expenseWithCodesList = [];
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }

    openFICDropDown(e, i) {
       
        e.preventDefault();
        e.stopPropagation();
        this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
        setTimeout(() => {
                 document.getElementById("code").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.financeIntShow = false;
        });
        this.expenseList[i].financeIntShow = true;
    }

        openTaxDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.taxCodeData = this.taxCodeTempList;
        setTimeout(() => {
            // if (taxAutoCompleteReference) {
                // document.taxAutoCompleteReference.nativeElement.focus();
                 document.getElementById("code").focus()
            // }
          }, 0);
        this.expenseList.forEach(element => {
            element.taxShow = false;
        });
        this.expenseList[i].taxShow = true;
    }


    openHsnDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.hsnCodeList = this.hsnCodeTempList;
        setTimeout(() => {
                 document.getElementById("code").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.hsnShow = false;
        });
        this.expenseList[i].hsnShow = true;
    }

    onFinanceSearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.finacerIntegrationTempCodeData != null) {

            if (searchText != "") {
                let filterdata = this.finacerIntegrationTempCodeData.filter((data) => {
                    if (data.financeIntegrationCode.toLowerCase().indexOf(searchText.toLowerCase()) >=0 || data.financeIntegrationName.toLowerCase().indexOf(searchText.toLowerCase()) >=0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.finacerIntegrationCodeData = filterdata;
            } else {
                this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
            }
        } else {

        }
    }

    onTaxCodeSearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.taxCodeTempList != null) {
            if (searchText != "") {
                let filterdata = this.taxCodeTempList.filter((data) => {
                    if (
                        data.taxCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.taxCodeData = filterdata;
            } else {
                this.taxCodeData = this.taxCodeTempList;
            }
        }
    }

    onHsnCodeSearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.hsnCodeTempList != null) {
            if (searchText != "") {
                let filterdata = this.hsnCodeTempList.filter((data) => {
                    if (
                        data.hsnCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.hsnCodeList = filterdata;
            } else {
                this.hsnCodeList = this.hsnCodeTempList;
            }
        }
    }
    getCanvasClick() {
        //     var div = document.getElementById("myCanvas");

        //     div.addEventListener('click', e => {
        //         if (div.classList.contains('disabled')) {return}
        //         // Do not perform default action, stop bubbling the event
        //         e.preventDefault();e.stopPropagation()

        //         // Mark event as invalid for older browsers
        //         return false
        //     })

        // div.style.cursor = "not-allowed";
        // div.style.pointerEvents = "none";



        // let canv = document.querySelector("myCanvas");
        // canv.setAttribute(
        //       "style",
        //       ` cursor:not-allowed;
        //         pointer-events: none;`
        //     );

    }

    async present() {
        this.isLoading = true;
        return await this.loadingController.create({
            // duration: 5000,
            message: 'Attachment is loading...please wait'
        }).then(a => {
            a.present().then(() => {
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async _dismiss() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    }

    isChecked(event) {
        this.AcknologmentBooleanCheck = event.detail.checked;
        if (this.AcknologmentBooleanCheck) {
            if (this.showViolationCheckBox) {
                if (this.AcknologmentViolationBooleanCheck) {
                    this.button_disable = true;
                } else {
                    this.button_disable = false;
                }
            } else {
                this.button_disable = true;
            }
        } else {
            this.button_disable = false;
        }
    }
    isViolationChecked(event) {
        this.AcknologmentViolationBooleanCheck = event.detail.checked;
        if (this.AcknologmentViolationBooleanCheck) {
            if (this.AcknologmentBooleanCheck) {
                this.button_disable = true;
            } else {
                this.button_disable = false;
            }
        } else {
            this.button_disable = false;
        }
    }

    isChecke(event) {
        this.AcknologmentBooleanCheck = event.detail.checked;
    }

    Confirmacknologment(AcknologmentForm?:TemplateRef<any>) {

        if(this.userInfo?.isEnableBudgetCheck && this.isErrorcode10 && this.budgetCategoryItems.length != 0){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV?.length > 0){
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }else{
                this.removeReportModal.skipBudgetCheckMessage = true;
            }
        }


        this.showloader = true;
        this.zone.run(() => {
            if (this.reportSummary?.recordType) {
                this.removeReportModal.recordType = this.reportSummary?.recordType;
            }
            this.removeReportModal.userId = this.userInfo.userId;
            this.removeReportModal.userGUID = this.userInfo.userGuId;
            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.removeReportModal.isSubmitWithoutApprovalFlow = this.skipApproalFlowSubmit;
            this.buttonDisabled = true;
            if(this.isRestrictSubmit) {
                this.showloader = false;
                this.toastr.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                this.buttonDisabled = false;
                this.submitDisable = false;
                this.showloader = false;
                return;
            }
            this.reportsService.draftReport(this.removeReportModal).subscribe((res) => {
                if(res?.errorCode != 10) { this.isRestrictSubmit = false; }
                if(res.errorCode == 10){
                    this.showloader = false;
                    this.isErrorcode10 = true;
                    this.budgetCategoryItems = [];
                    this.anomaliesCategoryItems = [];
                    this.removeReportModal.skipAnomolies = true;
                    this.removeReportModal.skipBudgetCheckMessage = true;
                    this.budgetCategoryItems = [...res.payload.budgetResponse];
                    this.anomaliesCategoryItems = [...res.payload.anomalies];
                    this.isRestrictSubmit = res?.payload?.isRestrict;
                    if (!document.querySelector('.modal-backdrop')) {
                        const config = {
                            ignoreBackdropClick: false,
                            class: "modal-lg modal-dialog-centered",
                            keyboard: false,
                        };
                        this.modalRef = this.modalService.show(AcknologmentForm, config);
                    }
                } else if (!res.isError && res.errorCode === 0) {
                    this.showloader = false;
                    this.modalRef?.hide();
                    this.submitDisable = false;
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    if (this.platformType === 2) {
                        this.backto.emit();
                        this.sharedService.setRefreshData(true);
                    } else {
                        this.getReportSummary();
                        this.reportBtns = false;

                    }
                    this.buttonDisabled = false;
                    this.getReportSummary();
                } else if(res?.errorCode == 11) {
                    this.buttonDisabled = false;
                    this.submitDisable = false;
                    this.showloader = false;
                    this.modalRef?.hide();
                    this.dTempHeader = 'Budget Insufficient';
                    this.dTempBody = res?.errorMessage;
                    const config = {
                        ignoreBackdropClick: false,
                        class: "modal-lg modal-dialog-centered",
                        keyboard: false,
                    };
                    this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
                    return true;
                } else if (res.isError === true) {
                    this.buttonDisabled = false;
                    this.submitDisable = false;
                    this.showloader = false;
                    this.removeReportModal.skipAnomolies = true;
                    this.removeReportModal.skipBudgetCheckMessage = true;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
        });
    }
    @ViewChild("dynamicTemp", { static: false }) private dynamicTemp;
    dTempHeader: any;
    dTempBody: any;

    resetClose() {
        this.modalRef?.hide();
        this.approveDisable = false;
        this.statusReason = '';
        this.getReportModal.skipAnomolies = false;
        this.getReportModal.skipBudgetCheckMessage = false;
        this.AcknologmentBooleanCheck = true;
        this.showdropdown = false;
        this.selectedStatus = '';

        
        setTimeout(() => {
            this.showViolationCheckBox = false;
        }, 1000);
    }
    isCheck(event) {
        this.check = event.detail.checked;
    }

    checkCheckbox(ev?) {
        setTimeout(() => {
          this.reportSummary?.expenseList.forEach((item: any) => {
            item.expenseAttachments.forEach(element => {
                element.isSelected = this.checkParent; 
            });
          });
        });
      }
      verifyEvent() {
        let allItems = 0;
        this.reportSummary?.expenseList.forEach((item: any) => {
            item.expenseAttachments.forEach(element => {
                allItems++;
            });
          });

        let selected = 0;
        this.reportSummary?.expenseList.map((item: any) => {
            item.expenseAttachments.forEach(element => {
                if (element.isSelected) selected++; 
            });
        });
        if (selected > 0 && selected < allItems) {
          // One item is selected among all checkbox elements
          this.indeterminateState = true;
          this.checkParent = false;
        } else if (selected == allItems) {
          // All item selected
          this.checkParent = true;
          this.indeterminateState = false;
        } else {
          // No item is selected
          this.indeterminateState = false;
          this.checkParent = false;
        }
      }


    selectCategoryFilters(event) {
        console.log('all select check boex :',this.checkedAll);

        if (event) {
            // Checkbox change triggered by user interaction
            this.reportSummary?.expenseList.forEach(child => {
              child.isSelected = this.checkedAll;
              child.expenseAttachments.forEach(subCheckbox => subCheckbox.isSelected = this.checkedAll);
            });
          }

        // this.checkedAll == (boolean == true ? (this.checkedAll == true ? false : true) : false);
        // this.reportSummary.expenseList.forEach((item) => {
        //     item.expenseAttachments.forEach(element => {
        //         this.checkedAll ? element.isSelected = true : element.isSelected = false;
        //     });
        // });

        // setTimeout(() => {
        //     this.reportSummary.expenseList.forEach((item) => {
        //         item.expenseAttachments.forEach(element => {
        //             element.isSelected = this.checkedAll;
        //     });
        //     });
        // });
    }

    selectSingleCategory(e, i, j) {
        if (e) {
            // Checkbox change triggered by user interaction
            this.checkedAll = this.reportSummary?.expenseList.every(child =>
              child.isSelected && child.expenseAttachments.every(subCheckbox => subCheckbox.isSelected)
            );
          }
        //  this.checkedAll != this.checkedAll;
        // this.check = true;
        // this.reportSummary.expenseList[i].expenseAttachments[j].isSelected = e.target.checked;
        // let totallength = 0;
        // let totalselectedAttchamnetCount = 0;
        // this.reportSummary.expenseList.forEach(element => {
        //     element.expenseAttachments.forEach(inner => {
        //         totallength += 1;
        //         if (inner.isSelected) {
        //             totalselectedAttchamnetCount += 1;
        //         }
        //     });
        // });
        // if (totallength != totalselectedAttchamnetCount) {
        //     this.checkedAll = false;
        //     this.indeterminateStates = false;
        // } else {
        //     this.checkedAll = true;
        //     this.indeterminateStates = true;
        // }

         // Check if all checkboxes are selected, including sub-checkboxes
            // const allCheckboxesSelected = this.reportSummary.expenseList.every(checkbox =>
            //     checkbox.expenseAttachments.every(subCheckbox => subCheckbox.isSelected)
            // );

            // // Check if all checkboxes are deselected, including sub-checkboxes
            // const allCheckboxesDeselected = this.reportSummary.expenseList.every(checkbox =>
            //     checkbox.expenseAttachments.every(subCheckbox => !subCheckbox.isSelected)
            // );

            // // Update selectAll based on the above conditions
            // this.checkedAll = allCheckboxesSelected;
            // if (!allCheckboxesSelected && !allCheckboxesDeselected) {
            //     this.checkedAll = false;
            // }
    }
    checkEnable() {
        let check = true;
        if (this.reportSummary.expenseList && this.reportSummary.expenseList.length) {
            this.reportSummary.expenseList.forEach(ele => {
                ele.expenseAttachments?.forEach(ele2 => {
                    if (ele2.isSelected) {
                        check = false;
                    }
                });
            });
        }
        return check;
    }
    downloadBtnn() {
        let selectedList = [];
        this.reportSummary.expenseList.forEach(element => {
            element.expenseAttachments.forEach(inner => {
                if (inner.isSelected) {
                    selectedList.push(inner.attachmentId)
                }
            });
        });
        let attachObj = {
            attachmentIds: selectedList
        }
        this.check = true;
        this.downloadfile = true;
        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.checkedAll = false;
                this.selectCategoryFilters(false);
                this.downloadProofs = res.payload.downloadFileResponseDTO;
                // this.downloadProofs.forEach(element => {
                //     if (element) {
                //         window.open(element.url);
                //     }
                // });
                const downloadFile = (url, index) => {
                    setTimeout(() => {
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = ''; // You can specify a filename here if needed
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }, index * 1000); // 1000 ms = 1 second delay between each download
                };
                
                this.downloadProofs.forEach((element, index) => {
                    if (element) {
                        downloadFile(element.url, index);
                    }
                });
                this.downloadfile = false;
                this.check = false;
                // window.open(this.downloadProof);
                this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });

    }

    sendBackCancel() {
        this.modalRef.hide();
        this.sendBackErrortxt = false;
        this.sendBackReason = '';
    }
    EditAdvance(editreport) {
        if ((editreport?.status == 3 && ((this.userInfo.isEnableAdvanceEditApprover === true) && (this.userInfo?.roleId == 3 && (JSON.parse(sessionStorage.getItem('isadmin')) == true)||this.userInfo?.roleId == 4 && (JSON.parse(sessionStorage.getItem('isadmin')) == true))) )||
        (editreport?.status == 2 &&(this.userInfo.isEnableAdvanceEditApprover === true) && ( this.userInfo?.roleId ==1 ||(this.userInfo?.roleId ==2 && (JSON.parse(sessionStorage.getItem('isadmin')) == true))))) {
          this.isAdvanceReadOnly = false;
        } else {
          this.isAdvanceReadOnly = true;
        }
        if (this.reportSummary?.claimTaggedTripList.length> 0) {
            sessionStorage.setItem('hideTripDetails', 'true');
        } else {
            sessionStorage.setItem('hideTripDetails', 'false');
        }
        let navInfo = {
          url: "approvals",
          advance: editreport,
          isAdvanceReadOnly: this.isAdvanceReadOnly,
          advancebtns:this.advancebtns
        };
        sessionStorage.setItem("toPathInfo", JSON.stringify(navInfo));
       
        this.router.navigateRoot(["newAdvance"]);
    
      }
    sendBackRequest() {
        if (this.sendBackReason == '') {
            this.sendBackErrortxt = true;
        } else {
            this.sendBackErrortxt = false;
            let sendBackModel = new SendBack();
            // sendBackModel.approverGUID = this.reportSummary.approverGUID;
            // sendBackModel.approverId = this.reportSummary.approverId;
            sendBackModel.claimGUID = this.reportSummary.claimGUID;
            sendBackModel.claimId = this.reportSummary.claimId;
            sendBackModel.description = this.sendBackReason;
            sendBackModel.sendEmailOnSendBack = this.sendEmailOnSendBack;
            this.sendbackloader = true;
            this.expenseService.getSendBackRequest(sendBackModel).subscribe(res => {
                this.sendbackloader = false;
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.modalRef.hide();
                    //this.getReportSummary();
                    if(JSON.parse(sessionStorage.getItem('isEmailApproval'))) {
                        this.getReportSummary();
                    } else {
                        this.backToreport(true);
                    }
                } else {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    this.modalRef.hide();
                }
            });
        }

    }

    reasonEntering(event) {
        if (this.sendBackReason != '') {
            this.sendBackErrortxt = false;
        } else {
            this.sendBackErrortxt = true;
        }
    }

    nextDetail() {
        this.selectedCategoryBaseExpenseList = [];
        this.unselectedCategoryBaseExpenseList = [];
        if (this.currentClaimIndex < this.reportList?.length - 1) {
            ++this.currentClaimIndex;
            this.reportClick(this.reportList[this.currentClaimIndex], '');

            if (this.reportList[this.currentClaimIndex]?.status === 1 || this.reportList[this.currentClaimIndex]?.status === 11) {
                this.reportBtns = true;
            } else {
                this.reportBtns = false;
            }
        }
       this.expenseList.forEach(element => {
        if (element.hasViolation) {
            this.showViolationCheckBox = true;

        }else{
            this.showViolationCheckBox = false;
        }
       });

    }

    preDetail() {
        this.selectedCategoryBaseExpenseList = [];
        this.unselectedCategoryBaseExpenseList = [];
        if (this.currentClaimIndex > 0) {
            --this.currentClaimIndex;
            this.reportClick(this.reportList[this.currentClaimIndex], '');

            if (this.reportList[this.currentClaimIndex]?.status === 1 || this.reportList[this.currentClaimIndex]?.status === 11) {
                this.reportBtns = true;
            } else {
                this.reportBtns = false;
            }
        }
        this.expenseList.forEach(element => {
            if (element.hasViolation) {
                this.showViolationCheckBox = true;
    
            }else{
                this.showViolationCheckBox = false;
            }
           });
    }

    moveToNext(from) {
        if (from == 'forward' && this.nextvalue == this.reportList.length - 1) {
            this.nextvalue = this.nextvalue + 1;
            this.reportSummaryRequestModal.claimId = this.reportList[this.nextvalue].claimId;
            this.reportSummaryRequestModal.claimGUID = this.reportList[this.nextvalue].claimGUID;
        } else if (this.nextvalue > 0) {
            this.nextvalue = this.nextvalue - 1;
            this.reportSummaryRequestModal.claimId = this.reportList[this.nextvalue].claimId;
            this.reportSummaryRequestModal.claimGUID = this.reportList[this.nextvalue].claimGUID;
        }
        this.getReportSummary();
    }

    reportClick(item, index) {
        if (this.reportList[this.currentClaimIndex]?.status === 1 || this.reportList[this.currentClaimIndex]?.status === 11) {
            this.reportBtns = true;
        } else {
            this.reportBtns = false;
        }
        this.reportSummaryRequestModal.claimId = item.claimId;
        this.reportSummaryRequestModal.claimGUID = item.claimGUID;
        this.nextvalue = index;
        this.getReportSummary();
    }

    async getPdfAsBase64(url: string): Promise<string> {
        try {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();

            // Convert the response data to base64
            const base64 = btoa(
                new Uint8Array(buffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return base64;
        } catch (error) {
            throw error;
        }
    }

    claimCostSummary(event){
        this.isFirstOpen = event;
    }
    historydetails(e){
        this.isTripOpenIcon=e
    }
    approverDetails(event){
        this.isApprovalOpen = event;
    }

    toggleRelatedProofsAccordion(event){
        this.isThirdOpen = event;
    }

    toggleTripDetailAcrdn(event){
        this.isTripOpen = event;
    }


    dateTimeFormat(date) {
        return moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD-MMM-YY HH:mm:ss')
    }
            
    openGSFICDropDown(e,i){
        e.preventDefault();
        e.stopPropagation();
        this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
        setTimeout(() => {
                 document.getElementById("gscode").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.gsfinanceIntShow = false;
        });
        this.expenseList[i].gsfinanceIntShow = true;
    }
    onfinanceEventFocus() {
        setTimeout(() => {
          this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
        }, 0);
    }
    opengsTaxDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.taxCodeData = this.taxCodeTempList;
        setTimeout(() => {
            // if (taxAutoCompleteReference) {
                // document.taxAutoCompleteReference.nativeElement.focus();
                 document.getElementById("code").focus()
            // }
          }, 0);
        this.expenseList.forEach(element => {
            element.gstaxShow = false;
        });
        this.expenseList[i].gstaxShow = true;
    }

    ontaxEventFocus() {
        setTimeout(() => {
          this.taxCodeData = this.taxCodeTempList;
        }, 0);
    }
    ongstaxEventFocus() {
        setTimeout(() => {
          this.taxCodeData = this.taxCodeTempList;
        }, 0);
    }
    onhsnEventFocus(){
        setTimeout(() => {
            this.hsnCodeList = this.hsnCodeTempList;
          }, 0);
    }
    onGsFinanceSearch(val) {

        let searchText = val.currentTarget.value.trim();
        if (this.finacerIntegrationTempCodeData != null) {

            if (searchText != "") {
                let filterdata = this.finacerIntegrationTempCodeData.filter((data) => {
                    if (
                        data.financeIntegrationCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0 || data.financeIntegrationName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.finacerIntegrationCodeData = filterdata;
            } else {
                this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
            }
        } else {

        }
    }


    onFristDimensionSearch(val,i) {
        let searchText = val.currentTarget.value.trim();
        if (this.expenseList[i].firstDimensionTempCodeData != null) {
            if (searchText != "") {
                let filterdata = this.expenseList[i].firstDimensionTempCodeData.filter((data) => {
                    if (
                        data.dimensionCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.expenseList[i].firstDimensionCodeData = filterdata;
            } else {
                this.expenseList[i].firstDimensionCodeData = this.expenseList[i].firstDimensionTempCodeData;
            }
        } else {

        }
    }
    
    onSecondDimensionSearch(val,i) {
        let searchText = val.currentTarget.value.trim();
        if (this.expenseList[i].secondDimensionTempCodeData != null) {

            if (searchText != "") {
                let filterdata = this.expenseList[i].secondDimensionTempCodeData.filter((data) => {
                    if (
                        data.dimensionCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.expenseList[i].secondDimensionCodeData = filterdata;
            } else {
                this.expenseList[i].secondDimensionCodeData = this.expenseList[i].secondDimensionTempCodeData;
            }
        } else {

        }
    }
    onThirdDimensionSearch(val,i) {
        let searchText = val.currentTarget.value.trim();
        if (this.expenseList[i].thirdDimensionTempCodeData != null) {

            if (searchText != "") {
                let filterdata = this.expenseList[i].thirdDimensionTempCodeData.filter((data) => {
                    if (
                        data.dimensionCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.expenseList[i].thirdDimensionCodeData = filterdata;
            } else {
                this.expenseList[i].thirdDimensionCodeData = this.expenseList[i].thirdDimensionTempCodeData;
            }
        } else {

        }
    }
    
    onGsTaxCodeSearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.taxCodeTempList != null) {
            if (searchText != "") {
                let filterdata = this.taxCodeTempList.filter((data) => {
                    if (
                        data.taxCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    } else {
                    }
                });
                this.taxCodeData = filterdata;
            } else {
                this.taxCodeData = this.taxCodeTempList;
            }
        }
    }

    ongsfinanceEventFocus() {
        setTimeout(() => {
          this.finacerIntegrationCodeData = this.finacerIntegrationTempCodeData;
        }, 0);
    }
    openDimensionOneDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.firstDimensionCodeData = this.firstDimensionTempCodeData;
        setTimeout(() => {
                 document.getElementById("code").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.thirddimension = false;
            element.seconddimension = false;
             element.firstdimension = false;
        });
        this.expenseList[i].firstdimension = true;
        // this.expenseList[i].seconddimension = false;
        // this.expenseList[i].thirdddimension = false;
    }
    openDimensionsecondDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.secondDimensionCodeData = this.secondDimensionCodeData;
        setTimeout(() => {
                 document.getElementById("list").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.thirddimension = false;
            element.seconddimension = false;
             element.firstdimension = false;
        });
        this.expenseList[i].seconddimension = true;
        // this.expenseList[i].firstdimension = false;
        // this.expenseList[i].thirdddimension = false;
    }
    openDimensionthirdDropDown(e, i) {
        e.preventDefault();
        e.stopPropagation();
        this.thirdDimensionCodeData = this.thirdDimensionTempCodeData;
        setTimeout(() => {
                 document.getElementById("listdata").focus()
          }, 0);
        this.expenseList.forEach(element => {
            element.thirddimension = false;
            element.seconddimension = false;
             element.firstdimension = false;
        });
        this.expenseList[i].thirddimension = true;
        // this.expenseList[i].firstdimension = false;
        // this.expenseList[i].seconddimension = false;
    }
    
    onfirstDimensionEventFocus(){
        setTimeout(() => {
            this.firstDimensionCodeData = this.firstDimensionTempCodeData;
          }, 0);
    }
    onsecondDimensionEventFocus(){
        setTimeout(() => {
            this.secondDimensionCodeData = this.secondDimensionTempCodeData;
          }, 0);
    }
    onthirdDimensionEventFocus(){
        setTimeout(() => {
            this.thirdDimensionCodeData = this.thirdDimensionTempCodeData;
          }, 0);
    }    

gsfinanceIntegrationCode(code, i) {
    this.expenseList[i].gstFinanceId = code?.financeIntegrationId;
    this.expenseList[i].gstFinanceCodeName = code?.financeIntegrationCode;
    this.expenseList[i].gsfinanceIntShow = false;

    this.expenseList[i].gsfinaceIntCodeName = code.financeIntegrationCode + ' - ' + code.financeIntegrationName;
  
}

    firstDimensionCode(code, i) {
        this.expenseList[i].baDimension1 = code?.id;
        this.expenseList[i].baDimension1Name = code?.dimensionCode;
        this.expenseList[i].firstdimension = false;
    }
    secondDimensionCode(code, i) {
        this.expenseList[i].baDimension2 = code?.id;
        this.expenseList[i].baDimension2Name = code?.dimensionCode;
        this.expenseList[i].seconddimension = false;
    }
    thirdDimensionCode(code, i) {
        this.expenseList[i].baDimension3 = code?.id;
        this.expenseList[i].baDimension3Name = code?.dimensionCode;
        this.expenseList[i].thirddimension = false;
    }
    

    totalApproveAmountCal(){
        this.totalApprovedAmount = 0;     
        // this.totalReimbursable = 0;
        this.totalApprovedAmount = this.expenseList?.reduce((sum, item) => sum + parseFloat(item?.unformattedOriginalApproveAmount != '' ? item?.unformattedOriginalApproveAmount : 0), 0);
        // this.appliedAdvance = this.totalApprovedAmount;
        if(this.totalApprovedAmount >= this.reportSummary?.availableBalance){
            this.appliedAdvance = this.reportSummary?.availableBalance;
        } else if(this.totalApprovedAmount <= this.reportSummary?.availableBalance){
            this.appliedAdvance = this.totalApprovedAmount;
        }
        if(!this.getReportModal?.isLinkedToAdvancePayment){
            this.totalReimbursable = this.expenseList?.reduce((sum, item) => sum + parseFloat(item?.unformattedOriginalApproveAmount != '' ? item?.unformattedOriginalApproveAmount : 0), 0);
            this.totalApprovedAmount = 0;
            this.totalApprovedAmount = this.expenseList?.reduce((sum, item) => sum + parseFloat(item?.unformattedOriginalApproveAmount != '' ? item?.unformattedOriginalApproveAmount : 0), 0);
            this.appliedAdvance = 0;
        }
        this.totalReimbursable = this.totalApprovedAmount - this.appliedAdvance;
        if(this.totalReimbursable < 0 ) {
            this.totalReimbursable = 0;
        }
        // this.totalReimbursable = this.expenseList.reduce((sum, item) => sum + item?.unformattedOriginalRejectedAmount != '' ? item?.unformattedOriginalRejectedAmount : 0, 0);
    }

    loadRepushModify = false;
    rePushModify(RepushHistory: TemplateRef<any>) {
        this.loadRepushModify = true;
        this.expenseService.getIntegrationHistory({ claimId: this.reportSummary?.claimId, claimGUID: this.reportSummary?.claimGUID }).subscribe((res) => {
            if (res.errorCode == 0) {
                this.integrationHistory = [];
                if (res.payload.integrationHistory.length != 0) {
                    res.payload.integrationHistory.forEach(element => {
                        element.repushSpinner = false;
                    });
                    this.showRepushButton = res?.payload?.showRepushButton || false;
                }
                this.repushloading = false;
                this.loadRepushModify = false;
                this.integrationHistory = [...res.payload.integrationHistory];
            }
        })
    }


    isModifyRepushClicked = false;
    modifyRepush(approvePopup: TemplateRef<any>) {
        this.redirectToList = false;
        // this.isModifyRepushClicked = true;
        // const config = {
        //     ignoreBackdropClick: false,
        //     class: this.reportSummary.recordType == 2 ? "modal-lg modal-dialog-centered" : "modal-xl modal-dialog-centered",
        //     keyboard: false,
        // };
        // this.modalRef = this.modalService.show(approvePopup, config);

        this.totalApproveAmountCal()
        if (this.reportSummary?.isRepushAndApprove) {
            this.isModifyRepushClicked = true;
            const config = {
                ignoreBackdropClick: false,
                class: this.reportSummary.recordType == 2 ? "modal-lg modal-dialog-centered" : "modal-xl modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(approvePopup, config);
        } else {
            this.loadRepushModify = true;
            const obj = {
                claimId: this.reportSummary?.claimId,
                claimGUID: this.reportSummary?.claimGUID,
                legalEntityGuId: this.userInfo.legalEntityGuId,
                legalEntityId: this.userInfo.legalEntityId
            }
            this.expenseService.modifyRepush(obj).subscribe(res => {
                this.loadRepushModify = false;
                this.getReportSummary();
                const config = {
                    ignoreBackdropClick: false,
                    class: this.reportSummary.recordType == 2 ? "modal-lg modal-dialog-centered" : "modal-xl modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(approvePopup, config);
            }, err => {
                this.toastr.error(err, this.translate.instant('alerts.error'));
            })
        }

        // this.loadRepushModify = true;
        // const obj = {
        //     claimId: this.reportSummary?.claimId,
        //     claimGUID: this.reportSummary?.claimGUID,
        //     legalEntityGuId: this.userInfo.legalEntityGuId,
        //     legalEntityId: this.userInfo.legalEntityId
        // }
        // this.expenseService.modifyRepush(obj).subscribe(res => {
        //     this.loadRepushModify = false;
        //     this.getReportSummary();
        //     const config = {
        //         ignoreBackdropClick: false,
        //         class: this.reportSummary.recordType == 2 ? "modal-lg modal-dialog-centered" : "modal-xl modal-dialog-centered",
        //         keyboard: false,
        //     };
        //     this.modalRef = this.modalService.show(approvePopup, config);
        // }, err => {
        //     this.toastr.error(err, this.translate.instant('alerts.error'));
        // })
    }

    getReimbursedAmount(a,b) {
        return parseFloat((a || 0).toString().replace(/,/g, '')) - parseFloat((b || 0).toString().replace(/,/g, ''));
    }

    reimbursedAmountCalculation(){

        if(this.reportSummary.isLinktoAdvace){
            if(this.reportSummary?.unformattedApprovedAmount > this.globalAvailableAdv){
                return this.reportSummary?.unformattedApprovedAmount - this.globalAvailableAdv;
            } else if(this.globalAvailableAdv > this.reportSummary?.unformattedApprovedAmount){
                return 0;
            }

        }   else{
            // console.log('link to advance false');
            this.recordRptPymntModel.isLinkedToAdvancePayment = this.recordRptPymntModel.isLinkedToAdvancePayment || false;
            return this.reportSummary.employeePaidApproved;
        }
    }

    appliedAdavnceAmountCalculation(){
        if(this.reportSummary.isLinktoAdvace){
            if(this.reportSummary?.unformattedApprovedAmount > this.apiAvailableAdv){
                return this.apiAvailableAdv;
            } else if(this.apiAvailableAdv > this.reportSummary?.unformattedApprovedAmount){
                return this.reportSummary?.unformattedApprovedAmount;
            }

        }else{
            return 0;
        }
    }

    confirmWithdraw(withdrawlConfirmation: TemplateRef<any>, claim?) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-md baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(withdrawlConfirmation, config);
    }

    withdraw() {
        const obj = {
            claimId: this.reportSummary.claimId,
            claimGUID: this.reportSummary.claimGUID,
            isWithdraw: true
        };
        this.expenseService.getSendBackRequest(obj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.modalRef?.hide();
                this.reportBtns = true;
                this.getReportSummary();
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                this.modalRef?.hide();
            }
        });
    }

    confirmStatus() {
        this.confirmshowloader = true;
        const obj = {
            userId: this.userInfo.userId,
            claimId: this.reportSummary.claimId,
            claimGUID: this.reportSummary.claimGUID,
            statusDesc: this.selectedStatus,
            comment:this.statusReason
        }
        this.expenseService.saveStatusRequest(obj).subscribe(res => {
            this.statusReason = '';
            if (!res.isError && res.errorCode === 0) {
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.modalRef?.hide();
                this.confirmshowloader = false;
                // this.reportBtns = true;
                this.getReportSummary();
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                this.modalRef?.hide();
            }
        });
    //     this.showdropdown = false;
    //    this.reportSummary.statusDescription = this.selectedStatus;
    //    this.selectedStatus='';
    //    this.modalRef?.hide();
    }

    statusDropdown(statusdes) {
        // console.log(statusdes)
        this.selectedStatus = statusdes;
        this.showeditoption = false;
        this.showdropdown = true;
        this.isDropdownOpen = true;
        // console.log(this.isDropdownOpen)
    }

    DropdownSelectedValue(statusConfirmation, i?) {
        //  console.log(this.selectedStatus)
        this.showdropdown = false;
        if(this.selectedStatus !=null && this.selectedStatus !='' ){
            this.StatusConfirmation(statusConfirmation);
        }
    }

    StatusConfirmation(statusConfirmation: TemplateRef<any>,) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-md baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(statusConfirmation, config);
    }

    hideStatusDropdown(){
        if(!this.substatusmouseover){
         this.showdropdown = false;
        }
        
    }


    searchMerchants(ev, item) {
        this.getMerchantlist(item, ev.term);
    }
    
    getMerchantlist(item, searchText?) {
        console.log(item);
        if (item?.categoryId) {
            let obj = {
                searchText: searchText || '',
                categoryId: item?.categoryId,
                legalEntityId: this.userInfo?.legalEntityId,
                legalEntityGUID: this.userInfo?.legalEntityGuId
            }
            this.merchantsList = [];
            this.commonService.getMerchantlistByCategory(obj).subscribe((res) => {
                this.merchantsList = res?.payload?.merchantList || [];
                console.log(this.merchantsList);
            })
        }
    }

    showStayType() {
        const index = this.reportSummary?.claimTaggedTripList?.findIndex(t => t.productId == 2 && t.tripTravelType);
        return index >= 0;
    }

    getTravelType(index) {
        let stayType;
        if(index >= 0 && this.reportSummary?.claimTaggedTripList[index].productId == 2) {
            const tripTravelType = this.reportSummary?.claimTaggedTripList[index]?.tripTravelType;
            switch (tripTravelType) {
                case 1:
                    stayType = 'Hotel (Company Booked)';
                    break;
                case 2:
                    stayType = 'Hotel (Self Booked)';
                    break;
                case 3:
                    stayType = 'Stay with Family & Friends';
                    break;
                default:
                    stayType = null;
                    break;
            }
        }

        return stayType;
    }

    historyEmailModal() {
        this.emailHistoryLoading = true;
        this.getEmailHistory();
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-xl baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(this.emailHistoryModal, config);
    }

    emailHistoryList: any = [];
    emailHistoryLoading: boolean = false;
    getEmailHistory() {
        console.log(this.reportSummary);
        let obj = {
            "action": "HISTORY",
            "claimId": this.reportSummary.claimId,
            "claimGuid": this.reportSummary.claimGUID
        }
        this.reportsService.getEmailHistory(obj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.emailHistoryList = res?.payload || [];
                this.emailHistoryList = this.emailHistoryList?.map(item => ({
                    ...item,
                    recepient_EmailsList: item?.recepient_Emails?.split(',')
                }));
                this.emailHistoryLoading = false;
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
            this.emailHistoryLoading = false;
        }, err => {
            this.emailHistoryLoading = false;
            this.toastr.error(err, this.translate.instant('alerts.error'));
        })
    }

    resendEmailObj: any;
    resendEmailModal(emailHistory) {
        this.modalRef?.hide();
        this.resendEmailObj = emailHistory;
        this.resendEmailObj.additionalEmails = null;
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalEmailResendRef = this.modalService.show(this.emailResendModal, config);
    }

    resendEmailLoader: boolean = false;
    resendEmail() {
        this.resendEmailLoader = true;
        let obj = {
            "action": "RESEND",
            "emailId": this.resendEmailObj.email_Id,
            "emailGuid": this.resendEmailObj.email_Guid,
            "additionalEmails": this.resendEmailObj.additionalEmails
        }
        this.reportsService.getEmailHistory(obj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.modalEmailResendRef?.hide();
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
            this.resendEmailLoader = false;
        }, err => {
            this.resendEmailLoader = false;
            this.toastr.error(err, this.translate.instant('alerts.error'));
        })
    }

    linkedToAdvanceChange(isLinked) {
        this.reportSummary.isLinkedToAdvancePayment = isLinked;
        this.getAdvanceLinkSummary();
    }

    advanceLinkSummary: any;
    advanceRefList: any = [];
    advanceRefCode: any;
    getAdvanceLinkSummary(isRecordPayment?) {
        let expenseList = [];
        this.expenseList?.forEach(item => {
            expenseList.push({
                "expenseId": item.expenseId,
                "amount": item.unformattedOriginalApproveAmount,
                "originalCurrencyId": item.originalCurrencyId,
            })
        });

        let obj = {
            "claimId": this.reportSummary.claimId,
            "claimGUID": this.reportSummary.claimGUID,
            "nonTravelAdvanceRefId": this.reportSummary?.nonTravelAdvanceRefId || null,
            "approvedAmount": isRecordPayment ? this.reportSummary.unformattedApprovedAmount : this.totalApprovedAmount,
            "isLinkedToAdvance": this.reportSummary.isLinkedToAdvancePayment,
            "expenseList": expenseList
        }

        this.reportsService.getAdvanceLinkSummary(obj).subscribe((res) => {
            if(!res?.isError && res?.payload) {
                this.advanceLinkSummary = res.payload;
                this.advanceRefList = res?.payload?.advances || [];
                if(res?.payload?.defaultAdavnceId && res?.payload?.defaultAdavnceCode) {
                    this.advanceRefCode = res?.payload?.defaultAdavnceCode;
                    this.reportSummary.nonTravelAdvanceRefId = res?.payload?.defaultAdavnceId;
                }
            }
        });
    }

    advanceRefChange(event: any) {
        this.advanceRefCode = event;
        this.reportSummary.nonTravelAdvanceRefId = event.advanceId;
        this.getAdvanceLinkSummary();
    }
    zoom = 1.0;
    zoomIn() {
        this.zoom += 0.25;
    }

    zoomOut() {
        if (this.zoom > 0.25) {
            this.zoom -= 0.25;
        }
    }

    getCustomFieldsInfo(leID, leGUID) {
        const requestModel = {
            "legalEntityGUID": leGUID,
            "legalEntityId": leID,
            "companyId": this.userInfo.companyId,
            "companyGUID": this.userInfo.companyGuId,
        }
        this.commonService.getFieldsInfo(requestModel).subscribe((response) => {
            response.payload.isLoggedInUserEntity = false;
            sessionStorage.setItem('customFieldsInfo', JSON.stringify(response.payload));
        })
    }

    selectedSegment: string = 'standard';
    private readonly segments = ['standard', 'hybrid', 'sat'];
    positions: { [key: string]: 'left' | 'right' | null } = {
        standard: null,
        hybrid: 'right',
        sat: 'right'
    };

    segmentChanged3(event: any) {
        const newSegment = event.detail.value;
        // const oldIndex = this.segments.indexOf(this.selectedSegment);
        const newIndex = this.segments.indexOf(newSegment);

        // Update positions based on the direction of movement
        this.segments.forEach(segment => {
            const segmentIndex = this.segments.indexOf(segment);
            if (segment === newSegment) {
                this.positions[segment] = null;
            } else if (segmentIndex < newIndex) {
                this.positions[segment] = 'left';
            } else {
                this.positions[segment] = 'right';
            }
        });


        // Add auto-scrolling logic
        const selectedButton = document.querySelector(`ion-segment-button[value="${newSegment}"]`) as HTMLElement;
        if (selectedButton) {
            const segmentContainer = selectedButton.closest('ion-segment');
            if (segmentContainer) {
                const containerWidth = segmentContainer.clientWidth;
                const buttonLeft = selectedButton.offsetLeft;
                const buttonWidth = selectedButton.offsetWidth;

                segmentContainer.scrollTo({
                    left: buttonLeft - (containerWidth / 2) + (buttonWidth / 2),
                    behavior: 'smooth'
                });
            }
        }


        this.zoom = 1.0;
        this.selectedSegment = newSegment;
    }


}
/*partial approvial model */
/*this is only for mobile*/
@Component({
    selector: 'reject-modal',
    template: `
        <ion-content class="add-expense">
            <ion-header class="ion-no-border">
                <ion-toolbar>
                    <ion-buttons slot="start" (click)="returnToDetails()">
                        <img src="assets/svg/back.svg"/>
                    </ion-buttons>
                    <ion-title class="main-heading" *ngIf="!rejectTitle">{{'approvals.approvedpartial' |  translate}}</ion-title>
                    <ion-title class="main-heading" *ngIf="rejectTitle">{{'approvals.rejectall' |  translate}}</ion-title>dd
                </ion-toolbar>
            </ion-header>
            <ion-grid >
                <ion-row>
                    <ion-col>
                        <h6>
                            {{'approvals.rejected' |  translate}}
                        </h6>
                    </ion-col>
                </ion-row>
                <ion-row  size="12" *ngFor="let list of rejectExparray; let i = index">
                    <ion-card   style="width: 100%;" class="p-2" *ngIf="list.status != 4">
                        <h6 class="exp-value font-weight-bold pull-right">
                        <span *ngIf="list?.originalCurrencyIcon; else code">
                            <i class="fa {{ list?.originalCurrencyIcon }}"></i>
                        </span>
                            <ng-template #code>{{ list?.originalCurrencyCode }}</ng-template>
                            {{ list?.originalAmount ? list.originalAmount : 0 }}
                        </h6>
                        <ion-text class="exp-name pl-3">
                            {{ list?.categoryName }} {{ list?.description ? '-' : ''}} {{ list?.description }}
                        </ion-text>
                        <ion-card-content class="exp-text">
                            <ion-text class="d-block sub-text">
                                {{'expenses.createdon' | translate}}
                                <span class="exp-font">{{ list?.createdDate }}</span>  
                                <span style="margin-right: -5rem;" class="exp-value pull-right">{{list?.expenseCode}} </span>
                            </ion-text>
                        </ion-card-content>
                        <ion-row>
                            <form [formGroup]="reasonForm" (ngSubmit)="partialSubmit()">
                                <div class="col-md-12" formArrayName="rejectedExpenseList">
                                    <div [formGroupName]="i">
                                        <span *ngIf="reportSummary.recordType == 1;">
                                            <input id="expenseId" formControlName="expenseId" type="hidden"
                                                   [(ngModel)]="list.expenseId">
                                        </span>
                                        <span *ngIf="reportSummary.recordType == 3">
                                            <input id="expenseId" formControlName="expenseId" type="hidden"
                                                   [(ngModel)]="list.localConveyanceId">
                                        </span>
                                        <ion-label position="floating">{{'approvals.comment' | translate}}</ion-label>
                                        <ion-textarea
                                                rows="3"
                                                color="dark"
                                                cols="60"
                                                formControlName="reason"
                                        ></ion-textarea>
                                    </div>
                                </div>
                        </form>
                    </ion-row>
                </ion-card>
                </ion-row>
            </ion-grid>
        </ion-content>
        <ion-footer>
            <ion-button class="btn-ne-secondary-web pl-3" *ngIf="userInfo.roleId !== 3"
             [disabled]="partialBtnLoad" (click)="partialSubmit()">{{'approvals.done' | translate}}
                <span class="spinner-border spinner-border-sm pl-2" role="status" aria-hidden="true"
                    *ngIf="partialBtnLoad">
                </span>
            </ion-button>
            <ion-button class="btn-ne-secondary-web pl-3" *ngIf="userInfo.roleId == 3"
                    [disabled]="partialBtnLoad" (click)="financeprartialSubmit()">{{'approvals.done' |translate}}
                 <span class="spinner-border spinner-border-sm pl-2" role="status" aria-hidden="true"
                    *ngIf="partialBtnLoad">
                 </span>
        </ion-button>
        </ion-footer>
    `,
})

export class RejectModalComponent implements OnInit {
    @Input() rejectExparray;
    @Input() claimGUID;
    @Input() editreport;
    @Input() claimId;
    @Input() reportSummary;
    @Input() rejectBoolean;
    @Input() rejectTitle;
    @Input() expenseWithCodesList;
    @Input() isAdmin;
    @Input() partialBtnLoad;
    @Input() categoryBaseExpenseList;
    reasonForm: FormGroup;
    rejectedModel = new RejectedModel();
    userInfo: any;
    returnData = false;
    getReportModal = new GetReportModal();

    constructor(
        public platformService: PlatformService,
        public modalController: ModalController,
        public router: Router,
        public expenseService: ExpensesService,
        public toastr: ToastrService,
        public reportsService: ReportsService,
        public formBuilder: FormBuilder,
        public sharedService: SharedService,
        private translate: TranslateService
    ) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }


    ngOnInit() {
        this.reasonForm = this.formBuilder.group({
            rejectedExpenseList: this.formBuilder.array([this.createItem()]),
        });
        this.reasonForm.reset();
        this.rejectControls.removeAt(0);
        this.rejectExparray.forEach(expense => {
            this.rejectControls.push(this.createItem(expense));
        });
    }

    createItem(exp?) {
        if (exp) {
            return this.formBuilder.group({
                expenseId: [exp?.expenseId],
                reason: [""],
            });
        }
    }
    get rejectControls() {
        return this.reasonForm.get("rejectedExpenseList") as FormArray;
    }

    partialSubmit() {
        this.getReportModal = new GetReportModal();
        let rejectaaray = [];
        this.reasonForm.controls.rejectedExpenseList.value.forEach(element => {
            let dict = this.setRejectedComments(element);
            rejectaaray.push(dict);
        });
        this.getReportModal.rejectedExpenseList = rejectaaray;
        this.getReportModal.claimId = this.claimId;
        this.getReportModal.claimGUID = this.claimGUID;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        if (this.userInfo.roleId == 3 || this.userInfo.roleId == 4) {
            this.reportsService.FinanceApprove(this.getReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.returnData = true;
                    this.expenseWithCodesList = [];
                    this.returnToDetails();
                    this.sharedService.setRefreshData(true);

                } else if (res.isError && res.errorCode === 2) {
                    this.returnData = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
        } else if (!this.rejectBoolean) {
            if (this.userInfo.isCategoryBasedEnabled) {
                rejectaaray.forEach(element => {
                    element.isApproved = false;
                });
                this.categoryBaseExpenseList.forEach(element => {
                    rejectaaray.forEach(innerelement => {
                        if (element.expenseId != innerelement.expenseId) {
                            let req = {
                                expenseId: element.expenseId,
                                isApproved: true
                            }
                            rejectaaray.push(req);
                        }
                    });
                });
            }
            this.partialBtnLoad = true;
            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
            } else {
                this.getReportModal.rejectedExpenseList = rejectaaray;
            }
            this.reportsService.Approve(this.getReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.returnData = true;
                    this.returnToDetails();
                    this.expenseWithCodesList = [];
                    this.sharedService.setRefreshData(true);
                    this.partialBtnLoad = false;

                } else if (res.isError && res.errorCode === 2) {
                    this.returnData = false;
                    this.expenseWithCodesList = [];
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    this.partialBtnLoad = false;
                }
            });
        } else {
            this.partialBtnLoad = true;
            if (this.userInfo.isCategoryBasedEnabled) {
                rejectaaray.forEach(element => {
                    element.isApproved = false;
                });
            }
            if (this.userInfo.isCategoryBasedEnabled) {
                this.getReportModal.expenseList = rejectaaray;
            } else {
                this.getReportModal.rejectedExpenseList = rejectaaray;
            }
            this.reportsService.rejectAll(this.getReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.returnData = true;
                    this.returnToDetails();
                    this.expenseWithCodesList = [];
                    this.sharedService.setRefreshData(true);
                    this.partialBtnLoad = false;
                } else if (res.isError && res.errorCode === 2) {
                    this.returnData = false;
                    this.expenseWithCodesList = [];
                    this.partialBtnLoad = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
        }
    }
    financeprartialSubmit() {
        let rejectaaray = [];
        this.reasonForm.controls.rejectedExpenseList.value.forEach(element => {
            let dict = this.setRejectedComments(element);
            rejectaaray.push(dict);
        });
        this.getReportModal.rejectedExpenseList = rejectaaray;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.recordType = this.editreport.recordType;
        this.getReportModal.claimId = this.claimId;
        this.getReportModal.claimGUID = this.claimGUID;
        this.partialBtnLoad = true;
        if (this.isAdmin && this.reportSummary.status == 2) {
            if (!this.rejectBoolean) {
                this.reportsService.Approve(this.getReportModal).subscribe((res) => {
                    if (!res.isError && res.errorCode === 0) {
                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                        this.returnData = true;
                        this.returnToDetails();
                        this.expenseWithCodesList = [];
                        this.sharedService.setRefreshData(true);
                        this.partialBtnLoad = false;

                    } else if (res.isError && res.errorCode === 2) {
                        this.returnData = false;
                        this.expenseWithCodesList = [];
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                        this.partialBtnLoad = false;
                    }
                });
            } else {
                this.reportsService
                    .rejectAll(this.getReportModal).subscribe((res) => {
                        if (!res.isError && res.errorCode === 0) {
                            this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                            this.returnData = true;
                            this.returnToDetails();
                            this.expenseWithCodesList = [];
                            this.sharedService.setRefreshData(true);
                            this.partialBtnLoad = false;

                        } else if (res.isError && res.errorCode === 2) {
                            this.returnData = false;
                            this.expenseWithCodesList = [];
                            this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                            this.partialBtnLoad = false;
                        }
                    });
            }
        } else {
            this.getReportModal.expenseWithCodesList = this.expenseWithCodesList;
            this.reportsService.FinanceApprove(this.getReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.returnData = true;
                    this.returnToDetails();
                    this.expenseWithCodesList = [];
                    this.sharedService.setRefreshData(true);
                    this.partialBtnLoad = false;
                } else if (res.isError && res.errorCode === 2) {
                    this.returnData = false;
                    this.expenseWithCodesList = [];
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    this.partialBtnLoad = false;
                }
            });
        }
    }
    setRejectedComments(ele: any) {
        var dict = {};
        if (ele.expenseId) {
            dict = { expenseId: ele.expenseId, reason: ele?.reason };
            return dict;
        }
        return dict;
    }
    returnToDetails() {
        // this.modalController.dismiss({
        //     dismissed: true,
        //     data: this.returnData
        // });
        this.modalController.dismiss({ dismissed: true })
    }

    
}


@Component({
    selector: 'reject-modal',
    template: `
    <ion-content class="add-expense">
    <ion-header class="modal-header border-bottom-0">

        <h4 class="modal-title"><b>Reject All</b>
        </h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="returnToDetails()">
            <span aria-hidden="true">&times;</span>
        </button>
    </ion-header>
    <ion-grid>
        <ion-row>
            <ion-col>
                     <h6>
                        <h5>There are items in the report which you have rejected. Kindly share your reason for rejecting them.</h5>
                     </h6>
             </ion-col>
        </ion-row> 
        <ion-row  size="12" *ngFor="let list of expenseList; let i = index">
            <ion-card   style="width: 100%;" class="p-2" *ngIf="list.status != 4">
            <h6 class="exp-value font-weight-bold pull-right">
            <span *ngIf="list?.originalCurrencyIcon; else code">
                <i class="fa {{ list?.originalCurrencyIcon }}"></i>
            </span>
                <ng-template #code>{{ list?.originalCurrencyCode }}</ng-template>
                {{ list?.originalAmount ? list.originalAmount : 0 }}
            </h6>
            <ion-text class="exp-name pl-3">
                {{ list?.categoryName }} {{ list?.description ? '-' : ''}} {{ list?.description }}
            </ion-text>
            <ion-card-content class="exp-text">
                <ion-text class="d-block sub-text">
                    {{'expenses.createdon' | translate}}
                    <span class="exp-font">{{ list?.createdDate }}</span>  
                    <span style="margin-right: -5rem;" class="exp-value pull-right">{{list?.expenseCode}} </span>
                </ion-text>
            </ion-card-content>
            <ion-row>
                <form [formGroup]="rejectreasonForm">
                    <div class="col-md-12" formArrayName="rejectedExpenseList">
                        <div [formGroupName]="i">
                            <span *ngIf="list.recordType == 1;">
                                <input id="expenseId" formControlName="expenseId" type="hidden"
                                   [(ngModel)]="list.expenseId">
                            </span>
                            <span *ngIf="list.recordType == 3">
                                <input id="expenseId" formControlName="expenseId" type="hidden"
                                   [(ngModel)]="list.localConveyanceId">
                            </span>
                            <ion-label position="floating">{{'approvals.comment' | translate}}</ion-label>
                            <ion-textarea
                                rows="3"
                                color="dark"
                                cols="60"
                                formControlName="reason"
                            >   </ion-textarea>
                        </div>
                    </div>
                </form>
            </ion-row>
        </ion-card>
        </ion-row>
    </ion-grid>
    </ion-content>
    <ion-footer>
        <ion-button class="btn-ne-secondary-web pl-3" [disabled]="partialBtnLoad"
          (click)="rejectAllFinance()">{{'approvals.done' |
             translate}}
            <span class="spinner-border spinner-border-sm pl-2" role="status" aria-hidden="true"
            *ngIf="partialBtnLoad"></span>
        </ion-button>
    <ion-footer>
        `,
})
export class FinanceModalPage implements OnInit {

    @Input() expenseList;
    expensedata = [];
    rejectreasonForm: FormGroup;
    getReportModal = new GetReportModal();
    userInfo: any;
    @Input() reportSummary;
    @Input() rejectBoolean;
    @Input() rejectTitle;
    @Input() expenseWithCodesList;
    @Input() partialBtnLoad;
    @Input() claimId;
    @Input() claimGUID;
    returnData = false;
    constructor(
        private modalController: ModalController,
        public formBuilder: FormBuilder,
        public router: Router,
        public expenseService: ExpensesService,
        public toastr: ToastrService,
        public reportsService: ReportsService,
        public sharedService: SharedService,
        public translate: TranslateService
    ) { this.userInfo = JSON.parse(sessionStorage.getItem("userInfo")); }

    ngOnInit() {
        this.expenseList.forEach((element: any) => {
            this.expensedata.push(element);
        });
        this.rejectreasonForm = this.formBuilder.group({
            rejectedExpenseList: this.formBuilder.array([this.createItem()]),
        });
        this.expenseList.forEach(expense => {
            this.rejectControls.push(this.createItem(expense));
        });
    }
    createItem(exp?) {
        if (exp) {
            return this.formBuilder.group({
                expenseId: [exp?.expenseId],
                reason: [""],
            });
        } else {
            return this.formBuilder.group({
                expenseId: [exp?.expenseId],
                reason: [""],
            });
        }
    }
    get rejectControls() {
        return this.rejectreasonForm.get("rejectedExpenseList") as FormArray;
    }
    setRejectedComments(ele: any) {
        var dict = {};
        if (ele.expenseId) {
            dict = { expenseId: ele.expenseId, reason: ele?.reason };
            return dict;
        }
        return dict;
    }


    rejectAllFinance() {

        let rejectaaray = [];
        this.expenseList.forEach(element => {
            let dict = this.setRejectedComments(element);
            rejectaaray.push(dict);
        });

        this.getReportModal.rejectedExpenseList = rejectaaray;
        this.partialBtnLoad = true;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.getReportModal.claimId = this.claimId;
        this.getReportModal.claimGUID = this.claimGUID;
        this.reportsService
            .FinanceReject(this.getReportModal)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                    this.returnData = true;
                    this.returnToDetails();
                    this.sharedService.setRefreshData(true);
                    this.partialBtnLoad = false;
                } else if (res.isError && res.errorCode === 2) {
                    this.returnData = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    this.partialBtnLoad = false;
                }
            });
    }
    returnToDetails() {
        this.modalController.dismiss({ dismissed: true })
    }
}

/*
   approveExpence(list, i) {

        list.checkImage = !list.checkImage
        this.expenseList[i].checkImage = list.checkImage;
        this.expenseList[i].crossImage = !list.checkImage;
        if (this.expenseList[i].checkImage) {
            this.expenseList[i].checkimg = 'right-green';
            this.expenseList[i].crossimg = 'close-grey'
        } else {
            this.expenseList[i].checkimg = 'right-grey';
            this.expenseList[i].crossimg = 'close-red'
        }
        this.expenseList.forEach((item) => {
            if (item.checkImage) {
                item.checkimg = 'right-green';
                item.crossimg = 'close-grey';
                this.selectedExpense.push(item.expenseId);
                this.selectedExpense = _.uniq(this.selectedExpense);

            } else {
                item.checkimg = 'right-grey';
                item.crossimg = 'close-red';
                const index = _.indexOf(this.selectedExpense, item.expenseId);
                if(index != -1)this.selectedExpense.splice(index, 1);
            }
        });
        this.addReportExpense.expenseIds = this.selectedExpense;

    }
    rejectExpence(list, i) {

        list.crossImage = !list.crossImage
        this.expenseList[i].crossImage = list.crossImage
        this.expenseList[i].checkImage = !list.crossImage
        if (this.expenseList[i].crossImage) {
            this.expenseList[i].crossimg = 'close-red';
            this.expenseList[i].checkimg = 'right-grey';
        } else {
            this.expenseList[i].crossimg = 'close-grey';
            this.expenseList[i].checkimg = 'right-green';
        }
        this.expenseList.forEach((item) => {
            if (item.crossImage) {
                item.crossimg = 'close-red';
                item.checkimg = 'right-grey';
                const index = _.indexOf(this.selectedExpense, item.expenseId);
               if(index != -1)this.selectedExpense.splice(index, 1);

            } else {
                item.checkimg = 'right-green';
                item.crossimg = 'close-grey';

                this.selectedExpense.push(item.expenseId);
                this.selectedExpense = _.uniq(this.selectedExpense);
            }
        });
        this.addReportExpense.expenseIds = this.selectedExpense;
    }
*/
