import { Component, OnDestroy, OnInit, TemplateRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ModalController, MenuController, NavParams, NavController, AngularDelegate, AlertController, Platform, ActionSheetController } from '@ionic/angular';
import { ExpenseAndMileageComponent } from './expenseandmileage/expenseandmileage.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavigationExtras, Router } from '@angular/router';
// import { Storage } from '@ionic/storage';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateconfigService } from 'src/app/services/translateconfig.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DashboardModel, GetDashboardData } from 'src/app/models/Dashboard/dashboard.model';
import { SharedService } from 'src/app/services/common-services/shared.service';
import {
    ExpenseAttachments,
    ExpenseAttachmentsModel,
    ExpenseModel,
    ocrAttachment,
    ocrReqModel
} from 'src/app/models/Expenses/expenses.model';
import { ExpensesService } from 'src/app/services/expenses.service';
import { ChartOptions } from 'chart.js';
import { CurrencyPipe } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CategoryAndMerchantModel, CategoryList, CurrencyList, FilterModal } from 'src/app/models/Common/common.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { SettingsService } from 'src/app/services/settings-services';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { CanvasJS } from 'src/assets/canvasjs/canvasjs.angular.component';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import * as Chart from 'chart.js';
import { request } from 'http';
import { ReportsService } from 'src/app/services/reports.service';
import { CompanyService } from 'src/app/services/company.service';

interface Food {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [ThemeService, TranslateconfigService, NgbCarouselConfig]
})

export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('fileUpload', { static: false }) fileUploadInput: ElementRef;
    @ViewChild("outstandingAdvance", { static: false }) private outstandingAdvance;
    outstandingAdvanceMsg: any;
    platformType: number;
    modalRef: BsModalRef;
    dashboardMore = false;
    dashboardDetails = false;
    themeName: any;
    userDashboardDetail: any;
    userInfo: any;
    langName: any;
    showLoading = true;
    fromPage: string;
    dashboardModel = new DashboardModel();
    /*img uploader */
    isUserToggle: any;
    imgContent: string = "";
    expenseAttachmentsModel = new ExpenseAttachmentsModel();
    expenseModel = new ExpenseModel();
    ocrReqModel = new ocrReqModel();
    ocrAttachment = new ocrAttachment();
    imageLoaded: boolean = false;
    imgUpload = false;
    fileName: any;
    hideNewExpBtn = true;
    loaded: boolean = false;
    proofid: any;
    imgNum = 1;
    imageIndex: number;
    capturedImage: any;
    uploadedfiles = [];
    /*img uploader */

    dashboardRes: any;
    dashboardRes_cat: any
    categoryChartData = [];
    categoryLabels = [];
    public CategoryLegend = false;
    public _backgroundColors = [{ backgroundColor: ["#ed8f8f", "#c991e3", "#82e7d4", "#ff7f0e", "#2ca02c", "#6495ED", "#008080", "#7a771b", "#CCCCFF", "#9FE2BF", "#DAF7A6"] }];
    isTravelEnabled: boolean = false;
    totalExpenseReportedAmount = 0;
    CategoryMerchantModel = new CategoryAndMerchantModel();
    categoryAndMerchantModel = new CategoryAndMerchantModel();
    currencyList: any;
    commaSeperatorArray: any;
    totalDistanceVal: any = 0;
    sessionId: any = 0;
    checkUserPreferance: boolean = false;
    isfc365: boolean = false;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    banners = [];
    filterModal = new FilterModal();
    showcalender: boolean = false;
    start_date: any;
    end_date: any;
    max_date: any;
    pieChartOptions: any;
    retakePicture = false;

    // @ViewChild(BaseChartDirective) public chart: BaseChartDirective;


    constructor(
        private title: Title,
        private navContrl: NavController,
        private router: Router,
        private camera: Camera,
        private platformService: PlatformService,
        private dashboardService: DashboardService,
        public modalController: ModalController,
        private modalService: BsModalService,
        private translateconfigService: TranslateconfigService,
        public menuCtrl: MenuController,
        private datechangeService: DatechangeService,
        // public storage: Storage,
        private toastr: ToastrService,
        private themeService: ThemeService,
        public sharedService: SharedService,
        private navParams: NavParams,
        private expenseService: ExpensesService,
        private commonService: CommonService,
        private authenticationService: AuthenticationService,
        private geolocation: Geolocation,
        private diagostic: Diagnostic,
        public alertController: AlertController,
        private zone: NgZone,
        private settingService: SettingsService,
        private reportsService: ReportsService,
        private refreshToken: AuthenticationService,
        private platform: Platform,
        config: NgbCarouselConfig,
        public actionSheetController: ActionSheetController,
        private companyService: CompanyService
    ) {
        this.isfc365 = JSON.parse(localStorage.getItem('fc365'));

        this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
        });
    }

    ngOnInit() {
        sessionStorage.removeItem('tripData');
        this.authenticationService.setShowHeaderEV(true);
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        if (this.userInfo && (this.platform.is('android') || this.platform.is('ios'))) {
            this.tokenRefresh(this.userInfo);
        }
        this.checkRestriction();
        this.getCustomFieldsInfo();
        this.getCostCenters();
        this.getApprovalStats();
        this.getCompanyData();
        this.getForeignExchnage();

        const date = new Date();
        date.setDate(date.getDate() - 30);
        // to disply date 
        this.filterModal.fromDate = moment(date).format('YYYY-MM-DD');
        this.filterModal.toDate = moment().endOf('day').format('YYYY-MM-DD');

        this.start_date = moment(date).format(Constants.mobileCalenderFormat);
        this.end_date = moment().endOf('day').format(Constants.mobileCalenderFormat);
        this.max_date = moment(new Date()).format("YYYY-MM-DD");



        this.platformType = this.platformService.getPlatformType();
        if (this.platformType == 1) {
            if (this.userInfo.attachmentFormat != null || this.userInfo.attachmentFormat != '') {
                this.commaSeperatorArray = this.userInfo.attachmentFormat.split(',');
            }
        }
        // USER preference recall 
        let user_preference = JSON.parse(localStorage.getItem('user-preference'));
        if (user_preference) {
            if (user_preference?.mileageCategoryId) {
                this.checkUserPreferance = true;
            } else {
                this.checkUserPreferance = false;
            }
        } else {
            this.getUserPreferance();
        }

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewExpBtn = false;
        }

        this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
            this.showLoading = true;

            // to refresh selected dates
            this.filterModal.fromDate = moment(date).format('MMM-DD-YYYY');
            this.filterModal.toDate = moment().endOf('day').format('MMM-DD-YYYY');

            this.start_date = moment(date).format(Constants.mobileCalenderFormat);
            this.end_date = moment().endOf('day').format(Constants.mobileCalenderFormat);
            this.max_date = moment(new Date()).format("YYYY-MM-DD");
            this.getDashboard();
            // if(JSON.parse(sessionStorage.getItem('m-users')) == null){
            //     this.getMangerUsers();
            // };
            // if (val) {
            //     this.getDashboard();
            // } else {
            //     this.getDashboard();
            // }
        });

        this.authenticationService.setisTravelEnabled(this.userInfo.isTravelEnabled);

        this.authenticationService.getisTravelEnabled().subscribe(status => {
            if (status === true) {
                this.isTravelEnabled = true;
            } else if (status === false) {
                this.isTravelEnabled = false
            }
        });
    }

    ionViewWillEnter() {      
        if(sessionStorage.getItem('cameraRetake') && sessionStorage.getItem('cameraRetake') == 'true') {
            this.retakePicture = true;
            this.naviagteTo('camera');
        } else {
            this.retakePicture = false;
        }
        let applicationTitle = this.userInfo.applicationTitle;
        if (applicationTitle) {
            this.title.setTitle('Dashboard ' + ' | ' + applicationTitle);
        } else {
            this.title.setTitle('Dashboard | NoExpenses');
        }

        if (this.userInfo.favIconUrl) {
            this.favIcon.href = this.userInfo.favIconUrl;
        } else {
            this.favIcon.href = './assets/images/Noexpfav.svg';
        }

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewExpBtn = false;
        }
        this.menuCtrl.enable(true, 'mainMenu');
        this.menuCtrl.enable(false, 'settingMenu');
        this.dashboardDetails = true;
        this.showLoading = true;
        this.platformType = this.platformService.getPlatformType();


        // Currency recall functionality using local storage 
        if (!JSON.parse(localStorage.getItem('currencyList'))) {
            this.getCurrencylist();
        }

        // Banners recall functionality using local storage 
        if (JSON.parse(localStorage.getItem('banners'))) {
            this.banners = [...JSON.parse(localStorage.getItem('banners'))];
        } else {
            this.getBanners();
        }
        this.sharedService.getPreCategory().subscribe((value) => {
            if (!value) {
                this.getmastCategorylist();
            }
        })
    }

    getBanners() {
        let object = {
            "legalEntityId": this.userInfo.legalEntityId,
            "legalEntityGUID": this.userInfo.legalEntityGuId
        }

        this.settingService.getBanner(object).subscribe(res => {
            if (res.errorCode == 0 && !res.isError) {
                this.banners = res.payload.banners;
                localStorage.setItem('banners', JSON.stringify(this.banners))
            }
        })
    }
    bannerPage(itemLink) {
        let url = itemLink;
        if (url) window.open(url, '_blank');
    }

    getDashboard() {
        let filterData = { ...this.filterModal };
        filterData.fromDate = moment(this.filterModal.fromDate).format('DD-MM-YYYY');
        filterData.toDate = moment(this.filterModal.toDate).format('DD-MM-YYYY');
        this.dashboardModel.legalEntityId = this.userInfo.legalEntityId;
        this.dashboardModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.dashboardModel.userId = this.userInfo.userId;
        this.dashboardModel.userGUID = this.userInfo.userGuId;
        this.dashboardModel.filterBy = filterData;
        this.dashboardModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            this.dashboardModel.viewType = this.userInfo.roleId;
        } else {
            this.dashboardModel.viewType = 1;
        }
        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewExpBtn = false;
        }

        // this.dashboardService.getDashboard(this.dashboardModel).subscribe(response => {
        //     if (!response.isError && response.errorCode === 0) {
        //         this.dashboardRes = response.payload;
        //         this.userDashboardDetail = new GetDashboardData(response.payload);
        //         this.categoryLabels = [];
        //         this.categoryChartData = [];
        //         this.totalExpenseReportedAmount = this.dashboardRes?.unformattedTotalAmountProcessed + this.dashboardRes?.unformattedTotalAmountReimbursed;

        //         if (this.dashboardRes?.categoryList) {
        //             this.dashboardRes?.categoryList.forEach(element => {
        //                 this.categoryChartData.push(element.unformattedAmount);
        //                 this.categoryLabels.push(element.category);
        //             });
        //             this.categoryoptions = {
        //                 tooltips: {
        //                     callbacks: {
        //                         label: function (tooltipItem, data) {
        //                             let value: any = data.datasets[0].data[tooltipItem.index];
        //                             let currencyPipe = new CurrencyPipe('en');
        //                             let formattedNumber = currencyPipe.transform(value, 'INR', 'symbol');
        //                             value = data.labels[tooltipItem.index] + ' ' + formattedNumber; //value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //                             return value;
        //                         }
        //                     }
        //                 },
        //             };
        //         }
        //     }
        //     this.showLoading = false;
        // });

        this.loadingChart = true;
        this.dashboardService.getDashboardSummary(this.dashboardModel).subscribe(response => {
            if (!response.isError && response.errorCode === 0) {
                this.dashboardRes = response.payload;
                this.dashboardRes_cat = response.payload.categoryList;
                this.userDashboardDetail = new GetDashboardData(response.payload);
                this.categoryLabels = [];
                this.categoryChartData = [];
                let data_points = [];

                if(response.payload.pendingCount != null){
                    this.sharedService.setPeningApprovalCount(response.payload.pendingCount);
                }else{
                    this.sharedService.setPeningApprovalCount(null);
                }
                if (this.dashboardRes?.categoryList) {
                    this.dashboardRes?.categoryList.forEach(element => {
                        let request_object = {
                            y: element.unformattedAmount >= 0 ? element.unformattedAmount : 0,
                            label: element.category
                        }
                        data_points.push(request_object);
                    });

                    if (data_points.length != 0) {
                        // this.renderChart(data_points);
                        // this.openPieChart(data_points);
                        this.openPieChart(data_points);
                    }
                } else {
                    let dataPoints = [{ y: 1, label: "No data" }];
                    // this.renderChart(dataPoints);
                    // this.openPieChart(dataPoints);
                    this.openPieChart(data_points);
                }

            }
            this.showLoading = false;
            this.loadingChart = false;
        }, err => {
            this.loadingChart = false;
        });
    }
    // getMangerUsers(){
    //     let reqeust =  {
    //      "companyId":this.userInfo?.companyId,
    //      "companyGuid":this.userInfo?.companyGuId,
    //      "searchText":"",
    //      "pageNumber":1,
    //      "pageSize":1000,
    //      "sortColumn":"",
    //      "sortType":"asc",
    //      "userId": this.userInfo?.userId,
    //      "userGuid": this.userInfo?.userGuId,
    //      "action": 'emulate-users'
    //     }
    //     console.log(reqeust);
    //     this.commonService.getUserManagerList(reqeust).subscribe( res =>{
    //         console.log('users response :',res);
    //         if(res?.errorCode == 0 && res?.payload?.usersList?.length != 0){
    //             sessionStorage.setItem('m-users',JSON.stringify(res.payload.usersList));
    //         }
    //     })
    // }

    loadingChart = false;
    isZeroChart = false;
    openPieChart(data_points) {
        const currencySymbol = String.fromCharCode(parseInt(this.userInfo?.currencySymbol?.replace('&#', ''), 10));
        let data = [];
        if(data_points?.length == 0) {
            data_points.push({y: 1, label: 'No Data'});
        }
        data_points?.forEach(d => {
            let label = '';
            if(d.label?.length>12){
                label = d.label.slice(0,12) +'...'
            } else {
                label = d.label
            }
            data.push([label, d.y, d.label + ` : ${currencySymbol} ` + d.y]);

        });

        let llr =  data_points?.filter(item => item?.y > 0);
        this.isZeroChart = llr?.length == 0 ? true : false;

        this.pieChartOptions = {
            type: 'PieChart',
            data: data,
            columnNames: ['Browser', 'Percentage', {'type': 'string', 'role': 'tooltip'}],
            webOptions: {
                pieHole: 0.7,
                chartArea: { width: '80%', height: '80%' },
                pieSliceText: 'none',
                legend: {
                    position: 'labeled',
                },
                width:600,
                height:365,
                tooltip: { text: 'percentage' },

            },
            mobileOptions: {
                pieHole: 0.7,
                chartArea: { width: '90%', height: '90%' },
                pieSliceText: 'none',
                legend: {
                    position: 'labeled',
                },
                width:'94%',
                // height:385,
                tooltip: { text: 'percentage' },
            },
        }
        this.loadingChart = false;
    }

    goToNewForm(root: any) {
        this.sharedService.setMapData(null);
        this.fromPage = "dashboard";
        let navInfo = {
            url: 'dashboard'
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.navContrl.navigateRoot([root]);
    }

    async openNewexpmobile(type, fromPettyCash?: boolean) {
        if (fromPettyCash) {
            sessionStorage.setItem('fromPettyCash', String(true));
        }
        this.fromPage = "dashboard";
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: 'my-custom-class',
            componentProps: {
                type,
                fromPage: this.fromPage
            }
        });
        modal.onDidDismiss().then(res => {
            this.getDashboard();
        })
        return await modal.present();
    }


    more() {
        this.dashboardMore = true;
        this.dashboardDetails = false;
    }

    backToDashbord() {
        this.dashboardDetails = true;
        this.dashboardMore = false;
    }

    openNewReport(fromPettyCash?: boolean) {
        const restriction = this.commonService.checkRestriction('nonTravelClaim', 'nonTravelClaimMsg');
        if (restriction?.isRestricted) {
            this.outstandingAdvanceMsg = restriction.msg || '';
            this.showOutstandingAdvanceModal();
            return;
        }
        let navInfo = {
            url: 'dashboard',
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        if (fromPettyCash) {
            sessionStorage.setItem('fromPettyCash', String(true));
        }
        this.navContrl.navigateRoot(['newreport'], { queryParams: { fromPage: 'dashboard' } });
    }

    openNewAdvance() {
        let navInfo = {
            url: 'dashboard',
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.navContrl.navigateRoot(['newAdvance'], { queryParams: { fromPage: 'dashboard' } });
    }

    openNewPettyCash() {
        this.navContrl.navigateRoot(["add-petty-cash"]);
    }


    setTheme(theme, name) {
        this.themeName = name;
        this.themeService.removeTheme(sessionStorage.getItem('current-Theme'));
        this.themeService.setTheme(theme);
    }

    setLang(lang, name) {
        this.langName = name;
        this.translateconfigService.setLanguage(lang);
    }

    naviagteTo(url, fromPettyCash?: boolean) {

        if (fromPettyCash) {
            sessionStorage.setItem('fromPettyCash', String(true));
            this.navContrl.navigateRoot(["/petty-cash/" + url]);
        } else {
            if(this.userDashboardDetail) {
                sessionStorage.setItem('toPathInfo', JSON.stringify(this.userDashboardDetail));
            }
            this.navContrl.navigateRoot(["/" + url]);
        }
    }

    naviagteToPettyCashRequest() {
        sessionStorage.setItem('fromPettyCash', String(true));
        this.navContrl.navigateRoot(["/petty-cash"]);
    }

    ngOnDestroy() {
        this.sharedService.setRefreshData(false);
        this.sharedService.setExpenseData(null);
    }

    ionViewWillLeave() {
        this.sharedService.setRefreshData(false);
        this.sharedService.setExpenseData(null);
    }

    /*img uploader */

    handleDragEnter() {
    }

    handleDragLeave() {
    }

    handleDrop(e) {
        e.preventDefault();
        e.target.files = e.dataTransfer.files;
        this.handleInputChange(e);
    }

    handleImageLoad() {
        this.imageLoaded = true;
    }

    onSlide(item) {
        this.imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
        this.proofid = this.expenseModel.expenseAttachments[parseInt(item.current.replace("slideId_", ""), 10)].attachmentId;
        this.imgNum = this.imageIndex + 1;
    }

    handleInputChange(event) {
        this.imgUpload = true;
        if (!event.target) {
            return;
        }
        if (!event.target.files) {
            return;
        }

        var file = event.target.files;
        var expensetion = file[0].name.split("?")[0].split("#")[0].split('.').pop();
        if (this.userInfo.attachmentFormat) {
            if (this.commaSeperatorArray.indexOf(expensetion) > -1) {
            } else {
                this.toastr.error('invalid extension. Extension Should be ' + this.commaSeperatorArray.join(', ') + '', 'Error');
                this.imgUpload = false;
                return;
            }
        }



        let filesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
            var reader = new FileReader();
            var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
            // var pattern = /image-*/;
            this.fileName = imgfile.name;
            this.loaded = false;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(imgfile);
        }
    }

    _handleReaderLoaded(e) {
        var reader = e.target;
        this.imgContent = reader.result;
        this.loaded = true;
        this.uploadImage(this.imgContent, this.fileName);
    }

    resetInputFile() {
        this.fileUploadInput.nativeElement.value = "";
    }

    removeImage() {
        if (this.imageIndex == undefined) {
            this.imageIndex = 0;
        }
        this.expenseModel.expenseAttachments.splice(this.imageIndex, 1);
        this.imgContent = "";
        this.fileName = "";
    }

    uploadImage(img, name) {

        this.expenseAttachmentsModel.content = img;
        this.expenseAttachmentsModel.fileName = name;
        this.expenseModel.expenseAttachments.push(this.expenseAttachmentsModel);
        let arr = this.expenseModel.expenseAttachments;
        this.expenseModel.expenseAttachments = arr.map(item => new ExpenseAttachments(item));
        this.getOcrData();
    }

    getOcrData() {
        this.ocrReqModel.attachments = [];
        let res = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
        this.ocrAttachment.fileName = res.fileName;
        this.ocrAttachment.base64Image = res.content;
        this.ocrReqModel.attachments.push(this.ocrAttachment);
        this.ocrReqModel.extract = true;
        this.ocrReqModel.uploadType = 'expenses';
        this.expenseService.ocrDataPulling(this.ocrReqModel).subscribe(res => {
            if (!res.isError) {
                this.imgUpload = false;
                let attachFilename = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
                attachFilename.url = res.payload.attachments[0].uploadedURL;
                delete attachFilename.content;
                this.expenseModel.expenseAttachments[0].content = res.payload.attachments[0].base64;
                let navInfo = {
                    url: 'dashboard',
                    attachment: this.expenseModel.expenseAttachments,
                    extractedData: res.payload.extractedData
                };
                this.getCurrencylist(navInfo);
            }
        });
    }

    getCurrencylist(navInfo?) {
        this.categoryAndMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.categoryAndMerchantModel.legalEntityGUID =
            this.userInfo.legalEntityGuId;
        this.commonService
            .getCurrencylist(this.categoryAndMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload;
                    this.currencyList = listarray.map((list) => new CurrencyList(list));
                    localStorage.setItem('currencyList', JSON.stringify(this.currencyList));
                    if (navInfo) {
                        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
                        this.navContrl.navigateRoot(['newExpenseMileageBulk']);
                    }

                } else if (!res.isError && res.errorCode == 2) {
                }
            });
    }
    getmastCategorylist() {
        this.CategoryMerchantModel.recordType = 1;
        this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.CategoryMerchantModel.locationId = '';
        this.commonService
            .getCatgryMasterlist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload;
                    let categoryList = [];
                    categoryList = listarray.expenseCategoryList.map((list) => new CategoryList(list));
                    this.sharedService.setPreCategory(categoryList);
                    sessionStorage.setItem('catList',JSON.stringify(categoryList))
                }
            });
    }
    goToGPStraking() {
        localStorage.setItem('fromTM', 'dashboard');
        localStorage.setItem('fromUP', 'dashboard');
        this.geolocation.getCurrentPosition().then((resp) => {
            this.sessionId = this.generateSessionKey();
            localStorage.setItem('sessionId', this.sessionId);
            if (localStorage.getItem('fromGPS')) {
                this.totalDistanceVal = 0.00;
                localStorage.setItem('gpsDistance', this.totalDistanceVal);
                localStorage.removeItem('fromGPS')
                if (this.checkUserPreferance) {
                    this.navContrl.navigateForward(['gpstracking']);
                } else {
                    this.navContrl.navigateForward(['mileagedefaults']);
                }
            } else {
                if (this.checkUserPreferance) {
                    this.navContrl.navigateRoot(['gpstracking']);
                } else {
                    this.navContrl.navigateRoot(['mileagedefaults']);
                }
            }
        }).catch((error) => {
            this.allowLocationsFromSettings();
        });
    }

    generateSessionKey() {
        var result, i, j;
        result = '';
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
                result = result;
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    }
    async allowLocationsFromSettings() {
        const alert = await this.alertController.create({
            mode: "md",
            message: "Location permission is must to use the Track Mileage functionality. Please enable your location permission in settings.",
            backdropDismiss: false,
            buttons: [
                {
                    text: 'No',
                    role: "cancel",
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.zone.run(() => {
                            //this.diagostic.switchToLocationSettings();
                            // this.goToDashBoard();
                            // window.location.reload();
                            // this.ionViewWillEnter();
                            this.diagostic.switchToSettings();
                        });
                    },
                },
            ],
        });
        await alert.present();
    }

    getUserPreferance() {
        let requestObj = {
            userId: this.userInfo.userId,
            userGUID: this.userInfo.userGuId,
        }
        this.commonService.getUserPreferance(requestObj).subscribe((res) => {
            localStorage.setItem('user-preference', JSON.stringify(res.payload));
            if (!res.isError && res.errorCode === 0) {
                localStorage.setItem('user-preference', JSON.stringify(res.payload))
                if (res.payload.mileageCategoryId) {
                    this.checkUserPreferance = true;
                } else {
                    this.checkUserPreferance = false;
                }
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.errorMessage, "Fail");
            }

        });
    }
    navigateToClaim(value) {
        let navInfo = {
            url: 'dashboard',
            pointerValue: value
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.navContrl.navigateRoot('expenses');
    }

    navigateToApprovals() {
        this.navContrl.navigateRoot(["/approvals"]);
    }

    navigateTo(name, statusIds) {
        // this.navContrl.navigateRoot(["/" + nvstring]);
        // let navInfo = {
        //     url: 'dashboardFilter',
        //     status: statusIds
        // };
        // sessionStorage.setItem('dashboardFilter', JSON.stringify(navInfo));
        // this.router.navigate(['/expenses']);
        const data = { from: 'dashboardFilter', name: name, ids:  statusIds, fromDate: this.dashboardModel?.filterBy?.fromDate, toDate: this.dashboardModel?.filterBy?.toDate };
        this.router.navigate(['/expenses'], { state: { data } });
        // if (statusIds == 'Draft') {
        //     const data = { name: 'Draft', ids: [1] };
        //     this.router.navigate(['/expenses'], { state: { data } });
        // } else {
        //     const data = { name: statusIds, ids: [] };
        //     this.router.navigate(['/expenses'], { state: { data } });
        // }
    }

    renderChart(datapoints) {
        // let currencyPipe = new CurrencyPipe('en');
        // let formattedNumber = currencyPipe.transform(value, 'INR', 'symbol');
        let chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            startAngle: 60,
            indexLabelFontSize: 17,
            indexLabel: "ddd",
            valueFormatSting: "#, ## 0.00",
            toolTip: {
                content: "<b>{label}:</b> ₹ {y}"
            },
            data: [{
                type: "doughnut",
                startAngle: 60,
                dataPoints: datapoints
            }]
        });
        chart.render();
    }

    openDatepicker(customCalender: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-lg modal-dialog-centered",
            keyboard: false
        };
        this.modalRef = this.modalService.show(customCalender, config);
    }

    convertDateToUtc(date) {
        let returndate = moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"))
            .utc(true)
            .toISOString(false);
        return returndate;
    }

    fromDateValue(ev) {
        this.start_date = this.convertDateToUtc(ev);
    }
    toDateValue(ev) {
        this.end_date = this.convertDateToUtc(ev);
    }
    // openPieChart(data_points) {
    //     let labels = [];
    //     let data = [];
    //     data_points.forEach(d => {
    //         labels.push(d.label);
    //         data.push(d.y);
    //     })
    //         let canvas = document.getElementById('myChart') as HTMLCanvasElement;
    //         const ctx = canvas.getContext('2d');
    //         var myChart = new Chart(ctx, {
    //             type : 'doughnut',
    //             data : {
    //                 labels : labels,
    //                 datasets : [ { 
    //                 backgroundColor : [  '#EA526F', '#23B5D3', '#279AF1','#935FA7', '#DAFEB7','#EA526F', '#23B5D3', '#279AF1','#935FA7', '#DAFEB7'],
    //                 data : data
    //                 } ]
    //             },
                
    //             options : {
    //                 title : {
    //                     display : true,
    //                     text : ''
    //                 },
    //                 legend: {
    //                     display: false,
                        
    //                 },
    //                 rotation: -0.5 * Math.PI,
    //             cutoutPercentage: 70,
    //             tooltips: {
    //                 callbacks: {
    //                   label: function(tooltipItem, data) {
    //                     var label = data.labels[tooltipItem.index] || '';
    //                     var value = data.datasets[0].data[tooltipItem.index] || '';
            
    //                     // Format the label and value as needed
    //                     label = label;
    //                     value = '₹' + value;
            
    //                     return label + ' : ' + value;
    //                   }
    //                 }
    //               }
    //             },
    //         });
    // }
    // openPieChart(data_points) {


        
    // }
    apply_date() {
        this.modalRef.hide();
        console.log('selected date :',this.start_date,this.end_date);
        console.log('selected date 123:',moment(this.start_date).format('YYYY-MM-DD') ,this.datechangeService.convertCalendarToAPI(this.start_date),this.datechangeService.convertCalendarToAPI(this.end_date));
        this.filterModal.fromDate =  moment(this.start_date).format('YYYY-MM-DD');
        this.filterModal.toDate = moment(this.end_date).format('YYYY-MM-DD');
        this.dashboardModel.filterBy = this.filterModal;
        this.getDashboard();
    }
  
    openNewClaim() {
        this.navContrl.navigateRoot(["newreport"]);
    }

    getCustomFieldsInfo() {
        const requestModel = {
            "legalEntityGUID": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "companyId": this.userInfo.companyId,
            "companyGUID": this.userInfo.companyGuId,
        }
        this.commonService.getFieldsInfo(requestModel).subscribe((response) => {
            response.payload.isLoggedInUserEntity = true;
            sessionStorage.setItem('customFieldsInfo', JSON.stringify(response.payload));
            // console.log('\n\n\n');
            // console.log('CUSTOM FIELDS __________ ', JSON.parse(sessionStorage.getItem('customFieldsInfo')));
            // console.log('\n\n\n');
        })
    }

    getForeignExchnage() {
        const requestModel = {
            "legalEntityGuid": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "baseCurrencyId": this.userInfo?.baseCurrency?.currencyId
        }
        this.commonService.getForeignExchnage(requestModel).subscribe((response) => {
            sessionStorage.setItem('foreignCurrencies', JSON.stringify(response?.payload?.currencies || []))
        })
    }

    getCostCenters() {
        const requestModel = {
            "legalEntityGuid": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "companyId": this.userInfo.companyId,
            "companyGUID": this.userInfo.companyGuId,
        }
        this.settingService.getCostCenterList(requestModel).subscribe((res) => {
            sessionStorage.setItem('costCenters', JSON.stringify(res?.payload?.costCenterList || []));
            // console.log('Cost Centers __________ ', res.payload.costCenterList || []);
        })

        this.settingService.getProjectCodesList(requestModel).subscribe((res) => {
            sessionStorage.setItem('projectCodes', JSON.stringify(res?.payload?.projectList || []));
            // console.log('Project Codes __________ ', res.payload.projectList || []);
        })

        this.settingService.getDepartmentsist(requestModel).subscribe((res) => {
            sessionStorage.setItem('departments', JSON.stringify(res?.payload?.departmentList || []));
            // console.log('Departmemnts __________ ', res.payload.departmentList || []);
        })
        this.settingService.getBrandsList(requestModel).subscribe((res) => {
            sessionStorage.setItem('bands', JSON.stringify(res?.payload?.bandList || []));
            // console.log('Bands __________ ', res.payload.bandList || []);
        })
        this.settingService.getDesignationsList(requestModel).subscribe((res) => {
            // console.log('Designation __________ ', res.payload.designationList || []);
            sessionStorage.setItem('designations', JSON.stringify(res?.payload?.designationList || []));
        })
    }

    refreshTokenTime: any;
    refreshTokenCount: number;
    tokenRefresh(userInfo) {
        if (userInfo != null) {
            const requestbody = {
                email: userInfo.email,
                refreshToken: userInfo.refreshToken
            };
            this.refreshToken.refreshToken(requestbody).subscribe(res => {
                if (res.payload) {
                    this.userInfo = userInfo;
                    this.userInfo.accessToken = res.payload.sessionInfo.accessToken;
                    const userData = JSON.stringify(this.userInfo);
                    sessionStorage.setItem('userInfo', userData);
                    localStorage.setItem('userInfo', userData);
                    sessionStorage.setItem('token', res.payload.sessionInfo.accessToken);
                    localStorage.setItem('token', res.payload.sessionInfo.accessToken);
                    this.refreshTokenCount = 0;
                    sessionStorage.setItem('refreshTokenCount', JSON.stringify(this.refreshTokenCount));
                    this.clearInterval();
                    if (this.platformType == 1) {
                        this.refreshToken.status.next(true);
                    }
                } else {
                    localStorage.removeItem("gpsDistance");
                    this.refreshToken.status.next(false);
                    this.clearInterval();
                    // if (this.platformType == 1) {
                    //     window.location.reload();
                    // }
                }
            }, err => {
                localStorage.removeItem("gpsDistance");
                this.refreshToken.status.next(false);
                this.clearInterval();
                // if (this.platformType == 1) {
                //     window.location.reload();
                // }
            });
        } else {
            this.refreshToken.status.next(false);
        }
    }

    clearInterval() {
        clearInterval(this.refreshTokenTime);
    }

    openTripsPopup(tripPopup: TemplateRef<any>) {
        const restriction = this.commonService.checkRestriction('travelClaim', 'travelClaimMsg');
        if (restriction?.isRestricted) {
            this.outstandingAdvanceMsg = restriction.msg || '';
            this.showOutstandingAdvanceModal();
            return;
        }
        const config = {
            class: "modal-xl modal-dialog-centered",
            keyboard: false
        };
        this.modalRef = this.modalService.show(tripPopup, config);
    }

    async openNewClaimSelection(tripSelectionModal: TemplateRef<any>) {
        if (this.userInfo?.showTripsOnClickNewClaim) {
            this.openTripsPopup(tripSelectionModal)
        } else if (!this.userInfo?.showTripDetails) {
            this.openNewReport();
        } else if(this.userInfo?.showTripDetails && !this.userInfo?.showTripsOnClickNewClaim) {
            const actionSheet = await this.actionSheetController.create({
                header: 'Add New Claim',
                cssClass: 'my-custom-class',
                mode: 'ios',
                buttons: [
                    {
                        text: (this.userInfo?.showModifiedNameOfNonTravel && this.userInfo?.modifiedNameOfNonTravel) ? this.userInfo?.modifiedNameOfNonTravel : 'New Non-Travel Claim',
                        icon: 'add-circle',
                        handler: () => {
                            this.openNewReport();
                        },
                    },
                    {
                        text: 'New Travel Claim',
                        icon: 'add-circle',
                        handler: () => {
                            this.openTripsPopup(tripSelectionModal);
                        },
                    },
                    {
                        text: 'Cancel',
                        icon: 'close',
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        },
                    },
                ],
            });
            await actionSheet.present();

            const { role } = await actionSheet.onDidDismiss();
            // console.log('onDidDismiss resolved with role', role);
        }
    }

    getApprovalStats() {
        let isadmin = JSON.parse(sessionStorage.getItem('isadmin'));
        let requestModel = {
            userId: this.userInfo?.userId,
            userGUID: this.userInfo?.userGuId,
            viewType: isadmin ? this.userInfo?.roleId : 1,
            legalEntityId: this.userInfo?.legalEntityId,
            recordType: null,
            legalEntityGUID: this.userInfo?.legalEntityGuId,
            isApprovalTab: true
        }
        this.reportsService.approvialStats(requestModel).subscribe((res) => {
            sessionStorage.setItem('approval-tabs', JSON.stringify(res.payload.mainMenu || []));
        })
    }

    getCompanyData() {
        this.companyService.getCompanyData().subscribe((data: any) => {
            sessionStorage.setItem('companyData', JSON.stringify(data || []));
            let index = data?.findIndex(d => d.companyId == this.userInfo.companyId);
            if(this.userInfo?.showGoogleLocationSearch && index >= 0) {
                this.loadGoogleMapsApi(data[index].googleAPIKey);
            }
        })
    }
    
    loadGoogleMapsApi(API_KEY) {
        this.companyService.loadGoogleMapsApi(API_KEY).then(() => {
            console.log('Google Maps API loaded');
        }).catch(err => {
            console.error('Error loading Google Maps API', err);
        });
    }

    showOutstandingAdvanceModal() {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(this.outstandingAdvance, config);
    }

    checkRestriction() {
        let requestModel = {
            userId: this.userInfo?.userId,
            userGUID: this.userInfo?.userGuId
        }
        this.reportsService.checkRestriction(requestModel).subscribe((res) => {
            if (res?.payload) {
                let restriction = {
                    nonTravelClaim: res?.payload?.restrictNewNonTravelClaim,
                    travelClaim: res?.payload?.restrictNewTravelClaim,
                    nonTravelAdvance: res?.payload?.restrictNewNonTravelAdvance,
                    travelAdvance: res?.payload?.restrictNewTravelAdvance,
                    nonTravelClaimMsg: res?.payload?.restrictNonTravelClaimMessage,
                    travelClaimMsg: res?.payload?.restrictTravelClaimMessage,
                    nonTravelAdvanceMsg: res?.payload?.restrictNonTravelAdvanceMessage,
                    travelAdvanceMsg: res?.payload?.restrictTravelAdvanceMessage
                }
                sessionStorage.setItem('restriction', JSON.stringify(restriction));
            }
        });
    }

    closeDownloadOptTemp() {
        this.modalRef.hide();
    }

}
