import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { Constants } from "../Constants";
import { HttpClient, HttpHandler } from "@angular/common/http";
import { concatMap, delay, retryWhen, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(public httpClient: HttpClient) { }

  createReport(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  reportList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  advancesummary(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/user-preference/advance-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getReportDetails(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  removeReport(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/delete";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  ApproveList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/approve-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getReportSummary(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/report-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getSettlAdvance(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/settle-advance";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  approvialStats(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approver/stats";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  draftReport(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/submit";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  rejectAll(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  rejectAllEmailApproval(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/report/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  Approve(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/approve";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  Reject(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  financeBulkApprove(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance/approve";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  financeBulkReject(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  acknowledgeOfflineBills(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/save-offline-bill-status";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  EmailApprove(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/report/approve";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  FinanceApprove(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance/approve";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  EmailFinanceApprove(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/finance/approve";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  FinanceReject(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  EmailFinanceReject(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/finance/reject";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  removeExpense(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/remove-link";
    const body = requestBody;
    return this.httpClient.post(url, body)

  }

  downloadList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/approve-export";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  walletRequest(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/wallet/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  recordAdvanceAPI(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-payment/record-advance";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  EmailApprovalSummary(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/report-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  addComment(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/comment-box/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  addEmailComment(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/nocognito-comment-box/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCommentHistory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/comment-box/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getCommentEmailHistory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/nocognito-comment-box/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  fetchTripDetails(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/fetch";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  fetchUnclaimedTrips(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/trip-details/uncaimed-trips';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  fetchClaimedTrips(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/trip-details/claimed-trips';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  tripDetailsPool(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/pool";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTripDetails(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getFinaceIntegrationCodeTaxCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/codes/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getNocognitoFinaceIntegrationCodeTaxCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/nocognito-recurrent/codes/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMultiTripDetails(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/getMultiTrip";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTripServiceList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/service-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settlementOptVerfiction(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/settlement-otp-verification";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getEmailHistory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email/history";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getAdvanceLinkSummary(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/advanceLinkSummary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSurrenderInfo(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/surrenderInfo";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  checkRestriction(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/wallet/check-restriction";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  // getTripCatchDetails(requestBody: any , next: HttpHandler): Observable<any> {
  //   const url = Constants.DOMAIN_URL + "v1/trip-details/get";
  //   const body = requestBody;
  //   return this.httpClient.post(url, body);
  // }

}
