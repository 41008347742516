import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { PlatformService } from "src/app/services/platformbase.service";
import { ActivatedRoute, NavigationEnd, Route, Router } from "@angular/router";
import { ModalController, MenuController, NavParams, NavController, AlertController, IonRouterOutlet } from "@ionic/angular";
import { ExpenseAndMileageComponent } from "src/app/components/dashboard/expenseandmileage/expenseandmileage.component";
import { ExpensesService } from "src/app/services/expenses.service";
import {
    ExpenseModel,
    ExpenseListModel,
    BulkItemModel,
    MileageBulkItemModel,
    MyCardModel,
} from "src/app/models/Expenses/expenses.model";
import { AppConfig } from "src/app/config/app.config";
import { FetchTripInfo, GetReportModal, GetTripInfo, RemoveReportModal, Report, ReportListResModel, TripInfoPool } from "src/app/models/Reports/reports.model";
import { ReportsService } from "src/app/services/reports.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import * as _ from "lodash";
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CategoryAndMerchantModel, ExpenseAndReportModel, FilterModal, UserModel } from 'src/app/models/Common/common.model';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras } from '@angular/router';
import { error } from "protractor";
import { CommonService } from "src/app/services/common-services/common.service";
import { DatechangeService } from "src/app/services/common-services/datechange.service";
import * as moment from 'moment';
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { CustomDateService } from "src/app/services/custom-date.service";
import { threadId } from "worker_threads";
import { categoryList } from "src/app/models/Dashboard/dashboard.model";
import { MatDialog } from '@angular/material/dialog';
import { filter } from "rxjs/operators";

@Component({
    selector: "app-newreport",
    templateUrl: "./newreport.component.html",
    styleUrls: ["./newreport.component.scss"],
})
export class NewreportComponent implements OnInit {
    //  @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
    //  @ViewChild('fromDate') fromDate: ElementRef<HTMLInputElement>;
    @ViewChild("claimTypefocusOff", { static: false }) claimTypefocus: ElementRef;
    @ViewChild("defaultcalenderopening") calenderOpenRefrence: ElementRef;
    @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
    @ViewChild('todate') todate: MatDatepicker<Date>;
    @ViewChild("incompleteTemp", { static: false }) private incompleteTemp;
    @ViewChild("dynamicTemp", { static: false }) private dynamicTemp;
    @ViewChild("expensesOutsideStartEndDates", { static: false }) private expensesOutsideStartEndDates;
    isMobileDevice: boolean = false;
    events: string[] = [];
    isFirstOpen = true;
    platformType: number;
    userInfo: any;
    expenseList: any;
    selectedItemsList = [];
    expensesAmt: any;
    walletAdvance = 0;
    addReport: FormGroup;
    submitted = false;
    reportModel = new Report();
    expenseModel = new ExpenseModel();
    searchBar = false;
    modalRef: BsModalRef;
    modalRefDynamic: BsModalRef;
    panelOpenState = false;
    selectedList = [];
    showUnReported = true;
    disableBtn: boolean = false;
    totalRecords: number = 0;
    filterModal = new FilterModal();
    editreport: any;
    editTitle = false;
    getReport: any;
    pageTo: any;
    appliedAmt = 0;

    isAdvLinkedBefore = true;
    reporttitle: boolean = false;

    editAdvApplied = 0;
    previousExpCount = 0;

    allSubscrps: any;
    expnsListSubcr: any;
    toNewExp = false;
    fromPettyCash = false;
    expenseDataSubscr: any;

    tripIdDetails: any;
    tripListRes: any;
    isTripTagged = false;
    tripBtnDisabled = false;
    viewTripDisabled = false;
    currentTripInd = -1;
    // showDatepicker:boolean=false;
    showLoading = false;
    defaultobject = {
        searchText: '',
        tripStartDate: '',
        tripEndDate: ''
    }
    minDate: any;
    maxDate: any;

    maxDateStr: any = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
    expensetempList: any[];
    deletereport: any;
    currentDateTime = moment(new Date()).format("DD MMM HH:mm:ss");
    removeReportModal = new RemoveReportModal();
    claimTaggedTripList = [];
    invoiceDetails: any;
    AcknologmentBooleanCheck: boolean = true;
    AcknologmentViolationBooleanCheck: boolean = true;
    showloader: boolean = false;
    showViolationCheckBox: boolean = false;
    button_disable: boolean = true;
    minimumDate: Date;
    maximumDate: Date;
    maxExpire: Date;
    allowMax: any;
    showdatepicker: boolean = true;
    @ViewChild("claimStartDate", { read: undefined, static: true }) claimStartDate: MatDatepicker<Date>;
    @ViewChild("claimEndDate", { read: undefined, static: true }) claimEndDate: MatDatepicker<Date>;
    selectedDate: string;
    _claimMin: any;
    _claimMinMobile: any;
    _claimMax: any;
    navInfoData: any;
    showCategory: boolean = false;
    openfromdate: boolean = false;
    opentodate: boolean = false;
    private scrollChangeCallback: () => void;
    @ViewChild('fromdate') fromDatepicker: ElementRef;
    showView = false;
    isMobile: any;
    customDateSub: Subscription;
    isAdminView: boolean = false;
    isErrorcode10:boolean = false;
    budgetCategoryItems = [];
    anomaliesCategoryItems = [];

    
    selectedExpPosition:any = 0;
    availableAdvance: number = 0;
    appliedAdvance: number = 0;
    totalReimbursable: number = 0;
    AppliedTravelAdvance: number = 0;
    tripTravellers=[];
    child_cats = [];
    allCategories = [];
    isReadOnly: any;
    timesList = [
        { value: 0, label: '00:00' },
        { value: 1, label: '01:00' },
        { value: 2, label: '02:00' },
        { value: 3, label: '03:00' },
        { value: 4, label: '04:00' },
        { value: 5, label: '05:00' },
        { value: 6, label: '06:00' },
        { value: 7, label: '07:00' },
        { value: 8, label: '08:00' },
        { value: 9, label: '09:00' },
        { value: 10, label: '10:00' },
        { value: 11, label: '11:00' },
        { value: 12, label: '12:00' },
        { value: 13, label: '13:00' },
        { value: 14, label: '14:00' },
        { value: 15, label: '15:00' },
        { value: 16, label: '16:00' },
        { value: 17, label: '17:00' },
        { value: 18, label: '18:00' },
        { value: 19, label: '19:00' },
        { value: 20, label: '20:00' },
        { value: 21, label: '21:00' },
        { value: 22, label: '22:00' },
        { value: 23, label: '23:00' }
    ]
    pageRefreshed: boolean = false;
    enableCategoryFor: any;
    dTempHeader: any;
    dTempBody: any;

    mycardslist = [];
    overallClaimType: any = 1;
    isDiableClaimtype: boolean = false;
    showMultiCurrencies: boolean = false;
    constructor(
        public customDate: CustomDateService,
        private commonService: CommonService,
        private platformService: PlatformService,
        public modalController: ModalController,
        public menuCtrl: MenuController,
        private router: NavController,
        private angularRouter: Router,
        public expensesService: ExpensesService,
        public reportsService: ReportsService,
        public formBuilder: FormBuilder,
        public toaster: ToastrService,
        private modalService: BsModalService,
        private navParams: NavParams,
        public sharedService: SharedService,
        private datechangeService: DatechangeService,
        public toastr: ToastrService,
        private translate: TranslateService,
        private activateRouter: ActivatedRoute,
        public alertController: AlertController,
        public dialog: MatDialog,
        private routerOutlet: IonRouterOutlet
    ) {
        this.angularRouter.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
            if (event.id === 1 && event.url === event.urlAfterRedirects) {
                this.pageRefreshed = true;
            } else {
                this.pageRefreshed = false;
            }
        })
        if (this.angularRouter.url.includes('petty-cash/new-report') || JSON.parse(sessionStorage.getItem('fromPettyCash'))) {
            this.fromPettyCash = true;
        }
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        if(this.userInfo?.isAllowClaimFromSpecificDate && this.userInfo?.claimFormStartsFrom) {
            let specificDate = this.userInfo?.claimFormStartsFrom;
            if (!moment.utc(specificDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(specificDate) || this.checkDateFormat(specificDate)) {
                this.userInfo.allowedExpenseDate = moment(specificDate).format('MM-DD-YYYY');
            }
        }
        // setTimeout(() => {
        //     // this.showView = true;
        // }, 200)
        this.isMobile = this.platformService.isAndroidIOS();
        
    }

    dateAndMonthChange(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    showDatepicker = false;
    dp_date: any;
    dp_type: any;
    openDateTimePicker(type, date, minDate?) {
        sessionStorage.removeItem('datePickerRightSide')
        if(date == null) { date = '';}
        if (date && date != '' && !moment.utc(date, 'DD-MM-YYYY', true).isValid() || moment.isMoment(date)) {
            date = moment(date).format('DD-MM-YYYY');
        }
        if (minDate && minDate != '' && !moment.utc(minDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(minDate)) {
            minDate = moment(minDate).format('DD-MM-YYYY');
        }
        this.dp_date = date;
        this.dp_type = type;
        sessionStorage.setItem('selectedDate', this.dp_date);
        sessionStorage.setItem('min_Date', minDate);
        this.showDatepicker = true;
    }


    getSelectedDate() {
        this.customDateSub = this.customDate.getSelectedDate().subscribe((date: any) => {
            if(date) {
                this.addReport?.controls[this.dp_type]?.setValue(date);
                this.reportModel[this.dp_type] = date;
                this.reportFormControl[this.dp_type]?.setValue(this.convertDateToUtc(date))
                this.showDatepicker = false;
                if(this.dp_type == 'claimStartDate') {
                    this.dp_type = 'claimEndDate'
                    this.showDatepicker = true;
                    this.openDateTimePicker('claimEndDate', this.reportFormControl.claimEndDate.value, this.reportFormControl.claimStartDate.value)
                    if(this.reportFormControl?.claimStartDate?.value) {
                        this.minimumDate = new Date(this.reportFormControl.claimStartDate.value);
                    }
                }
            }

            
            // this.showDatepicker = false;

        })
    }

    // convertDateToUtc(date) {
    //     this.selectedDate = moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"))
    //       .utc(true)
    //       .toISOString(false);
    //     return this.selectedDate;
    //   }


    ngOnInit() {
        this.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.addReport = this.formBuilder.group({
            claimName: ["", Validators.required],
            claimId: [""],
            tripId: [""],
            claimStartDate: ["", [Validators.required]],
            claimEndDate: ["", [Validators.required]],
            claimTypeId: ["", this.userInfo?.isEnableClaimTypes ? [Validators.required] : []],
            claimTypeName: [""],
            claimCategoryName: [""],
            claimCategoryId: [""],
            isLinkedToAdvancePayment: [true],
            isLinkedToPettyCashPayment: "false",
            advanceRefCode: [null],
        });
        let date = new Date();
        // this.minimumDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this.maxExpire = new Date(date.getFullYear() + 40, 11);
        this.allowMax = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this._claimMax = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (this.userInfo.allowedExpenseDate) {
            this._claimMin = new Date(this.userInfo.allowedExpenseDate);
            this._claimMinMobile = moment(this.userInfo.allowedExpenseDate, 'MM-DD-YYYY').format();
        }
        this.getCategories();
        this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo"));
        this.getClaimTypes();

    }

    getAbsValue(val) {
        return val < 0 ? Math.abs(val) : val;
    }

    handleTabKeyPress(event: KeyboardEvent, type: any) {
        if (event.key === 'Tab' && !event.shiftKey && type) {
            // event.preventDefault();
            // type.open();
            if(type == 'start') {
                this.dp_type = 'claimStartDate'
                this.showDatepicker = true;
                this.openDateTimePicker('claimStartDate', this.reportFormControl.claimStartDate.value)
            } else if(type == 'end') {
                this.dp_type = 'claimEndDate'
                this.showDatepicker = true;
                this.openDateTimePicker('claimEndDate', this.reportFormControl.claimEndDate.value, this.reportFormControl.claimStartDate.value)
            }
        }
    }

    ionViewWillEnter() {
        this.getSelectedDate();
        this.validateReport();
        this.activateRouter.queryParams.subscribe(res => {
            this.pageTo = res.fromPage;
        });

        this.platformType = this.platformService.getPlatformType();
        this.isMobileDevice = this.platformService.isMobileDevice();
        // this.reportModel.isLinkedToAdvancePayment = true;
        this.reportModel.isLinkedToPettyCashPayment = false;
        if (!this.userInfo.allowItemizedExpenses) {
            // this.reportModel.claimName = 'Claim created on ' + this.currentDateTime;
        }
        // this.validateReport();
        this.setInitialData();
    }

    ngOnDestroy() {
        sessionStorage.removeItem('fromPettyCash');
        this.resetSubscrptns();
        window.removeEventListener("scroll", this.scrollChangeCallback, true);
      }

    ngAfterViewInit() {
        this.scrollChangeCallback = () => this.onContentScrolled();
        window.addEventListener("scroll", this.scrollChangeCallback, true);
      }

    ionViewWillLeave() {
        if (this.customDateSub) {
            this.customDateSub.unsubscribe();
        }
        sessionStorage.removeItem('fromPettyCash');
        this.resetSubscrptns();
    }

    resetSubscrptns() {
        if (this.expenseDataSubscr) {
            this.expenseDataSubscr.unsubscribe();
        }
        this.sharedService.setExpenseData(null);
        this.sharedService.setRefreshData(false);
        if (!this.toNewExp) {
            // sessionStorage.setItem('toPathInfo', null);
        }
    }

    tripEstimateDetails: any;
    isNonTravelClaim: boolean = true;
    setInitialData() {
        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        this.navInfoData = JSON.parse(sessionStorage.getItem("toPathInfo"));
        // console.log('value is :',val);
        if(this.navInfoData?.advanceSummary?.availableTripAdvance) {
            let totalExpenseAmount = 0;
            this.navInfoData?.expenseList?.forEach(exp => {
                totalExpenseAmount += parseFloat(exp?.originalAmount?.toString().replace(/,/g, '') || 0);
            });
            this.navInfoData.advanceSummary.tripAdvanceAppliedAmount = totalExpenseAmount;
            this.navInfoData.advanceSummary.availableTripAdvance = parseFloat(this.navInfoData.advanceSummary.availableTripAdvance?.toString().replace(/,/g, '') || 0) - totalExpenseAmount;
            if(this.navInfoData.advanceSummary.availableTripAdvance < 0) {
                this.navInfoData.advanceSummary.availableTripAdvance = 0;
            }
            if(this.navInfoData.advanceSummary.tripAdvanceAppliedAmount > this.navInfoData.advanceSummary.availableTripAdvance) {
                this.navInfoData.advanceSummary.tripAdvanceAppliedAmount = this.navInfoData.advanceSummary.availableTripAdvance;
            }
        }
        if (val) {
            console.log('value is :',val);
            if (val["url"]) {
                if ((val["url"] == 'report-detailView') ||
                    (val["url"] == 'detailreport') ||
                    (val["url"] == 'reports') ||
                    (val["url"] == 'dashboard') ||
                    (val["url"] == 'newreport') || (val["url"] == 'petty-cash/new-report')) {
                    if (val["report"]) {
                        // console.log('return report data :',val["report"]);
                        this.reportModel = val["report"];
                        this.getAdvanceLinkSummary();
                        this.addReport.controls.claimStartDate.patchValue(this.convertDateToUtc(val['report']?.claimStartDate));
                        this.addReport.controls.claimEndDate.patchValue(this.convertDateToUtc(val['report']?.claimEndDate));
                        this.addReport.controls.claimStartTime.patchValue(val['report']?.claimStartTime || '00:00');
                        this.addReport.controls.claimEndTime.patchValue(val['report']?.claimEndTime || '00:00');
                        this.addReport.controls.claimTypeId.patchValue(val['report']?.claimTypeId || null);
                        this.addReport.controls.claimTypeName.patchValue(val['report']?.claimTypeName || null);
                        this.addReport.controls.claimCategoryId.patchValue(val['report']?.claimCategoryId || null);
                        this.addReport.controls.claimCategoryName.patchValue(val['report']?.claimCategoryName || null);
                        this.minimumDate = new Date(this.addReport.controls.claimStartDate.value);

                    }
                    this.showMultiCurrencies = val["showMultiCurrencies"];
                    if (this.reportModel?.claimId) {
                        this.editTitle = true;
                        this.reporttitle = false
                    }
                    if (val["appliedAmount"]) {
                        this.appliedAmt = val["appliedAmount"];
                    }
                    if (val["reporttitle"]) {
                        this.reporttitle = val["reporttitle"];
                        this.editTitle = false;
                    }
                    if (val["editAppliedAmt"]) {
                        this.editAdvApplied = val["editAppliedAmt"];
                    }
                    if (val["isAdvLinkedBefore"]) {
                        this.isAdvLinkedBefore = val["isAdvLinkedBefore"];
                    }
                    if (val["walletAdvance"]) {
                        this.walletAdvance = val["walletAdvance"];
                    }
                    console.log(448, val);
                    if (val["claimTaggedTripList"]) {
                        this.claimTaggedTripList = val["claimTaggedTripList"];
                        if(this.claimTaggedTripList?.length > 0) {
                            this.overallClaimType = 2;
                            this.showAddNewButtons();
                        }
                        this.isNonTravelClaim = false;
                    } else if(val["expenseList"]?.length > 0 && val["expenseList"][0]?.serviceList) {
                        this.isNonTravelClaim = false;
                        // console.log(val);
                        // console.log(val["expenseList"]);
                        // this.expenseList = val["expenseList"]?.map(value => ({ ...value, isSelected: true })) || [];
                        // console.log(this.expenseList);
                        // this.claimTaggedTripList = val["expenseList"][0]?.serviceList || [];
                        this.overallClaimType = 2;
                        this.addTrips(val["expenseList"][0]?.serviceList || [])
                        // this.addExpensesList(this.claimTaggedTripList);
                    }
                    if(val["mycards"]) {
                        this.isNonTravelClaim = false;
                        this.addCards(val["mycards"])
                    }
                    if(this.isNonTravelClaim && this.userInfo?.prepopulateTypeOfClaim && this.userInfo?.prepopulateTypeOfClaimId) {
                        this.addReport.controls.claimName.patchValue((this.userInfo?.showModifiedNameOfNonTravel && this.userInfo?.modifiedNameOfNonTravel) ? this.userInfo?.modifiedNameOfNonTravel : 'Non-Travel Claim');
                        this.reportModel.claimName = (this.userInfo?.showModifiedNameOfNonTravel && this.userInfo?.modifiedNameOfNonTravel) ? this.userInfo?.modifiedNameOfNonTravel : 'Non-Travel Claim';
                        this.addReport.controls.claimTypeId.patchValue(this.userInfo?.prepopulateTypeOfClaimId || '');
                        this.addReport.controls.claimTypeName.patchValue(this.userInfo?.prepopulateTypeOfClaimName || '');
                        this.reportModel.claimTypeId = this.userInfo?.prepopulateTypeOfClaimId || null;
                        this.reportModel.claimTypeName = this.userInfo?.prepopulateTypeOfClaimName || null;
                    }
                    // console.log(this.claimTaggedTripList);
                    if (val["tripTravellers"]) {
                        this.tripTravellers = val["tripTravellers"];
                    }
                    if (sessionStorage.getItem('tripData')) {
                        let tripData = JSON.parse(sessionStorage.getItem('tripData'))
                        this.tripData.bookingType = tripData?.bookingType || null;
                        this.tripData.bookingTypeName = tripData?.bookingTypeName || null;
                        this.tripData.costCenterCode = tripData?.costCenterCode || null;
                        this.tripData.reasonForTravel = tripData?.reasonForTravel || null;
                        this.tripData.customFields = (tripData?.customFields && typeof tripData?.customFields == 'string') ? JSON.parse(tripData.customFields) : [];
                        this.tripData.customFields?.forEach(t => {
                            t.key = t.key.replace(/_/g, ' ');
                        });
                        this.trip_estimate = this.claimTaggedTripList[0]?.tripEstimateCost || 0;
                        this.tripEstimateInUSD = this.claimTaggedTripList[0]?.tripEstimateInUSD || null;
                    }
                    if(val["tripEstimateDetails"]) {
                        this.tripEstimateDetails = typeof val["tripEstimateDetails"] == 'string' ? JSON.parse(val["tripEstimateDetails"]) : val["tripEstimateDetails"];
                    }
                    
                    if (val["expenseList"] && val["expenseList"].length > 0 && !this.pageRefreshed && !val["report"]?.fromMytrips && !val["mycards"]) {

                        let list = val["expenseList"];
                        this.onsaveClick(list);

                        // if(val["removeOption"]){
                        //     val["expenseList"].splice(val['indexV'],1);
                        //     let list = val["expenseList"];
                        //     this.onsaveClick(list);
                        // }else{
                        //     let list = val["expenseList"];
                        //     this.onsaveClick(list);
                        // }

                        // if(val["fromNewExp"]){
                        //     val["expenseList"].forEach(element => {
                        //         this.selectedItemsList.push(element);
                        //     });
                        // }
                        // this.reportModel.isLinkedToAdvancePayment = this.isAdvLinkedBefore;
                        this.expenseDataSubscr = this.sharedService.getExpenseData().subscribe((data) => {
                            let pInfo = JSON.parse(sessionStorage.getItem('toPathInfo'));
                            if (data) {
                                this.updateNewExpense(data);
                            } else if(this.isMobileDevice && (pInfo?.refresh_claimData == true || pInfo?.refresh_claimData == 'true')) {
                                this.reportModel.claimId = pInfo?.report?.claimId;
                                this.reportModel.claimGUID = pInfo?.report?.claimGUID;
                
                                let allExpenses = pInfo?.allExpenses;
                                const isIncompleteClaim = allExpenses?.findIndex((object) => object.expenseId == null);
                                if (isIncompleteClaim >= 0) {
                                    this.selectedItemsList = allExpenses;
                                    this.expenseList = allExpenses;
                                    this.claimTaggedTripList = pInfo?.claimTaggedTripList || [];
                                    this.tripEstimateDetails = pInfo?.tripEstimateDetails || [];
                                } else {
                                    this.selectedItemsList = [];
                                    this.expenseList = [];
                                    this.reportModel.claimId = pInfo?.report?.claimId;
                                    this.reportModel.claimGUID = pInfo?.report?.claimGUID;
                                    this.getReportDetails(true);
                                }
                            } else {
                                this.fetchSelectedItems();
                            }
                        });
                        this.linkedAdvnce(this.reportModel.isLinkedToAdvancePayment);
                        // if (sessionStorage.getItem('tripData')) {
                        //     console.log(480);
                            
                        //     let tripData = JSON.parse(sessionStorage.getItem('tripData'))
                        //     this.tripData.bookingType = tripData?.bookingType || null;
                        //     this.tripData.bookingTypeName = tripData?.bookingTypeName || null;
                        //     this.tripData.costCenterCode = tripData?.costCenterCode || null;
                        //     this.tripData.reasonForTravel = tripData?.reasonForTravel || null;
                        //     this.tripData.customFields = tripData?.customFields ? JSON.parse(tripData.customFields) : [];
                        //     this.tripData.customFields?.forEach(t => {
                        //         t.key = t.key.replace(/_/g, ' ');
                        //     });
                        //     this.trip_estimate = this.claimTaggedTripList[0]?.tripEstimateCost || 0;
                        //     console.log(this.tripData);
                        // }
                    } else if (!val["report"]?.fromMytrips && !val["mycards"]) {
                        this.pageRefreshed = false;
                        if(this.reportModel?.claimId) {
                            this.editTitle = true;
                        }
                        this.setUpAPIData(false);
                    }
                    if(this.overallClaimType == 1) {
                        this.showAddNewButtons();
                    }
                    // if(JSON.parse(sessionStorage.getItem('linkAdvance')) != null){
                    //     console.log('link to advance sesstion data true',JSON.parse(sessionStorage.getItem('linkAdvance')));
                    //     this.reportModel.isLinkedToAdvancePayment = JSON.parse(sessionStorage.getItem('linkAdvance'));
                    // }else{
                    //     console.log('link to advance sesstion data false');
                    //     if(this.walletAdvance != 0 && this.userInfo.isLinkedToAdvanceMandatory){
                    //         this.reportModel.isLinkedToAdvancePayment = true;
                    //     }else{
                    //         this.reportModel.isLinkedToAdvancePayment = false;
                    //     }
                    // }
                }
            }

            if (!this.userInfo.allowItemizedExpenses) {
                if (this.selectedItemsList?.length) {
                    this.reportModel.claimId = this.selectedItemsList[0].claimId;
                }
            }
        }
    }

    setUpAPIData(hasExp) {
        if (hasExp) {
            this.advanceAmtApi();
        } else {
            this.getUnassignedExpenseList();
            // if (this.editTitle) {
            //     this.getReportDetails();
            // } else {
            //     this.showLoading = false;
            // }
            this.advanceAmtApi();
        }
    }

    validateReport() {
        this.addReport = this.formBuilder.group({
            claimName: ["", Validators.required],
            claimId: [""],
            tripId: [""],
            claimStartTime: ['00:00', this.userInfo.enableClaimStartTimeEndTime ? [Validators.required] : []],
            claimEndTime: ['00:00', this.userInfo.enableClaimStartTimeEndTime ? [Validators.required] : []],
            claimStartDate: ["", [Validators.required]],
            claimEndDate: ["", [Validators.required]],
            isLinkedToAdvancePayment: [true],
            isLinkedToPettyCashPayment: "false",
            claimTypeId: [""],
            // claimTypeId: [""],
            claimTypeName: ["", this.userInfo?.isEnableClaimTypes ? [Validators.required] : []],
            claimCategoryName: ["", (this.userInfo?.isEnableCategorySelectionAtClaim && !this.userInfo?.isEnableCategorySelectionAtExpense) ? [Validators.required] : []],
            claimCategoryId: [""],
            advanceRefCode: [null],
        });

    }

    skipApproalFlowSubmit: boolean = false;
    createReport(action, AcknologmentForm?: TemplateRef<any>, skipApprovalFlow?) {        
        this.button_disable = true;
        this.submitted = true;

        if(this.isFutureDates()) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.futureDateTemp, config);
            return;
        }

        if(this.expenseOutsideStartEndDate(moment(this.reportModel.claimStartDate, 'DD-MM-YYYY'), moment(this.reportModel.claimEndDate, 'DD-MM-YYYY'))) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-dialog-centered modal-lg baa",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.expensesOutsideStartEndDates, config);
            return;
        }

        if (this.addReport.invalid) {
            return;
        }

        this.reportModel.expenseIds = [];
        this.expenseList?.forEach((value) => {
            if (value.isSelected) {
                this.reportModel.expenseIds.push(value.expenseId);
            }
        });
        const isIncompleteClaim = this.expenseList?.findIndex((object) => object.expenseId == null);
        if (isIncompleteClaim >= 0) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.incompleteTemp, config);
            return;
        }

        if (action == 1) {
            this.submitted = true;
        }
        if (this.reportModel?.claimId) {
            this.reportModel.claimId = this.reportModel.claimId;
        }
        if (this.reportModel?.claimGUID) {
            this.reportModel.claimGUID = this.reportModel.claimGUID;
        }
        this.reportModel.recordType = this.fromPettyCash ? 4 : 1;
        this.reportModel.advancePaymentId = "";
        if (this.reportModel?.isLinkedToAdvancePayment) {
            this.reportModel.advanceAmount = this.appliedAmt;
        }
        if (this.reportModel?.isLinkedToPettyCashPayment) {
            this.reportModel.pettyCashAmount = this.appliedAmt;
        }
        this.reportModel.legalEntityId = this.userInfo?.legalEntityId;
        this.reportModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
        this.reportModel.userId = this.userInfo?.userId;
        this.reportModel.userGUID = this.userInfo?.userGuId;
        this.reportModel.action = action;
        this.disableBtn = true;        
        this.reportModel.advanceAmount = this.reportModel.advanceAmount? this.reportModel.advanceAmount : 0;
        this.isErrorcode10 = false;
        this.budgetCategoryItems = [];
        this.anomaliesCategoryItems = [];
        this.reportModel.totalBaseAmount = this.strToNum(this.reportModel.totalBaseAmount);
        if(this.reportFormControl?.claimStartTime?.value && this.reportFormControl?.claimEndTime?.value) {
            this.reportModel.claimStartTime = this.reportFormControl.claimStartTime.value;
            this.reportModel.claimEndTime = this.reportFormControl.claimEndTime.value;
        }

        this.skipApproalFlowSubmit = skipApprovalFlow || null;
        console.log(this.skipApproalFlowSubmit);
        if(this.skipApproalFlowSubmit && this.strToNum(this.reportModel?.sentBackAmount) != this.strToNum(this.reportModel?.totalBaseAmount)) {
            this.disableBtn = false;
            this.modalRef?.hide();
            this.dTempHeader = 'Skip Approval FLow';
            this.dTempBody = `New claim amount must be equal to the previously approved claim amount (<i class="fa ${this.userInfo?.baseCurrency?.currencyIcon}"></i> ${this.reportModel?.sentBackAmount})`;
            const config = {
                ignoreBackdropClick: false,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
            return true;
        }
        
        if (AcknologmentForm && action == 2) {
            // if(this.userInfo.isEnableBudgetCheck && this.userInfo.isEnableBudgetUser){
            //     this.SubmitReportApiCall(AcknologmentForm);
            // }else{
                // this.reportModel.skipBudgetCheckMessage = true;
                // this.reportModel.skipAnomolies = true;
                this.OpenAcknowledgementPopModal(AcknologmentForm);
            // }
        } else {
            let obj = JSON.parse(JSON.stringify({ ...this.reportModel }));
            obj.isSubmitWithoutApprovalFlow = this.skipApproalFlowSubmit;
            if(this.isRestrictSubmit) {
                this.showloader = false;
                this.toaster.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }
            this.reportsService.createReport(obj).subscribe((response) => {
                this.disableBtn = false;
                if (!response.isError && response.errorCode === 0) {
                    this.toaster.success(response.payload, this.translate.instant('alerts.succes'));
                    this.reportModel.action = 2;
                    if (this.pageTo == 'dashboard') {
                    } else {
                        if (this.fromPettyCash) {                            
                        } else {
                        }
                    }
                } else if (response.isError && response.errorCode === 2) {
                    this.toaster.error(response.errorMessage, this.translate.instant('alerts.error'));
                    this.reportModel.action = 2;
                }
                this.reportModel.action = 2;
            });
        }
    }

    isChecked(event) {
        this.AcknologmentBooleanCheck = event.detail.checked;
        if (this.AcknologmentBooleanCheck) {
            if (this.showViolationCheckBox) {
                if (this.AcknologmentViolationBooleanCheck) {
                    this.button_disable = true;
                } else {
                    this.button_disable = false;
                }
            } else {
                this.button_disable = true;
            }
        } else {
            this.button_disable = false;
        }
    }
    isViolationChecked(event) {
        this.AcknologmentViolationBooleanCheck = event.detail.checked;
        if (this.AcknologmentViolationBooleanCheck) {
            if (this.AcknologmentBooleanCheck) {
                this.button_disable = true;
            } else {
                this.button_disable = false;
            }
        } else {
            this.button_disable = false;
        }
    }

    Confirmacknologment() {
        this.showloader = true;
        if(this.isErrorcode10 && this.budgetCategoryItems.length != 0){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV?.length > 0){
                this.showloader = false;
                this.toaster.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
                return;
            }else{
                this.reportModel.skipBudgetCheckMessage = true;
                this.reportModel.skipAnomolies = true;
            }
        }

        this.SubmitReportApiCall();
        
    }

    isRestrictSubmit: boolean = false;
    SubmitReportApiCall(AcknologmentForm?){
        let obj = JSON.parse(JSON.stringify({ ...this.reportModel }));
        obj.isSubmitWithoutApprovalFlow = this.skipApproalFlowSubmit;
        if(this.isRestrictSubmit) {
            this.showloader = false;
            this.toaster.error('Insufficient budget. Please contact your Admin.', this.translate.instant('alerts.error'));
            return;
        }
        this.reportsService.createReport(obj).subscribe((response) => {
            if(response?.errorCode != 10) { this.isRestrictSubmit = false; }
            if(response.errorCode == 10){
                this.showloader = false;
                this.isErrorcode10 = true;
                this.budgetCategoryItems = [];
                this.anomaliesCategoryItems = [];
                this.reportModel.skipAnomolies = true;
                this.reportModel.skipBudgetCheckMessage = true;
                this.budgetCategoryItems = [...response.payload?.budgetResponse];
                this.anomaliesCategoryItems = [...response.payload?.anomalies];
                this.isRestrictSubmit = response?.payload?.isRestrict;
                this.OpenAcknowledgementPopModal(AcknologmentForm);
            } else if(response?.errorCode == 11){
                this.showloader = false;
                this.disableBtn = false;
                this.modalRef?.hide();
                this.dTempHeader = 'Budget Insufficient';
                this.dTempBody = response?.errorMessage;
                const config = {
                    ignoreBackdropClick: false,
                    class: "modal-lg modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
            } else if (!response.isError && response.errorCode == 0) {
                this.showloader = false;
                // this.isErrorcode10 = false;
                // this.budgetCategoryItems = [];
                this.resetClose();
                this.toaster.success(response.payload, this.translate.instant('alerts.succes'));
                if (this.pageTo == 'dashboard') {
                    this.router.navigateRoot(["/dashboard"]);
                } else {
                    if (this.fromPettyCash) {
                        this.router.navigateRoot(["/petty-cash/expenses"]);
                    } else {
                        this.router.navigateRoot(["/expenses"]);
                    }
                }
            } else if (response.isError && response.errorCode === 2) {
                this.showloader = false;
                this.disableBtn = false;
                this.reportModel.skipAnomolies = false;
                this.reportModel.skipBudgetCheckMessage = false;
                // this.isErrorcode10 = false;
                // this.budgetCategoryItems = [];
                this.toaster.error(response.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    OpenAcknowledgementPopModal(AcknologmentForm?: TemplateRef<any>){
        if (!document.querySelector('.modal-backdrop')) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(AcknologmentForm, config);
        }
    }

    get reportFormControl() {
        return this.addReport.controls;
    }

    expenseAndReportModel = new ExpenseAndReportModel();

    getUnassignedExpenseList() {
        this.showLoading = true;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.expenseAndReportModel.recordType = this.fromPettyCash ? 4 : 1;
        this.expensesService
            .getUnassignedExpenseList(this.expenseAndReportModel)
            .subscribe((response) => {
                
                if (!response.isError && response.errorCode == 0) {
                    this.totalRecords = response.payload.expensesCount;
                    const listarray = response.payload.expenseResponseList;
                    // this.selectedItemsList = [];
                    // console.log(734);
                    this.expenseList = listarray.map(
                        (list) => new ExpenseListModel(list)
                    );
                // console.log(741, this.expenseList);
                    this.expensetempList = response.payload.expenseResponseList;
                    // if (this.expenseList?.length == 0) {
                    //     this.isFirstOpen = false;
                    // }
                    
                }
                if (this.editTitle) {

                    this.getReportDetails(false);
                } else {

                    this.showLoading = false;
                }
            });
    }


    changeExpense() {
        this.fetchSelectedItems();
    }

    fetchSelectedItems() {
        // this.selectedItemsList = this.expenseList
        // console.log(this.expenseList);

        this.selectedItemsList = this.expenseList.filter((value) => {
            return value.isSelected;
        });

        this.expensesAmt = this.selectedItemsList
            .map((t) => t.unformattedBaseAmount)
            .reduce((a, value) => a + value, 0);
        this.resetClose();

        this.claimTaggedTripList?.forEach(c => {
            if(c.paidBy == 1) {
                this.expensesAmt += parseFloat(c.totalAmount?.toString().replace(/,/g, '') || 0);
            }
        })

        if (this.fromPettyCash) {
            this.linkedAdvnce(this.reportModel?.isLinkedToPettyCashPayment);
        } else {
            this.linkedAdvnce(this.reportModel?.isLinkedToAdvancePayment);
        }
        this.selectedItemsList?.forEach(element => {
            if (element.hasViolation) {
                this.showViolationCheckBox = true;
            }
        });
        // this.reportModel.claimName = this.selectedItemsList[0].claimName;
        // this.reportModel.claimId = this.selectedItemsList[0].claimId;
    }

    removereport() {
        if(this.deletereport?.cardTrasactionId){
            let selectedRecors = this.sharedService.getSelectedRecords();
            if(selectedRecors?.length != 0){
                _.remove(selectedRecors, item => item?.cardTrasactionId == this.deletereport?.cardTrasactionId);
                this.sharedService.setSelectedRecords(selectedRecors);
            }
        }
        if(this.deletereport?.expenseId){
            this.deleteExpense(this.deletereport.expenseId)
        }
        this.deletereport.isSelected = false;
        this.fetchSelectedItems();
    }

    editExpenseIndex: any;
    EditExpense(item, index) {
        // console.log(this.expenseList);
        // let navInfo = {
        //     url: 'newreport',
        //     expenseList: this.expenseList,
        //     isEdit: true,
        //     expenseId: item.expenseId,
        //     expenseGUID: item.expenseGUID,
        //     status: item.status,
        // };
        // sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        // this.router.navigateRoot(["newExpenseMileageBulk"]);
        if(this.isFutureDates()) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.futureDateTemp, config);
            return;
        }
        if(this.userInfo?.allowedExpenseDate && this.userInfo?.allowedExpenseDate != '') {
            if (this.dateBeforeAllowedDate(this.reportModel.claimStartDate) || this.dateBeforeAllowedDate(this.reportModel.claimEndDate)) {
                return;
            }
        }
        this.submitted = true;
        if (this.addReport.invalid) {
            return;
        }
        if (this.claimTaggedTripList.length > 0) {
            this.enableCategoryFor = 'travel'
        }
        else {
            this.enableCategoryFor = 'non-travel'
        }

        const cardExpenseIndex = this.expenseList?.findIndex(e => e.cardTrasactionId != null && e.cardTrasactionId != '');
        if (cardExpenseIndex >= 0) {
            this.enableCategoryFor = 'my-cards'
        }
        let currentAmount = 0;
        this.expenseList.forEach(e => {
            currentAmount += parseInt(e.baseAmount?.toString().replace(/,/g, '') || 0)
            let catIndex = this.allCategories?.findIndex(c => c.categoryId == e.categoryId);
            if(catIndex >= 0) {
                e.isStartEndDateEnabled = this.allCategories[catIndex].isStartEndDateEnabled || false;
                e.isSpentLocationManditory = this.allCategories[catIndex].isSpentLocationManditory || false;
            }
        });
        const advanceapplied = (this.editTitle &&
            this.isAdvLinkedBefore) ? ((this.walletAdvance -
                this.editAdvApplied) || 0) : ((this.walletAdvance - this.appliedAmt) || 0)


        let selectedExp = [this.expenseList[index]];
        this.editExpenseIndex = index;
        let navInfo = {
            url: 'newreport',
            isEdit: true,
            fromExpenseEdit: true,
            expenseId: item.expenseId,
            expenseGUID: item.expenseGUID,
            report: this.reportModel,
            allExpenses: this.expenseList,
            editExpenseIndex: index,
            expenseList: selectedExp,
            appliedAmount: this.appliedAmt,
            editAppliedAmt: this.editAdvApplied,
            isAdvLinkedBefore: this.isAdvLinkedBefore,
            // walletAdvance: (this.reportModel?.isLinkedToAdvancePayment ==true)?this.walletAdvance:0,
            walletAdvance: this.walletAdvance,
            removeOption: true,
            indexV: index,
            claimTaggedTripList: this.claimTaggedTripList,
            tripTravellers:this.tripTravellers,
            expensesAmt: this.expensesAmt || 0,
            originalExpenseAmount: item.originalAmount || 0,
            advanceapplied: (this.reportModel?.isLinkedToAdvancePayment ==true)? advanceapplied:0,
            formClaimEdit: true,
            isLinkedToPettyCashPayment:this.reportModel?.isLinkedToPettyCashPayment,
            isLinkedToAdvancePayment:this.reportModel?.isLinkedToAdvancePayment,
            advanceSummary : this.editTitle ? this.getReport?.advanceSummary :this.navInfoData?.advanceSummary,
            tripEstimateDetails: this.tripEstimateDetails || null,
            tripData: JSON.parse(sessionStorage.getItem('tripData')) || null,
        };
        sessionStorage.setItem('fromPettyCash', JSON.stringify(this.fromPettyCash));
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        // console.log(navInfo);
        // this.router.navigateRoot(["newExpenseMileageBulk"]);
        if(this.isMobileDevice) {
            this.router.navigateRoot(["newExpenseMileageBulk"]);
        } else {
            this.openExpenseCreationForm();
        }
    }
    handleInputChange(event) {
        this.getMycardlist(event.detail.value);
    }

    openNewexpweb(openTabIndex,cardsPopup?:TemplateRef<any>) {

        if(cardsPopup){
            this.getMycardlist();
            const config = {
                ignoreBackdropClick: false,
                class: "modal-xl modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(cardsPopup, config);
            return;
        }
        this.submitted = true;
        if (this.claimTaggedTripList.length > 0) {
            this.enableCategoryFor = 'travel'
        }
        else {
            this.enableCategoryFor = 'non-travel'
        }

        if(this.isFutureDates()) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(this.futureDateTemp, config);
            return;
        }
        if(this.userInfo?.allowedExpenseDate && this.userInfo?.allowedExpenseDate != '') {
            if (this.dateBeforeAllowedDate(this.reportModel.claimStartDate) || this.dateBeforeAllowedDate(this.reportModel.claimEndDate)) {
                return;
            }
        }

        const cardExpenseIndex = this.expenseList?.findIndex(e => e.cardTrasactionId != null && e.cardTrasactionId != '');
        if (cardExpenseIndex >= 0) {
            this.enableCategoryFor = 'my-cards'
        }
        if (this.addReport.invalid) {
            return;
        }
        this.toNewExp = true;
        var currentUrl = 'newreport';
        if (this.angularRouter.url.includes('petty-cash/new-report')) {
            currentUrl = 'petty-cash/new-report';
        }
        const advanceapplied = (this.editTitle &&
                    this.isAdvLinkedBefore) ? ((this.walletAdvance -
                        this.editAdvApplied) || 0) : ((this.walletAdvance - this.appliedAmt) || 0)

        // console.log(this.addReport.controls.claimStartTime.value);
        // console.log(this.addReport.controls.claimEndTime.value);

        if(this.userInfo?.enableClaimStartTimeEndTime){
            this.reportModel.claimStartTime = this.addReport.controls.claimStartTime.value;
            this.reportModel.claimEndTime = this.addReport.controls.claimEndTime.value;
        }
        this.reportModel.fromMytrips = false;

        let navInfo = {
            url: currentUrl,
            openTabIndex: openTabIndex,
            enableCategoryFor: this.enableCategoryFor,
            allExpenses: this.expenseList,
            report: this.reportModel,
            expenseList: this.expenseList,
            appliedAmount: this.appliedAmt,
            editAppliedAmt: this.editAdvApplied,
            isAdvLinkedBefore: this.isAdvLinkedBefore,
            claimTaggedTripList: this.claimTaggedTripList,
            tripTravellers:this.tripTravellers,
            walletAdvance: this.walletAdvance,
            isLinkedToPettyCashPayment:this.reportModel?.isLinkedToPettyCashPayment,
            isLinkedToAdvancePayment:this.reportModel?.isLinkedToAdvancePayment,
            nonTravelAdvanceRefId: this.reportModel?.nonTravelAdvanceRefId || null,
            advanceapplied: (this.reportModel?.isLinkedToAdvancePayment ==true)? advanceapplied:0,
            expensesAmt: this.expensesAmt || 0,
            reporttitle:true,
            tripEstimateDetails: this.tripEstimateDetails || null,
            tripData: JSON.parse(sessionStorage.getItem('tripData')) || null,
            advanceSummary : this.editTitle ? this.getReport?.advanceSummary :this.navInfoData?.advanceSummary
        };

        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        // this.openExpenseCreationForm();
        if(this.isMobileDevice) {
            this.router.navigateRoot(["newExpenseMileageBulk"]);
        } else {
            this.openExpenseCreationForm();
        }
    }

    async openExpenseCreationForm() {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "auto-height",
            mode: this.isMobileDevice ? 'ios' : 'md',
            presentingElement: this.routerOutlet.nativeEl
        });
        modal.onDidDismiss().then(res => {
            let pInfo = JSON.parse(sessionStorage.getItem('toPathInfo'));
            if(pInfo?.refresh_claimData == true || pInfo?.refresh_claimData == 'true') {
                this.reportModel.claimId = pInfo?.report?.claimId;
                this.reportModel.claimGUID = pInfo?.report?.claimGUID;

                let allExpenses = pInfo?.allExpenses;
                const isIncompleteClaim = allExpenses?.findIndex((object) => object.expenseId == null);
                if (isIncompleteClaim >= 0) {
                    this.selectedItemsList = allExpenses;
                    this.expenseList = allExpenses;
                    this.claimTaggedTripList = pInfo?.claimTaggedTripList || [];
                    this.tripEstimateDetails = pInfo?.tripEstimateDetails || [];
                } else {
                    this.selectedItemsList = [];
                    this.expenseList = [];
                    this.reportModel.claimId = pInfo?.report?.claimId;
                    this.reportModel.claimGUID = pInfo?.report?.claimGUID;
                    this.getReportDetails(true);
                }


                // console.log(this.selectedItemsList);
                // console.log(this.expenseList);
                // console.log(pInfo);
                // console.log(this.isFromMyCards);
                // console.log(this.editExpenseIndex);

                // let allExpenses = pInfo?.allExpenses;
                // console.log(allExpenses);
                // // let rr = allExpenses?.splice(this.editExpenseIndex, 1, pInfo?.expenseList[0]);
                // // console.log(rr);
                // // console.log(allExpenses);
                // this.selectedItemsList = allExpenses;
                // this.expenseList = allExpenses;
                
                

                
                // this.selectedItemsList = [];
                // this.expenseList = [];
                // this.reportModel.claimId = pInfo?.report?.claimId;
                // this.reportModel.claimGUID = pInfo?.report?.claimGUID;
                // this.getReportDetails(true);
            }
            this.getAdvanceLinkSummary();
        })
        return await modal.present();
    }

    ToReportModal(template: TemplateRef<any>) {

        const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(template, config);
    }

    backToReport() {
        if (this.fromPettyCash) {
            this.router.navigateRoot(["/petty-cash/report"]);
            return;
        }
        if (this.pageTo == 'dashboard') {
            this.router.navigateRoot(["/dashboard"]);
        } else {
            if (this.platformType == 1) {
                this.modalRef.hide();
            }
            this.router.navigateRoot(["/reports"]);
        }
    }


    selectedReport=[];
    backToclaim(list) {
        this.modalRef.hide();
        if (this.fromPettyCash) {
            this.router.navigateRoot(["/petty-cash/report"]);
            return;
        }
        if (this.pageTo == 'dashboard') {
            this.router.navigateRoot(["/dashboard"]);
        // } else if(this.backToPage) {
        //     this.router.navigateRoot([this.backToPage]);
        } else {
            if (this.platformType == 1) {
                this.modalRef.hide();
            }
            this.router.navigateRoot(["/expenses"]);
        }

        // if(this.reportModel.claimId || list?.claimId){
        //  this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
        // this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        // this.removeReportModal.userId = this.userInfo.userId;
        // this.removeReportModal.userGUID = this.userInfo.userGuId;
        // this.removeReportModal.recordType = list?.recordType;
        // this.removeReportModal.categoryType = 3;
        // if (list) {
        //     this.selectedReport.push(list.claimId);
        // }else{
        //     this.selectedReport.push(this.reportModel.claimId)
        // }
        // this.removeReportModal.claimIds = this.selectedReport;
        // this.reportsService
        //     .removeReport(this.removeReportModal)
        //     .subscribe((data) => {
        //         if (!data.isError && data.errorCode == 0) {

        //             this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
        //             if (this.fromPettyCash) {
        //                 this.router.navigateRoot(["/petty-cash/report"]);
        //                 return;
        //             }
        //             if (this.pageTo == 'dashboard') {
        //                 this.router.navigateRoot(["/dashboard"]);
        //             } else {
        //                 if (this.platformType == 1) {
        //                     this.modalRef.hide();
        //                 }
        //                 this.router.navigateRoot(["/expenses"]);
        //             }
        //         } else if (data.isError && data.errorCode == 2) {
        //             this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
        //         } 
        //     });
        // }else{
        //     if (this.fromPettyCash) {
        //         this.router.navigateRoot(["/petty-cash/report"]);
        //         return;
        //     }
        //     if (this.pageTo == 'dashboard') {
        //         this.router.navigateRoot(["/dashboard"]);
        //     } else {
        //         if (this.platformType == 1) {
        //             this.modalRef.hide();
        //         }
        //         this.router.navigateRoot(["/expenses"]);
        //     }
        // }

    }

    onsearchExpense(val) {
        let searchText = val.currentTarget.value.trim();
        this.filterModal.searchText = searchText;
        if (this.expensetempList != null) {
            if (searchText != "") {
                let filterdata = this.expensetempList.filter((data: any) => {
                    if (data.merchantName != null) {
                        if (
                            data.merchantName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                            0
                        ) {
                            return data;
                        }
                    }


                    if (data.categoryName != null) {
                        if (
                            data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                            0
                        ) {
                            return data;
                        }
                    }
                    if (data.description != null) {
                        if (
                            data.description.toLowerCase().indexOf(searchText.toLowerCase()) >=
                            0
                        ) {
                            return data;
                        }
                    }
                    if (data.baseAmount != null) {
                        if (
                            data.baseAmount.indexOf(searchText) >=
                            0
                        ) {
                            return data;
                        }
                    }


                });
                this.expenseList = filterdata;
            } else {
                this.expenseList = this.expensetempList;
            }
        }
        // this.getUnassignedExpenseList();
    }

        
    maskedAccountNumber(accountNumber){
        // Mask all but the last four digits with 'X'
        if (accountNumber && accountNumber.length >= 4) {
            const lastFourDigits = accountNumber.slice(-4);
            return `XXXX-${lastFourDigits}`;
          }
          return accountNumber;
      }

    getMycardlist(searchkey?){
    
        let request  = {
            "legalEntityGUID": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "userId": this.userInfo.userId,
            "userGUID": this.userInfo.userGuId,
            "searchText" : searchkey ? searchkey : '',
            'pageSize': 50,
            "viewType": JSON.parse(sessionStorage.getItem('isadmin')) == true ? this.userInfo.roleId : 1
        }
        this.expensesService.getUnClaimedCards(request).subscribe(res => {
            let listarray = res?.payload?.cardsList || [];
            this.mycardslist = [];
            this.mycardslist = this.mycardslist.concat((listarray.map((list) => new MyCardModel(list))));
            this.mycardslist = this.mycardslist.map(row => ({ ...row, checked: false }));

            const selectedRecords = this.sharedService.getSelectedRecords();
            this.mycardslist.forEach(row => {
                row.checked = selectedRecords.some(record => record.cardTrasactionId === row.cardTrasactionId);
            });
        })
    }

    addSearchBar() {
        this.searchBar = !this.searchBar;
    }

    onsaveClick(listModel) {
        if (listModel) {
            this.totalRecords = listModel.length;
            this.expenseList = listModel;
            // this.reportModel.claimName = listModel[listModel.length - 1].claimName;
            // this.reportModel.claimId = listModel[listModel.length - 1].claimId;
            if (this.expenseList?.length == 0) {
                this.isFirstOpen = true;
            }
        }
    }

    enableCardsProceed: boolean = true;
    showCheckedRows(event,item){
        item.checked = event.srcElement.checked;
        const selectedRecords = this.mycardslist.filter(row => row.checked);
        this.enableCardsProceed = selectedRecords?.length > 0 ? false : true;
    }
    onCheckboxClick(event,item){
        item.checked = event.detail.checked;
        const selectedRecords = this.mycardslist.filter(row => row.checked);
        this.enableCardsProceed = selectedRecords?.length > 0 ? false : true;
    }


    updateNewExpense(data) {
        if (data?.expenseResponseDTOList) {
            data.expenseResponseDTOList.forEach(exp => {
                const index = this.expenseList.findIndex(e => e.expenseId == exp.expenseId);
                if(index >= 0) {
                    this.expenseList.splice(index, 1);
                }
                let expense = new ExpenseListModel(exp);
                this.expenseList.push(expense);
                this.expenseList[this.expenseList.length - 1].isSelected = true;
            });
        } else if (data?.perDiemExpenseList) {
            data.perDiemExpenseList.forEach((exp, index) => {
                let expense = new ExpenseListModel(exp);
                this.expenseList.splice(index, 0, expense);
                this.expenseList[index].isSelected = true;
            });
        } else {
            let expense = new ExpenseListModel(data);
            this.expenseList.push(expense);
            this.expenseList[this.expenseList.length - 1].isSelected = true;
        }

        this.fetchSelectedItems();
    }

    otherExpense() {
        this.showUnReported = true;
    }

    getComponentProps(type) {
        return {
            type: type,
            claimName: null
        };
    }

    async openNewexpmobile(type) {
        this.toNewExp = true;
        var currentUrl = 'newreport';
        if (this.angularRouter.url.includes('petty-cash/new-report')) {
            currentUrl = 'petty-cash/new-report';
        }
        let navInfo = {
            url: currentUrl,
            report: this.reportModel,
            expenseList: this.expenseList,
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        const props = this.getComponentProps(type);
        if (this.editreport) {
            props.claimName = this.editreport.claimName;
        }
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: props
        });
        modal.onDidDismiss().then(res => {
            if (res) {
                let request = {
                    merchantName: res.data.merchantName,
                    originalAmount: res.data.originalAmount,
                    expenseId: res.data.expenseId
                }
                
                this.selectedItemsList.push(request);
                this.expenseList.push(res.data);
                this.expenseList[this.expenseList.length - 1].isSelected = true;
            }
        })
        return await modal.present();
    }

    getReportModal = new GetReportModal();
    trip_estimate: any;
    tripEstimateInUSD: any;
    tripData: any = {
        bookingType: '',
        customFields: '',
        costCenterCode: '',
        reasonForTravel: '',
        bookingTypeName: ''
    }
    getReportDetails(stopLoading?) {
        this.showLoading = stopLoading ? false : true;
        this.getReportModal.claimId = this.reportModel?.claimId;
        this.getReportModal.claimGUID = this.reportModel?.claimGUID;
        this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.getReportModal.userId = this.userInfo.userId;
        this.getReportModal.userGUID = this.userInfo.userGuId;
        this.getReportModal.recordType = this.fromPettyCash ? 4 : 1;
        this.reportsService.getReportDetails(this.getReportModal).subscribe(data => {
            console.log(1152, data);
            
            this.showLoading = false;
            if (!data.isError && data.errorCode == 0) {
                this.overallClaimType = data.payload.overallClaimType;
                this.showAddNewButtons();
                this.showMultiCurrencies = data?.payload?.showMultiCurrencies || false;
                if(this.showMultiCurrencies) {
                    data?.payload?.expenseList?.forEach(el => {
                        const validateIcon = icon => this.commonService.isIconPresent(icon) ? icon : null;                        
                        el.originalCurrencyIcon = validateIcon(el.originalCurrencyIcon);
                        el.baseCurrencyIcon = validateIcon(el.baseCurrencyIcon);
                    });
                }
                this.getReport = new ReportListResModel(data.payload);
                this.claimTaggedTripList = this.getReport?.claimTaggedTripList || [];
                this.trip_estimate = this.claimTaggedTripList?.length > 0 ?this.claimTaggedTripList[0]?.tripEstimateCost : 0;
                this.tripEstimateInUSD = this.claimTaggedTripList?.length > 0 ?this.claimTaggedTripList[0]?.tripEstimateInUSD : null;
                this.tripData.customFields = (data?.payload?.customFields && typeof data?.payload?.customFields == 'string') ? JSON.parse(data?.payload?.customFields) : null;
                this.tripData.reasonForTravel = data?.payload?.reasonForTravel || null;
                this.tripData.bookingType = data?.payload?.bookingType || null;
                this.tripData.bookingTypeName = data?.payload?.bookingTypeName || null;
                this.tripData.costCenterCode = data?.payload?.costCenterCode || null;
                this.tripEstimateDetails = data?.payload?.tripEstimateDetails || null;
                this.tripData.tripStartDate = data?.payload?.tripStartDate || null;
                this.tripData.tripEndDate = data?.payload?.tripEndDate || null;
                sessionStorage.setItem('tripData', JSON.stringify(this.tripData));
                // this.tripTravellers=[...this.getReport.tripTravellers]
                this.addReport.controls.claimStartDate.patchValue(
                    this.convertDateToUtc(this.getReport?.claimStartDate)
                );
                this.addReport.controls.claimEndDate.patchValue(
                    this.convertDateToUtc(this.getReport?.claimEndDate)
                );
                this.addReport.controls.claimTypeId.patchValue(this.getReport?.claimTypeId || '');
                this.addReport.controls.claimTypeName.patchValue(this.getReport?.claimTypeName || '');
                this.addReport.controls.claimCategoryId.patchValue(this.getReport?.claimCategoryId || '');
                this.addReport.controls.claimCategoryName.patchValue(this.getReport?.claimCategoryName || '');

                this.previousExpCount = 0;

                this.getReport?.expenseList?.forEach(data => {
                    let expense = new ExpenseListModel(data);
                    // this.expenseList.unshift(expense);
                    expense.isSelected = true;
                    this.expenseList.push(expense);
                    // this.expenseList[0].isSelected = true;
                    // this.expenseList[0].isPrevious = 1;
                    // this.previousExpCount = this.previousExpCount + 1;
                });
                if (!this.userInfo.allowItemizedExpenses) {
                    this.selectedItemsList = [];
                    this.fetchSelectedItems();
                }

                this.reportModel = this.getReport;
                if (this.reportModel?.recordType == 1) {
                    this.isAdvLinkedBefore = this.reportModel?.isLinkedToAdvancePayment;
                } else {
                    this.isAdvLinkedBefore = this.reportModel?.isLinkedToPettyCashPayment
                }
                if (this.fromPettyCash) {
                    this.linkedAdvnce(this.reportModel?.isLinkedToPettyCashPayment);
                } else {
                    this.linkedAdvnce(this.reportModel?.isLinkedToAdvancePayment);
                }
                this.getAdvanceLinkSummary();
            } else {

                this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
            }
        });

    }

    toogleReported() {
        this.showUnReported = !this.showUnReported;
    }

    /* link advance start */
    globalFoxedWallet = 0;
    advanceAmtApi() {
        let walletModel = new UserModel();
        walletModel.userId = this.userInfo.userId;
        walletModel.userGUID = this.userInfo.userGuId;
        this.reportsService.walletRequest(walletModel).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                this.globalFoxedWallet = this.fromPettyCash ? res.payload?.pettyCashAdvanceBalance : res.payload?.advanceBalance;
                this.walletAdvance = this.fromPettyCash ? res.payload?.pettyCashAdvanceBalance : res.payload?.advanceBalance;
                if (this.fromPettyCash) {
                    this.linkedAdvnce(this.reportModel?.isLinkedToPettyCashPayment);
                } else {
                    if(this.walletAdvance != 0 && this.userInfo.isLinkedToAdvanceMandatory){
                        this.reportModel.isLinkedToAdvancePayment = true;
                    }else{
                        this.reportModel.isLinkedToAdvancePayment = false;
                    }
                    this.linkedAdvnce(this.reportModel?.isLinkedToAdvancePayment);
                }
            }
        });
    }

    linkedAdvnce11(linked: any, advEdited?) {
        this.appliedAmt = 0;
        if (!linked) {
            return;
        }
        if (this.editTitle && this.isAdvLinkedBefore) {
            this.editAdvApplied = 0;
            this.appliedAmt = this.reportModel?.unformattedAdvanceAmount;
        } else {
            if (this.expensesAmt > 0) {
                if ((this.expensesAmt > this.walletAdvance) || (this.expensesAmt == this.walletAdvance)) {
                    this.appliedAmt = this.walletAdvance;
                } else if (this.expensesAmt < this.walletAdvance) {
                    this.appliedAmt = this.expensesAmt;
                }
            }
        }
    }

    clickedlinkedAdvnce = false;
    setclickedlinkedAdvnce() {
        this.clickedlinkedAdvnce = true;
    }

    linkedAdvnce(linked: any) {
        // console.log('fetching values :',this.editTitle,this.getReport?.expenseList,this.navInfoData?.expenseList);
        this.appliedAdvance = 0;
        this.totalReimbursable = 0;
        if(linked && this.walletAdvance != 0){
            if(this.editTitle && this.getReport?.expenseList != null){
                this.balanceCaluculation(this.getReport.expenseList);
                   
            }else if(!this.editTitle && this.navInfoData.expenseList != null){
                this.balanceCaluculation(this.navInfoData.expenseList);
            }
        }else{
            this.availableAdvance = this.walletAdvance;
            this.appliedAdvance = 0;
            this.totalReimbursable = 0;
            if(this.editTitle && this.getReport?.expenseList != null){
                this.getReport.expenseList.forEach(element => {
                this.totalReimbursable = this.totalReimbursable + parseInt(element?.unformattedBaseAmount);
                });
            }else if(!this.editTitle && this.navInfoData.expenseList != null){
                this.navInfoData.expenseList.forEach(element => {
                this.totalReimbursable = this.totalReimbursable + parseInt(element?.unformattedBaseAmount);
                });
            }
        }
    }

    balanceCaluculation(incoming){
        incoming.forEach(element => {
            this.appliedAdvance = this.appliedAdvance + parseInt(element?.unformattedBaseAmount);
        });
        this.availableAdvance = this.walletAdvance;
        if(this.appliedAdvance > this.availableAdvance){
            this.totalReimbursable = this.appliedAdvance - this.availableAdvance;
            this.appliedAdvance = this.availableAdvance;
        }
    }

    /* link advance start */

    /* link tripDetails start */
    getTripDetails(showPopup: TemplateRef<any>, apiType?) {
        this.fetchTripApi(showPopup);
    }

    showTripIdPopUp(EditGenralForm: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
    }

    tripListPopUp(EditGenralForm: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
    }

    cancelTripModl() {
        this.defaultobject.searchText = '';
        this.defaultobject.tripStartDate = '';
        this.defaultobject.tripEndDate = '';
        this.modalRef.hide();
    }

    fetchTripApi(showPopup: TemplateRef<any>, isFilter?) {
        this.tripBtnDisabled = true;
        this.isTripTagged = false;
        let modl = new FetchTripInfo();
        modl.legalEntityId = this.userInfo.legalEntityId;
        modl.legalEntityGUID = this.userInfo.legalEntityGuId;
        modl.companyId = this.userInfo.companyId;
        modl.companyGUID = this.userInfo.companyGuId;
        modl.searchText = this.defaultobject.searchText;
        modl.tripStartDate = this.defaultobject.tripStartDate;
        modl.tripEndDate = this.defaultobject.tripEndDate;

        this.reportsService.fetchTripDetails(modl).subscribe((res) => {
            this.tripBtnDisabled = false;
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                this.tripListRes = res?.payload;
                if (isFilter) {

                } else {
                    this.tripListPopUp(showPopup);
                }
            } else {
                this.tripListRes = [];
                this.toaster.error(res.errorMessage, 'Error');
            }
        });
    }

    // dateChanged(e, index: any, formItem: any) {
    //     var date: any;
    //     if (this.platformType == 1) {
    //         date = this.datechangeService.convertCalendarToAPI(e.target.value);
    //     } else {
    //         // date = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
    //     }
    //     // this.totalLcModel.localConveyanceList[index].expensedDate = date;
    //     // formItem.controls.expensedDate.patchValue(this.convertDateToUtc(this.totalLcModel.localConveyanceList[index].expensedDate));
    // }

    changedTripInd(selectedIndx: any) {
        this.tripListRes?.forEach((item, i) => {
            if (i == selectedIndx) {
                if (item?.isSelected == 1) {
                } else {
                    item.isSelected = 1;
                }
            } else {
                item.isSelected = 0;
            }
        });
    }

    hideUnhideTrip(i: any) {
        this.currentTripInd = -1;
        if (this.tripIdDetails && (this.tripListRes[i]?.tripId == this.tripIdDetails?.tripId)) {
            if (this.tripListRes[i]?.visible) {
                this.tripListRes[i].visible = false;
            } else {
                this.tripListRes[i].visible = true;
            }
        } else {
            this.currentTripInd = i;
            this.getTripApi(null, i);
        }

    }

    tagTripToReport() {
        this.currentTripInd = -1;
        var hasDetails = false;
        var selectedInd = -1;
        this.tripListRes?.forEach((item, i) => {
            if (item?.isSelected == 1) {
                selectedInd = i;
                if (this.tripIdDetails && (item?.tripId == this.tripIdDetails?.tripId)) {
                    hasDetails = true;
                    this.tagTripReport1();
                }
            }
        });

        if (!hasDetails) {
            if (selectedInd != -1) {
                this.currentTripInd = selectedInd;

                this.getTripApi(null, selectedInd, true);
            } else {
                this.toaster.error('Select any trip to continue', 'Error');
            }
        }
    }

    tagTripReport1() {
        this.isTripTagged = true;
        this.reportModel.tripId = this.tripIdDetails?.tripId;
        this.reportModel.poolId = this.tripIdDetails.poolId;
        this.reportModel.dynamoId = this.tripIdDetails.dynamoId;
        this.reportModel.tripName = this.tripIdDetails.tripName;
        this.reportModel.tripStartDate = this.tripIdDetails.tripStartDate;
        this.reportModel.tripEndDate = this.tripIdDetails.tripEndDate;
        this.cancelTripModl();
    }

    async discardTrip() {
        const alert = await this.alertController.create({
            cssClass: '',
            mode: "md",
            id: "hardwareConform",
            header: '',
            message: 'Are you sure you want to remove trip?',
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: 'Cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        for (var i = 0; i < this.commonService.modalInst.length; i++) {
                            this.commonService.modalInst[i].dismiss();
                        }
                    }
                }, {
                    text: 'Confirm',
                    handler: () => {
                        this.discardAccepted();
                    }
                }
            ]
        });
        await alert.present();
    }

    discardAccepted() {
        this.tripListRes?.forEach((item) => {
            item.isSelected = 0;
        });
        this.isTripTagged = false;
        this.reportModel.tripId = null;
        this.reportModel.poolId = null;
        this.reportModel.dynamoId = null;
        this.reportModel.tripName = null;
        this.reportModel.tripStartDate = null;
        this.reportModel.tripEndDate = null;
    }

    getTripApi(showPopup?, currentInd?, isTag?) {
        this.viewTripDisabled = true;
        const getTripModl = new GetTripInfo();

        if (this.isTripTagged) {
            getTripModl.tripGUID = this.tripIdDetails?.tripGUID;
            getTripModl.tripId = this.tripIdDetails?.tripId;
        } else {
            getTripModl.tripGUID = this.tripListRes[currentInd]?.tripGUID;
            getTripModl.tripId = this.tripListRes[currentInd]?.tripId;
        }
        getTripModl.legalEntityId = this.userInfo.legalEntityId;
        getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
        getTripModl.companyId = this.userInfo.companyId;
        getTripModl.companyGUID = this.userInfo.companyGuId;

        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            this.currentTripInd = -1;
            this.viewTripDisabled = false;
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                this.tripIdDetails = res?.payload;
                this.tripListRes?.forEach((item) => {
                    if (this.tripIdDetails && (item?.tripId == this.tripIdDetails?.tripId)) {
                        item.visible = true;
                        item.isSelected = true;
                    } else {
                        item.visible = false;
                        item.isSelected = false;
                    }
                });
                if (isTag) {
                    this.tagTripReport1();
                }
                if (showPopup) {
                    this.showTripIdPopUp(showPopup);
                }
            } else {
                this.toaster.error(res.errorMessage, 'Error');
            }
        });
    }

    StartDateChange(event) {
        this.minDate = new Date(event.value);
        this.defaultobject.tripStartDate = moment(new Date(event.value)).format('DD-MM-yyyy');
    }

    EndDateChange(event) {
        this.defaultobject.tripEndDate = moment(new Date(event.value)).format('DD-MM-yyyy');
    }

    getFromDateCall(ev) {

        this.minDateStr = moment(ev.detail.value).format("DD-MM-YYYY");
        this.defaultobject.tripStartDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }

    getToDateCall(ev) {

        this.defaultobject.tripEndDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }


    resetClose() {
        this.modalRef?.hide();
        this.reportModel.skipAnomolies = false;
        this.reportModel.skipBudgetCheckMessage = false;
    }
    removeImage(deleteTemp?: TemplateRef<any>, item?) {
        this.deletereport = item;
        if (deleteTemp) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(deleteTemp, config);

        }
    }

    selectedTripInvoiceArray: any[];
    pdfloader: boolean = false;
    tripsPdfCall(pdfSelection1: TemplateRef<any>, trip) {
        this.invoiceDetails = [];
        trip?.tripServiceAttachments?.forEach((innerelement) => {
            if (innerelement.fileURL != null) {
                let fileName = "file." + innerelement.fileURL?.split("?")[0]?.split(".")?.pop();
                let object_data = {
                    fileName: fileName,
                    content: null,
                    url: innerelement.fileURL
                };
                if (this.returnExtension(fileName) == 'pdf') {
                    this.getPdfAsBase64(innerelement.fileURL).then((baseRes) => {
                        const linkSource = `data:application/pdf;base64,${baseRes}`;
                        object_data.content = baseRes;
                    });
                }
                this.invoiceDetails.push(object_data);
            } else if (innerelement.invoiceUrl != null) {
                let fileName = "file." + innerelement.invoiceUrl?.split("?")[0]?.split(".")?.pop();
                let object_data = {
                    fileName: fileName,
                    content: null,
                    url: innerelement.invoiceUrl
                };
                if (this.returnExtension(fileName) == 'pdf') {
                    this.getPdfAsBase64(innerelement.invoiceUrl).then((baseRes) => {
                        const linkSource = `data:application/pdf;base64,${baseRes}`;
                        object_data.content = baseRes;
                    });
                }
                this.invoiceDetails.push(object_data);
            }
        });
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection1, config);
    }
    getTripDetail(expenseDetails) {
        // this.defaultBoolean = true;
        let getTripModl = new GetTripInfo();
        getTripModl.legalEntityId = this.userInfo.legalEntityId;
        getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
        getTripModl.requestId = expenseDetails.requestId;
        getTripModl.requestGUID = expenseDetails.requestGUID;
        getTripModl.productId = expenseDetails.productId;
        getTripModl.tripId = expenseDetails.tripId;
        getTripModl.tripGUID = expenseDetails.tripGUID;
        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                if (expenseDetails.productId == "1") {
                    res?.payload.flightDetails.flights.forEach((element) => {
                        element.invoiceDetails?.forEach((innerelement) => {
                            if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                                let object_data = {
                                    fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                                    content: innerelement.base64Invoice,
                                    url: innerelement.invoiceUrl,
                                    isDeleted: "",
                                    remarks: expenseDetails.productName,
                                    referenceId: expenseDetails.tripId,
                                };
                                // this.expenseModel.expenseAttachments.push(object_data);
                                this.selectedTripInvoiceArray.push(object_data);
                            }

                        });
                    });
                }
                this.pdfloader = false;
            }
        });
    }

    returnExtension(filename) {
        let text = filename.split("?")[0].split(".").pop();
        return text.toLowerCase();
    }
    base64dataRe(incomingD) {
        const linkSource = `data:application/pdf;base64,${incomingD}`;
        return linkSource;
    }
    defaultIndex = 0;
    preBtn() {
        if (this.defaultIndex != 0) {
            this.defaultIndex = this.defaultIndex - 1;
            this.zoom = 1.0;
        }
    }
    closeSelection() {
        this.defaultIndex = 0;
        this.modalRef.hide();
    }
    isPdf: boolean;
    pdfData: string;
    nextBtn() {
        if (this.defaultIndex + 1 == this.invoiceDetails.length) {
            // this.defaultIndex = 0;
        } else {
            this.defaultIndex = this.defaultIndex + 1;
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split("?")[0].split(".").pop() == "pdf") {
                this.isPdf = true;
                const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64
                    }`;
                this.pdfData = linkSource;
            } else {
                this.isPdf = false;
            }
            this.zoom = 1.0;
        }
    }
    convertDateToUtc(date) {
        this.selectedDate = moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"))
            .utc(true)
            .toISOString(false);
        return this.selectedDate;
    }
    fromdateChange(event, template?: TemplateRef<any>) {
        const currentDate = this.reportModel.claimStartDate;
        // if (!this.expenseOutsideStartEndDate(event.target.value, moment(this.reportModel.claimEndDate, 'DD-MM-YYYY'))) {
            this.minimumDate = new Date(event.target.value);
            this.reportModel.claimStartDate = this.datechangeService.convertCalendarToAPI(event?.target?.value);

            if (event.target.value) {
                setTimeout(() => {
                    this.calenderOpenRefrence.nativeElement.focus();
                    this.openfromdate = false;
                    this.opentodate = true;
                    this.todate.open();
                }, 100);
            }
            if (this.selectedItemsList?.length != 0) {
                this.givenDateIsExistorNot();
            }
            // this.reportModel.fromDate = this._fromdate;
        // } else {
        //     this.reportModel.claimStartDate = this.datechangeService.convertCalendarToAPI(moment(currentDate, 'DD-MM-YYYY').format());
        //     this.addReport.controls.claimStartDate.patchValue(
        //         this.convertDateToUtc(currentDate)
        //     );
        //     this.expensesOutsideType = 'start';
        //     this.currentTemplate = template;
        //     // this.openExpensesOutsideAlert();
        //     // alert('There are Expenses outside of START date!')
        // }
        
    }
    todateChange(event, template?: TemplateRef<any>) {
        const currentDate = this.reportModel.claimEndDate;
        // if (!this.expenseOutsideStartEndDate(moment(this.reportModel.claimStartDate, 'DD-MM-YYYY'), event.target.value)) {
            this.reportModel.claimEndDate = this.datechangeService.convertCalendarToAPI(event?.target?.value);
            //this.reportModel.claimEndDate = this._todate; 
            if (this.selectedItemsList?.length != 0) {
                this.givenDateIsExistorNot();
            }
        // } else {
        //     this.reportModel.claimEndDate = this.datechangeService.convertCalendarToAPI(moment(currentDate, 'DD-MM-YYYY').format());
        //     this.addReport.controls.claimEndDate.patchValue(
        //         this.convertDateToUtc(currentDate)
        //     );
        //     this.expensesOutsideType = 'end';
        //     this.currentTemplate = template;
        //     // this.openExpensesOutsideAlert();
        //     // alert('There are Expenses outside of END date!')
        // }
    }
    /* link tripDetails end */
    onClickedCategory() {
        this.showCategory = false;
        this.fromdate.close();
    }

    givenDateIsExistorNot() {
        let claimFdate = this.addReport.controls.claimStartDate.value;
        let claimTdate = this.addReport.controls.claimEndDate.value;        
        if (claimFdate && claimTdate) {
            this.selectedItemsList?.forEach(element => {                
                if(moment(element.expensedDate, 'DD-MM-YYYY').isSameOrAfter(claimFdate) && moment(element.expensedDate, 'DD-MM-YYYY').isSameOrBefore(claimTdate)) {
                    element.dateExccessed = false;
                } else {
                    element.dateExccessed = true;
                }
                // if (new Date(element.expensedDate) >= new Date(claimFdate) && new Date(element.expensedDate) <= new Date(claimTdate)) {
                //     element.dateExccessed = false;
                // } else {
                //     element.dateExccessed = true;
                // }
            });
        }
    }

    fromDatev2() {
        this.openfromdate = true;
        this.opentodate = false;
    }
    dp_mouseOver = false;
    onClickedOutsideCalenderfrom(e) {
        let el = <HTMLElement>e?.target;
        if (this.openfromdate && el && (el.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
            || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused"
            || el.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-arrow mat-calendar-invert" || el.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el.className == "mat-calendar-period-button mat-button mat-button-base" || el.className == "mat-button-wrapper" || el.className == "mat-calendar-body-cell-content" || el.className == "mat-calendar-arrow" || el.className == "mat-calendar-header" || el.className == "cdk-overlay-container" || el.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today"
            || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-spacer")) {
            this.fromdate.open();
        } else if(el) {
            this.fromdate.close();
        }
        if(this.isMobile == 1 && !this.dp_mouseOver && this.dp_type == 'claimStartDate') {
            this.dp_type = null;
        }

    }
    toDatev2() {
        this.opentodate = true;
        this.openfromdate = false;
    }
    onClickedOutsideCalendertodate(e) {
        let el = <HTMLElement>e?.target;
        if (this.opentodate && el && (el.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-keyboard-focused"
            || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-touch-focused"
            || el.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-arrow mat-calendar-invert" || el.className == "mat-calendar-body-cell-content mat-calendar-body-today" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el.className == "mat-calendar-period-button mat-button mat-button-base" || el.className == "mat-button-wrapper" || el.className == "mat-calendar-body-cell-content" || el.className == "mat-calendar-arrow" || el.className == "mat-calendar-header" || el.className == "cdk-overlay-container" || el.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today"
            || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-spacer")) {
            this.todate.open();
        } else if(el) {
            this.todate.close();
        }
        if(this.isMobile == 1 && !this.dp_mouseOver && this.dp_type == 'claimEndDate') {
            this.dp_type = null;
        }
    }

    isInt(amount){
        if(amount == undefined) {
            return true;
        } else {
            return amount % 1 === 0;
        }
    }

    expensesOutsideType: string;
    currentTemplate: any;
    expenseOutsideStartEndDate(start, end) {
        let isExpenseOutside = false;
        this.selectedItemsList?.forEach(s => {
            if (moment(s.expensedDate, 'DD-MM-YYYY').isSameOrAfter(start) && moment(s.expensedDate, 'DD-MM-YYYY').isSameOrBefore(end) && !isExpenseOutside) {
                isExpenseOutside = false;
            } else {
                isExpenseOutside = true;
            }
        })
        
        return isExpenseOutside;
    }

    openExpensesOutsideAlert() {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(this.currentTemplate, config);
    }

    convertAmount(amount) {        
        let a = (amount || 0).toString();
        return parseInt(a.replace(/,/g, ''), 10);
    }
    parseIntt(value){
        return parseFloat(value.replace(/,/g, ""));
    }

    onContentScrolled() {
        this.fromdate?.close();
        this.todate?.close();
    }

    deleteExpense(id) {
        const obj = {
          "expenseIds": [id],
          "userId": this.userInfo.userId,
          "userGUID": this.userInfo.legalEntityGuId,
          "legalEntityId": this.userInfo.legalEntityId,
          "legalEntityGUID": this.userInfo.legalEntityGuId
        }
        this.expensesService.deleteExpense(obj).subscribe((res: any) => {
          if(!res.isError) {
            this.expenseList.splice(this.selectedExpPosition,1);
            if(this.editTitle){
                this.getReport.expenseList.splice(this.selectedExpPosition,1);
            }else{
                this.navInfoData.expenseList.splice(this.selectedExpPosition,1);
            }
            this.linkedAdvnce(this.reportModel.isLinkedToAdvancePayment);
          }

        })
      }

      convertToBasecurrency(amount, convertAmount) {
        let a = (amount || 0).toString();
        return parseInt(a.replace(/,/g, ''), 10) * parseInt(convertAmount);
      }
      formatDate(date) {
        if (date) {
          return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');
    
        }
      }

      AppliedTravelAdvanceCalculation(){}

    claimTypes: any = [];
    claimTypesTemp: any = [];
    getClaimTypes() {
        const obj = {
            "action": "claim_types",
            "legalEntityGuid": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "companyId": this.userInfo.companyId,
            "companyGUID": this.userInfo.companyGuId,
        }
        this.expensesService.getClaimTypes(obj).subscribe(res => {
            this.claimTypes = res.payload || [];
            this.claimTypesTemp = [...this.claimTypes];

            if (this.navInfoData?.isTravelClaim && !this.navInfoData?.report?.claimTypeId) {
                const { id, claimType } = this.claimTypes[0] || {};
                this.addReport.controls.claimTypeId.patchValue(id);
                this.addReport.controls.claimTypeName.patchValue(claimType);
                this.reportModel.claimTypeId = id;
                this.reportModel.claimTypeName = claimType;
                this.isDiableClaimtype = true;
            }
        })
    }
    
    claimTypeDisable() {
        const hasClaimType = this.reportFormControl?.claimTypeId?.value && this.reportFormControl?.claimTypeName?.value;
        const isPrepopulatedClaim = this.userInfo?.prepopulateTypeOfClaim && this.userInfo?.prepopulateTypeOfClaimId;
    
        // Combine conditions into a single check
        const isDisableClaimType =
            hasClaimType &&
            (
                this.disableClaimChange() ||
                (this.claimTaggedTripList?.length > 0 && !this.isDiableClaimtype) ||
                isPrepopulatedClaim
            );
    
        return !!isDisableClaimType;
    }
    

    eventProp(ev, id?) {
        ev.preventDefault();
        ev.stopPropagation();
        setTimeout(() => {
            document.getElementById(id).focus()
        }, 0);
    }

    currentField = '';
    closeOptionsPopup: boolean = false;
    mouseover = false;
    showSubMenu(field?) {
        this.closeOptionsPopup = false;
        this.currentField = field;
    }

    selectedClaimType(event, type) {
        this.reportFormControl.claimTypeId.patchValue(type.id);
        this.reportFormControl.claimTypeName.patchValue(type.claimType);
        this.reportModel.claimTypeId = type.id;
        this.reportModel.claimTypeName = type.claimType;
        this.currentField = '';
        console.log('sdsdsds',type);

        let categoriesList = [];

        if(this.allCategories?.length != 0){
            this.allCategories?.forEach((le) => {
                if(le.claimTypeId?.split(',')?.includes(type.id?.toString())) {
                  categoriesList.push(le);
                }
              });
        }

        // this.child_cats = categoriesList;
        this.child_cats = this.getSubCategories(categoriesList);
        console.log('this.child_cats',this.child_cats);
    }

    async getPdfAsBase64(url: string, thumbnail?): Promise<string> {
        try {
          const response = await fetch(url);
          const buffer = await response.arrayBuffer();
    
          // Convert the response data to base64
          const base64 = btoa(
            new Uint8Array(buffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          return base64;
        } catch (error) {
          console.error('Error:', error);
          throw error;
        }
      }

    selectedClaimCategory(ev) {
        this.reportFormControl.claimCategoryId.patchValue(ev.categoryId);
        this.reportFormControl.claimCategoryName.patchValue(ev.categoryName);
        this.reportModel.claimCategoryId = ev.categoryId;
        this.reportModel.claimCategoryName = ev.categoryName;
    }

    selectedTime(ev, type) {
        if(type == 'start'){
            this.reportFormControl.claimStartTime.patchValue(ev);
            this.reportModel.claimStartTime = ev;
        } else if(type == 'end'){
            this.reportFormControl.claimEndTime.patchValue(ev);
            this.reportModel.claimEndTime = ev;
        }
    }

    CategoryModel = new CategoryAndMerchantModel();
    getCategories() {
        this.CategoryModel.recordType = 1;
        this.CategoryModel.legalEntityId = this.userInfo.legalEntityId;
        this.CategoryModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.commonService.getCatgryMasterlist(this.CategoryModel).subscribe((res) => {
            this.allCategories = [];
            if (!res.isError && res.errorCode == 0) {
                let categoryfiltertype;
                if (this.navInfoData?.mycards?.length > 0) {
                    categoryfiltertype = '3';
                }
                else if (this.claimTaggedTripList?.length > 0 || (this.navInfoData?.["expenseList"]?.length > 0 && this.navInfoData?.["expenseList"][0]?.serviceList?.length > 0)) {
                    categoryfiltertype = '1';
                }
                else {
                    categoryfiltertype = '2';
                }
                let listarray: any = [];
                // let listarray = res.payload?.expenseCategoryList || [];
                //  console.log(res.payload)
                //  this.allCategories = res.payload?.expenseCategoryList || [];
                if (this.userInfo?.isEnableClaimTypes && this.reportFormControl?.claimTypeId?.value && this.reportFormControl?.claimTypeName?.value) {
                    res.payload?.expenseCategoryList?.forEach((le) => {
                        if (le.claimTypeId?.split(',')?.includes(this.reportFormControl?.claimTypeId?.value?.toString())) {
                            listarray.push(le);
                        }
                    });
                } else {
                    listarray = res.payload?.expenseCategoryList || [];
                }

                listarray?.forEach((le) => {
                    if (le?.expenseTypeOptionIds?.split(',')?.includes(categoryfiltertype)) {
                        this.allCategories.push(le);
                    }
                    else if (le?.expenseTypeOptionIds == null) {
                        this.allCategories.push(le);
                    }
                });
                // this.allCategories = res.payload?.expenseCategoryList || [];
                this.child_cats = this.getSubCategories(this.allCategories);
                // console.log('this.child_cats', this.child_cats);
            } else if (res.isError == true) {
                this.toastr.error(
                    res.errorMessage,
                    this.translate.instant("alerts.error", { timeOut: 3000 })
                );
            }
        });
    }

    getSubCategories(categories: any) {
        let subCategories = [];
        categories.forEach(c => { c.children = [] });
        categories.forEach(c => {
            if (c.parentCategoryId) {
                const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
                if (index >= 0) {
                    categories[index].children.push(c);
                }
            }
        })
        subCategories = categories.filter((c) => c.parentCategoryId == null);
        console.log('this.child_cats _____ ', subCategories);
        return subCategories;
    }

    claimBasicData: any;
    openTripsPopup(tripPopup: TemplateRef<any>) {
        console.log('addReport _____ ', this.addReport.value);
        console.log('reportModel ____ ', this.reportModel);
        console.log('selectedItemsList _____ ', this.selectedItemsList);
        console.log({
            claimId: this.reportModel.claimId,
            claimName: this.reportModel.claimName,
            claimGUID: this.reportModel.claimGUID,
            claimStartDate: this.reportModel.claimStartDate,
            claimEndDate: this.reportModel.claimEndDate,
            // claimCategoryId: this.reportModel.claimCategoryId,
            // claimCategoryName: this.reportModel.claimCategoryName,
            // claimTypeId: this.reportModel.claimTypeId,
            // claimTypeName: this.reportModel.claimTypeName,
            // claimStartTime: this.reportModel.claimStartTime,
            // claimEndTime: this.reportModel.claimEndTime,
            // report: this.reportModel,
            // expenseList: this.expenseList
        });

        this.claimBasicData = {
            claimId: this.reportModel.claimId,
            claimName: this.reportModel.claimName,
            claimGUID: this.reportModel.claimGUID,
            claimStartDate: this.reportModel.claimStartDate,
            claimEndDate: this.reportModel.claimEndDate
        }

        const config = {
            class: "modal-xl modal-dialog-centered",
            keyboard: false
        };
        this.modalRef = this.modalService.show(tripPopup, config);
    }

    removeTaggedTrips(removedeleteTemp?: TemplateRef<any>) {
        const config = {
          ignoreBackdropClick: false,
          class: "modal-md modal-dialog-centered",
          keyboard: false,
        };
        this.modalRef = this.modalService.show(removedeleteTemp, config);
      }

    // deleteTrip() {
    //     console.log(this.reportModel);
    //     this.claimTaggedTripList = [];
    //     this.reportModel.claimTaggedTripList = [];
    // }

    closeModal() {
        this.modalRef?.hide();
    }

    closeDynamicModal() {
        this.modalRefDynamic?.hide();
    }

    deleteTrip() {
        console.log(this.claimTaggedTripList);
        let trip = this.claimTaggedTripList[0];
        this.closeModal();
        if(!trip.claimId) {
            this.claimTaggedTripList = [];
            this.reportModel.claimTaggedTripList = [];
        } else {
            const obj = {
            "id": trip.id,
            "GUID": this.userInfo.legalEntityGuId,
            "claimId": trip.claimId,
            "claimGUID": trip.claimGUID,
            "tripId": trip.tripId,
            "tripGUID": trip.tripGUID
            }
            this.expensesService.deleteClaimTrip(obj).subscribe((res: any) => {
                if (!res.isError) {
                    this.claimTaggedTripList = [];
                    this.reportModel.claimTaggedTripList = [];
                    this.toastr.success(res.payload, 'Success');
                }
            })
        }
      }

    async openTripEstimatePopup(tripEstimatePopOver?: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-xl",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(tripEstimatePopOver, config);
    }

    addTrips(services) {
        this.overallClaimType = 2;
        this.showAddNewButtons();
        // this.backToPage = 'mytrips';
        this.CategoryModel.recordType = 1;
        this.CategoryModel.legalEntityId = this.userInfo.legalEntityId;
        this.CategoryModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.commonService.getCatgryMasterlist(this.CategoryModel).subscribe((res) => {
            this.allCategories = [];
            if (!res.isError && res.errorCode == 0) {
                this.allCategories = res.payload?.expenseCategoryList || [];
                services?.forEach(service => {
                    let obj = {
                        "id": null,
                        "expenseId": null,
                        "claimId": null,
                        "claimGUID": null,
                        "CompanyPaid": service.CompanyPaid,
                        "requestId": service.requestId,
                        "requestGUID": service.requestGUID,
                        "tripId": service.tripId,
                        "tripGUID": service.tripGUID,
                        "startDate": service.tripStartDate,
                        "endDate": service.tripEndDate,
                        "fromLocation": service.tripName,
                        "toLocation": "",
                        "totalAmount": service.tripCost,
                        "tripEstimateCost": service.tripEstimateCost,
                        "tripEstimateInUSD": service.tripEstimateInUSD,
                        "tripTravelType": service.tripTravelType,
                        "paymentMode": service.paymentMode,
                        "productId": service.productId,
                        "productName": service.productName,
                        "isAssociated": false,
                        "paidBy": service.paidBy,
                        "budgetRefNumber": service.budgetRefNumber,
                        "financeIntegrationCode": service.financeIntegrationCode,
                        "categoryId": service.categoryId,
                        "evaCurrency": service.evaCurrency,
                        "tripServiceAttachments": service.invoiceList,
                    }
                    this.claimTaggedTripList.push(obj);
                })
                this.trip_estimate = this.claimTaggedTripList?.length > 0 ? this.claimTaggedTripList[0]?.tripEstimateCost : 0;
                this.tripEstimateInUSD = this.claimTaggedTripList?.length > 0 ? this.claimTaggedTripList[0]?.tripEstimateInUSD : null;
                this.addTripExpensesList(services);
            }
        });
        // services?.forEach(service => {
        //     let obj = {
        //         "id": null,
        //         "expenseId": null,
        //         "claimId": null,
        //         "claimGUID": null,
        //         "CompanyPaid": service.CompanyPaid,
        //         "requestId": service.requestId,
        //         "requestGUID": service.requestGUID,
        //         "tripId": service.tripId,
        //         "tripGUID": service.tripGUID,
        //         "startDate": service.tripStartDate,
        //         "endDate": service.tripEndDate,
        //         "fromLocation": service.tripName,
        //         "toLocation": "",
        //         "totalAmount": service.tripCost,
        //         "tripEstimateCost": service.tripEstimateCost,
        //         "tripTravelType": service.tripTravelType,
        //         "paymentMode": service.paymentMode,
        //         "productId": service.productId,
        //         "productName": service.productName,
        //         "isAssociated": false,
        //         "paidBy": service.paidBy,
        //         "budgetRefNumber": service.budgetRefNumber,
        //         "financeIntegrationCode": service.financeIntegrationCode,
        //         "categoryId": service.categoryId,
        //         "evaCurrency": service.evaCurrency,
        //         "tripServiceAttachments": service.invoiceList,
        //     }
        //     this.claimTaggedTripList.push(obj);
        // })
        // this.addTripExpensesList(services);
    }

    cardsProcess(){
        const selectedRecords = this.mycardslist.filter(row => row.checked);
        this.sharedService.setSelectedRecords(selectedRecords);
        _.remove(this.selectedItemsList, item =>  item?.isCardExpense ?  item.cardTransactionId !== null : false);
        _.remove(this.expenseList, item =>  item?.isCardExpense ?  item.cardTransactionId !== null : false);
        _.remove(this.navInfoData.expenseList, item =>  item?.isCardExpense ?  item.cardTransactionId !== null : false);
        this.modalRef.hide();

        selectedRecords.forEach((item,index) => {
            let bulkItem = new BulkItemModel();
             bulkItem.categoryName = item?.categoryName;
             bulkItem.merchantName = item?.merchantName;
             bulkItem.merchantCode = item?.merchantCode;
             bulkItem.taxAmount = item?.taxAmount;
             bulkItem.taxPercentage = item?.taxPercentage;
             bulkItem.taxNumber = item?.taxNumber
             bulkItem.categoryName = "";
             bulkItem.merchantName = item?.merchantName;
             bulkItem.categoryType = '';
             bulkItem.categoryId = '';
             bulkItem.expensedDate = item?.expensedDate;
             bulkItem.cardTrasactionId = item?.cardTrasactionId;
             bulkItem.description = item?.description;
             bulkItem.invoiceNumber = item?.invoiceNumber;
             bulkItem.taxNumber = item?.taxNumber;
             bulkItem.location = item?.spentLocation;
             bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
             bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
             bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
             bulkItem.expConvRate = 1;
             bulkItem.paidBy = 1;
             bulkItem.paidByName = 'Corporate card';
             bulkItem.bandId = this.userInfo?.bandId || null;
             bulkItem.bandName = this.userInfo?.bandName || null;
             bulkItem.departmentId = this.userInfo?.departmentId || null;
             bulkItem.departmentName = this.userInfo?.departmentName || null;
             bulkItem.costCenterId = this.userInfo?.costCenterId || null;
             bulkItem.costCenterName = this.userInfo?.costCenterName || null;
             bulkItem.designationId = this.userInfo?.designationId || null;
             bulkItem.designationName = this.userInfo?.designationName || null;
             bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
             bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;
             bulkItem.isSelected = true;
             bulkItem.categoryType = 1;
             bulkItem.expenseType = 1;
             bulkItem.status = 1;
             bulkItem.amount = item.amount;
             bulkItem.originalAmount = Number(item?.amount);
             bulkItem.isCardExpense = true;
             this.navInfoData?.expenseList?.push(bulkItem);
             this.selectedItemsList.push(bulkItem);
             this.expenseList.push(bulkItem);
        });
    }

    backToPage: any;
    addTripExpensesList(services) {
        this.selectedItemsList = [];
        this.expenseList = [];
        services?.forEach(item => {
            console.log(item);
            if (!item.CompanyPaid && item?.isSelfDriveCabRequest != 2 && !item.isEmptyTrip) {
                let bulkItem = new BulkItemModel();
                if (item?.tripStartDate) {
                    // bulkItem.expensedDate = this.givenOCRDate(item?.tripStartDate);
                    bulkItem.expensedDate = item?.tripStartDate;
                } else {
                    bulkItem.expensedDate = '';
                }
                if (item?.tripEndDate) {
                    // bulkItem.expenseEndDate = this.givenOCRDate(item?.tripEndDate);
                    bulkItem.expenseEndDate = item?.tripEndDate;
                } else {
                    bulkItem.expenseEndDate = '';
                }
                bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
                bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
                bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
                bulkItem.expConvRate = 1;
                bulkItem.description = item.tripName;
                bulkItem.invoiceNumber = item?.invoiceNumber ? item.invoiceNumber : '';
                bulkItem.taxNumber = item?.taxNumber ? item.taxNumber : '';
                bulkItem.taxPercentage = item?.taxPercentage ? item.taxPercentage : '';
                bulkItem.taxAmount = item?.taxAmount ? item.taxAmount : '';
                bulkItem.originalAmount = Number(item.tripCost);
                bulkItem.expenseDateExist = false;
                bulkItem.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
                bulkItem.userlists = [];
                bulkItem.paidBy = item.CompanyPaid ? 1 : 2;
                bulkItem.bandId = this.userInfo?.bandId || null;
                bulkItem.bandName = this.userInfo?.bandName || null;
                bulkItem.departmentId = this.userInfo?.departmentId || null;
                bulkItem.departmentName = this.userInfo?.departmentName || null;
                bulkItem.costCenterId = this.userInfo?.costCenterId || null;
                bulkItem.costCenterName = this.userInfo?.costCenterName || null;
                bulkItem.designationId = this.userInfo?.designationId || null;
                bulkItem.designationName = this.userInfo?.designationName || null;
                bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
                bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;
                bulkItem.isSelected = true;
                bulkItem.categoryType = 1;
                bulkItem.expenseType = 1;
                bulkItem.status = 1;
                let invoices = [];
                item.invoiceList?.forEach(invoice => {
                    if (invoice?.invoiceUrl) {
                        const urlParts = invoice?.invoiceUrl?.split('/');
                        const fileName = urlParts[urlParts?.length - 1];
                        invoices.push({
                            fileName: fileName,
                            url: invoice.invoiceUrl,
                            content: null,
                            attachmentType: "1",
                        });
                    }
                });
                bulkItem.expenseAttachments = invoices || [];
                const category = _.find(this.allCategories, data => { return item?.productId && (data.productId == item?.productId) })
                if (category) {
                    bulkItem.categoryType = category?.categoryType;
                    bulkItem.categoryName = category?.categoryName;
                    bulkItem.categoryCode = category?.categoryCode;
                    bulkItem.categoryId = category?.categoryId;
                    bulkItem.isPolicyCalcPerNight = category?.isPolicyCalcPerNight || false;
                    bulkItem.isStartEndDateEnabled = category?.isStartEndDateEnabled || false;
                    bulkItem.isSpentLocationManditory = category?.isSpentLocationManditory || false;
                    bulkItem.merchantList = category?.merchantList || [];
                    bulkItem.merchantList?.forEach(m => {
                        if (item?.supplierName && (item?.supplierName == m.merchantName)) {
                            bulkItem.merchantName = m?.merchantName;
                            bulkItem.merchantCode = m?.merchantCode;
                        }
                        if (item?.merchantName && item?.merchantName == m.merchantName) {
                            bulkItem.merchantName = m?.merchantName;
                            bulkItem.merchantCode = m?.merchantCode;
                        }
                    })

                    //  THE CODE IS FOR TO RESTICTION THE CATEGORY WHICH IS  ALREADY SETUP WITH ANOTHER CATEGORY
                    let request = {
                        'categoryName': category?.categoryName,
                        'categoryRestricted': category?.categoryRestricted != null ? category?.categoryRestricted : null,
                        'isCategoryRestricted': category?.isCategoryRestricted
                    }
                    // console.log('categoryRes __ ', request);
                } else {
                    bulkItem.categoryName = "";
                    // bulkItem.merchantName = item.merchantName;
                    // bulkItem.productId = null;
                }

                // console.log(bulkItem)
                // console.log('\n\n');
                this.selectedItemsList.push(bulkItem);
                this.expenseList.push(bulkItem);
                // console.log(this.selectedItemsList);
            } else if (!item.CompanyPaid && item?.isSelfDriveCabRequest == 2 && !item.isEmptyTrip) {
                let newModel = new MileageBulkItemModel();
                newModel.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
                newModel.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
                newModel.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
                newModel.expConvRate = 1;
                newModel.exchangeRate = 1;
                newModel.showOptions = (this.userInfo.showAdvanceOptions && this.userInfo.viewAdvanceOptions) ? true : false;
                newModel.showStateList = false;
                newModel.loaderBoolean = false;
                newModel.bulkImgUpload = false;
                newModel.vehicalList = [];
                newModel.expenseAttachments = [];
                newModel.checkPointsList = [];
                newModel.categoryRuleLimitAmountWarning = false;
                newModel.categoryLimitAmountWarning = false;
                newModel.categoryLimitReceiptWarning = false;
                newModel.limitType = 1;
                newModel.limitValue = 0;
                newModel.violationType = 1;
                newModel.taxCodeId = '';
                newModel.monthlyDataList = [];
                newModel.bandId = '';
                newModel.departmentId = '';
                newModel.costCenterId = '';
                newModel.projectCodeId = '';
                newModel.bandName = '';
                newModel.departmentName = '';
                newModel.costCenterName = '';
                newModel.projectCodeName = '';
                newModel.showAdditionalFields = false;
                newModel.categoryCode = '';
                newModel.distance = item.distance;
                newModel.description = item.tripName;
                newModel.expensedDate = item?.tripStartDate;
                newModel.location = item?.pickupStateName || null;
                newModel.isSelfDriveCabRequest = item.isSelfDriveCabRequest || null;
                newModel.distance = item.distance || null;
                newModel.location = item?.pickupStateName || null;
                newModel.isSelected = true;
                newModel.categoryType = 2;
                newModel.expenseType = 1;
                newModel.status = 1;
                newModel.originalAmount = Number(item.tripCost);
                // ONEEEE
                let invoices = [];
                item.invoiceList?.forEach(invoice => {
                    if (invoice?.invoiceUrl) {
                        const urlParts = invoice?.invoiceUrl?.split('/');
                        const fileName = urlParts[urlParts?.length - 1];
                        invoices.push({
                            fileName: fileName,
                            url: invoice.invoiceUrl,
                            content: null,
                            attachmentType: "1",
                        });
                    }
                });
                newModel.expenseAttachments = invoices || [];

                this.selectedItemsList.push(newModel);
                this.expenseList.push(newModel);
            }
        })
    }

    addCards(cards) {
        this.overallClaimType = 3;
        this.showAddNewButtons();
        // this.backToPage = 'mycards';
        this.selectedItemsList = [];
        this.expenseList = [];
        this.navInfoData?.mycards?.forEach((item,index) => {
            let bulkItem = new BulkItemModel();
             bulkItem.categoryName = item?.categoryName;
             bulkItem.merchantName = item?.merchantName;
             bulkItem.merchantCode = item?.merchantCode;
             bulkItem.taxAmount = item?.taxAmount;
             bulkItem.taxPercentage = item?.taxPercentage;
             bulkItem.taxNumber = item?.taxNumber
             bulkItem.categoryName = "";
             bulkItem.merchantName = item?.merchantName;
             bulkItem.categoryType = '';
             bulkItem.categoryId = '';
             // this.datechangeService.convertCalendarToAPI(e.target.value)
             bulkItem.expensedDate = item?.expensedDate;
             bulkItem.cardTrasactionId = item?.cardTrasactionId;
             bulkItem.description = item?.description;
             bulkItem.invoiceNumber = item?.invoiceNumber;
             bulkItem.taxNumber = item?.taxNumber;
             bulkItem.location = item?.spentLocation;
             bulkItem.currencyCode = this.userInfo.baseCurrency.currencyCode;
             bulkItem.originalCurrencyId = this.userInfo.baseCurrency.currencyId;
             bulkItem.originalCurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
             bulkItem.expConvRate = 1;
             bulkItem.paidBy = 1;
             bulkItem.paidByName = 'Corporate card';
             bulkItem.bandId = this.userInfo?.bandId || null;
             bulkItem.bandName = this.userInfo?.bandName || null;
             bulkItem.departmentId = this.userInfo?.departmentId || null;
             bulkItem.departmentName = this.userInfo?.departmentName || null;
             bulkItem.costCenterId = this.userInfo?.costCenterId || null;
             bulkItem.costCenterName = this.userInfo?.costCenterName || null;
             bulkItem.designationId = this.userInfo?.designationId || null;
             bulkItem.designationName = this.userInfo?.designationName || null;
             bulkItem.projectCodeId = this.userInfo?.projectCodeId || null;
             bulkItem.projectCodeName = this.userInfo?.projectCodeName || null;
             bulkItem.isSelected = true;
             bulkItem.categoryType = 1;
             bulkItem.expenseType = 1;
             bulkItem.status = 1;
             bulkItem.amount = item.amount;
             bulkItem.originalAmount = Number(item?.amount);
             this.navInfoData.expenseList.push(bulkItem);
             this.selectedItemsList.push(bulkItem);
             this.expenseList.push(bulkItem);
        });
    }


    strToNum(str) {
        return parseFloat(str?.toString().replace(/,/g, '') || 0);
    }

    disableClaimChange() {
        let disable = false;
        this.selectedItemsList?.forEach(item => {
            if(item?.expenseId && disable == false) {
                disable = true;
            }
        });
        return disable;
    }

    isTripProofAvailable(index) {
        let invoiceUrlCount = 0;
        this.claimTaggedTripList[index]?.tripServiceAttachments?.forEach(invoice => {
            if (invoice.invoiceUrl != null) {
                invoiceUrlCount++;
            }
        });
        return invoiceUrlCount > 0 ? true : false;
    }

    @ViewChild("futureDate", { static: false }) private futureDateTemp;
    isFutureDates() {
        let claimStartDate = this.reportModel.claimStartDate;
        let claimEndDate = this.reportModel.claimEndDate;
        if (!moment.utc(claimStartDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(claimStartDate) || this.checkDateFormat(claimStartDate)) {
            claimStartDate = moment(claimStartDate).format('DD-MM-YYYY');
        }
        if (!moment.utc(claimEndDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(claimEndDate) || this.checkDateFormat(claimEndDate)) {
            claimEndDate = moment(claimEndDate).format('DD-MM-YYYY');
        }
        let isFutureData = false;
        if(moment(claimStartDate, 'DD-MM-YYYY').isAfter(moment()) || moment(claimEndDate, 'DD-MM-YYYY').isAfter(moment())) {
            isFutureData = true;
        } else {
            isFutureData = false;
        }
        return isFutureData;
    }

    checkDateFormat(date) {
        let cleanedDate = date?.toString().replace(/\(.*\)/, '').trim();
        return (!moment.utc(cleanedDate, 'DD-MM-YYYY', true).isValid() && !moment(cleanedDate, 'ddd MMM DD YYYY HH:mm:ss ZZ', true).isValid())
    }

    dateBeforeAllowedDate(date) {
        let allowedDate = moment(this.userInfo?.allowedExpenseDate, 'MM-DD-YYYY').format('Do MMM, YYYY');
    
        if (!moment.utc(date, 'DD-MM-YYYY', true).isValid() || moment.isMoment(date) || this.checkDateFormat(date)) {
            date = moment(date).format('Do MMM, YYYY');
        } else if (moment.utc(date, 'DD-MM-YYYY', true).isValid()) {
            date = moment(date, 'DD-MM-YYYY').format('Do MMM, YYYY');
        }
    
        if (moment(date, 'Do MMM, YYYY').isBefore(moment(allowedDate, 'Do MMM, YYYY'))) {
            this.dTempHeader = 'Date Restriction';
            this.dTempBody = `You can't create the claim before ${allowedDate}.`;
            const config = {
                ignoreBackdropClick: false,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
            return true;
        } else {
            return false;
        }
    }

    showAddNewExpense: boolean = true;
    showAddNewMileage: boolean = true;
    showAddNewPerDiem: boolean = true;
    showAddNewCard: boolean = true;
    showAddNewButtons() {
        this.showAddNewExpense = this.showExpenseType('1');
        this.showAddNewMileage = this.showExpenseType('2');
        this.showAddNewPerDiem = this.showExpenseType('3');
        this.showAddNewCard = this.showExpenseType('4');
    }

    showExpenseType(enableFor) {
        const claimTypeMap = {
            1: { enableKey: 'enableNonTravelExpenseType', typeIdsKey: 'nonTravelExpenseTypeIds' },
            2: { enableKey: 'enableTravelExpenseType', typeIdsKey: 'travelExpenseTypeIds' },
            3: { enableKey: 'enableCardExpenseType', typeIdsKey: 'cardExpenseTypeIds' },
        };
    
        const claimType = claimTypeMap[this.overallClaimType];
        if (claimType && this.userInfo?.[claimType.enableKey] && this.userInfo[claimType.typeIdsKey]?.length > 0) {
            return this.userInfo[claimType.typeIdsKey].includes(enableFor);
        }
        return true;
    }

    showStayType() {
        const index = this.claimTaggedTripList?.findIndex(t => t.productId == 2 && t.tripTravelType);
        return index >= 0;
    }

    getTravelType(index) {
        let stayType;
        if(index >= 0 && this.claimTaggedTripList[index].productId == 2) {
            const tripTravelType = this.claimTaggedTripList[index]?.tripTravelType;
            switch (tripTravelType) {
                case 1:
                    stayType = 'Hotel (Company Booked)';
                    break;
                case 2:
                    stayType = 'Hotel (Self Booked)';
                    break;
                case 3:
                    stayType = 'Stay with Family & Friends';
                    break;
                default:
                    stayType = null;
                    break;
            }
        }

        return stayType;
    }

    linkedToAdvanceChange(isLinked) {
        this.reportModel.isLinkedToAdvancePayment = isLinked;
        this.getAdvanceLinkSummary();
    }

    advanceLinkSummary: any;
    advanceRefList: any = [];
    getAdvanceLinkSummary() {
        if(!this.reportModel?.claimId) {
            return;
        }

        let expenseList = [];
        this.selectedItemsList?.forEach(item => {
            expenseList.push({
                "expenseId": item.expenseId,
                "amount": item.unformattedOriginalAmount,
                "originalCurrencyId": item.originalCurrencyId,
            })
        });

        let obj = {
            "claimId": this.reportModel?.claimId,
            "nonTravelAdvanceRefId": this.reportModel?.nonTravelAdvanceRefId,
            "claimGUID": this.reportModel?.claimGUID,
            "approvedAmount": null,
            "isLinkedToAdvance": this.reportModel?.isLinkedToAdvancePayment,
            "expenseList": expenseList
        }

        this.reportsService.getAdvanceLinkSummary(obj).subscribe((res) => {
            if(!res?.isError && res?.payload) {
                this.advanceLinkSummary = res.payload;
                this.advanceRefList = res?.payload?.advances || [];
                if(res?.payload?.defaultAdavnceId && res?.payload?.defaultAdavnceCode) {
                    this.addReport.controls.advanceRefCode.patchValue(res?.payload?.defaultAdavnceCode);
                    this.reportModel.nonTravelAdvanceRefId = res?.payload?.defaultAdavnceId;
                }
            }
        });
    }

  zoom = 1.0;
  zoomIn() {
    this.zoom += 0.25;
  }

  zoomOut() {
    if (this.zoom > 0.25) {
      this.zoom -= 0.25;
    }
  }

    advanceRefChange(event: any) {
        this.addReport.controls.advanceRefCode.patchValue(event);
        this.reportModel.nonTravelAdvanceRefId = event.advanceId;
        this.getAdvanceLinkSummary();
    }
}


