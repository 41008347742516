import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-add-perdiem-types',
  templateUrl: './add-perdiem-types.component.html',
  styleUrls: ['./add-perdiem-types.component.scss'],
})
export class AddPerdiemTypesComponent implements OnInit {
  perDiemTypeForm: FormGroup;
  userInfo: any;
  selectedLegalEntity: any;
  perDiemTypeId: any;
  perDiemTypeGUID: any;

  constructor(
    private fb: FormBuilder, private navCtrl: NavController,
    private toastController: ToastController,
    private settingService: SettingsService,
    private translate: TranslateService,
    public toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.perDiemTypeForm = this.fb.group({
      perDiemTypeName: [null, Validators.required],
      isPerHourCalculation: [false],
      isAttachmentMandatory: [false]
    });
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    console.log(this.activatedRoute.snapshot.params);
    this.perDiemTypeId = this.activatedRoute.snapshot.params["id"] || null;
    this.perDiemTypeGUID = this.activatedRoute.snapshot.params["guid"] || null;
    if(this.perDiemTypeId && this.perDiemTypeGUID) {
      this.getPerDiemType();
    }
  }

  get f() {
    return this.perDiemTypeForm.controls;
  }

  getPerDiemType() {
    let obj = {
      perDiemTypeId: this.perDiemTypeId,
      perDiemTypeGUID: this.perDiemTypeGUID,
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getPerDiemType(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.perDiemTypeForm.setValue({
          perDiemTypeName: res?.payload?.perDiemTypeName,
          isPerHourCalculation: res?.payload?.isPerHourCalculation,
          isAttachmentMandatory: res?.payload?.isAttachmentMandatory
        })
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  submitted = false;
  savingPdType = false;
  async onSubmit() {
    if (this.perDiemTypeForm.invalid) {
      this.perDiemTypeForm.markAllAsTouched();
      return;
    }

    this.submitted = true;
    this.savingPdType = true;

    let obj = {
      perDiemTypeId: this.perDiemTypeId,
      perDiemTypeGUID: this.perDiemTypeGUID,
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      perDiemTypeName: this.perDiemTypeForm.value.perDiemTypeName,
      isPerHourCalculation: this.perDiemTypeForm.value.isPerHourCalculation,
      isAttachmentMandatory: this.perDiemTypeForm.value.isAttachmentMandatory
    }

    this.settingService.createPerDiemType(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.savingPdType = false;
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.goBack();
      } else if (res.isError == true) {
        this.savingPdType = false;
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    }, err => {
      this.savingPdType = false;
    })
  }

  resetForm() {
    this.perDiemTypeForm.reset({
      autoCalculateDistance: true,
      mandatoryLocations: false
    });
  }

  goBack() {
    this.navCtrl.back();
  }

}
